import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { Article } from "src/app/models/strapi/article";
import { StrapiService } from "src/app/services/strapi.service";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-vendor-card",
	templateUrl: "./vendor-card.component.html",
	styleUrls: ["./vendor-card.component.scss"],
})
export class VendorCardComponent extends AutoUnsubscribeMixin() implements OnInit {
	@Input() title: string;
	@Input() articles: Array<Article>;
	@Input() showInteractions: boolean = false;
	@Input() cssClass: string;

	private userId: number;
	public isOpen: boolean = false;
	
	constructor(private strapiService: StrapiService, private store: Store<IAppState>) {super();}

	ngOnInit(): void {
		this.store.select(selectUser).pipe(takeUntil(this.destroy$)).subscribe(u => this.userId = u.userId)
		if (this.showInteractions) {
			this.strapiService.getArticleInteractionCountsMultiple(this.articles.map(a => a.id), this.userId).subscribe((resp:any) => {
				resp.forEach(a => {
					let art = this.articles.find(i => i.id == a.id);
					art.commentCount = a.commentCount;
					art.userCommented = a.userCommented;
					art.likeCount = a.likeCount;
					art.userLiked = a.userLiked;
				})
			});
		}
	}
	toggleLike(article) {
		if(article.userLiked) {
			this.strapiService.unlike(article.id, 'article', this.userId).subscribe(() => {
				article.likeCount -= 1;
				article.userLiked = false;
			})
		} else {
			this.strapiService.like(article.id, 'article', this.userId).subscribe(() => {
				article.likeCount += 1;
				article.userLiked = true;
			})
		}
	}
}
