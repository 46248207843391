import { Component, OnInit, Input } from '@angular/core';
import { Article } from 'src/app/models/strapi/article';

@Component({
  selector: 'app-newsfeed-article-listing',
  templateUrl: './newsfeed-article-listing.component.html',
  styleUrls: ['./newsfeed-article-listing.component.scss']
})
export class NewsfeedArticleListingComponent implements OnInit {

	@Input() articles: Array<Article>
	@Input() showNewsletterOptions: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
