import { Product } from "./product";


export class Special {
    specialId: number;
    title: string;
    description: string;
    startDate: Date;
    endDate: Date;
    allowMultipleUse: boolean;
    enterDate: Date;
    threshold: number;
    meetThresholdWithAnyProduct: boolean;
    groupBy: string;
    groupByMin: number;
    companyId: number | null;
    specialRequirements: SpecialRequirement[];
    specialRewards: SpecialReward[];

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class SpecialRequirement {
    specialRequirementId: number;
    specialId: number;
    quantity: number;
	howManyMet: number;
    specialRequirementProducts: SpecialRequirementProduct[];
}

export class SpecialReward {
    specialRewardId: number;
    specialId: number;
    specialRewardTypeId: number;
    description: string;
    amount: number;
    percentOrDollar: string;
    quantity: number;
    specialRewardProducts: SpecialRewardProduct[];
}

export class SpecialRequirementProduct {
    specialRequirementProductId: number;
    specialRequirementId: number;
    productId: number;
	quantityInCart: number;
	cartItemId: number;
	product: Product;
}

export class SpecialRewardProduct {
    specialRewardProductId: number;
    specialRewardId: number;
    productId: number;
	product: Product;
}

export class AppliedSpecial {
    cartItemId: number;
	details: Array<AppliedSpecialDetail>;
    isComplete: boolean;
}
export class AppliedSpecialDetail {
	quantity: number;
    specialPrice: number;
    special: SpecialSummary;	
}

export class SpecialSummary {
	rewardDescription: string;
	specialId: number;
	specialName: string;
}