import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { ProUser } from "src/app/models/pro-user";
import { Article } from "src/app/models/strapi/article";
import { Newsfeed } from "src/app/models/strapi/newsfeed";
import { StrapiService } from "src/app/services/strapi.service";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";
import { Permissions } from "src/app/modules/controls/enums/permissions";


@Component({
	selector: "app-newsfeed",
	templateUrl: "./newsfeed.component.html",
	styleUrls: ["./newsfeed.component.scss"],
})
export class NewsfeedComponent extends AutoUnsubscribeMixin() implements OnInit {

  public searchTerm: string;
	public articles: Array<Article>;
	public fullArticleList: Array<Article>;
	public recentArticles: Array<Article>;
	public popularArticles: Array<Article>;
	public featuredArticles: Array<Article>;
	public allArticles: Array<Article>;
	public mainArticle: Article;
	private user: ProUser;
	public tagList = ['newsletter', 'photography', 'gadgets'];
  public Permissions = Permissions;

	private RECENT_COUNT = 3;
	private FEATURED_COUNT = 3;
	private POPULAR_COUNT = 2;
	private ALL_COUNT = 5;

  constructor(private strapiService: StrapiService, private store: Store<IAppState>, private router: Router) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(selectUser)
			.pipe(takeUntil(this.destroy$))
			.subscribe((u) => (this.user = u));
		this.getArticles();
	}


	getArticles() {
		this.strapiService.getArticles().subscribe(resp => {
			this.fullArticleList = resp;  //saving a copy of everything, so we can re-assign on window resize
			this.breakOutArticles();
		})
	}

	breakOutArticles() {
		this.articles = this.fullArticleList;
		this.mainArticle = this.articles.sort(this.sortByFeatured).splice(0,1)[0];
		this.recentArticles = this.articles.sort(this.sortByDate).splice(0, this.RECENT_COUNT);
		this.featuredArticles = this.articles.sort(this.sortByFeatured).splice(0, this.FEATURED_COUNT);
		this.popularArticles = this.articles.sort(this.sortByViews).splice(0, this.POPULAR_COUNT);
		this.allArticles = this.articles.sort(this.sortByDate).splice(0, this.ALL_COUNT);

		this.mainArticle.badge = "Featured";
		this.popularArticles = this.popularArticles.map(a => ({ ...a, badge: 'Popular' }));
		//this.recentArticles = this.recentArticles.map(a => ({ ...a, badge: 'Recent' }));
		this.featuredArticles = this.featuredArticles.map(a => ({ ...a, badge: 'Featured' }))
	}

	sortByDate(a,b) {
		if(a.postDate < b.postDate) { return 1; }
		if(a.postDate > b.postDate) { return -1; }
		return 0;
	}
	sortByViews(a,b) {
		if(a.views < b.views) { return 1; }
		if(a.views > b.views) { return -1; }
		return 0;
	}
	sortByFeatured(a:Article,b:Article) {
		if(a.articleType == 'Feature' && b.articleType != 'Feature') { return -1; }
		if(a.articleType != 'Feature' && b.articleType == 'Feature') { return 1; }
		if(a.articleType == 'Feature' && b.articleType == 'Feature') {
			if(a.postDate < b.postDate) { return 1; }
			if(a.postDate > b.postDate) { return -1; }
		}
		return 0;
	}

	search() {
		this.router.navigate(['/newsfeed', 'search', this.searchTerm])
	}
}
