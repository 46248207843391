
<p>This return is for the following items only</p>
<div class="items">
	<div class="row header">
		<div class="id">ID</div>
		<div class="product">Product</div>
		<div class="quantity">Quantity</div>
		<div class="defect-type">Defect Type</div>
		<div class="description">Description</div>
	</div>
	<div class="row" *ngFor="let item of items">
		<div class="id">{{item.id}}</div>
		<div class="product">{{item.productCode}} - {{item.productDescription}}</div>
		<div class="quantity">{{item.quantity}}</div>
		<div class="defect-type">{{item.returnTypeReadable}}</div>
		<div class="description">{{item.errorDescription}}</div>
	</div>
</div>
