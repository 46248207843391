import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { map, switchMap } from "rxjs/operators"
import jwt_decode from "jwt-decode";
import { DataService } from "src/app/services/data.service"
import { GetEzPayStatus, Login, LogInAs, LogInAsSuccess, LoginSuccess, SetEzPayStatus, UserActionTypes } from "../actions/user.actions"
import { IUserState } from "../state/user.state"
import { ErrorMessageSet } from "../actions/site.actions";
import { ProUser } from "src/app/models/pro-user";
import { SetWarehouseSearchCriteria, SetWarehouseSearchResults } from "../actions/search.actions";


@Injectable()
export class UserEffects {
	
	constructor(private actions$:Actions, private store$:Store<IUserState>, private dataService:DataService) {}

	login$ = createEffect(() => 
		this.actions$.pipe(
			ofType(UserActionTypes.LOGIN),
			switchMap((action: Login) => {
				return this.dataService.login(action.username, action.password).pipe(
					map((resp: any) => {
						if(resp.error) {
							return new ErrorMessageSet(resp.error);
						}
						return new LoginSuccess(resp.token, new ProUser(jwt_decode(resp.token)))
					})
				)
			})
		)
	)

	getEzPayStatus$ = createEffect(() => 
			this.actions$.pipe(
				ofType(UserActionTypes.GET_EZPAY_STATUS),
				switchMap((action: GetEzPayStatus) => {
					return this.dataService.getEzPayStatus().pipe(
						map((resp: any) => {
							if(resp.error) {
								return new ErrorMessageSet(resp.error);
							}
							return new SetEzPayStatus(resp);
						})
					)
				})
			)
	)
	
	logInAs$ = createEffect(() => 
		this.actions$.pipe(
			ofType(UserActionTypes.LOG_IN_AS),
			switchMap((action: LogInAs) => {
				return this.dataService.logInAs(action.userId).pipe(
					map((resp: any) => {
						if(resp.error) {
							return new ErrorMessageSet(resp.error);
						}
						return new LogInAsSuccess(resp.token, new ProUser(jwt_decode(resp.token)))
					})
				)
			})
		)
	)

	logout$ = createEffect(() => 
		this.actions$.pipe(
			ofType(UserActionTypes.LOGOUT),
			switchMap((action: LogInAs) => {
				return [new SetWarehouseSearchCriteria(null), new SetWarehouseSearchResults(null)]
			})
		)
	)

}	


		