
export class DashboardHeaderButton {
	public text: string;
	public icon: string;
	public cssClass: string;
	public cssHoverClass: string;
	public callback: any;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}