<div class="rebates">
	<div class="current rebates-container" [ngClass]="{active: currentRebatesVisible}">
		<div class="section-title" (click)="currentRebatesVisible = !currentRebatesVisible"><span>Current Instant Rebates</span></div>
		<div class="rebates">
			<p *ngIf="instantRebateGroups.current.length == 0">No Current Instant Rebates at this time</p>
			<app-landing-rebate-tile *ngFor="let rebate of instantRebateGroups.current" [rebate]="rebate"></app-landing-rebate-tile>
			<div class="view-all-link" *ngIf="instantRebateGroups.current.length > instantRebateLimit">View All</div>
		</div>
	</div>

	<div class="redeemable rebates-container" [ngClass]="{active: redeemableRebatesVisible}">
		<div class="section-title" (click)="redeemableRebatesVisible = !redeemableRebatesVisible"><span>Redeemable Instant Rebates</span></div>
		<div class="rebates">
			<p *ngIf="instantRebateGroups.redeemable.length == 0">No Redeemable Instant Rebates at this time</p>
			<app-landing-rebate-tile *ngFor="let rebate of instantRebateGroups.redeemable" [rebate]="rebate"></app-landing-rebate-tile>
			<div class="view-all-link" *ngIf="instantRebateGroups.redeemable.length > instantRebateLimit">View All</div>
		</div>
	</div>	

	<div class="future rebates-container" [ngClass]="{active: futureRebatesVisible}">
		<div class="section-title" (click)="futureRebatesVisible = !futureRebatesVisible"><span>Upcoming Instant Rebates</span></div>
		<div class="rebates">
			<p *ngIf="instantRebateGroups.future.length == 0">No Upcoming Instant Rebates at this time</p>
			<app-landing-rebate-tile *ngFor="let rebate of instantRebateGroups.future" [rebate]="rebate"></app-landing-rebate-tile>
			<div class="view-all-link" *ngIf="instantRebateGroups.future.length > instantRebateLimit">View All</div>
		</div>
	</div>	
</div>
