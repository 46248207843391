import { Component, Input, Output, OnInit } from "@angular/core";
import { Subject } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { StolenGoodsReport, StolenGoodsReportProduct } from "src/app/models/dashboard/stolen-goods-report";
import { DataService } from "src/app/services/data.service";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/state/app.state";
import { ErrorMessageSet, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { DatePipe } from "@angular/common";

@Component({
	selector: "app-stolen-goods-form",
	templateUrl: "./stolen-goods-form.component.html",
	styleUrls: ["./stolen-goods-form.component.scss"],
})
export class StolenGoodsFormComponent implements OnInit {

	public incidentTypes = [
		{ label: 'Robbery', value: 1 },
		{ label: 'Fraud', value: 2 },
	];
	public form: UntypedFormGroup;

	@Input() stolenGoodsReport: StolenGoodsReport;
	@Output() onReportSaved: Subject<StolenGoodsReport> = new Subject();
	constructor(private fb: UntypedFormBuilder, private dataService: DataService, private store: Store<IAppState>, private datePipe: DatePipe) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			storeName: [ this.stolenGoodsReport.storeName, Validators.required ],
			contact: [ this.stolenGoodsReport.contact, Validators.required ],
			phone: [ this.stolenGoodsReport.phone, Validators.required ],
			city: [ this.stolenGoodsReport.city, Validators.required ],
			state: [ this.stolenGoodsReport.state, Validators.required ],
			dateTheft: [ this.stolenGoodsReport.dateTheft, Validators.required ],
            incidentType: [this.stolenGoodsReport.incidentType, Validators.required],
            notes: [this.stolenGoodsReport.notes, Validators.required],
			products: this.fb.array([])
		});
		this.addNewProduct();
	}

	get reportProducts() {
		return this.form.controls['products'] as UntypedFormArray;
	}
	addNewProduct(product = '', serialNumber = '') {
		const productForm = this.fb.group({
			product: [product, Validators.required],
			serialNumber: [serialNumber, Validators.required]
		})
		this.reportProducts.push(productForm);
	}

	deleteProduct(idx) {
		this.reportProducts.removeAt(idx);
	}
	submit() {
		this.form.markAllAsTouched();
		if(this.form.valid) {
			this.getReportFromForm();
			this.dataService.submitStolenGoodsReport(this.stolenGoodsReport).subscribe((resp:any) => {
				if(resp.success) {
					this.onReportSaved.next(this.stolenGoodsReport);
					this.store.dispatch(new SuccessMessageSet(`Incident report for ${this.stolenGoodsReport.incidentTypeName} on ${this.datePipe.transform(this.stolenGoodsReport.dateTheft, 'MM/dd/yyyy')} saved.`))
				} else {
					this.store.dispatch(new ErrorMessageSet("There was a problem saving your report.  Please try again"))
				}
			})
		}
	}

	getReportFromForm() {
		this.stolenGoodsReport.storeName = this.form.controls["storeName"].value;
		this.stolenGoodsReport.contact = this.form.controls["contact"].value;
		this.stolenGoodsReport.phone = this.form.controls["phone"].value;
		this.stolenGoodsReport.city = this.form.controls["city"].value;
		this.stolenGoodsReport.state = this.form.controls["state"].value;
		this.stolenGoodsReport.dateTheft = this.form.controls["dateTheft"].value;
        this.stolenGoodsReport.incidentType = this.form.controls["incidentType"].value;
        this.stolenGoodsReport.notes = this.form.controls["notes"].value;
		this.stolenGoodsReport.incidentProducts = this.getProductsFromForm();
	}

	getProductsFromForm() {
		let arr = []
		this.reportProducts.controls.forEach(c => arr.push(new StolenGoodsReportProduct({ product: c.value.product, serialNumber: c.value.serialNumber })));
		return arr;
	}
}


