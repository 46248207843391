import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { takeUntil } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { Special } from "src/app/models/shopping/specials";
import { DataService } from "src/app/services/data.service";
import { SpecialDetailsComponent } from "./special-details/special-details.component";

@Component({
	selector: "app-specials",
	templateUrl: "./specials.component.html",
	styleUrls: ["./specials.component.scss"],
	providers: [DialogService],
})
export class SpecialsComponent extends AutoUnsubscribeMixin() implements OnInit {
	public specials: Array<Special>;
	private dialogRef: DynamicDialogRef;
	constructor(private dataService: DataService, private dialogService: DialogService, private route: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		this.dataService.getSpecials().subscribe((r) => {
			this.specials = r;
			this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
				if (params.get("id")) {
					let id = params.get("id");
					this.getDetails(this.specials.find((s) => s.specialId == parseInt(id)));
				}
			});
		});
	}
	getDetails(special) {
		this.dataService.getSpecialDetails(special.specialId).subscribe((d) => {
			this.specials = this.specials.map((s) => (s.specialId == d.specialId ? new Special(d) : s));
			this.dialogRef = this.dialogService.open(SpecialDetailsComponent, {
				data: {
					special: new Special(d),
				},
			});
		});
	}
}
