
export class Category {
	public catId: number;
	public catName: string;
	public parentCatId: number;
	public isActive: boolean;
	public isPublic: boolean;
	public displayOrder: number;
	public salesCopy: string;
	public eGRP: number;
	public type: string;
	public templateId: number;
	public proConsumer: number;
	public imageName: string;

	public children: Array<Category>;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}