
<div class="container">
	<ng-container *ngIf="userReport.pointsUser">
		<header>
			<h1>{{userReport.pointsUser.firstName}} {{userReport.pointsUser.lastName}}</h1>
			<ul>
				<li>
					<span class="dt">Email</span>
					<span class="dd">{{userReport.pointsUser.email}}</span>
				</li>
				<li>
					<span class="dt">Points</span>
					<span class="dd">{{userReport.pointsUser.points}}</span>
				</li>
				<li>
					<span class="dt">User Name</span>
					<span class="dd">{{userReport.pointsUser.userName}}</span>
				</li>
				<li>
					<span class="dt">Last Login</span>
					<span class="dd">{{userReport.pointsUser.lastLogin | date: 'MMMM d, yyyy'}}</span>
				</li>
			</ul>
		</header>
		<main>
			<div class="row header">
				<div class="caption">Caption</div>
				<div class="available">Points Available</div>
				<div class="earned">Points Earned</div>
				<div class="date">Earned On</div>
			</div>
			<div class="row" *ngFor="let info of userReport.pointsUserReport">
				<div class="caption">{{info.caption}}</div>
				<div class="available">{{info.pointsAvailable}}</div>
				<div class="earned">{{info.pointsEarned}}</div>
				<div class="date">{{info.earnedOn | date: 'MMMM d, yyyy'}}</div>
			</div>
		</main>		
	</ng-container>
	<ng-container *ngIf="!userReport.pointsUser">
		<header><h1>There is no information for this user</h1></header>
	</ng-container>
	<footer>
		<div class="button" (click)="back()">Back to Overview</div>
	</footer>
</div>