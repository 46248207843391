import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DealersComponent } from "./components/dealers/dealers.component";
import { LandingComponent } from "./components/landing/landing.component";
import { NoAccessComponent } from "./components/no-access/no-access.component";
import { RootComponent } from "./components/root/root.component";
import { SearchComponent } from "./components/search/search.component";
import { StaffComponent } from "./components/staff/staff.component";
import { TemplateComponent } from "./components/template/template.component";
import { AuthGuard } from "./guards/auth.guard";
import { PermissionGuard } from "./guards/permission.guard";
import { ForgotPasswordComponent } from "./modules/auth/components/forgot-password/forgot-password.component";
import { LoginComponent } from "./modules/auth/components/login/login.component";
import { ResetPasswordComponent } from "./modules/auth/components/reset-password/reset-password.component";
import { ArticleComponent } from "./modules/cms/components/article/article.component";
import { NewsfeedFilteredComponent } from "./modules/cms/components/newsfeed-filtered/newsfeed-filtered.component";
import { NewsfeedComponent } from "./modules/cms/components/newsfeed/newsfeed.component";
import { NewsletterComponent } from "./modules/cms/components/newsletter/newsletter.component";
import { VendorComponent } from "./modules/cms/components/vendor/vendor.component";
import { VendorsComponent } from "./modules/cms/components/vendors/vendors.component";
import { Permissions } from "./modules/controls/enums/permissions";
import { AccountDataComponent } from "./modules/dashboard/components/account-data/account-data.component";
import { DashboardTemplateComponent } from "./modules/dashboard/components/dashboard-template/dashboard-template.component";
import { EzPayComponent } from "./modules/dashboard/components/ez-pay/ez-pay.component";
import { InstantRebatesComponent } from "./modules/dashboard/components/instant-rebates/instant-rebates.component";
import { InvoiceHistoryComponent } from "./modules/dashboard/components/invoice-history/invoice-history.component";
import { InvoicesComponent } from "./modules/dashboard/components/invoices/invoices.component";
import { LogInAsComponent } from "./modules/dashboard/components/log-in-as/log-in-as.component";
import { MostFrequentlyBoughtComponent } from "./modules/dashboard/components/most-frequently-bought/most-frequently-bought.component";
import { OrderHistoryPrintComponent } from "./modules/dashboard/components/order-history/order-history-print/order-history-print.component";
import { OrderHistoryComponent } from "./modules/dashboard/components/order-history/order-history.component";
import { OverviewSearchComponent } from "./modules/dashboard/components/overview/overview-search/overview-search.component";
import { OverviewComponent } from "./modules/dashboard/components/overview/overview.component";
import { PermissionsComponent } from "./modules/dashboard/components/permissions/permissions.component";
import { PointsCompanyComponent } from "./modules/dashboard/components/points-management/points-company/points-company.component";
import { PointsManagementComponent } from "./modules/dashboard/components/points-management/points-management.component";
import { PointsUserComponent } from "./modules/dashboard/components/points-management/points-user/points-user.component";
import { PriceProtectionPrintComponent } from "./modules/dashboard/components/price-protection/price-protection-print/price-protection-print.component";
import { PriceProtectionComponent } from "./modules/dashboard/components/price-protection/price-protection.component";
import { RebateClaimPrintComponent } from "./modules/dashboard/components/rebate-claim/rebate-claim-print/rebate-claim-print.component";
import { RebateClaimComponent } from "./modules/dashboard/components/rebate-claim/rebate-claim.component";
import { SarPrintComponent } from "./modules/dashboard/components/sar/sar-print/sar-print.component";
import { SarRequestComponent } from "./modules/dashboard/components/sar/sar-request/sar-request.component";
import { SarComponent } from "./modules/dashboard/components/sar/sar.component";
import { ShippingErrorsComponent } from "./modules/dashboard/components/shipping-errors/shipping-errors.component";
import { StoreAddressComponent } from "./modules/dashboard/components/store-address/store-address.component";
import { SubscriptionsComponent } from "./modules/dashboard/components/subscriptions/subscriptions.component";
import { UsersComponent } from "./modules/dashboard/components/users/users.component";
import { ForumLandingComponent } from "./modules/forum/components/forum-landing/forum-landing.component";
import { BrandPageComponent } from "./modules/shopping-cart/components/brand-page/brand-page.component";
import { BundlePageComponent } from "./modules/shopping-cart/components/bundle-page/bundle-page.component";
import { CartPageComponent } from "./modules/shopping-cart/components/cart-page/cart-page.component";
import { CategoryPageComponent } from "./modules/shopping-cart/components/category-page/category-page.component";
import { ProductPageComponent } from "./modules/shopping-cart/components/product-page/product-page.component";
import { QuickAddComponent } from "./modules/shopping-cart/components/quick-add/quick-add.component";
import { ReceiptComponent } from "./modules/shopping-cart/components/receipt/receipt.component";
import { SpecialsComponent } from "./modules/shopping-cart/components/specials/specials.component";
import { ThankyouComponent } from "./modules/shopping-cart/components/thankyou/thankyou.component";
import { WarehouseComponent } from "./modules/warehouse/warehouse/warehouse.component";
import { StolenGoodsReportComponent } from "./modules/dashboard/components/stolen-goods-report/stolen-goods-report.component";
import { SellThroughComponent } from "./modules/dashboard/components/sell-through/sell-through.component";
import { PointsApprovalComponent } from "./modules/dashboard/components/points-management/points-approval/points-approval.component";
import { ReportsComponent } from "./modules/dashboard/components/reports/reports.component"
import { HeadtoheadComponent } from "./modules/cms/components/headtohead/headtohead.component";


const routes: Routes = [
	{ path: "", component: RootComponent, children: [
		{ path: "login", component: LoginComponent },
		{ path: "access/login", component: LoginComponent },
		{ path: "forgot-password", component: ForgotPasswordComponent },
		{ path: "reset/:code", component: ResetPasswordComponent },
		{
			path: "",
			component: TemplateComponent,
			children: [
				{ path: "", component: LandingComponent, pathMatch: "full", canActivate: [AuthGuard] },
				{
					path: "shop",
					canActivate: [AuthGuard],
					children: [
						{ path: "category/:id", component: CategoryPageComponent },
						{ path: "category", component: CategoryPageComponent },
						{ path: "product/:id", component: ProductPageComponent },
						{ path: "brand/:id", component: BrandPageComponent },
						{ path: "brand", component: BrandPageComponent },
						{ path: 'quick-add', component: QuickAddComponent },
						{ path: 'thankyou', component: ThankyouComponent },
						{ path: 'bundle/:id', component: BundlePageComponent },
					],
				},
				{ path: "cms", canActivate: [AuthGuard], children: [
				{ path: 'article/:id/:slug', component: ArticleComponent},
				]},
				    { path: 'dashboard', component: DashboardTemplateComponent, children: [
					{ path: 'overview', component: OverviewComponent },
					{ path: 'overview/:term', component: OverviewSearchComponent },
					{ path: 'ezpay', component: EzPayComponent, canActivate: [PermissionGuard], data: { permission: Permissions.Payments } },
					{ path: 'promptpay', component: EzPayComponent, canActivate: [PermissionGuard], data: { permission: Permissions.Payments } },
					{ path: 'ezpay/:term', component: EzPayComponent, canActivate: [PermissionGuard], data: { permission: Permissions.Payments } },
					{ path: 'promptpay/:term', component: EzPayComponent, canActivate: [PermissionGuard], data: { permission: Permissions.Payments } },
					{ path: 'invoices', component: InvoicesComponent, canActivate: [PermissionGuard], data: { permission: Permissions.FinancialData } },
					{ path: 'invoice-history', component: InvoiceHistoryComponent, canActivate: [PermissionGuard], data: { permission: Permissions.FinancialData } },
					{ path: 'invoices/:tab', component: InvoicesComponent, canActivate: [PermissionGuard], data: { permission: Permissions.FinancialData } },
					{ path: 'order-history', component: OrderHistoryComponent, canActivate: [PermissionGuard], data: { permission: Permissions.OrderHistory } },
					{ path: 'most-frequently-bought', component: MostFrequentlyBoughtComponent },
					{ path: 'instant-rebates', component: InstantRebatesComponent },
					{ path: 'instant-rebates/:term', component: InstantRebatesComponent },
					{ path: 'rebate-claim', component: RebateClaimComponent },
					{ path: 'price-protection', component: PriceProtectionComponent },
					{ path: 'sar', component: SarComponent, canActivate: [PermissionGuard], data: { permission: Permissions.SarRead } },
					{ path: 'sar/new', component: SarRequestComponent },
					{ path: 'sell-through', component: SellThroughComponent },
					{ path: 'shipping-errors', component: ShippingErrorsComponent },
					{ path: 'subscriptions', component: SubscriptionsComponent },
					{ path: 'store-address', component: StoreAddressComponent, canActivate: [PermissionGuard], data: { permission: Permissions.AccountManagement } },
					{ path: 'account-data', component: AccountDataComponent, canActivate: [PermissionGuard], data: { permission: Permissions.AccountManagement } },
					{ path: 'users', component: UsersComponent, canActivate: [PermissionGuard], data: { permission: Permissions.AccountManagement } },
					{ path: 'permissions', component: PermissionsComponent, canActivate: [PermissionGuard], data: { permission: Permissions.AccountManagement } },
					{ path: 'permissions/user/:userId', component: PermissionsComponent, canActivate: [PermissionGuard], data: { permission: Permissions.AccountManagement } },
                    { path: 'points-management', component: PointsManagementComponent, canActivate: [PermissionGuard], data: { permission: Permissions.PointsManagement } },
					{ path: 'points/:userId', component: PointsUserComponent, canActivate: [PermissionGuard], data: { permission: Permissions.PointsManagement } },
					{ path: 'points', component: PointsCompanyComponent, canActivate: [PermissionGuard], data: { permission: Permissions.PointsManagement } },
                    { path: 'loginas', component: LogInAsComponent, canActivate: [PermissionGuard], data: { permission: Permissions.ProStaff } },
                    { path: 'stolen-goods-report', component: StolenGoodsReportComponent },
                    { path: 'approval', component: PointsApprovalComponent },
                    { path: 'Report', component: ReportsComponent }
                   
              ]
        },
				{ path: "shop/cart", component: CartPageComponent, canActivate: [AuthGuard, PermissionGuard], data: { permission: Permissions.ShopAccess }  },
				{ path: "forum", component: ForumLandingComponent, canActivate: [AuthGuard, PermissionGuard], data: { permission: Permissions.Forums } },
				{ path: 'vendors', component: VendorsComponent, canActivate: [AuthGuard, PermissionGuard], data: { permission: Permissions.Vendor } },
				{ path: 'vendor/:id', component: VendorComponent, canActivate: [AuthGuard, PermissionGuard], data: { permission: Permissions.Vendor } },

				{ path: 'newsfeed/:filterBy/:term', component: NewsfeedFilteredComponent, canActivate: [AuthGuard] },
				{ path: 'newsfeed/:filterBy', component: NewsfeedFilteredComponent, canActivate: [AuthGuard] },
				{ path: 'newsfeed', component: NewsfeedComponent, canActivate: [AuthGuard] },
				{ path: 'newsletter/:year/:month/:day', component: NewsletterComponent, canActivate: [AuthGuard, PermissionGuard], data: { permission: Permissions.CurrentEvents } },
				{ path: 'newsletter', component: NewsletterComponent, canActivate: [AuthGuard, PermissionGuard], data: { permission: Permissions.CurrentEvents } },


                { path: 'HeadtoHead', component: HeadtoheadComponent, canActivate: [AuthGuard, PermissionGuard], data: { permission: Permissions.CurrentEvents } },


				{ path: 'warehouse', component: WarehouseComponent, canActivate: [AuthGuard] },
				{ path: 'warehouse/new', component: WarehouseComponent, data: { newProductsOnly: true, autoRun: true }},
				{ path: 'warehouse/:type/:id', component: WarehouseComponent, data: { autoRun: true }},




      /*  { path: "product/:id", component: ProductPageComponent },*/

				{ path: 'staff', component: StaffComponent },
				{ path: 'specials', component: SpecialsComponent },
				{ path: 'specials/:id', component: SpecialsComponent},
				{ path: 'search/:query', component: SearchComponent },
				{ path: 'search', component: SearchComponent },
				{ path: 'dealers', component: DealersComponent },
				{ path: 'no-access', component: NoAccessComponent },				 
			],
		},
	]},
	{ path: 'receipt/:orderId', component: ReceiptComponent },
	{ path: 'dashboard/sar/print/:sarId', component: SarPrintComponent, canActivate: [PermissionGuard], data: { permission: Permissions.SarRead } },
	{ path: 'price-protection/print/:orderId', component: PriceProtectionPrintComponent },
	{ path: 'rebate/print/:orderId', component: RebateClaimPrintComponent },
	{ path: 'order/print/:orderId', component: OrderHistoryPrintComponent },
];

@NgModule({
      /*  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: "legacy", onSameUrlNavigation: 'reload' })],*/
          imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })],

	      exports: [RouterModule],
})
export class AppRoutingModule {}
