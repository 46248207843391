import { Action } from "@ngrx/store";
import { ProUser } from "src/app/models/pro-user";


export enum UserActionTypes {
	LOGIN = '[USER] Login',
	LOGIN_SUCCESS = '[USER] Login Success',
	LOGIN_FAILURE = '[USER] Login Failure',
	LOGOUT = '[USER] Logout',
	SET_USER = '[USER] Set User',
	LOG_IN_AS = '[USER] Log In As',
	LOG_IN_AS_SUCCESS = '[USER] Log In As Success',
	GET_EZPAY_STATUS = '[USER] Get EzPay Status',
	SET_EZPAY_STATUS = '[USER] Set EzPay Status',
}

export class Login implements Action {
	public readonly type = UserActionTypes.LOGIN;
	constructor(public username: string, public password: string) {}
}
export class LoginSuccess implements Action {
	public readonly type = UserActionTypes.LOGIN_SUCCESS;
	constructor(public token: string, public user: ProUser) {}
}
export class LoginFailure implements Action {
	public readonly type = UserActionTypes.LOGIN_FAILURE;
	constructor() {}
}
export class Logout implements Action {
	public readonly type = UserActionTypes.LOGOUT;
	constructor() {}
}
export class SetUser implements Action {
	public readonly type = UserActionTypes.SET_USER;
	constructor(public user: ProUser) {}
}
export class LogInAs implements Action {
	public readonly type = UserActionTypes.LOG_IN_AS;
	constructor(public userId: number) {}
}
export class LogInAsSuccess implements Action {
	public readonly type = UserActionTypes.LOG_IN_AS_SUCCESS;
	constructor(public token: string, public user: ProUser) {}
}
export class GetEzPayStatus implements Action {
	public readonly type = UserActionTypes.GET_EZPAY_STATUS;
	constructor() {}
}
export class SetEzPayStatus implements Action {
	public readonly type = UserActionTypes.SET_EZPAY_STATUS;
	constructor(public status: number) {}
}


export type UserActions = 
	Login |
	LoginSuccess |
	LoginFailure |
	Logout |
	SetUser |
	LogInAs |
	LogInAsSuccess |
	SetEzPayStatus 
	;
