
export class Address {
	public addressId: number;
	public companyId: number;
	public address1: string;
	public address2: string;
	public city: string;
	public state: string;
	public zip: string;
	public country: string;
	public isPrimary: boolean;
	public enterDate: Date;
	public updateDate: Date;
	public LastUpdatedBy: number;
	public isActive: boolean;
	public firstName: string;
	public lastName: string;
	public email: string;
	public phone: string;
	public addressTypeId: ADDRESS_TYPE;
  public companyName: string;
	constructor(o?: any) {
		Object.assign(this,o);
	}
}

export enum ADDRESS_TYPE {
	Undefined = 0,
	Billing = 1,
	Shipping = 2,
	Other = 3,
	Store = 4,
	Other_Ship_To = 5,
	Computyme_Vendor_AP_Address = 6,
	Pro_Vendor_Web_Address = 7,
	Vendor_Contact_Address = 8,
	User_Private = 9
}
