<div class="pane">
	<aside>
		<div class="search-area">
			<input [(ngModel)]="searchTerm" (keyup.enter)="search()">
			<div class="button transparent" (click)="clearSearch()" *ngIf="searchTerm">Clear</div>
			<div class="button" (click)="search()">Search</div>
		</div>
	</aside>

	<div class="tab-list">
		<div class="tab" (click)="setTab('Pay')" [ngClass]="{active: openTab == 'Pay'}">Pay</div>
		<div class="tab" (click)="setTab('Confirmation')" [ngClass]="{active: openTab == 'Confirmation'}">Confirmation</div>
		<div class="tab" (click)="setTab('Reprint')" [ngClass]="{active: openTab == 'Reprint'}">Reprint Receipts</div>
	</div>

	<div class="tab-content">
		<ng-container [ngSwitch]="openTab">
			<div class="head" *ngSwitchCase="'Pay'">
				<ng-container *ngIf="user.userType == 'Member' || user.userType == 'Client'">

					<div class="credit-available">				
						<h3>EZ Pay Savings</h3>
						<div class="item" *ngFor="let item of ezPaySavings"><span class="type">{{item.type}}</span><span class="amount">{{item.amount | currency}}</span></div>
					</div>

					<div class="pay-actions">
						<h3>Pay Actions</h3>
						<div class="pay-button button" (click)="payAll()" [ngClass]="{disabled: !paymentEnabled}"><mat-icon>file_download</mat-icon>Pay All</div>
						<div class="pay-button button" (click)="paySelected()" [ngClass]="{disabled: !paymentEnabled}"><mat-icon>file_download</mat-icon>Pay Selected</div>
						<div class="pay-button button" (click)="reset()" [ngClass]="{disabled: !paymentEnabled}"><mat-icon>file_download</mat-icon>Reset</div>
						<p *ngIf="!paymentEnabled">Payments are disabled between Fridays at 2pm EST and Monday at 9am EST</p>
					</div>

					<div class="selections">
						<h3>Invoice Selection(s)</h3>
						<div class="selection-button button" (click)="selectDue10th()"><mat-icon>folder_open</mat-icon>Due {{dueDate | date: 'MMM dd'}} Only</div>
						<div class="selection-button button" (click)="selectFridayEzPay()"><mat-icon>folder_open</mat-icon>Due {{nextEOWDay | date: 'MMM dd'}} {{ezPayName}}</div>
						<div class="selection-button button" (click)="selectLate()"><mat-icon>folder_open</mat-icon>Late Invoices</div>
					</div>

					<div class="payment-summary">
						<h3>Payment Summary</h3>
						<div class="summary-item"><span>Total $'s Selected:</span><div>{{totalSelected | currency}}&nbsp;</div></div>
						<div class="summary-item"><span>Total Saved Today:</span><div>{{totalSaved | currency}}&nbsp;</div></div>
						<div class="InvoiceDownload" (click)="ConsolidateEZPAY()"> Print All My Billings  </div>

						<div class="status">
							<label>Autopay</label>
							<div class="toggler">
								<input type="checkbox" [(ngModel)]="enrollStatus" (change)="setEnrollStatus()" >
								<div class="track-holder">
									<div class="track">
										<div class="dot"></div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</ng-container>
			</div>
		</ng-container>
		<app-table [data]="data" [tableInfo]="tableInfo" [caller]="this" (onCheckboxChecked)="checkboxChecked($event, true)" (onCheckboxUnchecked)="checkboxChecked($event, false)"></app-table>
	</div>

</div>

