<div class="form">
	<form [formGroup]="form">
		<div class="field">

			<div class="field">
				<label>Role</label>
				<p-multiSelect   optionvalue="1"    [options]="roleOptions" [formControlName]="'roles'" [filter]="false"  placeholder="Select Roles" display="chip"></p-multiSelect>
			</div>

			<label>First Name</label>
			<input pInputText type="text" [formControlName]="'firstName'">
		</div>
		<div class="field">
			<label>Last Name</label>
			<input pInputText type="text" [formControlName]="'lastName'">
		</div>
		<div class="field">
			<label>Phone</label>
			<input pInputText type="text" [formControlName]="'phone'">
		</div>
		<div class="field">
			<label>Email</label>
			<input pInputText type="text" [formControlName]="'email'">
		</div>
		<div class="field">
			<label>Username</label>
			<input pInputText type="text" [formControlName]="'username'">
		</div>
		<div class="field">
			<label>Address</label>
			<p-dropdown [options]="addressOptions" [formControlName]="'addressId'" [autoDisplayFirst]="false"></p-dropdown>
		</div>


			<div class="field">
				<label>Password</label>
				<input pInputText type="text" [formControlName]="'password'">
			</div>



	</form>
	<div class="actions">
		<div class="cancel" (click)="cancel()">Cancel</div>
		<div class="submit" (click)="submit()">{{user.userId > 0 ? 'Edit User' : 'Add User'}}</div>
	</div>
</div>
