import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { User } from "src/app/models/dashboard/user";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardTitle, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-points-management",
	templateUrl: "./points-management.component.html",
	styleUrls: ["./points-management.component.scss"],
})
export class PointsManagementComponent implements OnInit {

	public invitees: string;
  public pointsUsers: Array<User>;

	constructor(private dataService: DataService, private store: Store<IAppState>, private router: Router) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle("PRO Insider Signups"));
		this.getUsers();
	}

	getUsers() {
		this.dataService.getPointsUsers().subscribe(resp => this.pointsUsers = resp.sort(this.sortUsers));
	}
	sendInvites() {
		this.dataService.sendPointsInvites(this.invitees.split('\n')).subscribe((resp:any) => {
			if(resp.success) {
				this.getUsers();
				this.store.dispatch(new SuccessMessageSet("Email Invitation has been sent"));
				this.invitees = '';				
			} else {
				this.store.dispatch(new ErrorMessageSet("Your invitations could not be sent"))
			}

		});
	}
	removeUser(user) {
		this.dataService.deletePointsUser(user.userId).subscribe(() => this.pointsUsers = this.pointsUsers.filter(u => u.userId != user.userId))
	}

	private sortUsers(a,b) {
		if(a.lastName < b.lastName) return -1;
		if(a.lastName > b.lastName) return 1;
		return 0;
	}
}
