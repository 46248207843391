import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderHistoryItem } from 'src/app/models/dashboard/order-history-item';
import { OrderSearchCriteria } from 'src/app/models/dashboard/order-search-criteria';
import { DataService } from 'src/app/services/data.service';
import { SetDashboardTitle } from 'src/app/store/actions/site.actions';
import { IAppState } from 'src/app/store/state/app.state';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

	public orderStatusId: number | '';
	public orderTypeId: number | '';
	public startDate: Date | '';
	public endDate: Date | '';

	public orderHistoryItems: Array<OrderHistoryItem>;
  constructor(private dataService: DataService, private store: Store<IAppState>) { }

  ngOnInit(): void {
	this.store.dispatch(new SetDashboardTitle('Order History'))
	this.refresh();
	//this.search(new OrderSearchCriteria({ term: ' '}))
  }

  refresh(): void {
	let startDateString = '', endDateString = ''
	if(this.startDate) { startDateString = this.startDate.toLocaleDateString(); }
	if(this.endDate) { endDateString = this.endDate.toLocaleDateString(); }
	this.dataService.getOrderHistory(this.orderStatusId, this.orderTypeId, startDateString, endDateString).subscribe(h => this.orderHistoryItems = h.map(i => new OrderHistoryItem(i)))
  }

  search(criteria: OrderSearchCriteria) {
	//if(criteria.term.trim() == '') {
	//	this.refresh();
	//} else {
		this.dataService.searchOrderHistory(criteria).subscribe((h:any) => this.orderHistoryItems = h.map(i => new OrderHistoryItem(i)));
	//}
  }
}
