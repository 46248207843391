import { Component, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Category } from 'src/app/models/shopping/category';

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss']
})
export class CategoryTreeComponent implements OnInit, OnChanges {

	@Output() onCategorySelected: Subject<{category: Category, checked: boolean}> = new Subject();
	@Input() category: Category;
	@Input() showCheckbox: boolean = true;
	@Input() doSelectChildren: Subject<any>
	@Input() doClearAll: Subject<any>;
	@Input() categoriesSelected: Array<number>;
	@ViewChild('checkbox') checkbox: any;
	public checked: boolean

	public callSelectChildren: Subject<any> = new Subject();
	public callClearAll: Subject<any> = new Subject();
	public collapsed: boolean;
  constructor() { }

  ngOnInit(): void {
	  this.collapsed = true; // (this.category.catId !== 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
	if(changes.doSelectChildren?.currentValue) {
	  this.doSelectChildren.asObservable().subscribe(e => {
		this.checked = true;
		this.checkbox.binary = 1;
		this.onCheckboxClicked({checked: true}, this.category);
		this.callSelectChildren.next(null);
	  })		
	}
	if(changes.doClearAll?.currentValue) {
		this.doClearAll.asObservable().subscribe(e => {
			this.checked =false;
			this.onCheckboxClicked({checked: false}, this.category);
			this.callClearAll.next(null);
		})
	}
	if(changes.categoriesSelected?.currentValue) {
		this.checked = changes.categoriesSelected.currentValue.includes(this.category.catId);
	}
	if(changes.category?.currentValue) {
		changes.category.currentValue.children.sort((a,b) => {
			if(a.catName > b.catName) return 1;
			if(a.catName < b.catName) return -1;
			return 0;
		})
	}
  }
  bubbleUpCategorySelected(e) {
	  this.onCategorySelected.next(e);
  }
  
  
  onCheckboxClicked(e, cat:Category) {
	  this.onCategorySelected.next({category: cat, checked: e.checked});
	  if(e.checked) {
		this.callSelectChildren.next(null);
		this.collapsed = false;
	  }
  }


}
