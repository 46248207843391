import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authservice: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // add authorization header with jwt token if available
		if(request.url.indexOf('strapi') == -1 && request.url.indexOf("localhost:1337") == -1) {
			let token = this.authservice.token;
			if (!!token) {
			request = request.clone({
				setHeaders: {
				Authorization: `Bearer ${token}`,
				},
			});
			}			
		}


        return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if(event instanceof HttpResponse) {
					if(event.headers.has("New-Token")) {
						this.authservice.token = event.headers.get("New-Token")
						//sessionStorage.setItem("token", event.headers.get("New-Token"));
					}
				}
			})
		);
  }
}
