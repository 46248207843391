import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ProductImage } from "src/app/models/shopping/product";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-product-images",
	templateUrl: "./product-images.component.html",
	styleUrls: ["./product-images.component.scss"],
})
export class ProductImagesComponent implements OnInit, OnChanges {
	@Input() images: Array<ProductImage>;
	public activeImage: string;
	public environment = environment;

	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes?.images?.currentValue) {
			this.activeImage = changes.images.currentValue[0]?.imageName;
		}
	}

	setActiveImage(imageName: string) {
		this.activeImage = imageName;
	}
}
