export class Order {
    public orderId: number;
    public userId: number;
    public orderTypeId: number;
    public specialId: number;
    public poNumber: string;
    public signature: string;
    public shipToAddressId: number;
    public orderStatusId: number;
    public shippingCost: number;
    public enterDate: Date;
    public approvedDate: Date;
    public autoHold: boolean;
    public holdOverride: boolean;
    public shippingMethod: number;
    public emailAckSent: number;
	public orderItems: Array<OrderItem>
	
	constructor(o?: any) {
		Object.assign(this, o);
	}

	get orderTotal() {
		return this.orderItems ? this.orderItems.reduce((a,b) => a + b.quantity * b.unitCost, 0) : 0;
	}
	get orderType() {
		switch(this.orderTypeId) {
			case 1: return 'Web';
			case 2: return 'Discount Items';
			case 3: return 'Dated';
			case 4: return 'POS Order';
			case 5: return 'Drop Ship';
		}
	}
	get orderStatus() {
		switch(this.orderStatusId) {
			case 1: return 'Open';
			case 3: return 'EDI Error';
			case 5: return 'Approved';
			case 10: return 'Shipped';
			case 15: return 'Rejected';
			case 20: return 'EDI Processed';
			default: return 'Unknown';
		}

	}
}

export class OrderItem {
	public productCode: string;
	public modelName: string;
	public quantity: number;
	public unitCost: number;
	
	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class OrderBundle {
	public orderBundleId: number;
	public orderId: number;
	public bundleId: number;
	public name: string;
	public quantity: number;
	public retail: number;
	public products: Array<BundleProduct>

	
	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class BundleProduct {
	public bundleId: number;
	public bundleProductId: number;
	public priceProportion: number;
	public productCode: string;
	public productId: number;
	public promoPrice: number;
	public quantity: number;
		
	constructor(o?: any) {
		Object.assign(this, o);
	}

}