<div id="login">
	<div class="img" *ngIf="this.photo" [ngStyle]="{'background-image': 'url(' + photo.photo +')'}">
	</div>
	<div class="form">

		<div class="heading temp">
			<div>Sign In</div>
			<!--<div class="logo"><img src="../../../../../assets/images/promaster_logo.png"></div>-->
			<div class="logo-temp">PRO Connection</div>
		</div>

		<div class="field">

			<label for="username">Username</label>

			<input pInputText [(ngModel)]="username" id="username" placeholder="Input your username here" (keydown.enter)="login()">

		</div>

		<div class="field">
			<label for="password">Password</label>
			<p-password [toggleMask]="true" [(ngModel)]="password" id="password" placeholder="Input your password here" [feedback]="false"  (keydown.enter)="login()"></p-password>
			<div class="forgot-link"><a [routerLink]="['/forgot-password']">Forgot password?</a></div>
		</div>

		<div class="field">
			<button (click)="login()">Log In</button>
		</div>
	</div>
	<!--<div class="credit" style="display: none;">-->
	<div class="credit" *ngIf="photo">
		Photo credit:  {{photo.photographer}}
	</div>

</div>



