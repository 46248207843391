<div class="special-viewer" *ngIf="special">
	<div class="requirements" *ngIf="stage == 'Requirements'">
		<h1>Requirements</h1>
		<div class="requirement-bar" *ngIf="special.threshold > 0">
			<span>BUY</span> <div class="req-qty">{{special.threshold | currency}}</div> <span>OF</span> 
		</div>	
		<ng-container *ngFor="let requirement of special.specialRequirements">
			<div class="requirement-bar" *ngIf="special.threshold == 0">
				<span>BUY</span> <div class="req-qty">{{requirement.quantity}}</div> <span>OF</span> 
			</div>	
			<main>
				<div class="req-prods">
					<div class="req-prod" *ngFor="let product of requirement.specialRequirementProducts; let i = index">
						<div class="or"><span *ngIf="i > 0">OR</span></div>
						<div class="code">{{product.product.productCode}}</div>
						<div class="quantity"><input [(ngModel)]="product.quantityInCart"></div>
						<div class="name">{{product.product.modelName}}</div>
						<div class="retail">{{product.product.retail | currency}}</div>
						<div class="add"><button (click)="updateCart(product)">Add to Cart</button></div>
					</div>				
				</div>					
			</main>
	
			<div class="status-bar">
				<div *ngIf="requirement.quantity <= requirement.howManyMet">Congratulations! You qualify for this special</div>
				<div *ngIf="requirement.quantity > requirement.howManyMet">Please add {{requirement.quantity - requirement.howManyMet}} more product{{requirement.quantity - requirement.howManyMet == 1 ? '' : 's'}} to qualify for this special.</div>
			</div>
		</ng-container>
	</div>
	<div class="rewards" *ngIf="stage == 'Rewards'">
		<h1>Rewards</h1>
		<main>
			<ng-container *ngFor="let reward of special.specialRewards">
				<ng-container [ngSwitch]="reward.specialRewardTypeId">
					<ng-container *ngSwitchCase="1"> <!-- Save on this product -->
						<div class="reward-bar">{{reward.description}}</div>
						<div class="rew-prods">
							<div class="rew-prod" *ngFor="let item of getEligibleProducts(reward)">
								{{item.quantity}} unit{{item.quantity == 1 ? '' : 's'}} of {{item.product.modelName}}
							</div>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="2"> <!-- Save on additional purchases of this product -->
						<div class="reward-bar">{{reward.description}}</div>
						<div class="rew-prods">
							<div class="rew-prod" *ngFor="let item of getEligibleProducts(reward)">
								{{item.quantity}} unit{{item.quantity == 1 ? '' : 's'}} of {{item.product.modelName}}
							</div>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="3">
						<ng-container *ngFor="let reward of special.specialRewards">
							<div class="requirement-bar">
								<span>BUY</span> <div class="req-qty">{{reward.quantity}}</div> <span>OF</span> 
							</div>	
							<main>
								<div class="req-prods">
									<div class="req-prod" *ngFor="let product of reward.specialRewardProducts; let i = index">
										<div class="or"><span *ngIf="i > 0">OR</span></div>
										<div class="code">{{product.product.productCode}}</div>
										<div class="quantity"><input [(ngModel)]="product.quantityInCart"></div>
										<div class="name">{{product.product.modelName}}</div>
										<div class="retail">{{product.product.retail | currency}}</div>
										<div class="add"><button (click)="updateCart(product)">Add to Cart</button></div>
									</div>				
								</div>					
							</main>
						</ng-container>
					</ng-container>
					<div class="reward-bar" *ngSwitchCase="4">{{reward.description}}</div>
				</ng-container>
			</ng-container>			
		</main>

	</div>
</div>	
<div class="error" *ngIf="stage == 'Error'">
	<h1>Error</h1>
	<p class="error">There was an error loading the special.</p>
</div>
<div class="actions" *ngIf="special || stage == 'Error'">
	<div class="close" (click)="close()">Close</div>
	<div class="next" (click)="next()" *ngIf="special">Next</div>
</div>
