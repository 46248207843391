<li>
	<div class="category-item">
		<i class="pi" [ngClass]="{'pi-chevron-down': !collapsed, 'pi-chevron-right': collapsed}" (click)="collapsed = !collapsed" *ngIf="category.children?.length > 0"></i>
		<i class="pi" *ngIf="!category.children || category.children.length == 0">&nbsp;</i>
		<p-checkbox (onChange)="onCheckboxClicked($event, category)" [inputId]="'category_checkbox_' + category.catId" [(ngModel)]="checked" #checkbox binary="1"></p-checkbox>
		<label [for]="'category_checkbox_' + category.catId">{{category.catName}}</label>
	</div>
	<ul *ngIf="category.children.length> 0" [ngClass]="{collapsed: collapsed}">
											<ng-container *ngFor="let cat of category.children">
												<app-category-tree
												 [category]="cat"
												 [categoriesSelected]="categoriesSelected"
												 (onCategorySelected)="bubbleUpCategorySelected($event)"
												 [doSelectChildren]="callSelectChildren"
												 [doClearAll]="callClearAll">
												</app-category-tree>
											</ng-container>
	</ul>
</li>
