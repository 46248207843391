import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { PointsUserInfo } from "src/app/models/dashboard/points";
import { DataService } from "src/app/services/data.service";
import { SetDashboardTitle } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-points-user",
	templateUrl: "./points-user.component.html",
	styleUrls: ["./points-user.component.scss"],
})

export class PointsUserComponent implements OnInit {
	public userReport: PointsUserInfo;

	constructor(private route: ActivatedRoute, private dataService: DataService, private store: Store<IAppState>, private router: Router) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle("User Activity"));
		this.route.paramMap.subscribe((params) => {
			if (params.get("userId")) {
				this.dataService.getUserPointsReport(params.get("userId")).subscribe((i) => (this.userReport = i));
			}
		});
	}

	back()  {
		this.router.navigate(['/dashboard', 'points-management'])
	}
}
