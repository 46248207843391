<app-sar-print-section *ngIf="sarSections.CR_PRO?.length > 0" [summary]="sarSummary" [items]="sarSections.CR_PRO" [type]="'CR_PRO'" [title]="'SAR Return Merchandise Authorization'">
	<p>Please print this page out for your records.  A credit will be created and posted to your account once these items are returned.</p>
	<p class="b">Please print this page out and include with your Return shipment to:</p>	
	<address>
		PRO Inc SAR Returns<br>
		240 Long Hill Cross Rd. <br />
		Shelton, CT 06484<br />
	</address>
	<p>You MUST write the following RMA number on the box in bold letters: <b>{{sarPrint.sarId}}</b></p>
</app-sar-print-section>

<app-sar-print-section *ngIf="sarSections.CR?.length > 0" [summary]="sarSummary" [items]="sarSections.CR" [type]="'CR'" [title]="'Credit Advice'">
	<p>Please print this page out for your records.  A credit will be created and posted to your account.</p>	
	<p class="b">You do NOT need to send any product below to the PRO Warehouse:</p>
</app-sar-print-section>

<app-sar-print-section *ngIf="sarSections.RA?.length > 0" [summary]="sarSummary" [items]="sarSections.RA" [type]="'RA'" [title]="'SAR Return Merchandise Authorization'">
	<p>Please print this page out and include with your Return shipment to:</p>
	<address>
		PRO Warehouse Section 6-9<br>
		2950 Turnpike Drive<br/>
		Hatboro, PA 19040<br/>
	</address>
	<p>You MUST write the following RMA number on the box in bold letters: <b>{{sarPrint.sarId}}</b></p>
</app-sar-print-section>

<app-sar-print-section *ngIf="sarSections.MC?.length > 0" [summary]="sarSummary" [items]="sarSections.MC" [type]="'MC'" [title]="'Memory Credit Advice'">
	<p>Please print this page out for your records.  A credit will be created and posted to your account once the items are returned.</p>
	<p class="b">You must send these memory cards directly to the return center via US Mail at the following Address:</p>
	<address>
		Promaster Memory Return Center<br>
		13350 Kirkham Way<br/>
		Poway, CA 92064-7117<br/>
	</address>
	<p>Use ONLY a padded envelope and include a copy of this form.  Returns not received within 30 days will be charged back.</p>
</app-sar-print-section>

<app-sar-print-section *ngIf="sarSections.RE?.length > 0" [summary]="sarSummary" [items]="sarSections.RE" [type]="'RE'" [title]="'Merchandise NOT Processed'">
	<p>Please print this page out for your records.</p>	
	<p class="b">Below merchandise had been removed from your claim and no credit will be issued.</p>
</app-sar-print-section>

<app-sar-print-section *ngIf="sarPrint.status == 'Reject'" [summary]="sarSummary" [items]="sarItems" [type]="'XX'" [title]="'OPEN SAR - ** THIS SAR IS NOT YET APPROVED'">
	<p class="b">THIS SAR IS NOT YET APPROVED - THIS IS FOR YOUR RECORDS ONLY</p>
</app-sar-print-section>

