import { Component, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { ProUser } from "src/app/models/pro-user";
import { Article } from "src/app/models/strapi/article";
import { StrapiService } from "src/app/services/strapi.service";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-interactions",
	templateUrl: "./interactions.component.html",
	styleUrls: ["./interactions.component.scss"],
})
export class InteractionsComponent extends AutoUnsubscribeMixin() implements OnInit {
	@Input() article: Article;
	@Input() cssClass: string;
	@Output() onCommentClicked: Subject<Article> = new Subject();

	private user: ProUser;
	constructor(private strapiService: StrapiService, public store: Store<IAppState>) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(selectUser)
			.pipe(takeUntil(this.destroy$))
			.subscribe((u) => (this.user = u));
	}

	toggleLike() {
		if (this.article.userLiked) {
			this.strapiService.unlike(this.article.id, "article", this.user.userId).subscribe(() => {
				this.article.likeCount -= 1;
				this.article.userLiked = false;
			});
		} else {
			this.strapiService.like(this.article.id, "article", this.user.userId).subscribe(() => {
				this.article.likeCount += 1;
				this.article.userLiked = true;
			});
		}
	}
	commentsClicked() {
		this.onCommentClicked.next(this.article);
	}
}
