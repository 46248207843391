import { Action } from "@ngrx/store";
import { WarehouseSearchCriteria, WarehouseSearchResults } from "src/app/models/search/warehouse-search-results";


export enum SearchActionTypes {
	SET_CRITERIA = '[SEARCH] Set Criteria',
	SET_RESULTS = '[SEARCH] Set Results',
}

export class SetWarehouseSearchCriteria implements Action {
	public readonly type = SearchActionTypes.SET_CRITERIA;
	constructor(public criteria: WarehouseSearchCriteria) {}
}
export class SetWarehouseSearchResults implements Action {
	public readonly type = SearchActionTypes.SET_RESULTS;
	constructor(public results: WarehouseSearchResults) {}
}


export type SearchActions = 
	SetWarehouseSearchCriteria |
	SetWarehouseSearchResults
	;
