import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

export const AutoUnsubscribeMixin = <T extends Constructor>(base: T = class {} as T) =>
	class extends base implements OnDestroy {
		destroy$ = new Subject<boolean>();

		ngOnDestroy(): void {
			this.destroy$.next(true);
		}
	};

export type Constructor<T = {}> = new (...args: any[]) => T;
