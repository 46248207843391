

export class Sar {
	public draId: number;
	public submitDate: Date;
	public referenceNum: string;
	public notes: string;
	public status: string;
	public details: Array<SarDetails>;
	public link: string;

	constructor(o?: any) {
		//const { link, ...obj } = o; //get rid of the link coming from the api
		Object.assign(this, o);
	}

	get invoiceId() {
		return this.link.replace('<a href=/SAR/Details/', '').replace(' a> Print Approved SAR </a>', '')
	}

}

export class SarDetails {
	public productCode: number;
	public productDescription: string;
	public productCost: number;
	public quantity: number;
	public errorDescription: string;
	public returnType: string;
	public notes: string;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export class SarClaim {
	public accountNumber: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public phone: string;
	public referenceNumber: string;
	public products: Array<SarClaimProduct> = [];

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export class SarClaimProduct {
	public productCode: string;
	public productName: string;
	public quantity: number;
	public returnType: string;
	public description: string;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export class SarPrint {
	public company: string;
	public submittedBy: string;
	public contactPhone: string;
	public sarId: string;
	public submitDate: Date;
	public approvedBy: string;
	public authorizationCode: string;
	public dateApproved: Date;
	public refNum: string;
	public status: string;
	public type: string;
	public sarItems: Array<SarPrintItem>;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export class SarPrintItem {
	public id: number;
	public productCode: string;
	public productDescription: string;
	public productCost: number;
	public quantity: number;
	public errorDescription: string;
	public returnType: string;
	public notes: string;
	public type: string;

	constructor(o?:any) {
		Object.assign(this,o);
	}

	get returnTypeReadable() {
		const regex = /^([a-zA-Z]*)([A-Z]{1})/gm;
		const subst = `$1 $2`;
		return this.returnType.replace(regex, subst);
	}
}