
<div class="overview">
	<aside>
		<div class="search-area">
			<input [(ngModel)]="searchTerm" (keyup.enter)="search()">
			<div class="button" (click)="search()">Search</div>
		</div>
	</aside>
	
	<section class="order-history" *ngIf="orderSearchData?.length > 0">
		<header>
			<h1>Order History</h1>
			<a [routerLink]="['/dashboard', 'order-history']" *ngIf="orderSearchData.length > 10">View All</a>
		</header>
		<app-order-history-list [orderHistoryItems]="orderSearchData"></app-order-history-list>
	</section>
	<section class="sar-history" *ngIf="sarSearchData?.length > 0">
		<header>
			<h1>SAR</h1>
			<a [routerLink]="['/dashboard', 'sar']" *ngIf="sarSearchData.length > 10">View All</a>
		</header>
		<app-table [data]="sarSearchData" [tableInfo]="sarColumns"></app-table>
	</section>
	<section class="ezpay-history" *ngIf="ezpaySearchData?.length > 0">
		<header>
			<h1>EZ-Pay</h1>
			<a [routerLink]="['/dashboard', 'ez-pay']" *ngIf="ezpaySearchData.length > 10">View All</a>
		</header>
		<app-table [data]="ezpaySearchData" [tableInfo]="ezpayColumns"></app-table>
	</section>
	<section class="ir-history" *ngIf="irSearchData?.length > 0">
		<header>
			<h1>Instant Rebate</h1>
			<a [routerLink]="['/dashboard', 'instant-rebate']" *ngIf="irSearchData.length > 10">View All</a>
		</header>
		<app-table [data]="irSearchData" [tableInfo]="irColumns"></app-table>
	</section>
	
</div>
