
export class Owner {
	public userId: number;
	public firstName: string;
	public lastName: string;
	public company: string;
	public memberType: number;
	public accountNumber: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class MemberType {
	public memberTypeId: number;
	public memberType: string;
	public companies: Array<Company>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class Company {
	public companyName: string;
	public accountNumber: string;
	public memberTypeId: number;
	public owners: Array<Owner>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}