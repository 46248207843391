import { Component, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { SarClaim, SarClaimProduct } from "src/app/models/dashboard/sar";
import { ProUser } from "src/app/models/pro-user";
import { Product } from "src/app/models/shopping/product";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { SetDashboardTitle } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-sar-request",
	templateUrl: "./sar-request.component.html",
	styleUrls: ["./sar-request.component.scss"],
})
export class SarRequestComponent implements OnInit {
	public form: UntypedFormGroup;
	private user: ProUser;
	public sarClaim: SarClaim;
	public returnTypeOptions = [
		{ label: "Initial Defect", value: "Initial Defect" },
		{ label: "Customer Exchange", value: "CustomerExchange" },
	];
	constructor(private fb: UntypedFormBuilder, private authService: AuthService, private store: Store<IAppState>, private router: Router, private dataService: DataService) {}

	ngOnInit(): void {
		this.user = this.authService.user;
		this.sarClaim = new SarClaim({ accountNumber: this.user.accountNumber, firstName: this.user.name.split(" ")[0], lastName: this.user.name.split(" ")[1], email: this.user.email }); //TODO: fix this, because no space would break this
		this.store.dispatch(new SetDashboardTitle("New SAR"));

		this.form = this.fb.group({
			accountNumber: [this.sarClaim.accountNumber, Validators.required],
			firstName: [this.sarClaim.firstName, Validators.required],
			lastName: [this.sarClaim.lastName, Validators.required],
			email: [this.sarClaim.email, Validators.required],
			phone: ["", Validators.required],
			referenceNumber: ["", Validators.required],
			products: this.fb.array([])
		});
		this.addNewSarProduct();

	}

	get sarProducts() {
		return this.form.controls['products'] as UntypedFormArray;
	}

	selectProduct(product: Product, index: number) {
		let prod = this.sarProducts.at(index);
		prod.get('productCode').setValue(product.productCode);
		prod.get('productName').setValue(product.modelName)
	}
	addNewSarProduct() {
		const sarProductForm = this.fb.group({
			productCode: ['', Validators.required],
			productName: [''],
			quantity: [undefined, Validators.required],
			returnType: ['Initial Defect', Validators.required],
			description: ['', Validators.required]
		})
		this.sarProducts.push(sarProductForm);
	}
	deleteSarProduct(idx) {
		this.sarProducts.removeAt(idx);
		if(this.sarProducts.length == 0) {
			this.addNewSarProduct();
		}
	}
	close() {
		this.router.navigate(['/dashboard', 'sar']);
	}
	submit() {
		this.form.markAllAsTouched();
		if(this.form.valid) {
			this.dataService.submitSar(this.form.value).subscribe((resp:any) => {
				if(resp.success == true) {
					this.close();
				}
			});
		}
	}
}
