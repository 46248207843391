import { Component, OnInit, Input, Output , EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { PointsPurchase } from "src/app/models/dashboard/points";
import { DataService } from "src/app/services/data.service";
import { SetDashboardTitle } from "src/app/store/actions/site.actions";
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state';
import { getDate } from 'date-fns';
import { AuthService } from "src/app/services/auth.service";



@Component({
  selector: 'app-points-purchase',
  templateUrl: './points-purchase.component.html',
  styleUrls: ['./points-purchase.component.scss']
})


export class PointsPurchaseComponent implements OnInit {

  @Input() pointsPurchase!: PointsPurchase;


  public pointsPurchaseList: Array<PointsPurchase>


  //Decorate with output so that parent can get access to this variable. 
  @Output() outputFromChild: EventEmitter<{ orderID: number, Checked: boolean, AddressID: number  }> = new EventEmitter();


  constructor(private store: Store<IAppState>,
    private dataService: DataService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) { }
  ngOnInit(): void {}


  public addressChange(value: any, data: number, event: Event): void {

   // alert(value);
   // alert(data)


  }

  public onChange(data: number, value: any, event: Event): void
  {
    //alert(value);
    //alert(data)

    const isChecked: boolean = event.target['checked']; // or event.target.checked
    this.outputFromChild.emit({ orderID: data, Checked: isChecked, AddressID: value })
  }

}
