import { Component, OnInit, Input, Output, HostListener} from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MemberSummary } from "src/app/models/accounting/member-summary-data";
import { OrderHistoryItem } from "src/app/models/dashboard/order-history-item";
import { Permissions } from "src/app/modules/controls/enums/permissions";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardTitle } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

//New Below
// angular/core"; had added elements.
import { ProductSearchResult, SearchCriteria, SearchResults } from 'src/app/models/search/search-results';

import { AlgoliaService } from 'src/app/services/algolia.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { Product } from "src/app/models/shopping/product";
import { SearchType } from "src/app/models/search/algolia";


@Component({
	selector: "app-overview",
	templateUrl: "./overview.component.html",
	styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent implements OnInit {

	public daysOut: 30 | 60 | 90 = 30;
	public memberSummary: MemberSummary;
	public searchTerm: string;
	public orderHistoryItems: Array<OrderHistoryItem>;
	public Permissions = Permissions;


  //New ----Start
  @Input() touched: boolean = false;
  @Input() valid: boolean = false;
  @Input() placeholder: string;
  @Output() onSelect: Subject<Product> = new Subject();
  @HostListener('document:click') documentClick() {
	this.searchResults = null;
}
  @HostListener('document:keydown', ['$event']) documentKeydown(e: KeyboardEvent) {
	  e.key === 'Escape' && (this.searchResults = null);
  }
  public term: string;
  public activeIndex: number;
  public searchResults: SearchResults;
  public tempSearchType;
  public searchCriteria: SearchCriteria = new SearchCriteria();
  private cancelSearch: Subject<any> = new Subject();

  public searchType: number = 4;


// End

  constructor(private dataService: DataService, private store: Store<IAppState>, private router: Router, private algoliaService: AlgoliaService, private authService: AuthService) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle('Overview'));
		this.dataService.getMemberSummary().subscribe((m) => (this.memberSummary = m));
		this.dataService.getOrderHistory().subscribe(h => this.orderHistoryItems = h.map(i => new OrderHistoryItem(i)))
	}

	setDaysOut(e) {
		this.daysOut = e.target.value;
  }

	search() {
		this.cancelSearch.next(null);
		if (this.term?.length > 1) {
			this.searchCriteria.searchTerm = this.term;
			this.searchCriteria.searchType = SearchType.Backorder | SearchType.Invoice;
			this.dataService.search(this.searchCriteria)
				.pipe(debounceTime(500), takeUntil(this.cancelSearch))
				.subscribe(r => {
					console.log(r);
					this.algoliaService.queryID = r.queryID;
					this.algoliaService.index = r.index;
					this.searchResults = r;
	
				});

		} else {
			this.searchResults = null;
		}
	}
	openInvoice(number) {
		this.dataService.downloadInvoice(number).subscribe((resp:any) => {
			//the line below may get caught by a popup blocker - if so, just move it to before the call to downloadInvoice()
			let wdw = window.open('', 'invoice')
			wdw.location.href = URL.createObjectURL(resp);

		}, err => {
			switch(err.error.size) {
				case 30:// can't find file
					this.store.dispatch(new ErrorMessageSet("The invoice was not found"));
					break;
				case 32: //not your invoice
					this.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
					break;
			}
		})
	}

}
