import { EventEmitter, Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { Brand } from "../models/shopping/brand";
import { VendorShort } from "../models/vendor/vendor";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';


@Injectable()
export class SiteService {

	public onShowLoader: EventEmitter<string> = new EventEmitter();
	public onHideLoader: EventEmitter<string> = new EventEmitter();

	private _doCartRefresh: Subject<any> = new Subject();
	public onDoCartRefresh = this._doCartRefresh.asObservable();

	private _recentVendors: Array<VendorShort>;
	private _recentBrands: Array<Brand>;

	private _setLoaderVisible: Subject<boolean> = new Subject();
	public onSetLoaderVisible = this._setLoaderVisible.asObservable();

	private _resize: ReplaySubject<'xs' |'s' | 'm' | 'l' | 'xl'> = new ReplaySubject()
	public onResize = this._resize.asObservable();

	private _disallow: Subject<boolean> = new Subject();
	public onDisallow = this._disallow.asObservable();

	private _disallowedSizes: Array<'xs' |'s' | 'm' | 'l' | 'xl'> = [];
	private _currentSize;

	disallowSizes(val: Array<'xs' |'s' | 'm' | 'l' | 'xl'>) {
		this._disallowedSizes = val;
		this._disallow.next(this._disallowedSizes.includes(this._currentSize));
	}
	constructor(private breakpointObserver$: BreakpointObserver) {
		this.breakpointObserver$
		.observe(['(max-width: 1350px)', '(max-width: 1050px)', '(max-width: 850px)', '(max-width: 500px)'])
		.subscribe((state: BreakpointState) => {
			this._currentSize = 'xl';
			if(state.breakpoints['(max-width: 500px)']) { this._currentSize = 'xs'; }
			else if(state.breakpoints['(max-width: 850px)']) { this._currentSize = 's'; }
			else if(state.breakpoints['(max-width: 1050px)']) { this._currentSize = 'm'; }
			else if(state.breakpoints['(max-width: 1350px)']) { this._currentSize = 'l'; }
			this._resize.next(this._currentSize); 
			this._disallow.next(this._disallowedSizes.includes(this._currentSize));
		})
	 }

	doCartRefresh() {
		this._doCartRefresh.next(null);
	}
	showLoader(id: string): void {
		this.onShowLoader.next(id);
	}
	hideLoader(id: string): void {
		this.onHideLoader.next(id);
	}

	getRecentVendors() {
		if(this._recentVendors) {
			return this._recentVendors;
		}
		let rv = window.localStorage.getItem('recentVendors');
		if(rv) {
			this._recentVendors = JSON.parse(rv);
			return this._recentVendors;
		}
		return [];
	}
	addRecentVendor(vendor: VendorShort) {
		this._recentVendors = this._recentVendors || [];
		this._recentVendors = this._recentVendors.filter(v => v.vendorId != vendor.vendorId)
		this._recentVendors.splice(0, 0, vendor);
		this._recentVendors = this._recentVendors.slice(0, 10);
		window.localStorage.setItem('recentVendors', JSON.stringify(this._recentVendors));
	}
	getRecentBrands() {
		if(this._recentBrands) {
			return this._recentBrands;
		}
		let rv = window.localStorage.getItem('recentBrands');
		if(rv) {
			this._recentBrands = JSON.parse(rv);
			return this._recentBrands;
		}
		return [];
	}
	addRecentBrand(brand: Brand) {
		this._recentBrands = this._recentBrands || [];
		this._recentBrands = this._recentBrands.filter(b => b.brandId != brand.brandId)
		this._recentBrands.splice(0, 0, brand);
		this._recentBrands = this._recentBrands.slice(0, 10);
		window.localStorage.setItem('recentBrands', JSON.stringify(this._recentBrands));
	}
}
