import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CalendarDateFormatter } from "angular-calendar";
import { forkJoin, Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { ProUser } from "src/app/models/pro-user";
import { Product } from "src/app/models/shopping/product";
import { Article } from "src/app/models/strapi/article";
import { InstantRebateGroups } from "src/app/models/strapi/instant-rebate";
import { CmsVendor } from "src/app/models/strapi/vendor";
import { Permissions } from "src/app/modules/controls/enums/permissions";
import { DataService } from "src/app/services/data.service";
import { SiteService } from "src/app/services/site.service";
import { StrapiService } from "src/app/services/strapi.service";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";
import { CustomDateFormatter } from "../calendar/helpers/custom-date-formatter.provider";

const HOME_PAGE_ARTICLE_TOTAL = 4;
@Component({
	selector: "app-landing",
	templateUrl: "./landing.component.html",
	styleUrls: ["./landing.component.scss"],
	providers: [{
		provide: CalendarDateFormatter,
		useClass: CustomDateFormatter
	  }]
})
export class LandingComponent extends AutoUnsubscribeMixin() implements OnInit {
	public headerArticles: Array<Article>;
	public featureArticles: Array<Article>;
	public visibleFeatureCount: number = 4;

	public activeHeader: number = 0;
	public instantRebateGroups: InstantRebateGroups;
	public instantRebateLimit: number = 50;
	public newProducts: Array<Product> = [];
	public allNewProducts: Array<Product> = [];
	public featuredVendors: Array<CmsVendor> = [];
	public currentRebatesVisible: boolean = false;
	public redeemableRebatesVisible: boolean = false;
	private user: ProUser;
	private resizeObserver: ResizeObserver;
	public Permissions = Permissions;

	constructor(private strapiService: StrapiService, private store: Store<IAppState>, private router: Router, private dataService: DataService, private siteService: SiteService) {super();}

	ngOnInit(): void {
		this.store.select(selectUser).pipe(takeUntil(this.destroy$)).subscribe(u => this.user = u);
		let requests = [
			this.strapiService.getHeaderArticles(),
			this.strapiService.getFeatureArticles()
		];
		forkJoin(requests).subscribe((arr) => {
			this.headerArticles =  arr[0].sort(this.sortByDateDesc).slice(0,25);
			this.featureArticles = arr[1].sort(this.sortByDateDesc).slice(0,25);
			if(this.headerArticles.length == 0) {
				//no header articles, so just take the first one from the features
				this.headerArticles = this.featureArticles.splice(0,1).map(a => ({ ...a, articleType: 'Header'}));
			}
			//get interactions for header
			this.strapiService.getArticleInteractionCountsMultiple(this.headerArticles.map(a => a.id)).subscribe((resp:any) => {
				this.headerArticles.forEach(h => {
					let article = resp.find(a => a.id == h.id);
					h.commentCount = article.commentCount;
					h.likeCount = article.likeCount;
					h.userCommented = article.userCommented;
					h.userLiked = article.userLiked;
					h.views = article.views;
				})
			})
		});
		this.dataService.warehouseSearch({pageSize: 4, newProductsOnly: true}).subscribe((resp:any) => {
			this.allNewProducts = resp.products;
			this.newProducts = this.allNewProducts;
		})
		this.strapiService.getInstantRebateGroups(this.instantRebateLimit).subscribe(r => this.instantRebateGroups = r);
		this.strapiService.getFeaturedVendors(7).subscribe(resp => this.featuredVendors = resp);

		this.siteService.onResize.pipe(takeUntil(this.destroy$)).subscribe(size => {
			switch(size) {
				case 'xs': 
					this.visibleFeatureCount = 1; 
					this.newProducts = this.allNewProducts; 
					break;
				case 's': 
					this.visibleFeatureCount = 2; 
					this.newProducts = this.allNewProducts.slice(0,2);
					break;
				case 'm': 
					this.visibleFeatureCount = 2; 
					this.newProducts = this.allNewProducts.slice(0,3);
					break;
				case 'l': 
					this.visibleFeatureCount = 3; 
					this.newProducts = this.allNewProducts; 
					break;
				case 'xl': 
					this.visibleFeatureCount = 4; 
					this.newProducts = this.allNewProducts; 
					break;
			}
		})
     //timer for landing page carousel how long wait for first change and every change after, so keep same
    timer(10000, 10000).pipe(takeUntil(this.destroy$)).subscribe(() => this.advanceHeader(1))
	}

	goToArticle(article) {
		this.router.navigate(["/cms", "article", article.id, article.slug])
	}
	advanceHeader(num) {
		this.activeHeader += num;
		this.activeHeader = this.activeHeader % this.headerArticles.length;
	}

	sortByDateDesc(a,b) {
		if(a.postDate > b.postDate) return -1;
		if(a.postDate < b.postDate) return 1;
		return 0;
	}
}
