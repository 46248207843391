import { Component, Input, OnInit, Output} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Product } from "src/app/models/shopping/product";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-product-code-search",
	templateUrl: "./product-code-search.component.html",
	styleUrls: ["./product-code-search.component.scss"],
})
export class ProductCodeSearchComponent implements OnInit {

	@Input() touched: boolean = false;
	@Input() valid: boolean = false;
  @Output() onSelect: Subject<Product> = new Subject();

  @Input() Exclude: string;

	public productCode: string;
	public products: Array<Product> = [];
	public activeIndex: number;

	private cancelSearch: Subject<any> = new Subject();
	constructor(private dataService: DataService) {}

  ngOnInit(): void {   }

  search() {



		if (this.productCode?.length > 1) {
			this.cancelSearch.next(null);
			this.dataService
        .searchProductsByCode(this.productCode, this.Exclude)
				.pipe(takeUntil(this.cancelSearch))
				.subscribe((resp) => {this.products = resp; this.activeIndex = undefined; });
		} else {
			this.products = [];
		}
	}

	selectProduct(product) {
		this.productCode = product.productCode;
		this.onSelect.next(product);
		this.products = [];
	}

	onKeyUp(e) {
		if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >=96 && e.keyCode <=105)) {
			this.search();
		}
	}
	onKeyDown(e) {
		if(this.products?.length > 0) {
			switch(e.keyCode) {
				case 38: //up
					if(this.activeIndex) { this.activeIndex--; }
					this.scrollOptionIntoView('product_code_search_option_'+this.activeIndex)
					break;
				case 40: 
					this.activeIndex === undefined  ? this.activeIndex = 0 : this.activeIndex = Math.min(this.activeIndex + 1, this.products.length);
					this.scrollOptionIntoView('product_code_search_option_'+this.activeIndex)
					break;
				case 13: 
					if(this.activeIndex) { this.selectProduct(this.products[this.activeIndex]); }
					break;
			}			
		}

	}

	scrollOptionIntoView(id) {
		//not implemented yet
	}

}
