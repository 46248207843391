<div class="quick-order-form">
	<header>
		<h1>Quick Order Form</h1>
		<div class="rounded-button" (click)="uploadOrder()">Upload your file</div>
	</header>
	<main>
		<div class="add-item-form">
			<label>Item #</label>
			<input pInputText [(ngModel)]="productCode" (blur)="getProduct()" (keyup.enter)="goToQty()" #productCodeField tabindex="1">
			<label>Quantity</label>
			<input pInputText type="number" [(ngModel)]="quantity" (keyup.enter)="addItem()" #quantityField tabindex="2">
			<div class="button" (click)="addItem()" tabindex="3" (keyup.enter)="addItem()">Add</div>
		</div>
		<div class="added-items">
			<div class="row header">
				<div class="code">Product Code</div>
				<div class="name">&nbsp;</div>
				<div class="quantity">Quantity</div>
				<div class="price">Unit Price<br/>Ext Price</div>
				<div class="delete"></div>
			</div>

			<ng-container *ngFor="let item of added">
				<div class="row">
					<div class="code">{{item.productCode}}</div>
					<div class="name">{{item.modelName}}</div>
					<div class="quantity">{{item.cartItem.quantity}}</div>
					<div class="price">{{item.cartItem.itemPrice | currency}}<br />{{item.cartItem.itemPrice * item.quantity | currency}}</div>
					<div class="delete"><mat-icon (click)="delete(item)">delete</mat-icon></div>
				</div>
				
				<div class="replacement" *ngIf="item.returnCode == -10">
					<div class="alert">This product is a replacement for {{item.originalProductName}} ({{item.originalProductCode}})</div>
				</div>


				<!--MK added-->
				<div class="chips">
					<div class="special" *ngIf="item.cartItem.product.hasSpecial">Special</div>
					<div class="multiples" *ngIf="item.cartItem.product.specialPriceQuantity > 1">Multiples</div>
					<div class="special-order" *ngIf="item.cartItem.product.specOrder">Special Order</div>
				</div>



				<div class="upsell" *ngIf="item.itemUpsell && !item.rejectUpsell">
					<mat-icon>error</mat-icon>
					<span>This item is available for {{item.itemUpsell.cartonRetail | currency}} when purchased in quantities of {{item.itemUpsell.itemsInCarton}}.  Would you like to purchase {{item.itemUpsell.cartonsToOrder}} carton{{item.itemUpsell.cartonsToOrder == 1 ? '' : 's'}} instead?</span>
					<div class="actions">
						<div class="button" (click)="acceptUpsell(item.cartItem.cartItemId, item.productCode, item.itemUpsell)">Yes</div>
						<div class="button" (click)="rejectUpsell(item.cartItem.cartItemId, item.productCode, item.itemUpsell)">No</div>
					</div>
				</div>

				<ng-container *ngIf="item?.appliedSpecial">
					<div class="promo special" *ngIf="item.appliedSpecial.details.length == 1">
						<mat-icon>savings</mat-icon>
						<span>{{item.appliedSpecial.details[0].special.specialName}}</span>
					</div>
					<div class="promo special" *ngIf="item.appliedSpecial.details.length > 1">
						<ng-container *ngFor="let detail of item.appliedSpecial.details">
							<div class="special-row" *ngIf="detail.special?.specialId">
								<mat-icon>savings</mat-icon>
								<span>{{detail.quantity}} units qualify for {{detail.special.specialName}}</span>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</main>
</div>
