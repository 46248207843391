import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RebateEntry } from "src/app/models/dashboard/rebates";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-rebate-claim-print",
	templateUrl: "./rebate-claim-print.component.html",
	styleUrls: ["./rebate-claim-print.component.scss"],
})
export class RebateClaimPrintComponent implements OnInit {
	public rebate: RebateEntry;
	constructor(private dataService: DataService, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((map) => {
			if (map.get("orderId")) {
				this.dataService.getRebate(map.get("orderId")).subscribe((resp) => {
					this.rebate = resp;
					setTimeout(window.print, 500);
				});
			}
		});
	}
}
