import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PriceProtectionEntry, RebateEntry } from 'src/app/models/dashboard/rebates';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-price-protection-summary',
  templateUrl: './price-protection-summary.component.html',
  styleUrls: ['./price-protection-summary.component.scss']
})
export class PriceProtectionSummaryComponent implements OnInit {

	public entry: RebateEntry; //rebate entry and price protection entry are basically the same thing
	public orderId: string;
  constructor(private dataService: DataService, public config: DynamicDialogConfig ) { }

  ngOnInit(): void {
	this.orderId = this.config.data.claimId;
	this.dataService.getPriceProtection(this.orderId).subscribe(resp => this.entry = resp);
  }
  print() {
	window.open(`/price-protection/print/${this.orderId}`);
  }

}
