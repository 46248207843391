
<div class="head">
  <div class="pay-actions">
    <h3>Approval Actions</h3>
    <div class="pay-button button" (click)="payAll()"><mat-icon>file_download</mat-icon>Approve All</div>
    <div class="pay-button button" (click)="paySelected()"> <mat-icon>file_download</mat-icon>Approve Selected</div>
    <div class="pay-button button" (click)="reset()"> <mat-icon>file_download</mat-icon>Reset</div>
  </div>
</div>

<section class="results">
  <app-points-purchase *ngFor="let pointsPurchase of pointsPurchaseList" [pointsPurchase]="pointsPurchase" [inputFromParent]="inputText" (outputFromChild)="receiveChildData($event)">

  </app-points-purchase>
</section>
