<div class="container">
	<div class="users">
		<ul>
			<li *ngFor="let user of pointsUsers">
				<a [routerLink]="['/dashboard', 'points', user.userId]"><span>{{user.firstName}} {{user.lastName}}</span></a>
				<mat-icon (click)="removeUser(user)">delete</mat-icon>
			</li>
		</ul>
	</div>
	<div class="invite">

		<h3>Review & Approve</h3>
		<a [routerLink]="['/dashboard', 'approval']">Approve Employee Points</a>

		<h3>Company Activity</h3>
		<a [routerLink]="['/dashboard', 'points']">View Company Activity</a>
		<h3>Invite new users</h3>

		<p>Enter their email addresses in the box below (one email per line), and then click the "Send" button</p>
		<textarea [(ngModel)]="invitees"></textarea>
		<div class="button" (click)="sendInvites()">Send</div>
	</div>
</div>
