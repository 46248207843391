import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/shopping/product';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-shopping-home',
  templateUrl: './shopping-home.component.html',
  styleUrls: ['./shopping-home.component.scss']
})
export class ShoppingHomeComponent implements OnInit {

	public products:Array<Product>
	public count:number = 10;
  constructor(private dataService: DataService) { }

  ngOnInit() {
	this.refresh()
  }
  refresh() {
	this.dataService.getRandom(this.count).subscribe((resp: any) => this.products = resp.map(i => new Product(i)));
  }

}
