import { Component, Input, OnInit } from '@angular/core';
import { RecentForumPost } from 'src/app/models/forum/recent-forum-post';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-recent-forum-posts',
  templateUrl: './recent-forum-posts.component.html',
  styleUrls: ['./recent-forum-posts.component.scss']
})
export class RecentForumPostsComponent implements OnInit {

  @Input() count: number = 10;

  public posts: Array<RecentForumPost>;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getRecentForumPosts(this.count).subscribe(resp => this.posts = resp);
  }

}
