import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Address } from "src/app/models/account/address";
import { User } from "src/app/models/dashboard/user";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit, OnChanges {

	@Input()  user: User;
	@Output() onCancel: EventEmitter<boolean> = new EventEmitter();
	@Output() onSubmit: EventEmitter<User> = new EventEmitter();

  roleOptions: any[];
  roles = [];

	public form: UntypedFormGroup;
	public addressOptions: Array<{label: string, value: any}>;
	constructor(private fb: UntypedFormBuilder, private dataService: DataService) {}


  ngOnInit(): void {

		  let addresses;
      this.dataService.getAddresses(1).subscribe(a => {
			this.addressOptions = a.map(o => ({label: `${o.address1} ${o.city}, ${o.state}`, value: o.addressId}))
      //this.addressOptions.splice(0, 0, { label: '', value: undefined})
    })

 
    //OK to hard code as these will not change.
    this.roleOptions = [
      { label: "Owner", value: 1 },
      { label: "General Manager", value: 2},
      { label: "Buyer", value: 3 },
      { label: "Store Manager", value: 4 },
      { label: "Accounting", value: 5 },
      { label: "Sales", value: 6 },
      { label: "Marketing", value: 7 },
      { label: "User", value: 8 }
    ];


	}


  ngOnChanges(changes: SimpleChanges): void {
    this.roles = [];

    if (changes.user.currentValue.role)
    {
		this.roles = changes.user.currentValue.role.split(',').map(i => Number(i));
    }

    if (changes.user) {
        this.form = this.fb.group({
				firstName: [ this.user.firstName, Validators.required ],
				lastName: [ this.user.lastName, Validators.required ],
				phone: [ this.user.phone, Validators.required ],
				email: [ this.user.email, Validators.required ],
				username: [ this.user.username, Validators.required ],
				password: [ this.user.password, Validators.required ],
				addressId: [ this.user.addressId, Validators.required ],
        userId: [this.user.userId || 0, Validators.required],
        roles: [this.roles, null]
        });
		}
	}
	cancel() {
		this.onCancel.next(true);
	}
  submit()
  {
    this.form.markAllAsTouched();
    if (this.form.valid) {
		let newUser = new User(this.form.value);
		//get role names from hard coded list
		newUser.roleNames = this.roleOptions.filter(o => newUser.roles.includes(o.value)).map(o => o.label).join(',');

      this.onSubmit.next(newUser)
		}
  }

}
