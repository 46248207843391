<div class="sell-through-dialog">
	<div class="upload-form">
		<div class="type">
			<label>Week of Sell Through:</label>
			<p-calendar [(ngModel)]="dateRange" selectionMode="range" (onSelect)="selectWeek($event)" hideOnDateTimeSelect="true" #calendar></p-calendar>
		</div>
		<div class="upload-button">
			<div class="button">
				<mat-icon>add</mat-icon>
				<span>Upload/Add File</span>
			</div>
			<input type="file" (change)="upload($event)" #uploadOrderFileField>
		</div>
	</div>
	<div class="errors" *ngIf="uploadErrors.length > 0">
		<p>The following error(s) were found in your file:</p>
		<ul>
			<li class="error" *ngFor="let err of uploadErrors">
				<span>{{err.input}}{{err.input ? ':' : ''}} {{err.error}}</span>
			</li>
		</ul>
		<p *ngIf="uploadErrors.length > 1">Other data has been uploaded</p>
	</div>
	<footer>
		<div class="button" (click)="closeUploadDialog()">Close</div>
	</footer>

</div>
