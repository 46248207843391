import { Component, Input, OnInit } from '@angular/core';
import { InstantRebate } from 'src/app/models/strapi/instant-rebate';

@Component({
  selector: 'app-landing-rebate-tile',
  templateUrl: './landing-rebate-tile.component.html',
  styleUrls: ['./landing-rebate-tile.component.scss']
})
export class LandingRebateTileComponent implements OnInit {

	@Input() rebate: InstantRebate;
  constructor() { }

  ngOnInit(): void {
  }

}
