import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Category } from "src/app/models/shopping/category";
import { Product } from "src/app/models/shopping/product";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-category-page",
	templateUrl: "./category-page.component.html",
	styleUrls: ["./category-page.component.scss"],
})
export class CategoryPageComponent implements OnInit {
	public catId: number;
	public categories: Array<Category>;
	public products: Array<Product>;
	constructor(private route: ActivatedRoute, private dataService: DataService) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			if (params.get("id")) {
				this.catId = parseInt(params.get("id"));
				this.dataService.getCategoryProducts(this.catId).subscribe((resp:Array<Product>) => {
					this.products = resp.map(p => new Product(p));
				})
			} else {
				this.dataService.getCategories().subscribe((resp: Array<Category>) => {
					this.categories = resp.map(c => new Category(c));
				})
			}
		});
	}
}
