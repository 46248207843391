
<div class="container">
	<main *ngIf="article">
		<div class="images" [ngStyle]="{'background-color': article.backgroundColor}">
			<img class="main-image" *ngIf="article.articlePageImage" src="{{article.articlePageImage.url}}" />
			<img class="main-image" *ngIf="!article.articlePageImage && article.image" src="{{article.image.url}}" />
		</div>
		<article>
			<header>
				<h4><a *ngIf="article.vendors?.length > 0" [routerLink]="['/vendor', article.vendors[0].slug]" >{{article.vendors[0].vendorName}}</a>&nbsp;</h4>
				<h1 class="name">{{article.articleName}}</h1>
				<ng-container *ngIf="article.author.name; else noAuthor">
					<div class="avatar" [ngStyle]="{'background-image': 'url('+article.author.avatar?.url+')'}"></div>
					<div class="author" *ngIf="article.author.email">By <a href="mailto:{{article.author.email}}">{{article.author.name}}</a></div>
					<div class="author" *ngIf="!article.author.email">By {{article.author.name}}</div>
				</ng-container>
				<ng-template #noAuthor>
					<div class="no-avatar"></div>
					<div class="author"></div>
				</ng-template>
				<span class="datetime"><span class="date">{{article.postDate | date:'MMMM d, yyyy'}}</span><span class="time">{{article.postDate | date:' - h:mm a'}}</span></span>
				<div class="comment-link-holder">&nbsp;<div class="comment-link" *ngIf="article.enableComments" (click)="showComments = !showComments;goToComments()"><mat-icon>forum</mat-icon>Comments</div></div>
			</header>
			
			<div class="content" [innerHtml]="article.contentSafeHtml"></div>

			<div class="media" *ngIf="article.Media?.length > 0">
				<h4>Additional Files/Information</h4>
				<ul>
					<li *ngFor="let doc of article.Media">
						<a href="{{doc.documentLink || doc.url}}" class="{{doc.ext?.substring(1)}}" target="_blank">{{doc.documentName}}</a>
						<!--<a href="{{doc.url}}" class="{{doc.ext?.substring(1)}}" target="_blank">{{doc.name}}</a>-->
					</li>
				</ul>
			</div>

			<div class="interactions">
				<div class="likes" *ngIf="article.enableLikes" [ngClass]="{ userLiked: article.userLiked }" (click)="toggleLike()"><mat-icon>thumb_up</mat-icon>
					<span *ngIf="article.likeCount == 0">{{article.likeCount}} Like{{article.likeCount == 1 ? '' : 's'}}</span>
					<span *ngIf="likers.length == 1">{{likers[0].userId == user.userId ? 'You' : likers[0].fullName}}</span>
					<span *ngIf="likers.length > 1">
						{{likers[0].userId == user.userId ? 'You' : likers[0].firstName}} and {{likers.length - 1}} other{{likers.length == 2 ? '' : 's'}}
						<div class="all-likers">
							<div class="liker" *ngFor="let liker of likers; let i = index">
								<ng-container *ngIf="i < 20">{{liker.userId == user.userId ? 'You' : liker.fullName}}</ng-container>
								<ng-container *ngIf="i == 20">And {{likers.length - 20}} more</ng-container>
							</div>
						</div>
					</span>

				</div>
				<div class="comments" *ngIf="article.enableComments" [ngClass]="{ userCommented: article.userCommented, open: showComments }" (click)="showComments = !showComments"><mat-icon>forum</mat-icon>{{article.commentCount}} Comment{{article.commentCount == 1 ? '' : 's'}}</div>
				<div class="views" *ngIf="article.views"><mat-icon>visibility</mat-icon>{{article.views}} View{{article.views == 1 ? '' : 's'}}</div>
			</div>	

			<div id="commentSection" class="commentSection" [ngClass]="{ open: showComments }" *ngIf="article.enableComments">
				<app-comment-form [articleId]="article.id" (onAddComment)="addComment($event)" [permission]="permissions.Comments"></app-comment-form>
				<ng-container *ngFor="let comment of comments">
					<app-comments-display [comment]="comment" [articleId]="article.id"></app-comments-display>
				</ng-container>		
			</div>
		</article>



	</main>

	
</div>
