
<main class="container" *ngIf="vendor">
	<header>
		<h2>{{vendor.name}}</h2>
		<div class="actions">
			<div class="round-button" *ngIf="vendor.website"><a href="{{vendor.url}}" target="_blank">Visit Website</a></div>
		</div>
	</header>
	<section class="info">
		<div class="statement">
			<h4>Mission Statement</h4>
			<p [innerHTML]="vendor.companyStatement"></p>
		</div>
		<div class="contact">
			<h4>Vendor Contact</h4>
			<ul>
				<li *ngIf="vendor.phone"><mat-icon>call_outline</mat-icon>{{vendor.phone}}</li>
				<li *ngIf="vendor.website"><mat-icon>language_outline</mat-icon>{{vendor.website}}</li>
				<li *ngIf="vendor.address"><mat-icon>home_outline</mat-icon>{{vendor.address}}</li>
				<li><mat-icon>home_outline</mat-icon>{{vendor.city}}, {{vendor.state}} {{vendor.zip}}</li>
			</ul>
		</div>
		<div class="contact" *ngFor="let contact of vendor.contacts">
			<h4>{{contact.contactType}}</h4>
			<ul>
				<li><mat-icon>person_outline</mat-icon>{{contact.firstName}} {{contact.lastName}}</li>
				<li *ngIf="contact.phone"><mat-icon>phone_outline</mat-icon>{{contact.phone}}</li>
				<li *ngIf="contact.email"><mat-icon>contact_mail_outline</mat-icon>{{contact.email}}</li>
			</ul>
		</div>
	</section>
	<aside>
		<!-- notification -->
	</aside>

	<div class="articles" *ngIf="allArticles">

		<app-vendor-card [title]="'Marketing Materials'" [articles]="getArticles('MARKETING MATERIALS')" [permission]="Permissions.MarketingMaterials"><a href="{{vendor.marketingMaterials}}" target="_blank">{{vendor.marketingMaterials}}</a></app-vendor-card>


		<app-vendor-card [title]="'Freight Policy'" [permission]="Permissions.GeneralAccess" [articles]="getArticles('FREIGHT POLICY')">{{vendor.freightPolicy}}</app-vendor-card>


		<app-vendor-card [title]="'Return Policy'" [permission]="Permissions.GeneralAccess" [articles]="getArticles('RETURN POLICY')">{{vendor.returnPolicy}}</app-vendor-card>



		<ng-container *ngIf="instantRebateGroups">
			<div [permission]="Permissions.RebateRead">
				<h1 (click)="isOpenRebates = !isOpenRebates">Instant Rebates</h1>
				<app-instant-rebate-groups [instantRebateGroups]="instantRebateGroups"></app-instant-rebate-groups>
			</div>
		</ng-container>


		<app-vendor-card [title]="'Price Lists'" [articles]="getArticles('PRICELIST')" [permission]="Permissions.FinancialData" *ngIf="cmsVendor">
			<ng-container *ngIf="cmsVendor.priceListUrl"><a href="{{cmsVendor.priceListUrl}}" target="_blank">{{cmsVendor.priceListUrl}}</a></ng-container>
			<ng-container *ngIf="cmsVendor.priceList?.length > 0">
				<ng-container *ngFor="let pl of cmsVendor.priceList">
					<a href="{{pl.url}}" target="_blank">Price List</a>
				</ng-container>
			</ng-container>
		</app-vendor-card>


		<ng-container *ngIf="newProducts.length > 0">
			<h1 (click)="isOpenNewProducts = !isOpenNewProducts"><mat-icon [ngClass]="{ open: isOpenNewProducts }">expand_more</mat-icon>New Products</h1>
			<div class="new-products-container" hideable [ngClass]="{ open: isOpenNewProducts }">
				<p-carousel [value]="newProducts" [numVisible]="newProductsVisible" [numScroll]="1" [showIndicators]="false" [responsiveOptions]="responsiveOptions">
					<ng-template pTemplate="item" let-product>
						<div class="product">
							<app-product-tile [product]="product"></app-product-tile>
						</div>
					</ng-template>
				</p-carousel>
			</div>
		</ng-container>


		<ng-container *ngIf="getArticles('SPECIALS').length > 0">
			<h1 (click)="isOpenSpecials = !isOpenSpecials">  <mat-icon [ngClass]="{ open: isOpenSpecials }">expand_more</mat-icon>Specials</h1>
			<div class="specials-container" hideable [ngClass]="{ open: isOpenSpecials }">

				<p-carousel [value]="specialProducts" [numVisible]="newProductsVisible" [numScroll]="1" [showIndicators]="false" [responsiveOptions]="responsiveOptions">

					<ng-template pTemplate="item" let-product>
						<div class="product">
							<app-product-tile [product]="product"></app-product-tile>
						</div>
					</ng-template>

				</p-carousel>
			</div>
		</ng-container>


		<div class="latest-news" *ngIf="allArticles" [permission]="Permissions.GeneralAccess">
			<h1 (click)="isOpenNews = !isOpenNews"><mat-icon [ngClass]="{ open: isOpenNews }">expand_more</mat-icon>Latest News</h1>
			<div class="article-list" hideable [ngClass]="{ open: isOpenNews }">
				<div class="latest-news-article" *ngFor="let article of getArticles('GENERAL NEWS')">
					<app-landing-article-tile [article]="article"></app-landing-article-tile>
				</div>
			</div>
		</div>

	</div>

</main>
