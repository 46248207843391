<div class="container" *ngIf="mixMatch">
		<p>The product can only be purchased in multiples of {{mixMatch.multiple}}.  That can be any combination of the products listed below.</p>

		<div class="products">
			<div class="row" *ngFor="let mmp of mixMatch.products; let i = index">
				<div class="image"><img *ngIf="mmp.product.images?.length > 0" src="{{environment.image_url}}{{mmp.product.images[0].imageName}}" /></div>
				<div class="info">
					<div class="name">{{mmp.product.modelName}}</div>
					<div class="brand">{{mmp.product.brandName}}</div>
					<div class="vendor">Vendor #: {{mmp.product.caT_NO}}</div>
					<div class="upc">UPC: {{mmp.product.upc}}</div>
					<div class="product-code">Product Code: {{mmp.product.productCode}}<span [copy-clipboard]="mmp.product.productCode" (copied)="notifyCopy($event)"><i class="pi pi-copy"></i></span></div>
					<div class="prices">
						<span><label>Price:</label><b>{{mmp.product.retail | currency}}</b></span>
						<span *ngIf="mmp.product.map"><label>MAP:</label><b>{{mmp.product.map | currency}}</b></span>
						<span><label>Retail:</label><b>{{mmp.product.cusT_RETAIL | currency}}</b></span>
					</div>
				</div>
				<div class="quantity"><input pInputText [(ngModel)]="quantities[i]" type="number"></div>
			</div>

		</div>		

		<div class="row footer">
			<div class="image">&nbsp;</div>
			<div class="info">
				<button pButton (click)="addToCart()" [disabled]="!valid">Add To Cart</button>
			</div>
			<div class="quantity"><span>Total: </span>{{total}}</div>
		</div>		
	
</div>
