import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { UndoMessage } from "src/app/models/dashboard/undo-message";
import { TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";

export interface ISiteState {
	successMessage: string;
	errorMessage: string;
	searchOpen: boolean;
	headerSearchType: number,
	sideNavOpen: boolean;
	vendorPreviewOpen: boolean;
	navMenuOpen: string;
	dashboardTitle: string;
	dashboardHeaderButtons: Array<DashboardHeaderButton>;
	downloadDialogVisible: boolean;
	downloadData: Array<any>,
	downloadTableInfo: TableInfo,
	downloadHeaders: Array<{key: string, value: any}>,
	downloadFormats: Array<string>,
	undoMessage: UndoMessage;
	uploadOrderVisible: boolean;
	quickAddOpen: boolean;
}

export const initialSiteState: ISiteState = {
	successMessage: '',
	errorMessage: '',
	searchOpen: false,
	headerSearchType: 4,
	sideNavOpen: false,
	vendorPreviewOpen: false,
	navMenuOpen: '',
	dashboardTitle: '',
	dashboardHeaderButtons: [],
	downloadDialogVisible: false,
	downloadData: null,
	downloadTableInfo: null,
	downloadHeaders: null,
	downloadFormats: [ 'xls', 'csv', 'pdf' ],
	undoMessage: null,
	uploadOrderVisible: false,
	quickAddOpen: false,
}
