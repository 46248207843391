import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators'
import { AutoUnsubscribeMixin } from 'src/app/mixins/auto-unsubscribe.mixin';
import { Order } from 'src/app/models/shopping/order';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent extends AutoUnsubscribeMixin() implements OnInit {

	public orderId: number;
	public order:Order;
 constructor(private route: ActivatedRoute, private dataService: DataService) { super(); }

  ngOnInit(): void {
	this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
		if(params.get('orderId')) {
			this.orderId = parseInt(params.get('orderId'));
			this.dataService.getOrderHistoryDetails(this.orderId).subscribe(o => {
				this.order = new Order(o);
				setTimeout(() => window.print(), 500);
			 } )
			
		}
	})
  }

}
