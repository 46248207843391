import { Address } from "../account/address";
import { Permission } from "./permission";

export class User {
	public userId: number;
	public firstName: string;
	public lastName: string;
	public username: string;
	public password: string;
	public companyId: number;
  public addressId: number;

  public role: string;

  public phone: string;
	public email: string;
	public address: Address;

  public roles: Array<number> = [];

  public roleNames: string;

	public permissions: Array<Permission>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}
}
