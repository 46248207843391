<div id="forgot_password">
	<div class="img">

	</div>
	<div class="form">
		<div class="heading">
			<div (click)="backToLogin()">back</div>
			<div class="logo"><img src="../../../../../assets/images/promaster_logo.png"></div>
		</div>
		<h1>Forgot Password</h1>
		<div class="copy">
			<p>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
			<p>For security reasons, we will never send your password via email.</p>
		</div>

		<div class="field">
			<label for="email">Username or Email</label>
			<input pInputText [(ngModel)]="usernameOrEmail" id="email" placeholder="Input your username or email here">
		</div>
		<div>
			<button (click)="resetPassword()" >Reset Password</button>
		</div>
	</div>	
</div>



