import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Newsletter } from "src/app/models/strapi/newsletter";
import { StrapiService } from "src/app/services/strapi.service";

@Component({
	selector: "app-newsfeed-header",
	templateUrl: "./newsfeed-header.component.html",
	styleUrls: ["./newsfeed-header.component.scss"],
})
export class NewsfeedHeaderComponent implements OnInit {
	@Input() showNewsletterOptions: boolean = false;
	public searchTerm: string;
	public tagList = ["newsletter", "vendor", "pronews", "convention"];
	public activeTag: string;
	public newsletters: Array<Newsletter>;
	public newsletterId: number;
	public newsletterOptions: Array<{label: string, value: any}>;

	constructor(public router: Router, private route: ActivatedRoute, private strapiService: StrapiService) {}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			let req;
			if(params.filterBy == 'tag') {
				this.activeTag = params.term;
			}
			if(params.filterBy == 'search') {
				this.searchTerm = params.term;
				this.activeTag = ''
			}
		})

		if(this.showNewsletterOptions) {
			this.strapiService.getNewsletters().subscribe(n => {
				this.newsletters = n.sort(this.sortNewsletters);
				this.newsletterOptions = this.newsletters.map(n => ({ label: n.name, value: n.id }))
			});
		}
	}

	search() {
		this.router.navigate(["/newsfeed", "search", this.searchTerm]);
	}
	toggleTag(tag) {
		if(this.activeTag == tag) {
			this.activeTag = "";
			this.router.navigate(["/newsfeed"]);
		} else {
			this.router.navigate(["/newsfeed", "tag", tag]);
		}
	}

	clearSearch() {
		this.router.navigate(["/newsfeed"]);
	}
	goToNewsletter(val) {
		const id = val.value;
		const newsletter = this.newsletters.find(n => n.id == id)
		if(newsletter) {
			this.router.navigate(['/newsletter', newsletter.year, newsletter.month, newsletter.day]);
		}
	}

	private sortNewsletters(a,b) {
		if(a.year < b.year) return 1;
		if(a.year > b.year) return -1;
		if(a.month < b.month) return 1;
		if(a.month > b.month) return -1;
		if(a.day < b.day) return 1;
		if(a.day > b.day) return -1;
		return 0;
	}
}
