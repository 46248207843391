import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { take, takeUntil, tap } from "rxjs/operators";
import { ProUser } from "src/app/models/pro-user";
import { AuthService } from "src/app/services/auth.service";
import { AddToCart, CartActionTypes, GetCart, GetCartSuccess, ItemDeletedFromCart, OpenCart } from "src/app/store/actions/cart.actions";
import { Logout, SetEzPayStatus, SetUser, UserActionTypes } from "src/app/store/actions/user.actions";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";
import jwt_decode from "jwt-decode";
import { VendorShort } from "src/app/models/vendor/vendor";
import { SiteService } from "src/app/services/site.service";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { CloseQuickAdd, CloseSearch, CloseSideNav, HideUploadOrder, OpenQuickAdd, OpenSearch, OpenSideNav, SetNavMenuOpen, SetUndoMessage, SiteActionTypes, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { UndoMessage } from "src/app/models/dashboard/undo-message";
import { SubscriptionLike, timer } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { Permissions } from "src/app/modules/controls/enums/permissions";

@Component({
	selector: "app-template",
	templateUrl: "./template.component.html",
	styleUrls: ["./template.component.scss"],
})
export class TemplateComponent extends AutoUnsubscribeMixin() implements OnInit, AfterViewInit {
	@ViewChild('uploadOrderFileField') uploadFileFormField: ElementRef;
	public today: Date = new Date();
	public showSideCart: boolean = false;
	public cartQuantity: number = 0;
	public user: ProUser;
	public loaderVisible: boolean = false;
	public sidebarVisible: boolean = false;
	public showDashboardSubmenu: boolean = false;
	public showSearchDialog: boolean = false;
	public showQuickAdd: boolean = false;
	public showItemDeleted: boolean = false;
	public showUndoMessage: boolean = false;
	public showUploadOrder: boolean = false;
	public uploadOrderType: number = 0;
	public undoMessage: UndoMessage;
	public currentOpenMenu: string = '';
	public message: string = '';
	public messageType: 'success' | 'error';
	public ezPayText: 'EZPay' | 'PromptPay' | '' = '';
	public removeText: string = '';

	public loaderIds: Array<{ key: string; observable: SubscriptionLike }> = [];

	private lastDeletedItem: {productCode: string, quantity: number};
	
	public recentVendors: Array<VendorShort>;
	public uploadOrderTypeOptions = [
		{ label: 'Product Code, Quantity', value: 0 },
		{ label: 'Quantity, Product Code', value: 1 }
	];
	public uploadErrors: Array<any> = [];
	public Permissions = Permissions;
	public disallowed: boolean = false;
	constructor(
		private actions$: Actions, 
		private store: Store<IAppState>, 
		private router: Router, 
		private authService: AuthService, 
		private siteService: SiteService,
		private dataService: DataService,
	) { super(); }

	ngOnInit(): void {
		let user = this.authService.user;
		this.actions$
			.pipe(
				tap((a) => {
					switch (a.type) {
						case CartActionTypes.OPEN_CART:
							this.showSideCart = true;
							break;
						case CartActionTypes.CLOSE_CART:
							this.showSideCart = false;
							break;
						case CartActionTypes.GET_CART_SUCCESS:
							if((a as GetCartSuccess).cart?.cartItems) {
								this.cartQuantity = (a as GetCartSuccess).cart.cartItems.reduce((a, b) => a + b.quantity, 0) + (a as GetCartSuccess).cart.cartBundles.reduce((a, b) => a + b.quantity, 0);
							}
							break;
						case SiteActionTypes.OPEN_SEARCH:
							this.showSearchDialog = true;
							break;
						case SiteActionTypes.CLOSE_SEARCH:
							this.showSearchDialog = false;
							break;
						case SiteActionTypes.OPEN_SIDE_NAV:
							this.sidebarVisible = true;
							//setTimeout(() => document.getElementsByTagName('body')[0].addEventListener('click', this.closeSideNav.bind(this), true), 500);
							break;
						case SiteActionTypes.CLOSE_SIDE_NAV:
							this.sidebarVisible = false;
							this.showDashboardSubmenu = false;
							//document.getElementsByTagName('body')[0].removeEventListener('click', this.closeSideNav.bind(this), true)
							setTimeout(() => document.getElementsByClassName('p-sidebar-mask')[0]?.remove(), 250); //primeng bug, need to make sure this happens
							break;
						case CartActionTypes.ITEM_DELETED_FROM_CART:
							this.lastDeletedItem = {productCode: (a as ItemDeletedFromCart).productCode, quantity: (a as ItemDeletedFromCart).quantity };
							this.showItemDeleted = true;
							break;
						case SiteActionTypes.SET_NAV_MENU_OPEN:
							this.currentOpenMenu = (a as SetNavMenuOpen).menu;
							break;
						case SiteActionTypes.NAVIGATING:
							this.showItemDeleted = false;
							this.showDashboardSubmenu = false;
							this.loaderVisible = false;
							break;
						case SiteActionTypes.SET_UNDO_MESSAGE:
							this.undoMessage = (a as SetUndoMessage).undoMessage;
							this.showUndoMessage = (this.undoMessage != undefined);
							break;
						case SiteActionTypes.SHOW_UPLOAD_ORDER:
							this.showQuickAdd = false;
							setTimeout(() => document.getElementsByClassName('p-sidebar-mask')[0]?.remove(), 250); //primeng bug, need to make sure this happens
							this.showUploadOrder = true;
							break;
						case SiteActionTypes.HIDE_UPLOAD_ORDER:
							this.showUploadOrder = false;
							this.uploadErrors = [];
							this.uploadFileFormField.nativeElement.value = null;
							break;
						case UserActionTypes.SET_EZPAY_STATUS:
							this.ezPayText = (a as SetEzPayStatus).status == 1 ? 'EZPay' : ((a as SetEzPayStatus).status == 3 ? 'PromptPay' : '');
							break;
						case SiteActionTypes.OPEN_QUICK_ADD:
							this.showQuickAdd = true;
							break;
						case SiteActionTypes.CLOSE_QUICK_ADD:
							this.showQuickAdd = false;
					}
				})
			)
			.subscribe();
		this.store.pipe(select(selectUser), takeUntil(this.destroy$)).subscribe((u) => {
			if(u) {
				this.user = u;
			} else {
				if(this.authService.token) {
					this.store.dispatch(new SetUser(new ProUser(jwt_decode(this.authService.token))))
				}
			}
		});
		if(this.authService.isLoggedIn) {
			this.store.dispatch(new GetCart());
			this.dataService.getEzPayStatus().subscribe(resp => this.store.dispatch(new SetEzPayStatus(resp)))
		}

		this.recentVendors = this.siteService.getRecentVendors();
		this.siteService.onSetLoaderVisible.subscribe(v => this.loaderVisible = v)

		this.siteService.onDisallow.pipe(takeUntil(this.destroy$)).subscribe(d => this.disallowed = d)
	}

	ngAfterViewInit(): void {
		this.siteService.onShowLoader.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (this.loaderIds.filter((l) => l.key == id).length > 0) {
				//if hide has already fired...
				return;
			}
			let obs = timer(1000);
			this.loaderIds.push({
				key: id,
				observable: obs.pipe(take(1)).subscribe((val) => {
					this.loaderVisible = true;
				}),
			});
		});
		this.siteService.onHideLoader.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			let ldr = this.loaderIds.filter((o) => o.key == id);
			if (ldr.length > 0) {
				if (ldr[0].observable) {
					ldr[0].observable.unsubscribe();
				}
				ldr[0].observable = null;
			} else {
				this.loaderIds.push({ key: id, observable: null });
			}

			if (this.loaderIds.filter((x) => x.observable != null).length == 0) {
				this.loaderVisible = false;
			}
			this.loaderIds = this.loaderIds.filter(o => o.key != id)
		});
	}
	openCart() {
		this.store.dispatch(new OpenCart());
	}

	openDashboardSubmenu(e) {
		this.showDashboardSubmenu = true;
		e.stopPropagation()
	}
	openSearch() {
		this.store.dispatch(new OpenSearch());
	}
	openQuickOrder() {
		this.store.dispatch(new OpenQuickAdd())
	}
	onSideNavHide() {
		this.showDashboardSubmenu = false;
	}
	vendorSelected(v:VendorShort) {
		this.siteService.addRecentVendor(v);
		this.recentVendors = this.siteService.getRecentVendors();  //should be observable
		this.router.navigate(['/vendor', v.vendorId])
	}

	closeSearch() {
		this.store.dispatch(new CloseSearch());
	}
	logout() {
		this.authService.logout();
		this.store.dispatch(new Logout());
		this.router.navigate(['/login']);
	}

	undoDelete() {
		if(!this.lastDeletedItem) return;
		this.store.dispatch(new AddToCart(this.lastDeletedItem.productCode, 1));
		this.showItemDeleted = false;
	}
	openMenu(menu) {
		if(menu == this.currentOpenMenu) {
			menu = ''; //clicking again to close
		}
		this.store.dispatch(new SetNavMenuOpen(menu));
	}
	doUndo(callback, parameters) {
		callback(parameters);
	}
	closeSideNav() {
		if(this.sidebarVisible) {
			this.store.dispatch(new CloseSideNav())
		}
	}
	openSideNav() {
		this.store.dispatch(new OpenSideNav())
	}
	closeUploadOrder() {
		this.store.dispatch(new HideUploadOrder());
	}
	hideQuickAdd() {
		this.store.dispatch(new CloseQuickAdd());
	}

	quickProductSelect(product) {
		this.router.navigate(['/shop', 'product', product.productCode])
	}
	upload(e) {
		this.uploadErrors = [];
		this.dataService.uploadOrderFile(this.uploadOrderType, this.removeText, e.srcElement.files[0]).subscribe(resp => {
			this.uploadFileFormField.nativeElement.value = null;
			if(resp.length == 0) {
				this.store.dispatch(new HideUploadOrder());
				this.store.dispatch(new SuccessMessageSet("Your file was uploaded without errors"));
			} else {
				this.uploadErrors = resp;
			}
			this.store.dispatch(new GetCart());
		})
	}
}
