<div class="cart-tile-container">
	<ul *ngIf="cart">
		<li *ngFor="let item of cart.cartItems" class="cart-item">
			<app-cart-tile-item [cartItem]="item"></app-cart-tile-item>
		</li>
		<li class="total"></li>
	</ul>
	<div class="actions">
		<div class="total" *ngIf="cart">
			<div>Total: </div>
			<div>{{cart.getTotal() | currency}}</div>
		</div>
		<button pButton class="P-button-success check-out-button" (click)="goToCheckout()" label="Check Out"></button>		
	</div>

</div>
