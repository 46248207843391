import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "app-thankyou",
	templateUrl: "./thankyou.component.html",
	styleUrls: ["./thankyou.component.scss"],
})
export class ThankyouComponent implements OnInit {
	public orderNumber: number;
	constructor(private router: Router) {}

	ngOnInit(): void {
		this.orderNumber = history.state.orderId;
	}

	goTo(page) {
		switch(page) {
			case 'myaccount':
				this.router.navigate(['/account']);
				break;
			case 'shopping':
				this.router.navigate(['/warehouse']);
				break;
		}
	}
	print() {
		window.open('/receipt/'+this.orderNumber, '_blank', 'toolbar=0,location=0,menubar=0');
	}
}
