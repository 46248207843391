import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from "src/app/services/data.service";
import { reportDiscontinued } from "src/app/models/Reports/Report";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {


  public Discon: Array<reportDiscontinued>;

  constructor(private dataService: DataService) { }

   ngOnInit(): void {

    this.dataService.getDiscontinued().subscribe((c) => {
      //  this.Discon = c;
      this.Discon = c;
    });
}
}
