
<div class="container" *ngIf="entry">
	<h1>Price Protection</h1>
	<header>
		<div class="form-values">
			<dl>
				<dt>Order Number:</dt>
				<dd>{{entry.orderId}}&nbsp;</dd>
				<dt>Vendor:</dt>
				<dd>{{entry.vendor}}&nbsp;</dd>
				<dt>Rebate Week:</dt>
				<dd>{{entry.programWeek}}&nbsp;</dd>
				<dt>Submitted On</dt>
				<dd>{{entry.createdDate | date:"MMMM dd, yyyy"}}&nbsp;</dd>
				<dt>Submitted By:</dt>
				<dd>{{entry.rebateForm.fname}} {{entry.rebateForm.lname}}&nbsp;</dd>
				<dt>Debit Memo:</dt>
				<dd>{{entry.rebateForm.memo}}&nbsp;</dd>
			</dl>
		</div>
	</header>
	<main>
		<section>
			<h2>Items</h2>
			<ul class="line-items">
				<li class="header">
					<div class="model">Model Name</div>
					<div class="quantity">Quantity</div>
					<div class="reimbursement">Reimbursements</div>
					<div class="serials">Serial Numbers</div>
				</li>
				<li *ngFor="let item of entry.rebateLineItems">
					<div class="model">{{item.model}}</div>
					<div class="quantity">{{item.quantity}}</div>
					<div class="reimbursement">{{item.reimbursement / item.quantity | currency}}</div>
					<div class="serials">
						<ul>
							<li *ngFor="let serial of item.serials">{{serial}}</li>
						</ul>
					</div>
				</li>
				<li class="footer">
					<div class="model">Totals:</div>
					<div class="quantity">{{quantityTotal}}</div>
					<div class="reimbursement">{{reimbursementTotal   | currency}}</div>
					<div class="serials"></div>
				</li>
			</ul>
		</section>
		<section>
			<h2>Files</h2>
			<ul>
				<li *ngFor="let file of entry.rebateFiles">{{file.fileName}}</li>
			</ul>
		</section>
	</main>
</div>
