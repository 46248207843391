
<header>
	<h1>Claim Number: {{rebate.claimId}}</h1>
	<div class="button" (click)="newRebate()"><mat-icon>add</mat-icon>Add New Rebate</div>
</header>
<app-rebate-claim-details *ngIf="rebate" [rebate]="rebate"></app-rebate-claim-details>
<div class="actions">
	<div class="button" (click)="print()">Print</div>
	<div class="button" (click)="viewHistory()">View Status &amp; History</div>
</div>

