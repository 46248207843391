

	<div class="article {{mode}}">
		<div *ngIf="!article.image" class="image no-image"></div>
		<div *ngIf="article.image && mode != 'large'" class="image" [ngStyle]="darkenBg(article.image?.url, article.backgroundColor || '#fff')"></div>
		<div *ngIf="article.image && mode == 'large'" class="image" [ngStyle]="{'background-image': 'url(' + article.image?.url + ')', 'background-color': article.backgroundColor || '#fff'}"></div>
		<div class="info">
			<div class="badge" *ngIf="article.badge"><div class="tag">{{article.badge}}</div></div>
			<div class="title"><a [routerLink]="['/cms', 'article', article.id, article.slug]">{{article.articleName}}</a></div>
			<div class="excerpt">{{article.excerpt}}</div>
			<div class="author" *ngIf="article.author">
				<ng-container *ngIf="article.author; else noAuthor">
					<div class="avatar" [ngStyle]="{'background-image': 'url('+article.author.avatar?.url+')'}"></div>
					<div class="name" *ngIf="article.author.email">By <a href="mailto:{{article.author.email}}">{{article.author.name}}</a></div>
					<div class="name" *ngIf="!article.author.email">By {{article.author.name}}</div>
				</ng-container>
				<ng-template #noAuthor>
					<div class="avatar no-author"></div>
					<div class="name"></div>
				</ng-template>				
			</div>
			<div class="date">{{article.postDate | date: 'MMM dd, yyyy'}}</div>
		</div>
	</div>

