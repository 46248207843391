import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ProductTechSpec, TechSpec } from "src/app/models/shopping/product";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-tech-specs",
	templateUrl: "./tech-specs.component.html",
	styleUrls: ["./tech-specs.component.scss"],
})
export class TechSpecsComponent implements OnInit {
	@Input() productCode: string;

	public techSpecs: Array<TechSpec>;
	constructor(private dataService: DataService) {}

	ngOnInit(): void {
		this.dataService.getTechSpecs(this.productCode).subscribe((resp:any) => {
			this.techSpecs = resp;
		})
	}

}
