import { Component, HostListener, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { AlgoliaEvent } from "src/app/models/search/algolia";
import { Product } from "src/app/models/shopping/product";
import { AlgoliaService } from "src/app/services/algolia.service";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit {

	@Input() touched: boolean = false;
	@Input() valid: boolean = false;
	@Input() placeholder: string;
	@Output() onSelect: Subject<Product> = new Subject();
	@HostListener('document:click') onWindowResize() {
		this.showResults = false;
	}
	public term: string;
	private allProducts: Array<Product> = [];
	public topProducts: Array<Product> = []
	public topBrands: Array<{ brandName: string, brandId: number, products: Array<Product>}> = []
	public activeIndex: number;
	public showResults: boolean = false;

	private cancelSearch: Subject<any> = new Subject();
	public searchType: number = 1;
	public searchTypeOptions = [
		{ name: 'Product Code', value: 1 },
		{ name: 'Name', value: 2 },
	]
	constructor(private dataService: DataService, private router: Router, private algoliaService: AlgoliaService, private authService: AuthService) {}

	ngOnInit(): void {}

	search() {
		if (this.term?.length > 1) {
			this.cancelSearch.next(null);
			this.dataService
				.search({ searchTerm: this.term, searchType: 7, fromDate: null, toDate: null })
				.pipe(debounceTime(3333), takeUntil(this.cancelSearch), )
				.subscribe((resp:any) => {
					this.algoliaService.queryID = resp.queryID;
					this.algoliaService.index = resp.index;
					this.allProducts = resp.productSearchResults.map(p => new Product(p)); 
					this.topProducts = this.allProducts.slice(0,5); //just display top 5
					this.getTopBrands();
					this.activeIndex = undefined; 
					this.showResults = true;
				});
		} else {
			this.allProducts = [];
		}
	}

	getTopBrands() {
		this.topBrands = [];
		let allBrands = []
		this.allProducts.forEach(p => {
			let brand = allBrands.find(b => b.brandName == p.brandName)
			if(!brand) {
				allBrands.push({ brandName: p.brandName, brandId: p.brandId, products: [] })
			}
			allBrands.find(b => b.brandName == p.brandName).products.push(p)
		})
		allBrands.sort((a,b) => b.products.length - a.products.length);
		this.topBrands = allBrands.slice(0,6); //just get top 6 brands
	}
	selectProduct(product) {
		this.term = '';
		this.onSelect.next(product);
		this.allProducts = [];
	}

	goToProduct(code, position = null) {
		//log click
		if(position == null) {
			position = this.allProducts.findIndex(p => p.productCode == code) + 1;
		}
		const event: AlgoliaEvent = this.algoliaService.generateEventObject({eventType: 'click', eventName: 'Search Result Clicked', userToken: String(this.authService.user.userId), objectID: code, position })
		this.algoliaService.sendEvent(event);
		this.showResults = false;
		this.term = '';
		this.router.navigate(['/', 'shop', 'product', code])
	}
	onKeyUp(e) {
		if(this.allProducts?.length > 0) {
			switch(e.keyCode) {
				case 38: //up
					if(this.activeIndex) { this.activeIndex = Math.max(0, this.activeIndex - 1); }
					//this.scrollOptionIntoView('product_code_search_option_'+this.activeIndex)
					break;
				case 40: 
					this.activeIndex === undefined  ? this.activeIndex = 0 : this.activeIndex = Math.min(this.activeIndex + 1, 4);
					//this.scrollOptionIntoView('product_code_search_option_'+this.activeIndex)
					break;
				case 13: 
					if(!this.activeIndex) { 
						this.goToProduct(this.topProducts[0].productCode); 
					} else {
						this.goToProduct(this.topProducts[this.activeIndex].productCode); 
					}
					break;
				default:
					this.search();
			}			
		}  else {
			this.search();
		}
	}

	scrollOptionIntoView(id) {
		//not implemented yet
	}


}
