import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { Vendor } from "src/app/models/vendor/vendor";
import { DataService } from "src/app/services/data.service";
import { StrapiService } from "src/app/services/strapi.service";
import { CloseVendorPreview, SiteActionTypes } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-vendors",
	templateUrl: "./vendors.component.html",
	styleUrls: ["./vendors.component.scss"],
})
export class VendorsComponent extends AutoUnsubscribeMixin() implements OnInit {
	@ViewChild("letterList") letterList: ElementRef;

	private vendors: Array<Vendor>;
	public vendorsToDisplay: Array<Vendor>;
	public vendor: Vendor;
	public searchTerm: string;
	public vendorPreviewVisible: boolean = false;

	private isSliding: boolean = false;

	constructor(private dataService: DataService, private strapiService: StrapiService, private store: Store<IAppState>, private router: Router, private actions$: Actions) { super(); }

	ngOnInit(): void {
		this.dataService.getAllVendors().subscribe((resp: any) => {
			this.vendors = resp.map((v) => new Vendor(v)).sort(this.sortVendors);
			this.vendorsToDisplay = this.vendors;
			this.strapiService.getVendors().subscribe(vList => {
				vList.forEach(v => {
					let vend = this.vendors.find(vendor => String(vendor.vendorId) == v.slug);
					if(vend && v.priceList) {
						vend.priceList = v.priceList[0];
					}					
				});
			})
		});
		this.actions$.pipe(
			takeUntil(this.destroy$),
			ofType(SiteActionTypes.CLOSE_VENDOR_PREVIEW)
		).subscribe(() => {
			this.vendorPreviewVisible = false;
			setTimeout(() => document.getElementsByClassName('p-sidebar-mask')[0]?.remove(), 250); //primeng bug, need to make sure this happens
		})
	}

	sortVendors(a, b) {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	}
	showPreview(id) {
		this.dataService.getVendor(id).subscribe(vendor => {
			this.vendor = vendor;
			this.vendorPreviewVisible = true;
			this.vendor.priceList = this.vendors.find(v => v.vendorId == vendor.vendorId)?.priceList;
		})
	}

	scrollTo(letter) {}

	moveForward() {
		if (this.isSliding) return;
		this.isSliding = true;
		getComputedStyle(this.letterList.nativeElement).left;
		let left = parseInt(getComputedStyle(this.letterList.nativeElement).left);
		this.letterList.nativeElement.style.setProperty("--letters-start", left - 55 + "px");
		setTimeout(() => (this.isSliding = false), 250);
	}
	moveBack() {
		if (this.isSliding) return;
		this.isSliding = true;
		getComputedStyle(this.letterList.nativeElement).left;
		let left = parseInt(getComputedStyle(this.letterList.nativeElement).left);
		this.letterList.nativeElement.style.setProperty("--letters-start", left + 55 + "px");
		setTimeout(() => (this.isSliding = false), 250);
	}
	search() {
		this.vendorsToDisplay = this.vendors.filter(v => v.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1)
	}
	closePreview() {
		this.store.dispatch(new CloseVendorPreview());
	}
	goToVendor() {
		this.router.navigate(['/vendor', this.vendor.vendorId])
	}
}
