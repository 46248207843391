<div class="container">
	<header>
		<h1>Vendors</h1>
		<div class="letters desktop" *ngIf="false">
			<div class="forward" (click)="moveForward()">&lt;</div>
			<div class="letter-container">
				<ul #letterList>
					<li (click)="scrollTo('A')">A</li>
					<li (click)="scrollTo('B')">B</li>
					<li (click)="scrollTo('C')">C</li>
					<li (click)="scrollTo('D')">D</li>
					<li (click)="scrollTo('E')">E</li>
					<li (click)="scrollTo('F')">F</li>
					<li (click)="scrollTo('G')">G</li>
					<li (click)="scrollTo('H')">H</li>
					<li (click)="scrollTo('I')">I</li>
					<li (click)="scrollTo('J')">J</li>
					<li (click)="scrollTo('K')">K</li>
					<li (click)="scrollTo('L')">L</li>
					<li (click)="scrollTo('M')">M</li>
					<li (click)="scrollTo('N')">N</li>
					<li (click)="scrollTo('O')">O</li>
					<li (click)="scrollTo('P')">P</li>
					<li (click)="scrollTo('Q')">Q</li>
					<li (click)="scrollTo('R')">R</li>
					<li (click)="scrollTo('S')">S</li>
					<li (click)="scrollTo('T')">T</li>
					<li (click)="scrollTo('U')">U</li>
					<li (click)="scrollTo('V')">V</li>
					<li (click)="scrollTo('W')">W</li>
					<li (click)="scrollTo('X')">X</li>
					<li (click)="scrollTo('Y')">Y</li>
					<li (click)="scrollTo('Z')">Z</li>
				</ul>
			</div>
			<div class="back" (click)="moveBack()">&gt;</div>
		</div>
	</header>
	<aside>
		<span class="p-input-icon-left">
			<i class="pi pi-search"></i>
			<input pInputText placeholder="Search" [(ngModel)]="searchTerm" (keyup.enter)="search()">
		</span>
		<div class="button" (click)="search()">Search</div>
	</aside>
	<main *ngIf="vendorsToDisplay">
		<div class="row header">
			<div class="name">Name</div>
			<div class="overview desktop">Overview</div>
			<div class="website">Website</div>
			<div class="price-list">Price List</div>
			<div class="action"></div>
		</div>
		<div class="row" *ngFor="let vendor of vendorsToDisplay">
			<div class="name">{{vendor.name}}</div>
			<div class="overview desktop" (click)="showPreview(vendor.vendorId)">Preview</div>

			<div class="website"><span *ngIf="vendor.website"><a href="{{vendor.url}}" target="_blank">Website</a></span></div>

			<div class="price-list"><a *ngIf="vendor.priceList" [href]="vendor.priceList.url" target="_blank">Price List</a></div>

			<div class="action"><div class="button-container"><a class="button" [routerLink]="['/vendor', vendor.vendorId]">View</a></div></div>			

		</div>
	</main>
</div>

<p-sidebar [(visible)]="vendorPreviewVisible" position="right" [styleClass]="'side-vendor-preview'">
	<div class="sidebar-content" *ngIf="vendor">
		<h1>{{vendor.name}}</h1>
		<p [innerHTML]="vendor.blurb"></p>
		<div class="main-contact contact">
			<div>
				<h4>Vendor Contact</h4>
				<ul>
					<li *ngIf="vendor.phone"><mat-icon>call_outline</mat-icon>{{vendor.phone}}</li>
					<li *ngIf="vendor.website"><mat-icon>language_outline</mat-icon>{{vendor.website}}</li>
					<li *ngIf="vendor.address"><mat-icon>home_outline</mat-icon>{{vendor.address}}</li>
					<li><mat-icon>home_outline</mat-icon>{{vendor.city}}, {{vendor.state}} {{vendor.zip}}</li>
				</ul>				
			</div>
			<div>
				<a *ngIf="vendor.priceList" [href]="vendor.priceList.url" target="_blank">Price List</a>
			</div>
		</div>
		<div class="contacts">
			<div class="contact" *ngIf="vendor.contacts.length > 0">
				<h4>{{vendor.contacts[0].contactType}}</h4>
				<ul>
					<li><mat-icon>person_outline</mat-icon>{{vendor.contacts[0].firstName}} {{vendor.contacts[0].lastName}}</li>
					<li *ngIf="vendor.contacts[0].phone"><mat-icon>phone_outline</mat-icon>{{vendor.contacts[0].phone}}</li>
					<li *ngIf="vendor.contacts[0].email"><mat-icon>contact_mail_outline</mat-icon>{{vendor.contacts[0].email}}</li>
				</ul>
			</div>
			<div class="contact" *ngIf="vendor.contacts.length > 1">
				<h4>{{vendor.contacts[1].contactType}}</h4>
				<ul>
					<li><mat-icon>person_outline</mat-icon>{{vendor.contacts[1].firstName}} {{vendor.contacts[1].lastName}}</li>
					<li *ngIf="vendor.contacts[1].phone"><mat-icon>phone_outline</mat-icon>{{vendor.contacts[1].phone}}</li>
					<li *ngIf="vendor.contacts[1].email"><mat-icon>contact_mail_outline</mat-icon>{{vendor.contacts[1].email}}</li>
				</ul>
			</div>
		</div>
		<div class="actions">
			<div class="button" (click)="closePreview()">Close</div>
			<div class="button filled" (click)="goToVendor()">View Detail</div>
		</div>
	</div>
</p-sidebar>
