
export class Subscription {
	public subscriptionTypeId: number;
	public subscriptionName: string;
	public mailingTypeId: number;
	public allowMultipleQuantity: boolean;
	public users: Array<SubscriptionUser>

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class SubscriptionUser {
	public subscriptionId: number;
	public userId: number;
	public subscriptionTypeId: number;
	public quantity: number;
	public isPrimary: boolean;
	public firstName: string;
	public lastName: string;
	public email: string;
	public addressId: number;
	public address1: string;
	public city: string;
	public state: string;
	public zip: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}