import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CompanyPointsUserData } from 'src/app/models/dashboard/points';
import { DataService } from 'src/app/services/data.service';
import { SetDashboardTitle } from 'src/app/store/actions/site.actions';
import { IAppState } from 'src/app/store/state/app.state';

@Component({
  selector: 'app-points-company',
  templateUrl: './points-company.component.html',
  styleUrls: ['../points-user/points-user.component.scss', './points-company.component.scss']
})
export class PointsCompanyComponent implements OnInit {

  public data: Array<CompanyPointsUserData>

  constructor(private dataService: DataService, private store: Store<IAppState>) { }

  ngOnInit(): void {
    this.store.dispatch(new SetDashboardTitle("Company Activity"))

	this.dataService.getPointsCompanyData().subscribe(d => this.data = d)
  }


}
