
<div class="bundle" *ngIf="bundle">
	<div class="images">
		<app-bundle-images [images]="allImages"></app-bundle-images>
	</div>
	<div class="info">
		<aside>
			<div class="stock in"><i class="check"></i>In Stock</div>

	  <!--
		<div class="stock in" *ngIf="isInStock"><i class="check"></i>In Stock</div>
	  <div class="stock out" *ngIf="!isInStock">Out of Stock</div>
		-->

			<div class="chips">
				<div class="bundle">Bundle</div>
			</div>


		</aside>
		<h1>{{bundle.name}}</h1>
		<div class="bundle-code">
			<span>Bundle Code:</span>&nbsp;
			<span>{{bundle.bundleCode}}</span>
			<span [copy-clipboard]="bundle.bundleCode" (copied)="notifyCopy($event)"><i class="pi pi-copy"></i></span>
		</div>
		<div *ngIf="bundle.bundleCode === 'PROBundle2024'" class="costs">
		<div class="cost"><span>YOUR PRICE: </span><span class="price retail">{{bundle.retail | currency}}</span></div>
	</div>
		<div *ngIf="bundle.bundleCode !== 'PROBundle2024'" class="costs">
			<div class="cost"><span>FULL PRICE: </span><span class="price full">{{fullPrice | currency}}</span></div>
			<div class="cost"><span>YOUR PRICE: </span><span class="price retail">{{bundle.retail | currency}}</span></div>
			<div class="cost"><span>SAVINGS: </span><span class="price savings">{{fullPrice - bundle.retail | currency}}</span></div>
		</div>
		<div class="products">
			<p>Includes:</p>
			<div class="product">
				<div class="quantity header">Qty</div>
				<div class="product-name header">Product</div>
				<div class="product-code header">Product Code</div>
				<div class="retail header">Cost</div>
			</div>
			<div class="product" *ngFor="let product of bundle.products">
				<div class="quantity">{{product.quantity}}</div>
				<div class="product-name"><a [routerLink]="['/shop', 'product', product.product.productCode]">{{product.product.modelName}}</a></div>
				<div class="product-code">{{product.product.productCode}}</div>
				<div class="retail">{{product.product.retail | currency}}</div>
			</div>
		</div>
		<div class="purchase">
			<app-product-quantity [multiple]="1" [(quantity)]="quantity"></app-product-quantity>
			<div class="add-to-cart" (click)="addToCart();"><mat-icon>shopping_cart</mat-icon>Add to Cart</div>
		</div>
	</div>
</div>

