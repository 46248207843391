import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { SearchCriteria, SearchResults } from "src/app/models/search/search-results";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-search",
	templateUrl: "./search.component.html",
	styleUrls: ["./search.component.scss"],
})
export class SearchComponent extends AutoUnsubscribeMixin() implements OnInit {
	public term: string;
	public searchCriteria: SearchCriteria = new SearchCriteria();
	public searchResults: SearchResults;
	public tempSearchTerm: string; //this is to prevent highlighting something in the search results before doing the search

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private dataService: DataService,
	) { super(); }

	ngOnInit(): void {
		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
			if(params.get('query')) {
				this.tempSearchTerm = params.get('query');
				this.searchCriteria = new SearchCriteria({ searchType: 4, searchTerm: this.tempSearchTerm, fromDate: null, toDate: null});
				this.search();
			}
		})
	}

	search() {
		this.searchCriteria.searchTerm = this.tempSearchTerm;
		this.searchCriteria.searchType = parseInt(String(this.searchCriteria.searchType)); //need this bc radio button makes it a string
		this.dataService.search(this.searchCriteria).subscribe(r => this.searchResults = r);
	}

	seeAll(type) {
		this.searchCriteria.searchType = type;
		this.search();
	}
}
