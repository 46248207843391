import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ProUser } from "src/app/models/pro-user";
import { TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, ShowDownloadDialog } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

@Component({
	selector: "app-payment-confirmation",
	templateUrl: "./payment-confirmation.component.html",
	styleUrls: ["./payment-confirmation.component.scss"],
})
export class PaymentConfirmationComponent implements OnInit {
	public invoices: Array<any>;
	public receiptNumber: string;
	public tableInfo: TableInfo;
	public headers: Array<{key: string, value: any }>;
	private user: ProUser;
    private accountAddress: any;
    public DateSubmitted: Date;

  constructor(private dataService: DataService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private store: Store<IAppState>,
    private authService: AuthService, private datePipe: DatePipe, private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {

      this.receiptNumber = this.config.data.receiptNumber;

      this.DateSubmitted = this.config.data.DateSubmitted;

      this.dataService.getEzpayHistoricalDetail(this.receiptNumber).subscribe(resp => {

      this.invoices = resp;

	  this.dataService.getAccountAddress().subscribe(a => { this.accountAddress = a; this.getTableInfo();})
		});

      this.user = this.authService.user;
	}
	sum(field) {
		return this.invoices.reduce((a,b) => a + b[field], 0);
	}

	showDownloadOptions() {
		this.store.dispatch(new ShowDownloadDialog(this.invoices, this.tableInfo, this.headers));
	}
	close() {
		this.ref.close();
	}

    print() {
		const doc = new jsPDF({ orientation: "l" });
		if(this.headers) {
			let hdrs = [];
			this.headers.forEach(h => {
				hdrs.push([h.key, h.value])
			})
			hdrs.push([]); //blank row?
			autoTable(doc, { body: hdrs });
		}
		autoTable(doc, { head: [this.tableInfo.columns.filter(c => c.field).map((c) => c.title)], body: this.convertDataForPdf() });
		window.open(URL.createObjectURL(new Blob([doc.output('blob')], { type: 'application/pdf'})), 'payment');

	}

	getTableInfo() {
        this.user = this.authService.user;

		this.headers = [
			{ key: 'Receipt Number', value: this.receiptNumber },
			{ key: 'User', value: this.user.name },
			{ key: 'Account Number', value: this.user.accountNumber },
            { key: 'Date Submitted', value: this.datePipe.transform(this.config.data.DateSubmitted) },
			{ key: 'Saved Today', value: this.currencyPipe.transform(this.sum('proDiscount') + this.sum('ezPayDiscount')) }
		];

        if (this.accountAddress) {
			this.headers.push({ key: 'Company', value: `${this.accountAddress.dba}\n${this.accountAddress.address1}\n${this.accountAddress.address2 ? this.accountAddress.address2 + '\n' : ''}${this.accountAddress.city}, ${this.accountAddress.state} ${this.accountAddress.zip}\n${this.accountAddress.phone}`})
		}

		this.tableInfo = new TableInfo({
			columns: [
                { title: 'Invoice Number', field: 'invoice' },         
                { title: 'Deduction Date', field: 'deductionDate', type: 'date', format: 'MM/dd/yyyy' },              
				{ title: 'Vendor', field: 'vendor' },
				{ title: 'Vendor Invoice Number', field: 'vendorInvoiceNumber' },
				{ title: 'Gross', field: 'gross', type: 'currency', total: true },
				{ title: 'Pro Discount', field: 'proDiscount', type: 'currency', total: true },
				{ title: 'EZ-Pay Discount', field: 'ezPayDiscount', type: 'currency', total: true },
				{ title: 'Net To Pay', field: 'totalToPay', type: 'currency', total: true },
			],
		});
	}	

	private convertDataForPdf() {
		let arrs = [];
		this.invoices.forEach((r) => {
			let arr = [];
			this.tableInfo.columns.forEach((c) => {
				switch(c.type) {
					case 'currency': 
						arr.push(this.currencyPipe.transform(r[c.field])); break;
					case 'date':
						arr.push(this.datePipe.transform(r[c.field])); break;
					case 'inventory':
						arr.push(r[c.field] > 50 ? 'Yes' : 'No'); break;
					default: 
						arr.push(r[c.field]); break;
				}
			});
			arrs.push(arr);
		});
		let totals = [];
		this.tableInfo.columns.forEach(c => {
			if(c.total) {
				let tot = this.sum(c.field)
				switch(c.type) {
					case 'currency':
						totals.push(this.currencyPipe.transform(tot)); break;
					default:
						totals.push(tot); break;
				}
			} else {
				totals.push('');
			}
		})
		arrs.push(totals);
		//line below is slicker than above, but columns don't match headers if they're in a different order than what comes back from api
		//this.data.forEach(o => arrs.push(Object.keys(o).reduce((a,b) => [...a, o[b]], [])))
		return arrs;
	}
}
