
<section>
	<app-sar-print-heading [title]="title" [summary]="summary" [type]="type"></app-sar-print-heading>

	<main>
		<ng-content></ng-content>
	</main>

	<app-sar-print-items [items]="items"></app-sar-print-items>
</section>
