import { Component, OnInit } from '@angular/core';
import { MostFrequentlyBought } from 'src/app/models/dashboard/most-frequently-bought';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-most-frequently-bought-list',
  templateUrl: './most-frequently-bought-list.component.html',
  styleUrls: ['./most-frequently-bought-list.component.scss']
})
export class MostFrequentlyBoughtListComponent implements OnInit {

  public mostFrequentlyBought: Array<MostFrequentlyBought>;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
	this.dataService.getMostFrequentlyBought().subscribe(r => this.mostFrequentlyBought = r);
  }

}
