import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription, SubscriptionUser } from "src/app/models/dashboard/subscriptions";
import { User } from "src/app/models/dashboard/user";
import { DataService } from "src/app/services/data.service";
import { SetDashboardTitle } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-subscriptions",
	templateUrl: "./subscriptions.component.html",
	styleUrls: ["./subscriptions.component.scss"],
})
export class SubscriptionsComponent implements OnInit {
	public subscriptions: Array<Subscription>;
	public openLists: { [key: string]: boolean } = {};
	public allUsers: Array<User>;
	public userId: number;
	public quantity: number  = 1;
	constructor(private dataService: DataService, private store: Store<IAppState>) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle('Subscriptions'))
		this.dataService.getSubscriptions().subscribe((r) => {
			this.subscriptions = r;
			this.openLists = this.subscriptions.reduce((a, e) => {
				return { ...a, [`list${e.subscriptionTypeId}`]: false };
			}, {});
		});
		this.dataService.getUsers().subscribe(u => this.allUsers = u);
	}

	expand(id) {
		this.openLists["list" + id] = !this.openLists["list" + id];
	}

	unsubscribe(subscriptionId, typeId) {
		this.dataService.unsubscribe(subscriptionId).subscribe((r: any) => {
			if (r.success === true) {
				var list = this.subscriptions.find((s) => s.subscriptionTypeId == typeId);
				list.users = list.users.filter((u) => u.subscriptionId != subscriptionId);
			}
		});
	}
	subscribe(type) {
		this.dataService.subscribe(this.userId, type, this.quantity).subscribe((resp:any) => {
			let newUser = new SubscriptionUser({ ...this.allUsers.find(u => u.userId == this.userId), subscriptionTypeId: type, subscriptionId: resp.data.subscriptionId })
			this.subscriptions.find(s => s.subscriptionTypeId == type).users.push(newUser)
		})
	}
}
