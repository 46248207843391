import { Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, tap } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { CloseCart } from "src/app/store/actions/cart.actions";
import { AllMessagesClear, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
	public usernameOrEmail: string;
	constructor(private dataService: DataService, private store$: Store<IAppState>, private router: Router) {}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((e) => e instanceof NavigationStart),
				tap(() => {
					this.store$.dispatch(new CloseCart());
					this.store$.dispatch(new AllMessagesClear());
				})
			)
	}

	resetPassword() {
		if (this.usernameOrEmail) {
			this.dataService.forgotPassword(this.usernameOrEmail).subscribe((resp) => {
				this.store$.dispatch(new SuccessMessageSet("A link to reset your password has been sent to your email"));
			});
		}
	}
	backToLogin() {
		this.router.navigate(["/login"]);
	}
}
