import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RebateEntry } from "src/app/models/dashboard/rebates";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-price-protection-print",
	templateUrl: "./price-protection-print.component.html",
	styleUrls: ["./price-protection-print.component.scss"],
})
export class PriceProtectionPrintComponent implements OnInit {
	public entry: RebateEntry;
	constructor(private dataService: DataService, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((map) => {
			if (map.get("orderId")) {
				this.dataService.getPriceProtection(map.get("orderId")).subscribe((resp) => (this.entry = resp));
				setTimeout(window.print, 500);
			}
		});
	}
}
