import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { filter, take } from 'rxjs/operators';
import { ConfirmationService } from "primeng/api";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { User } from "src/app/models/dashboard/user";
import { ProUser } from "src/app/models/pro-user";
import { DataService } from "src/app/services/data.service";
import { SetDashboardHeaderButtons, SetDashboardTitle } from "src/app/store/actions/site.actions";
import { LogInAs, SetUser, UserActionTypes } from "src/app/store/actions/user.actions";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-users",
	templateUrl: "./users.component.html",
	styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {

	public users: Array<User>;
	public openActions: number;
	public showUserForm: boolean = false;
	public addEditUser: User;
	public proUser: ProUser;

	constructor(private dataService: DataService, private store: Store<IAppState>, private router: Router, private confirmService: ConfirmationService, private actions$: Actions) {}


  ngOnInit(): void {
    this.store.pipe(select(selectUser)).subscribe(a => this.proUser = a);
    this.dataService.getUsers().subscribe((u) => (this.users = u));
    this.store.dispatch(new SetDashboardTitle('Users'));
		this.store.dispatch(new SetDashboardHeaderButtons([
		new DashboardHeaderButton({ text: 'Add New User', callback: () => this.addNew() })
    ]))

    document.getElementsByTagName('body')[0].addEventListener('click', () => this.openActions = 0)


	}

  

	showActions(e, id) {
		e.stopPropagation();
		this.openActions = id;
	}

	addNew() {
		this.addEditUser = new User();
		this.showUserForm = true;
	}
	

	edit(user) {
    this.addEditUser = user;   
    this.showUserForm = true;

  }

	delete(user) {
		this.dataService.deleteUser(user.userId).subscribe((resp:any) => {
			if(resp.success) {
				this.users = this.users.filter(u => u.userId !== user.userId)
			}
		});
  }

	permissions(user) {
		this.router.navigate(['/dashboard', 'permissions', 'user', user.userId])
	}

	logInAs(user) {
		this.confirmService.confirm({ 
			message: `You will be logging in as ${user.firstName} ${user.lastName}.  To return to your own profile, you will need to log out and log back in as yourself.  Are you sure you want to continue?`,
			accept: () => {
				this.store.dispatch(new LogInAs(user.userId));
				this.actions$.pipe(filter(a => a.type == UserActionTypes.LOG_IN_AS_SUCCESS), take(1)).subscribe(() => this.router.navigate(['/']));
			},
		})
	}
	saveUser(user:User) {
		if(user.userId > 0) {
			this.dataService.editUser(user).subscribe((resp:any) => {
				this.users = this.users.map(u => u.userId == resp.data.user.userId ? resp.data.user : u);
				this.showUserForm = false;
			})
		} else {
			user.userId = 0;
			this.dataService.addUser(user).subscribe((resp: any) => {
				this.users.push(resp.data.user);
				this.showUserForm = false;
			})
		}
	}
}
