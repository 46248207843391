<div class="sar-header">
	<header>
		<div><img src="../../../../../../assets/images/promaster_logo.png" alt="ProMaster"></div>
		<h1>{{title}}</h1>
	</header>
	<div class="contact-info">
		<dl>
			<dt>Company</dt>
			<dd>{{summary.company}}&nbsp;</dd>
			<dt>SubmittedBy</dt>
			<dd>{{summary.submittedBy}}&nbsp;</dd>
			<dt>Contact Phone</dt>
			<dd>{{summary.contactPhone}}&nbsp;</dd>
		</dl>
	</div>
	<div class="sar-info">
		<div><span>Your Reference Number</span> <span>{{summary.refNum}}</span></div>
		<div><span>SAR ID</span> <span>{{summary.sarId}}</span></div>
		<div><span>Dated</span> <span>{{summary.submitDate | date:'M/d/yyyy'}}</span></div>
		<div><span>Status</span><span>{{summary.status}}</span></div>
		<div><span>Settlement Code</span><span>{{summary.authorizationCode}}</span></div>
		<div><span>Reviewed By</span><span>{{summary.approvedBy}}</span></div>
		<div><span>Reviewed Date</span><span>{{summary.dateApproved | date:'M/d/yyyy'}}</span></div>
		<div *ngIf="mustReceiveBy"><span>Must Be Received By</span><span>{{mustReceiveBy | date: 'M/d/yyyy'}}</span></div>
	</div>
</div>
