import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { AutoUnsubscribeMixin } from 'src/app/mixins/auto-unsubscribe.mixin';
import { AlgoliaEvent } from 'src/app/models/search/algolia';
import { ProductSearchResult, SearchCriteria, SearchResults } from 'src/app/models/search/search-results';
import { Permissions } from 'src/app/modules/controls/enums/permissions';
import { AlgoliaService } from 'src/app/services/algolia.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorMessageSet, OpenSearch, SiteActionTypes } from 'src/app/store/actions/site.actions';
import { IAppState } from 'src/app/store/state/app.state';
import { environment } from 'src/environments/environment';
import { InvoiceSearchResult } from '../../../models/search/search-results';


@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.scss']
})

export class SearchWidgetComponent extends AutoUnsubscribeMixin() implements OnInit {

	@ViewChild('searchTermField') searchTermField: ElementRef;

	public searchType: number = 7;

  public searchTypeOptions = [
		{ name: 'All Categories', value: 7 },
		{ name: 'Products', value: 4 },
		{ name: 'Articles', value: 2 },
		{ name: 'Invoices', value: 1 },
  ]

	public term: string;
	public searchCriteria: SearchCriteria = new SearchCriteria();
  public searchResults: SearchResults;

	private allProductSearchResults: ProductSearchResult[];
	public topBrands: Array<{brandName: string, brandId: number, products: Array<ProductSearchResult>}> = [];
	public tempSearchType;
	public Permissions = Permissions;
  public environment = environment;

	private cancelSearch: Subject<any> = new Subject();
	
	constructor(private dataService: DataService, private actions$: Actions, private router: Router, private store: Store<IAppState>, private authService: AuthService, private algoliaService: AlgoliaService) {super();}

	ngOnInit(): void {
		this.actions$
		.pipe(
			takeUntil(this.destroy$),
			tap((a) => {
				switch (a.type) {
					case SiteActionTypes.OPEN_SEARCH:
						this.searchType = (a as OpenSearch).headerSearchType;
						this.searchTermField.nativeElement.focus();
						break;

					case SiteActionTypes.CLOSE_SEARCH:
						this.term = '';
						this.searchResults = null;
						break;

				}
			})
		)
		.subscribe();

	}

	search() {
    if (this.term?.length > 1) {

			this.cancelSearch.next(null);
			this.searchCriteria.searchTerm = this.term;
			this.searchCriteria.searchType = this.searchType;
      this.dataService.search(this.searchCriteria)

        .pipe(debounceTime(500), takeUntil(this.cancelSearch))
        .subscribe(r =>
        {
          this.searchResults = r;

           //this.allProductSearchResults = r.productSearchResults;
           //this.searchResults = this.searchType != 4 ?
           //{ ...r, productSearchResults: r.productSearchResults.slice(0, 15) } : { ...r, productSearchResults: r.productSearchResults.slice(0, 3) }

          //  Invoice = 1,
          //  Articles = 2,
          //  Products = 4,
          //  Backorder = 8


          if (r.productSearchResults != null)
          this.searchResults = this.searchType != 4 ?
          { ...r, productSearchResults: r.productSearchResults.slice(0, 5) } : { ...r, productSearchResults: r.productSearchResults.slice(0, 3)}




              this.algoliaService.queryID = r.queryID;
					    this.algoliaService.index = r.index;

              if (r.productSearchResults)
              {
						    this.getTopBrands(r.productSearchResults);
					    }
                            this.tempSearchType = this.searchType
                            //this.searchTermField.nativeElement.focus();
                            //this.searchTermField.nativeElement.click();
                            //this.tempSearchType.nativeElement.focus();
                            //let el: HTMLElement = this.tempSearchType.nativeElement;
                            //el.click();
				    });
		}
	}

	getTopBrands(productResults) {
		this.topBrands = [];
		let allBrands = []
		productResults.forEach(p => {
			let brand = allBrands.find(b => b.brandName == p.brandName)
			if(!brand) {
			allBrands.push({ brandName: p.brandName, brandId: p.brandId, products: [] })
			}
			allBrands.find(b => b.brandName == p.brandName).products.push(p)
		})
		  allBrands.sort((a,b) => b.products.length - a.products.length);
        this.topBrands = allBrands.slice(0, 6); //just get top 6 brands
	}
	downloadInvoice(id) {
		this.dataService.downloadInvoice(id).subscribe((resp:any) => {
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = id+'.pdf';
            a.href = URL.createObjectURL(resp);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);

		}, err => {
			switch(err.error.size) {
				case 30:// can't find file
					this.store.dispatch(new ErrorMessageSet("The invoice was not found"));
					break;
				case 32: //not your invoice
					this.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
					break;
			}
		})
	}
	searchProducts() {
		this.router.navigate(['/warehouse', 'search', this.term])
	}
	goToProduct(code, position = null) {
		if(position == null) {
			position = this.allProductSearchResults.findIndex(p => p.productCode == code) + 1;
		}
		const event: AlgoliaEvent = this.algoliaService.generateEventObject({eventType: 'click', eventName: 'Search Result Clicked', userToken: String(this.authService.user.userId), objectID: code, position })
		this.algoliaService.sendEvent(event);
		this.router.navigate(['/shop', 'product', code]);
	}
}
