import { Component, ViewChild, AfterViewInit} from '@angular/core';
import { StrapiService } from "src/app/services/strapi.service";
import { headtohead } from "src/app/models/strapi/headtohead";
import { Route, ActivatedRoute, ParamMap } from '@angular/router';
import { forkJoin, Subject, timer } from "rxjs";
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-headtohead',
  templateUrl: './headtohead.component.html',
  styleUrls: ['./headtohead.component.scss'],
})

export class HeadtoheadComponent implements AfterViewInit
{
  page: number = 1;
  totalPages: number = 0;
  isLoaded: boolean = false;
  srcValue: string;

  public headtohead: Array<headtohead> = [];
  public headtohead1: Array<headtohead> = [];
  constructor(private strapiService: StrapiService, private route: ActivatedRoute, protected sanitizer: DomSanitizer) { }

  ngOnInit(): void
  {

    let request = [this.strapiService.getHeadToHeadComparisons()];

    forkJoin(request).subscribe((arr) => {
      const keys = Object.keys(arr[0].id);
        keys.forEach(item =>
        {
          console.log(arr[0].id[item].id);
         
          let requests = [this.strapiService.getHeadToHeadComparisonsComponent(arr[0].id[item].id)];

          forkJoin(requests).subscribe
            (
              (arr) =>
              {
                const keys = Object.keys(arr[0]);
                //alert(keys)
                let y = arr[0][keys[0] as keyof typeof arr[0]];
                this.headtohead.push(y);
              }
          )

    })
  }  
)


 }

  ngAfterViewInit(): void { }



}


