import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { RebateEntry } from "src/app/models/dashboard/rebates";

@Component({
	selector: "app-rebate-claim-summary",
	templateUrl: "./rebate-claim-summary.component.html",
	styleUrls: ["./rebate-claim-summary.component.scss"],
})
export class RebateClaimSummaryComponent implements OnInit {
	public rebate: RebateEntry;
	private orderId: number;
	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private router: Router) {}

	ngOnInit(): void {
		this.rebate = this.config.data.rebate;
		this.orderId = this.rebate.claimId;
	}

	viewHistory() {
		this.ref.close();
		this.router.navigate(["/dashboard", "instant-rebates"]);
	}
	newRebate() {
		this.ref.close();
		this.router.navigate(["/dashboard", "rebate-claim"]);
	}

	print() {
		window.open(`/rebate/print/${this.orderId}`)
	}

}
