
export class StolenGoodsReport {
	public id: number;
	public storeName: string;
	public city: string;
	public state: string;
	public contact: string;
	public phone: string;
    public dateTheft: Date;
    public notes: string;
    public dba: string;
	public incidentType: IncidentType;
	public incidentProducts: Array<StolenGoodsReportProduct> = [];

	constructor(o?: any) {
		Object.assign(this, o);
		if(this.incidentProducts.length == 0) {
			this.incidentProducts.push(new StolenGoodsReportProduct())
		}
	}
	get incidentTypeName() {
		switch(this.incidentType) {
			case 1: return 'Robbery';
			case 2: return 'Fraud';
			default: return 'Unknown'
		}
	}
}

export class StolenGoodsReportProduct {
	public id: number;
	public product: string;
	public serialNumber: string;
	public stolenGoodsRegistryID: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export enum IncidentType {
	Robbery = 1,
	Fraud = 2,
}
