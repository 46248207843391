<div class="pane">
	<div class="lists">
		<div class="list" *ngFor="let program of programs">
			<div class="header">{{program.vendorName}}</div>
			<ul class="programs" *ngIf="program.programDates">
				<li class="program" *ngFor="let date of program.programDates" (click)="showPPForm(program, date)">
					<div class="start">{{date | date: 'MMMM dd, yyyy'}}</div>
				</li>
			</ul>
		</div>
	</div>
</div>


<p-sidebar [(visible)]="sidebarVisible" position="right" (onHide)="activeProgram = null" [style]="{width: '60vw'}">
	<app-price-protection-form *ngIf="activeProgram" [program]="activeProgram" [programDate]="activeProgramDate" (onSubmit)="onSubmit($event)"></app-price-protection-form>
</p-sidebar>
