import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Article } from "src/app/models/strapi/article";
import { StrapiService } from "src/app/services/strapi.service";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/state/app.state";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { Subject } from "rxjs";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { ProUser } from "src/app/models/pro-user";

@Component({
	selector: "app-landing-article-tile",
	templateUrl: "./landing-article-tile.component.html",
	styleUrls: ["./landing-article-tile.component.scss"],
})
export class LandingArticleTileComponent extends AutoUnsubscribeMixin() implements OnInit {
	@Input() article: Article;

	private user: ProUser;

	constructor(private router: Router, private strapiService: StrapiService, private store: Store<IAppState>) {super();}

	ngOnInit(): void {
		this.store.select(selectUser).pipe(takeUntil(this.destroy$)).subscribe(u => this.user = u);
	}

	goToArticle() {
		this.router.navigate(["/cms", "article", this.article.id, this.article.slug])
	}

	toggleLike() {
		if(this.article.userLiked) {
			this.strapiService.unlike(this.article.id, 'article', this.user.userId).subscribe(() => {
				this.article.likeCount -= 1;
				this.article.userLiked = false;
			})
		} else {
			this.strapiService.like(this.article.id, 'article', this.user.userId).subscribe(() => {
				this.article.likeCount += 1;
				this.article.userLiked = true;
			})
		}
	}

}
