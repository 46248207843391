import { Image } from "./image";
import { marked } from 'marked';
import { Media } from "./media";

marked.setOptions({
	renderer: new marked.Renderer(),

	langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
	pedantic: false,
	gfm: true,
	breaks: false,
	sanitize: false,
	smartLists: true,
	smartypants: false,
	xhtml: false
});

export class headtohead {

	public id: number;
	public Name: string;
  public image: any;
  public path: string;
  public Title: string;

	constructor(o?: any) {

    Object.assign(this, o, { ...o.attributes })
  }
}
