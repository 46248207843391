export class Vendor {
	public vendorId: number;
	public name: string;
	public address: string;
	public city: string;
	public state: string;
	public zip: string;
	public phone: string;
	public website: string;
	public salesContactId: number;
	public billingContactId: number;
	public productContactId: number;
	public billingTerms: string;
	public freight: string;
	public onWeb: number;
	public addressID: number;
	public blurb: string;
	public members: boolean;
	public affiliates: boolean;
	public proVendor: boolean;
	public active: boolean;
	public contacts: Array<VendorContact>;
	public companyStatment: string;
	public freightPolicy: string;
	public marketingMaterials: string;
	public priceList: any;

	constructor(o?: any) {
		Object.assign(this, o);
	}

	get url() {
		return this.website.startsWith('http') ? this.website : 'http://' + this.website;
	}
}

export class VendorContact {
	public userId: number;
	public firstName: string;
	public lastName: string;
	public addressId: number;
	public phone: string;
	public email: string;
	public fax: string;
	public contactTypeId: number;
	public contactType: string;
	public address1: string;
	public address2: string;
	public city: string;
	public state: string;
	public zip: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}
export class VendorShort {
	public vendorId: number;
	public name: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}

}