
export class SellThroughItem {
	public productCode: string;
	public description: string;
	public inventory: number;
	public sales: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class SellThrough {
	public week: Date;
	public products: Array<SellThroughItem>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}