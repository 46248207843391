
<h1>Technical Specs</h1>
<ul *ngIf="techSpecs">
	<li class="section-name" *ngFor="let section of techSpecs">
		<span *ngIf="techSpecs.length > 1">{{section.sectionName}}</span>
		<ul>
			<li class="attribute" *ngFor="let att of section.attributes">
				<div>{{att.attributeName}}</div>
				<div>{{att.value}}</div>
			</li>
		</ul>
	</li>
</ul>