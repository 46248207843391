
<div class="users">
	<div class="row header">
		<div class="username">Username</div>
		<div class="first-name">First Name</div>
		<div class="last-name">Last Name</div>
		<div class="email">Email</div>
		<div class="phone">Phone</div>
		<div class="role">Role</div>
		<div class="more"></div>
	</div>
	<div class="row" *ngFor="let user of users">
		<div class="username">{{user.username}}</div>
		<div class="first-name">{{user.firstName}}</div>
		<div class="last-name">{{user.lastName}}</div>
		<div class="email">{{user.email}}</div>
		<div class="phone">{{user.phone}}</div>
		<div class="role">{{user.roleNames}}</div>
		<div class="more">
			<mat-icon (click)="showActions($event, user.userId)">more_horiz</mat-icon>
			<div class="actions" hideable="height 200ms linear" [ngClass]="{open: openActions == user.userId}">
				<div class="edit" (click)="edit(user)"><mat-icon>edit</mat-icon> Edit</div>
				<div class="permissions" (click)="permissions(user)"><mat-icon>rule</mat-icon> Set Permissions</div>
				<div class="log-in-as" (click)="logInAs(user)" *ngIf="proUser.userId != user.userId"><mat-icon>login</mat-icon> Log In As</div>
				<div class="delete" (click)="delete(user)"><mat-icon>delete</mat-icon> Delete</div>
			</div>
		</div>
	</div>
</div>

<p-dialog [(visible)]="showUserForm" showHeader="false" *ngIf="addEditUser" [closable]="false" [style]="{width: '40vw'}">
	<app-add-edit-user [user]="addEditUser" (onCancel)="showUserForm = false" (onSubmit)="saveUser($event)"></app-add-edit-user>
</p-dialog>
