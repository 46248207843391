
<div class="pane">
	<aside>
		<div class="search-area">
			<input [(ngModel)]="searchTerm" (keyup.enter)="search()">
			<div class="button transparent" (click)="clearSearch()" *ngIf="searchTerm">Clear</div>
			<div class="button" (click)="search()">Search</div>
		</div>
	</aside>
	<div class="tab-list">
		<div class="tab" (click)="setTab('IR')" [ngClass]="{active: openTab == 'IR'}">Instant Rebate</div>
		<div class="tab" (click)="setTab('PP')" [ngClass]="{active: openTab == 'PP'}">Price Protection</div>
	</div>
	<div class="tab-content">
		<app-table [data]="data" [caller]="this"  [tableInfo]="tableInfo"></app-table>
		<app-pager [page]="pageNumber" [pageCount]="pageCount" (onGoToPage)="goToPage($event)"></app-pager>
	</div>

</div>
