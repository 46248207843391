import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ShippedSummary } from "src/app/models/accounting/shipped-summary-data";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { TableColumn, TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, OpenSearch, SetDashboardHeaderButtons, SetDashboardTitle, ShowDownloadDialog } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";


@Component({
	selector: "app-invoices",
	templateUrl: "./invoices.component.html",
	styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit {
	public openTab: string = "MSS";
	public allData: { [key: string]: Array<any> } = {};
	public data: Array<any>;
	public tableInfo: TableInfo;
	public pageSize: number = 300;
	public pageNumber: number = 1;
	public pageCount: number;
  public searchstring: string = null;

	constructor(private store: Store<IAppState>, private dataService: DataService, private route: ActivatedRoute) {}
 
  ngOnInit(): void {

        this.searchstring = this.route.snapshot.queryParamMap.get('searchCriteria');
        console.log(this.searchstring); 


        this.store.dispatch(new SetDashboardTitle("Member Statement Summary"));

		  let buttons: Array<DashboardHeaderButton> = [
			new DashboardHeaderButton({ text: "Search", cssClass: "search-button", icon: "search", callback: () => { this.store.dispatch(new OpenSearch(1))} }), 
			new DashboardHeaderButton({ text: "Download", icon: "download", callback: () => this.showDownloadOptions() })];

      this.store.dispatch(new SetDashboardHeaderButtons(buttons));

    this.route.paramMap.subscribe(t =>
    {
      if (t.get('tab') == 'backorder')
      {
				this.openTab = "BOS"
			}
      if (t.get('tab') == 'shipped')
      {
				this.openTab = "OSS"
      }
      if (t.get('tab') == 'paid') {
        this.openTab = "MSH"
      }
    }
    )
        this.loadTabData();
  }

  //getStatementHistory

  loadTabData() {
   

    if (!this.allData[this.openTab]) {
      let apiCall: Observable<any>;
      switch (this.openTab) {
        case 'MSS':
                if (this.searchstring != null)
                    {apiCall = this.dataService.getStatementSummary(this.searchstring); break;}
                else
                    apiCall = this.dataService.getStatementSummary(null); break;
        case 'BOS':
                    apiCall = this.dataService.getBackordersSummary(); break;
        case 'MSH':
                    apiCall = this.dataService.getStatementHistory(); break;
				case 'OSS':
					apiCall = this.dataService.getShippedSummary().pipe(
						map((i:any) => i.map(item => new ShippedSummary(item))),
					); break;
            }
			apiCall.subscribe(r => {
				this.allData[this.openTab] = r;
				this.setData();
			});
    } else
    { this.setData(); }
		this.tableInfo = this.allTableInfo[this.openTab];
	}

  setData() {

		let first = (this.pageNumber - 1) * this.pageSize;
		let last = first + this.pageSize;
		this.data = this.allData[this.openTab].slice(first, last);
		this.pageCount = Math.ceil(this.allData[this.openTab].length / this.pageSize)
	}

	setTab(tab) {
		this.pageNumber = 1;
		this.openTab = tab;
		this.loadTabData();
	}

	showDownloadOptions() {
		this.store.dispatch(new ShowDownloadDialog(this.data, this.allTableInfo[this.openTab], []));
	}

	goToPage(page) {
		this.pageNumber = page;
		this.setData();
	}


  downloadPayables() {     
      this.dataService.downloadPayables().subscribe((resp: any) => {
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.download = 'Billings' +  '.pdf';
      a.href = URL.createObjectURL(resp);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    }, err => {
      switch (err.error.size) {
        case 30:// can't find file
          this.store.dispatch(new ErrorMessageSet("The invoice was not found"));
          break;
        case 32: //not your invoice
          this.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
          break;
      }
    })
  }


	downloadInvoice(row, caller) {
		//const id = row.link.replace('https://members.promaster.com/Invoices/', '').replace('.pdf', '')
		const id = row.invoice;
		caller.dataService.downloadInvoice(id).subscribe((resp:any) => {
			//the line below may get caught by a popup blocker - if so, just move it to before the call to downloadInvoice()
			let wdw = window.open('', 'invoice')
			/*
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = id+'.pdf';
            a.href = URL.createObjectURL(resp);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
			*/
			wdw.location.href = URL.createObjectURL(resp);

		}, err => {
			//wdw.close();
			switch(err.error.size) {
				case 30:// can't find file
					caller.store.dispatch(new ErrorMessageSet("The invoice was not found"));
					break;
				case 32: //not your invoice
					caller.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
					break;
			}
		})
  }


	get allTableInfo() {
		return {
			MSS: new TableInfo({
				columns: [
					{ title: 'Date', field: 'datePosted', type: 'date', format: 'MM/dd/yyyy', sort: true },
					{ title: 'Invoice', field: 'invoice', type: 'action', callback: this.downloadInvoice, sort: true },
          { title: 'Tracking No', field: 'tracking', sort: true, width: "13%" },
					{ title: 'Member PO', field: 'po', sort: true },
					{ title: 'Gross', field: 'gross', type: 'currency', sort: true, total: true },
					{ title: 'Disc', field: 'disc', sort: true },
					{ title: 'Due on', field: 'dueOn', type: 'date', format: 'MM/dd/yyyy', sort: true },
					{ title: 'Due on', field: 'dueOn', type: 'date', format: 'MM/dd/yyyy', sort: true },
					{ title: 'Discount', field: 'discount', type: 'currency', sort: true, total: true },
					{ title: 'Net', field: 'net', type: 'currency', sort: true, total: true },
					{ title: 'Vendor Invoice #', field: 'vendorInvoice', sort: true },
					{ title: 'Vendor', field: 'vendorName', sort: true },
				],
      }),
      MSH: new TableInfo({
        columns: [
          { title: 'Date', field: 'datePosted', type: 'date', format: 'MM/dd/yyyy', sort: true },
          { title: 'Invoice', field: 'invoice', type: 'action', callback: this.downloadInvoice, sort: true },
    /*      { title: 'Tracking No', field: 'tracking', sort: true, width: "13%" },*/
          { title: 'Member PO', field: 'po', sort: true },
          { title: 'Gross', field: 'gross', type: 'currency', sort: true, total: true },
          { title: 'Disc', field: 'disc', sort: true },
          { title: 'Due on', field: 'dueOn', type: 'date', format: 'MM/dd/yyyy', sort: true },

          { title: 'Date Paid', field: 'datePaid', type: 'date', format: 'MM/dd/yyyy', sort: true },

          { title: 'Discount', field: 'discount', type: 'currency', sort: true, total: true },
          { title: 'Net', field: 'net', type: 'currency', sort: true, total: true },
          { title: 'Vendor Invoice #', field: 'vendorInvoice', sort: true },
          { title: 'Vendor', field: 'vendorName', sort: true },
        ],
      }),
			BOS: new TableInfo({
        columns: [

          { title: 'PO', field: 'poNumber', sort: true},
          { title: 'Date', field: 'date', type: 'date', format: 'MM/dd/yyyy', sort: true },
          { title: 'Product', field: 'productCode'  ,sort: true},
          { title: 'Description', field: 'modelName', sort: true },
          { title: 'Quantity', field: 'leftOver', sort: true },
          { title: 'Est Del', field: 'dueDate', type: 'date', format: 'MM/dd/yyyy', sort: true },
          { title: 'Weborder', field: 'webOrder', sort: true },

          { title: 'Cost', field: 'cost', type: 'currency', sort: true },
          { title: 'Ext. Cost', field: 'costExt', type: 'currency', sort: true }
				],		
			}),	
			OSS: new TableInfo({
				columns: [
		  { title: 'PO', field: 'po' },
          { title: 'Ship Date', field: 'shipDate', type: 'date', format: 'MM/dd/yyyy', sort: true},
          { title: 'Product', field: 'product', sort: true},
          { title: 'Description', field: 'description', sort: true},
          { title: 'Quantity', field: 'quantity', sort: true },
          { title: 'Packages', field: 'packages', sort: true},
          { title: 'Tracking', field: 'trackingLinks', sort: true},
				],
			}),			
		}

	}
	
}

