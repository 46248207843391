
<div class="order-history-details" *ngIf="orderHistoryDetails">
	<div class="addresses">
		<div class="address shipping">
			<h1>Shipping Address</h1>
			<address>
				<div class="name" *ngIf="orderHistoryDetails.shipAddress.firstName || orderHistoryDetails.shipAddress.lastName">{{orderHistoryDetails.shipAddress.firstName}} {{orderHistoryDetails.shipAddress.lastName}}</div>
				<div class="address" *ngIf="orderHistoryDetails.shipAddress.address1">{{orderHistoryDetails.shipAddress.address1}}</div>
				<div class="address" *ngIf="orderHistoryDetails.shipAddress.address2">{{orderHistoryDetails.shipAddress.address2}}</div>
				<div class="address">{{orderHistoryDetails.shipAddress.city}}, {{orderHistoryDetails.shipAddress.state}} {{orderHistoryDetails.shipAddress.zip}}</div>
			</address>
		</div>
		<div class="address billing">
			<h1>Billing Address</h1>
			<address>
				<div class="name" *ngIf="orderHistoryDetails.billAddress.firstName || orderHistoryDetails.billAddress.lastName">{{orderHistoryDetails.billAddress.firstName}} {{orderHistoryDetails.billAddress.lastName}}</div>
				<div class="address" *ngIf="orderHistoryDetails.billAddress.address1">{{orderHistoryDetails.billAddress.address1}}</div>
				<div class="address" *ngIf="orderHistoryDetails.billAddress.address2">{{orderHistoryDetails.billAddress.address2}}</div>
				<div class="address">{{orderHistoryDetails.billAddress.city}}, {{orderHistoryDetails.billAddress.state}} {{orderHistoryDetails.billAddress.zip}}</div>
			</address>
		</div>
	</div>
	<div class="products">
		<div class="order-item header">
			<div class="image"></div>
			<div class="product-name">Product Name</div>
			<div class="quantity">Quantity</div>
			<div class="unit-price">Unit Cost</div>
			<div class="ext-price">Extended Cost</div>
		</div>
		<div class="order-item" *ngFor="let orderItem of orderHistoryDetails.orderItems">
			<div class="image"><img *ngIf="orderItem.image" src="{{environment.image_url}}{{orderItem.image.imageName}}"></div>
			<div class="product-name"><a [routerLink]="['/shop', 'product', orderItem.productCode]" [ngClass]="{'back-order': isBackorder(orderItem.modelName)}">{{orderItem.productCode}} - {{orderItem.modelName}}</a></div>
			<div class="quantity">{{orderItem.quantity}}</div>
			<div class="unit-price">{{orderItem.unitCost | currency}}</div>
			<div class="ext-price">{{orderItem.unitCost * orderItem.quantity | currency}}</div>
		</div>
		<div class="order-bundle" *ngFor="let orderBundle of orderHistoryDetails.orderBundles">
			<div class="product-name">{{orderBundle.name}}</div>
			<div class="quantity">{{orderBundle.quantity}}</div>
			<div class="unit-price">{{orderBundle.retail | currency}}</div>
			<div class="ext-price">{{orderBundle.retail * orderBundle.quantity | currency}}</div>
			<div class="bundle-details">
				<ng-container *ngFor="let bp of orderBundle.products">
					<div class="quantity">{{bp.quantity}}</div>
					<div class="model-name">{{bp.product.modelName}}</div>
					<div class="product-code">{{bp.productCode}}</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
