<main class="container">
	<div class="articles">
		<div class="breadcrumbs"></div>

		<div class="headers" [permission]="Permissions.GeneralAccess">

			<div class="header-article-content" *ngFor="let article of headerArticles; let i = index" [ngClass]="{active: i == activeHeader}">

				<div class="article-word">{{article.word}}</div>

				<div class="info">

					<h2><a (click)="goToArticle(article)">{{article.articleName}}</a></h2>

					<p>{{article.excerpt}} &nbsp;<a class="read-more" (click)="goToArticle(article)">Read More</a></p>

					<div class="social-activity" [permission]="Permissions.Comments">

						<div class="likes" *ngIf="article.enableLikes"><mat-icon>thumb_up</mat-icon>{{article.likeCount}} Likes</div>

						<div class="comments" *ngIf="article.enableComments"><mat-icon>forum</mat-icon>{{article.commentCount}} Comments</div>

						<div class="views"><mat-icon>visibility</mat-icon>{{article.views}} View{{article.views == 1 ? '' : 's'}}</div>
					</div>
				</div>
				<div class="image" [ngStyle]="{'background-image': 'url(' + article.image?.url + ')'}"><a (click)="goToArticle(article)"></a></div>
			</div>
		</div>

		<div class="header-indicators" *ngIf="headerArticles?.length > 1" [permission]="Permissions.GeneralAccess">
			<div class="arrow" (click)="advanceHeader(-1)">&larr;</div>
			<div class="header-pages">
				<div *ngFor="let ha of headerArticles; let i = index" (click)="activeHeader = i" [ngClass]="{active: i == activeHeader}"></div>
			</div>
			<div class="arrow" (click)="advanceHeader(1)">&rarr;</div>
		</div>
		<br />
		<br />
		<ul [permission]="Permissions.GeneralAccess">
			<p-carousel [value]="featureArticles" [numVisible]="visibleFeatureCount" [numScroll]="1" [showIndicators]="false">
				<ng-template pTemplate="item" let-article>
					<app-landing-article-tile [article]="article"></app-landing-article-tile>
				</ng-template>
			</p-carousel>
		</ul>


	</div>

	<div class="forum" [permission]="Permissions.Forums">
		<h1>Forum</h1>
		<app-recent-forum-posts [count]="4"></app-recent-forum-posts>
	</div>

	<div class="registry" [permission]="Permissions.GeneralAccess">
		<h1>Stolen Goods Registry</h1>
		<a [routerLink]="['/dashboard', 'stolen-goods-report']"><img src="/assets/images/Cuffs.jpg"></a>
	</div>

	<div class="calendar"[permission]="Permissions.CurrentEvents">
		<h1>Calendar</h1>
		<app-calendar></app-calendar>
	</div>

	<div class="new-products" [permission]="Permissions.GeneralAccess">
		<div class="heading">
			<h1>New Arrivals</h1>
			<div class="see-all-new"><a [routerLink]="['/warehouse', 'new']">Show New Arrivals &rarr; </a></div>
		</div>
		<div class="products-container">
			<div class="product" *ngFor="let product of newProducts"><app-product-tile [product]="product"></app-product-tile></div>
		</div>
	</div>

	<div class="vendors" *ngIf="featuredVendors" [permission]="Permissions.GeneralAccess">
		<ul>
			<li *ngFor="let vendor of featuredVendors">
				<a [routerLink]="['/vendor', vendor.slug]"><img *ngIf="vendor.VendorImage" src="{{vendor.VendorImage[0].url}}"></a>
			</li>
		</ul>
	</div>




	<div class="rebates" *ngIf="instantRebateGroups" [permission]="Permissions.RebateRead">
		<div class="header">
			<h1>Rebates</h1>
			<div class="button">
				<a [routerLink]="['/dashboard', 'rebate-claim']">Instant Rebates</a>
			</div>
		</div>
		<app-instant-rebate-groups [instantRebateGroups]="instantRebateGroups"></app-instant-rebate-groups>
	</div>
</main>
