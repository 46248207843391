import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import * as FileSaver from "file-saver";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { tap, takeUntil } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { SiteActionTypes } from "src/app/store/actions/site.actions";
import { selectDownloadData, selectDownloadFormats, selectDownloadHeaders, selectDownloadTableInfo } from "src/app/store/selectors/site.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-export-dialog",
	templateUrl: "./export-dialog.component.html",
	styleUrls: ["./export-dialog.component.scss"],
})
export class ExportDialogComponent extends AutoUnsubscribeMixin() implements OnInit {
	public showDownloadDialog: boolean = false;
	private data: Array<any>;
	public tableInfo: TableInfo;
	public headers: Array<{key: string, value: any}>;
	public formats: Array<string>;
	@Output() onDownload: EventEmitter<string> = new EventEmitter();
	constructor(private actions$: Actions, private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private store: Store<IAppState>) { super(); }

	ngOnInit(): void {
		this.actions$
			.pipe(
				tap((a) => {
					switch (a.type) {
						case SiteActionTypes.SHOW_DOWNLOAD_DIALOG:
							this.showDownloadDialog = true;
							break;
					}
				})
			).subscribe();
			this.store.pipe(takeUntil(this.destroy$), select(selectDownloadData)).subscribe(d => this.data = d);
			this.store.pipe(takeUntil(this.destroy$), select(selectDownloadTableInfo)).subscribe(t => this.tableInfo = t);
			this.store.pipe(takeUntil(this.destroy$), select(selectDownloadHeaders)).subscribe(h => this.headers = h);
			this.store.pipe(takeUntil(this.destroy$), select(selectDownloadFormats)).subscribe(f => this.formats = f)
	}

	downloadAs(type) {
		switch (type) {
			case "xls":
				this.exportExcel();
				break;
			case "pdf":
				this.exportPdf();
				break;
			case "csv":
				this.exportCsv();
				break;
		}
		this.showDownloadDialog = false;
	}

	exportPdf() {
		const doc = new jsPDF({ orientation: "l" });
		if(this.headers) {
			let hdrs = [];
			this.headers.forEach(h => {
				hdrs.push([h.key, h.value])
			})
			hdrs.push([]); //blank row?
			autoTable(doc, { body: hdrs });
		}
		autoTable(doc, { head: [this.tableInfo.columns.filter(c => c.field).map((c) => c.title)], body: this.convertDataForPdf() });
		doc.save("download.pdf");
	}

	exportExcel() {
		import("xlsx").then((xlsx) => {
			let xlsData = [];
			xlsData = xlsData.concat(this.data)
			let worksheet = xlsx.utils.json_to_sheet(this.reformatExcelData(xlsData));
			let worksheet_headers;
			let headerStartCell = 'A1'
			if(this.headers) {
				worksheet_headers = xlsx.utils.json_to_sheet(this.renameHeaders());
				let a = xlsx.utils.sheet_to_json(worksheet_headers);
				let b = xlsx.utils.sheet_to_json(worksheet);
				a = a.concat(['']).concat(['']).concat(b);
				worksheet = xlsx.utils.json_to_sheet(a, { skipHeader: true});
				headerStartCell = "A"+ String(this.headers.length + 2)
			}
			const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
			xlsx.utils.sheet_add_aoa(worksheet, [ this.tableInfo.columns.filter(c => c.title).map(c => c.title) ], { origin: headerStartCell})
			const excelBuffer: any = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
			this.saveAsExcelFile(excelBuffer, "data");
		});
	}

	renameHeaders() {
		let newHeaders = this.headers.map(h => ({[this.tableInfo.columns[0].field]: h.key, [this.tableInfo.columns[1].field]: h.value}));
		newHeaders.concat([]); //blank row
		return newHeaders;
	}
	reformatExcelData(data) {
		let newData = [];
		data.forEach(r => {
			let row = {};
			this.tableInfo.columns.forEach(c => {
				switch(c.type) {
					case 'currency':
						row[c.field] = this.currencyPipe.transform(r[c.field]); break;
					case 'date':
						row[c.field] = this.datePipe.transform(r[c.field]); break;
					case 'inventory':
						row[c.field] = r[c.field] > 50 ? 'Yes' : 'No'; break;
					case 'hidden': 
						//don't show this field
					default:
						row[c.field] = r[c.field]; break;
				}
			})
			newData.push(row)
		})
		let totalRow = {};
		this.tableInfo.columns.forEach(c => {
			if(c.total) {
				let tot = this.sum(c.field);
				switch(c.type) {
					case 'currency': 
						totalRow[c.field] = this.currencyPipe.transform(tot); break;
					default: 
						totalRow[c.field] = tot; break;
				}
			} else {
				totalRow[c.field] = null;
			}
		})
		newData.push(totalRow);
		return newData;
	}
	saveAsExcelFile(buffer: any, fileName: string): void {
		let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let EXCEL_EXTENSION = ".xlsx";
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE,
		});
		FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
	}

	exportCsv() {
		let csvData = [];
		if(this.headers) {
			this.headers.forEach(h => csvData.push(h.key + ',' + h.value));
			csvData.push('')
		}
		const colHeaders = this.tableInfo.columns.filter(c => c.field && c.title).reduce((a, c) => a + c.title + ",", "");
		csvData.push(colHeaders);
		this.data.forEach((r) => {
			let row = "";
			this.tableInfo.columns.filter(c => c.field && c.title).forEach((c) => {
				switch(c.type) {
					case 'currency':
						row += '"' + this.currencyPipe.transform(r[c.field]) + '",'; break;
					case 'date':
						row += '"' + this.datePipe.transform(r[c.field]) + '",'; break;
					case 'inventory':
						row += '"' + (r[c.field] > 50 ? 'Yes' : 'No') + '",'; break;
					case 'hidden': 
						//don't show this field
					default: 
						row += '"' + r[c.field] + '",'; break;
				}
			});
			csvData.push(row);
		});
		let totals = [];
		this.tableInfo.columns.forEach(c => {
			if(c.total) {
				let tot = this.sum(c.field);
				switch(c.type) {
					case 'currency': 
						totals.push('"'+this.currencyPipe.transform(tot)+'"'); break;
					default: 
						totals.push(tot);
				}
			} else {
				totals.push('')
			}
		})
		csvData.push(totals.join(','));
		const data: Blob = new Blob([csvData.join("\n")], { type: "text/csv" });
		FileSaver.saveAs(data, "data_csv_" + new Date().getTime() + ".csv");
	}
	private convertDataForPdf() {
		let arrs = [];
		this.data.forEach((r) => {
			let arr = [];
			this.tableInfo.columns.forEach((c) => {
				switch(c.type) {
					case 'currency': 
						arr.push(this.currencyPipe.transform(r[c.field])); break;
					case 'date':
						arr.push(this.datePipe.transform(r[c.field])); break;
					case 'inventory':
						arr.push(r[c.field] > 50 ? 'Yes' : 'No'); break;
					case 'hidden':
						//don't show this field
					default: 
						arr.push(r[c.field]); break;
				}
			});
			arrs.push(arr);
		});
		let totals = [];
		this.tableInfo.columns.forEach(c => {
			if(c.total) {
				let tot = this.sum(c.field)
				switch(c.type) {
					case 'currency':
						totals.push(this.currencyPipe.transform(tot)); break;
					default:
						totals.push(tot); break;
				}
			} else {
				totals.push('');
			}
		})
		arrs.push(totals);
		//line below is slicker than above, but columns don't match headers if they're in a different order than what comes back from api
		//this.data.forEach(o => arrs.push(Object.keys(o).reduce((a,b) => [...a, o[b]], [])))
		return arrs;
	}

	sum(field):number {
		return this.data.reduce((a,b) => a + b[field], 0)
	}

	allow(type) {
		return this.formats.some(f => f == type)
	}
}

export class TableInfo {
	public columns: Array<TableColumn>;
	public hasSubtable: boolean = false;
	public service?: any;  //dataService or the like
	public dataMethod?: string; //what method returns the data for subtable
	public rowKey?: string; //which field of main table serves as key to get subtable data
	public subTable?: TableInfo; //to allow this to be recursive
	public paging?: { pageSize: number };

	constructor(o?: any) {
		Object.assign(this,o);
	}
	get hasTotals() {
		return this.columns.reduce((a,b) => a || b.total, false)
	}
}
export class TableColumn {
	public field?: string;
	public title: string;
	public type?: string;
	public format?: string;
	public linkTo?: Array<string>;
	public sort?: boolean;
	public calcTotal?: boolean;
	public width?: string;
	public cssClass?: string;
	public id?: boolean;
	public text?: string;
	public callback?: any;
	public subtableInfo?: TableInfo
	public hideAtBp?: number;
	public total?:boolean;
	public showIf?: { field: string, value: any};
/*
	constructor(o: { title: string; field: string; type: string; format?: string; sort?: boolean; linkTo?: Array<string>; calcTotal?: boolean, width?: string, cssClass?: string }) {
		Object.assign(this, o);
	}
	*/
	constructor(o: any) {
		Object.assign(this, o);
	}
}
