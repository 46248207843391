
export class AlgoliaEvent {
	public eventType: string;
	public eventName: string;
	public index: string;
	public userToken: string;
	public authenticatedUserToken?: string;
	public timestamp: number;
	public queryID: string;
	public objectIDs: Array<string>;
	public filters?: string;
	public positions: Array<number>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export enum SearchType {
	Invoice = 1,
	Articles = 2,
	Products = 4,
	Backorder = 8,
}