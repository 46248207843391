<div class="special">
	<header>
		<h1>{{special.title}}</h1>
		<p>{{special.description}}</p>
		<div>Valid from: {{special.startDate | date: 'MM/dd/yyyy'}} to {{special.endDate | date: 'MM/dd/yyyy'}}</div>
	</header>
	<main>
		<h2>To redeem this special</h2>
		<div class="req" *ngFor="let req of special.specialRequirements">
			<h4>Buy {{req.quantity}} of</h4>
			<ul>
				<li *ngFor="let reqProd of req.specialRequirementProducts">
					{{reqProd.product.modelName}} ({{reqProd.product.productCode}})
				</li>
			</ul>
		</div>
		<h2>And get</h2>
		<ng-container *ngFor="let rew of special.specialRewards">
			<ng-container [ngSwitch]="rew.specialRewardTypeId">
				<div class="rew" *ngSwitchCase="1">~1~ {{rew.description}}</div>
				<div class="rew" *ngSwitchCase="2">~2~ {{rew.description}}</div>
				<div class="rew" *ngSwitchCase="3">
					Save <span *ngIf="rew.percentOrDollar == 'dollars'">{{rew.amount | currency}}</span><span *ngIf="rew.percentOrDollar == 'percent'">{{rew.amount}}%</span> on {{rew.quantity}} of
					<ul>
						<li *ngFor="let rewProd of rew.specialRewardProducts">
							{{rewProd.product.modelName}} ({{rewProd.product.productCode}})
						</li>
					</ul>
				</div>
				<div class="rew" *ngSwitchCase="4">~4~ {{rew.description}}</div>
				<div class="rew" *ngSwitchCase="5">~5~ {{rew.description}}</div>

			</ng-container>
		</ng-container>
	</main>
	<footer>
		<button pButton (click)="close()" label="Close" class="p-button-outlined"></button>
		<button pButton (click)="addToCart()" label="Add To Cart"></button>
	</footer>
</div>


<!--<pre>{{special | json}}</pre>-->