import { Image } from "./image";

export class CmsVendor {
	public id: number;
	public vendorName: string;
	public slug: string;
	public featuredDisplayOrder;
	public image: any;
	public priceListUrl: string;
	public priceList: Array<any>

	constructor(o?: any) {
		if(o.attributes) {
			o = { id: o.id, ...o.attributes }
		}
		let imageData = null;
		if(o.VendorImage) {
			imageData = new Image(o.VendorImage);
		}
		o.image = imageData;
		let priceList = o.priceList
		if(priceList?.data) {
			o.priceList = priceList.data.map(i => ({ id: i.id, ...i.attributes }))
		}
		Object.assign(this, o, o.Data);
	}
}