import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { PriceProtectionProgram } from 'src/app/models/dashboard/rebates';
import { DataService } from 'src/app/services/data.service';
import { SetDashboardTitle } from 'src/app/store/actions/site.actions';
import { IAppState } from 'src/app/store/state/app.state';
import { PriceProtectionSummaryComponent } from './price-protection-summary/price-protection-summary.component';

@Component({
  selector: 'app-price-protection',
  templateUrl: './price-protection.component.html',
  styleUrls: ['./price-protection.component.scss']
})
export class PriceProtectionComponent implements OnInit {

	public programs: Array<PriceProtectionProgram>
	public sidebarVisible: boolean = false;
	public activeProgram: PriceProtectionProgram;
	public activeProgramDate: Date;
	constructor(private store: Store<IAppState>, private dataService: DataService, private dialogService: DialogService) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle("Price Protection Claim"));
		this.dataService.getPriceProtectionPrograms().subscribe((p) => (this.programs = p));
	}
	showPPForm(program, date) {
		this.activeProgramDate = date;
		this.activeProgram = program;
		this.sidebarVisible = true;
	}
	onSubmit(e) {
		this.sidebarVisible = false;
		document.getElementsByClassName('p-sidebar-mask')[0]?.remove();
		this.dialogService.open(PriceProtectionSummaryComponent, { header: 'Price Protection Summary', data: { claimId: e.claimId }})
	}
/*
	showRebateForm(rebateProgram, vendorId, vendorName) {
		let entry = new RebateEntry();
		entry.rebateProgram = { ...rebateProgram, vendorId, vendorName };
		this.activeRebate = entry;
		this.sidebarVisible = true;
	}
	onSubmit(rebate) {
		this.sidebarVisible = false;
		document.getElementsByClassName('p-sidebar-mask')[0]?.remove();
		this.dialogService.open(RebateClaimSummaryComponent, { header: 'Rebate Summary', data: { rebate }})
	}
	*/
}


