import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Article } from "src/app/models/strapi/article";
import { Newsletter } from "src/app/models/strapi/newsletter";
import { StrapiService } from "src/app/services/strapi.service";

@Component({
	selector: "app-newsletter",
	templateUrl: "./newsletter.component.html",
	styleUrls: ["./newsletter.component.scss"],
})
export class NewsletterComponent implements OnInit {
	public articles: Array<Article> = [];
	constructor(private strapiService: StrapiService, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			if (params.get("year") && params.get("month") && params.get("day")) {
				this.strapiService.getNewsletter(params.get("year"), params.get("month"), params.get("day")).subscribe((a) => (this.articles = a));
			} else {
				this.strapiService.getLatestNewsletter().subscribe((a) => (this.articles = a));
			}
		});

	}
}
