import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";

@Component({
	selector: "app-pager",
	templateUrl: "./pager.component.html",
	styleUrls: ["./pager.component.scss"],
})
export class PagerComponent implements OnInit, OnChanges {
	@Input() page: number;
	@Input() pageCount: number;
	@Input() linksToShow: number = 7;
	@Output() onGoToPage: Subject<number> = new Subject();

	public pages: Array<number>;

	constructor() {}

	ngOnInit(): void {
		this.makePages();
	}

	goToPage(n) {
		this.onGoToPage.next(n);
		this.page = n;
		this.makePages();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes?.pageCount) {
			this.pageCount = changes.pageCount.currentValue;
			this.makePages();
		}
	}

	makePages() {
		if(this.pageCount <= this.linksToShow) {
			this.pages = Array.from({ length: this.pageCount }, (_, i) => i + 1);
		} else {
			this.pages = Array.from({length: this.linksToShow}, (e, i) => (this.page - Math.floor(this.linksToShow / 2) + i))
			if(this.pages[0] < 1) {
				this.pages = this.pages.map(p => p - this.pages[0] + 1)
			}
			if(this.pages[this.linksToShow - 1] > this.pageCount) {
				this.pages = this.pages.map(p => p - (this.pages[this.linksToShow - 1] - this.pageCount))
			}
		}
	}
}
