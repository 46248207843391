<div class="container">
	<main>
		<div class="row header">
			<div class="user">User</div>
			<div class="completed">Completed Modules</div>
			<div class="total">Total Modules</div>
			<div class="percent">Percent Complete</div>
		</div>
		<div class="row" *ngFor="let info of data">
			<div class="user"><a [routerLink]="['/dashboard', 'points', info.userid]">{{info.firstName}} {{info.lastName}}</a></div>
			<div class="completed">{{info.completedMods}}</div>
			<div class="total">{{info.totalMods}}</div>
			<div class="percent">{{info.percentComplete}}%</div>
		</div>
	</main>	
</div>