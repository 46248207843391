import { Component, OnInit } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { Cart } from "src/app/models/shopping/cart";
import { Special, SpecialRequirement, SpecialReward } from "src/app/models/shopping/specials";
import { DataService } from "src/app/services/data.service";
import { AddToCart, CartActionTypes, EditCartItem, UpdateCart } from "src/app/store/actions/cart.actions";
import { IAppState } from "src/app/store/state/app.state";
import { takeUntil } from 'rxjs/operators';
import { selectCart } from "src/app/store/selectors/cart.selectors";
import { Product } from "src/app/models/shopping/product";

@Component({
	selector: "app-special-viewer",
	templateUrl: "./special-viewer.component.html",
	styleUrls: ["./special-viewer.component.scss"],
})
export class SpecialViewerComponent extends AutoUnsubscribeMixin() implements OnInit {
	public special: Special;
	public stage: 'Requirements' | 'Rewards' | 'Error' = 'Requirements';
	public errorMessage;
	private cart: Cart;
	constructor(private store: Store<IAppState>, private actions$: Actions, private dataService: DataService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
		super();
	}

	ngOnInit(): void {
		this.loadSpecial(this.config.data.specialId);
		this.actions$.subscribe((a) => {
			switch (a.type) {
				case CartActionTypes.GET_CART_SUCCESS:
					this.loadSpecial(this.special.specialId);
					break;
			}
		});
		this.store.pipe(takeUntil(this.destroy$), select(selectCart)).subscribe(c => this.cart = c)
	}

	loadSpecial(id) {
		this.dataService.getSpecialDetails(id).subscribe((s) => (this.special = s), err => {
			this.errorMessage = err;
			this.stage = 'Error';
		});
	}
	updateCart(item) {
		if (item.cartItemId) {
			this.store.dispatch(new EditCartItem(item.cartItemId, item.product.productCode, item.quantityInCart, false));
		} else {
			this.store.dispatch(new AddToCart(item.product.productCode, item.quantityInCart));
		}
	}

	getEligibleProducts(reward: SpecialReward): Array<any> {
		let includedProducts: Array<{ product: Product, quantity: number }> = [];
		switch(reward.specialRewardTypeId) {
			case 1: { //buy X, get special on X
				//get all special requirement products for this special
				let allSRProducts = this.special.specialRequirements.reduce((a,b) => [ ...a, ...b.specialRequirementProducts ], []);
				//find cart items associated with these products
				let cartItemsIncluded = this.cart.cartItems.filter(i => allSRProducts.map(p => p.productId).includes(i.productId)).sort((a,b) => b.product.retail - a.product.retail); //in decending price order
				if(this.special.allowMultipleUse) {
					return cartItemsIncluded.map(i => ({product: i.product, quantity: i.quantity }));
				} else {
					
					this.special.specialRequirements.forEach(req => {
						let cartItemsForThisReq = this.cart.cartItems.filter(i => req.specialRequirementProducts.map(p => p.productId).includes(i.productId)).sort((a,b) => b.product.retail - a.product.retail);
						let qtyRemaining = req.quantity, ctr = 0;
						while(qtyRemaining > 0) {
							includedProducts.push({product:  cartItemsForThisReq[ctr].product, quantity: Math.min(qtyRemaining, cartItemsForThisReq[ctr].quantity)})
							qtyRemaining -= cartItemsForThisReq[ctr].quantity;
							ctr++;
						}
					})
					return includedProducts;
				}

			}
			case 2: { //buy X, get special on additional purchases of X
				let includedProducts = [];
				let allSRProducts = this.special.specialRequirements.reduce((a,b) => [ ...a, ...b.specialRequirementProducts ], []);
				let qualifyingCartItems = this.cart.cartItems.filter(i => allSRProducts.map(p => p.productId).includes(i.productId)).sort((a,b) => b.product.retail = a.product.retail);
				let expandedQualifyingItems = qualifyingCartItems.reduce((a,b) => [ ...a, ...Array(b.quantity).fill(b)], []); //expand so if you have 3 of an item, it shows up in the array 3 times
				let qtyQualifyingItems = qualifyingCartItems.reduce((a,b) => a + b.quantity, 0); //this is the total number of special-related items you have in your cart
				let requirementQuantity = this.special.specialRequirements.reduce((a,b) => a + b.quantity, 0); //this is the total number of items you need to buy
				let expandedRewardProducts = [];
				if(this.special.allowMultipleUse) {
					while(expandedQualifyingItems.length > 0) {
						expandedQualifyingItems.splice(0, requirementQuantity); //remove the ones that you need for the requirement
						expandedRewardProducts.push(...expandedQualifyingItems.splice(0, Math.min(reward.quantity, expandedQualifyingItems.length)));
					}
				} else {
					if(qtyQualifyingItems >= requirementQuantity) { //if you have at lease enough to meet the requirement...
						//you have enough, how many rewards can you get
						let qualifyingForReward = qtyQualifyingItems - requirementQuantity; //this is how many (beyond the requirements) that you have in the cart
						expandedQualifyingItems.splice(0, requirementQuantity); //remove the ones that you need for the requirement
						expandedRewardProducts = expandedQualifyingItems.splice(0, Math.min(reward.quantity, qualifyingForReward));
					}
				}
				expandedRewardProducts.forEach(p => {
					if(!includedProducts.some(i => i.product.productId == p.productId)) {
						includedProducts.push({product: p.product, quantity: 0})
					}
					includedProducts.find(i => i.product.productId == p.productId).quantity += 1;
				})
				return includedProducts;
			}

			case 3: { //buy X get special on Y
				
			}
		}
		let items = reward.specialRewardProducts.filter(p => this.cart.cartItems.map(i => i.productId).includes(p.productId)).map(p => p.product).sort((a,b) => a.retail - b.retail);
		if(this.special.allowMultipleUse) {

		}
	}
	close() {
		this.ref.close();
	}
	next() {
		switch(this.stage) {
			case 'Requirements':
				this.stage = 'Rewards'; break;
			case 'Rewards':
				this.stage = 'Requirements'; break;
		}
	}
}
