import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Calendar } from "primeng/calendar";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { SellThrough,SellThroughItem } from "src/app/models/dashboard/sell-through";
import { TableColumn, TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardHeaderButtons, SetDashboardTitle, ShowDownloadDialog, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-sell-through",
	templateUrl: "./sell-through.component.html",
	styleUrls: ["./sell-through.component.scss"],
})
export class SellThroughComponent implements OnInit {
	@ViewChild('calendar') calendar: Calendar;
	public showUpload: boolean = false;
	public dateRange;
	public sellThroughItems: Array<SellThroughItem>;
	public weeks: Array<any>;
	private allowedNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'Tab', 'Enter']; //, 'End', 'ArrowDown', 'PageDown', 'ArrowLeft', 'Clear', 'ArrowRight', 'Home', 'ArrowUp', 'PageUp', 'Insert', 'Alt'
	constructor(private dataService: DataService, private store: Store<IAppState>) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle("Panasonic Sell Through"))
		this.dataService.getSellThroughProducts().subscribe(items => this.sellThroughItems = items.map(i => ({ ...i, sales: 0, inventory: 0})));
		let buttons: Array<DashboardHeaderButton> = [
			new DashboardHeaderButton({ text: "Download Template", icon: "download", callback: () => this.showDownloadOptions() }),
			new DashboardHeaderButton({ text: "Upload Template", icon: "upload", callback: () => this.showUploadDialog() }),
		];
		this.store.dispatch(new SetDashboardHeaderButtons(buttons));
	}

	showDownloadOptions() {
		this.store.dispatch(new ShowDownloadDialog(this.sellThroughItems, this.tableInfo, [], ['csv']));
	}
	numericOnly(e) {
		if(e.key == 'Enter') {
			let focusables = document.querySelectorAll('input');
			const index = Array.prototype.indexOf.call(focusables, document.activeElement);
			if(e.shiftKey) {
				focusables[index - 1]?.focus();
			} else {
				focusables[index + 1]?.focus();
			}
			e.preventDefault();return;
		}
		if(!this.allowedNumbers.includes(e.key)) {
			e.preventDefault();
		}
	}

	selectWeek(e) {
		let start = new Date(e);
		start.setDate(start.getDate() - start.getDay());
		this.dateRange[0] = start;
	
		let end = new Date(start);
		end.setDate(start.getDate() + 6); 
		this.dateRange[1] = end;
		this.calendar.toggle();
	}

	clearZero(item, type) {
		if(item[type] == 0) { item[type] = undefined}
	}
	replaceZero(item, type) {
		if(item[type] == undefined) { item[type] = 0}
	}

	submit() {
		if(!this.dateRange) {
			this.store.dispatch(new ErrorMessageSet("Please enter a date"));
			window.scrollTo(0,0);
			return;
		}
		let sellThroughItems = this.sellThroughItems.map(i => ({ ...i, sales: i.sales || 0, inventory: i.inventory || 0 }));
		this.dataService.submitSellThrough(new SellThrough({ week: this.dateRange[1], products: sellThroughItems })).subscribe((resp:any) => {
			if(resp.success) {
				this.store.dispatch(new SuccessMessageSet("Your submission has been received.  Thank you."))
				window.scrollTo(0,0);
				this.dataService.getSellThroughHistory().subscribe(h => this.weeks = h)
			}
		});
	}

	showUploadDialog() {
		this.showUpload = true;
	}
	closeUploadDialog() {
		this.showUpload = false;
		this.dataService.getSellThroughHistory().subscribe(h => this.weeks = h)
	}


	get tableInfo() {
		return new TableInfo({
			columns: [
				new TableColumn({ field: 'productCode', title: 'Product Code'}),
				new TableColumn({ field: 'cataglogNumber', title: 'Catalog Number' }),
				new TableColumn({ field: 'description', title: 'Description'}),
				new TableColumn({ field: 'sales', title: 'Sales' }),
				new TableColumn({ field: 'inventory', title: 'On Hand' }),
			]
	})
	}
}
