import { Component, Input, OnInit } from '@angular/core';
import { Article } from 'src/app/models/strapi/article';

@Component({
  selector: 'app-newsfeed-article-tile',
  templateUrl: './newsfeed-article-tile.component.html',
  styleUrls: ['./newsfeed-article-tile.component.scss']
})
export class NewsfeedArticleTileComponent implements OnInit {

  @Input() article: Article;
  @Input() mode: string;
  constructor() { }

  ngOnInit(): void {
  }

  darkenBg(url, color) {
	let bg = {};
	if(color) {
		bg['background-color'] = color;
	}
	if(url) {
		bg['background-image'] = 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + url + ')'
	}

	return bg;
  }
}
