import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { Sar } from "src/app/models/dashboard/sar";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardHeaderButtons, SetDashboardTitle, ShowDownloadDialog } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";
import { TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { Permissions } from "src/app/modules/controls/enums/permissions";

@Component({
	selector: "app-sar",
	templateUrl: "./sar.component.html",
	styleUrls: ["./sar.component.scss"],
})
export class SarComponent implements OnInit {
	public searchTerm: string;
	public allSars: Array<Sar>;
	public sars: Array<Sar>;
	public page: number = 1;
	public pageSize: number = 20;
	public pageCount: number;
	public tableInfo: TableInfo = new TableInfo({
		columns: [
			{ title: 'SAR', field: 'draId' },
			{ title: 'SAR Date', field: 'submitDate'},
			{ title: 'Your Reference Number', field: 'referenceNum' },
			{ title: 'Status', field: 'status' },
			{ title: 'Notes', field: 'notes' },
		]
	});
	public openSarId: number;
	public Permissions = Permissions;

	constructor(private dataService: DataService, private store: Store<IAppState>, private router: Router, private authService: AuthService) {}

	ngOnInit(): void {
    this.store.dispatch(new SetDashboardTitle("Satisfaction Adjustment Requests"));
    let buttons: Array<DashboardHeaderButton> = [];

		if(this.authService.user.permissions & Permissions.SarProcess) {
			 buttons.push(new DashboardHeaderButton({ text: "Submit a New Claim", cssClass: "white",  callback: () => this.submitNew() }));
		}

    buttons.push(new DashboardHeaderButton({ text: "Download", icon: "download", callback: () => this.showDownloadOptions() }));
		this.store.dispatch(new SetDashboardHeaderButtons(buttons));

		this.getSars();
		
	}

	getSars() {
		this.dataService.getSars().subscribe((resp) => {
			this.allSars = resp.map(s => new Sar(s));
			this.sars = this.allSars.slice(0, this.pageSize);
			this.pageCount = Math.ceil(this.allSars.length / this.pageSize)
		});
	}

	goToPage(page) {
		let start = (page - 1) * this.pageSize;
		this.sars = this.allSars.slice(start, start + this.pageSize);
		this.page = page;
	}
	expandSar(sar) {
		if(this.openSarId == sar.draId) {
			this.openSarId = undefined;
			return;
		}
		this.openSarId = sar.draId;
		if(!sar.details) {
			this.dataService.getSarDetail(sar.draId).subscribe(resp => sar.details = resp)
		}
	}
	searchSar() {
		this.dataService.searchSars(this.searchTerm).subscribe((resp: any) => {
			this.allSars = resp;
			this.sars = this.allSars.slice(0, this.pageSize);
			this.pageCount = Math.ceil(this.allSars.length / this.pageSize)
		})
	}

	clearSearch() {
		this.searchTerm = '';
		this.getSars();
	}
	printSar(id) {
		window.open('/dashboard/sar/print/'+id, '_blank', 'toolbar=0,location=0,menubar=0');
	}
	submitNew() {
		this.router.navigate(['/dashboard', 'sar', 'new'])
	}

	showDownloadOptions() {
		this.store.dispatch(new ShowDownloadDialog(this.sars, this.tableInfo, []));
	}

	downloadInvoice(id) {
		this.dataService.downloadInvoice(id).subscribe((resp:any) => {
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = id+'.pdf';
            a.href = URL.createObjectURL(resp);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);

		}, err => {
			switch(err.error.size) {
				case 30:// can't find file
					this.store.dispatch(new ErrorMessageSet("The invoice was not found"));
					break;
				case 32: //not your invoice
					this.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
					break;
			}
		})
	}

}
