import { Cart, CartItemAlert } from "src/app/models/shopping/cart";
import { AppliedSpecial } from "src/app/models/shopping/specials";


export interface ICartState {
	cart: Cart;
	isCartOpen: boolean;
	appliedSpecials: Array<AppliedSpecial>;
	dropShipCost: number;
	cartItemAlerts: { [key: number]: CartItemAlert };
}

export const initialCartState: ICartState = {
	cart: null,
	isCartOpen: false,
	appliedSpecials: [],
	dropShipCost: null,
	cartItemAlerts: {},
}