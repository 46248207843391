import { WarehouseSearchCriteria, WarehouseSearchResults } from "src/app/models/search/warehouse-search-results";



export interface ISearchState {
	criteria: WarehouseSearchCriteria,
	results: WarehouseSearchResults;
}

export const initialSearchState: ISearchState = {
	criteria: null,
	results: null,
}