import { SiteActions, SiteActionTypes } from "../actions/site.actions";
import { initialSiteState, ISiteState } from "../state/site.state";


export function siteReducer(state: ISiteState = initialSiteState, action: SiteActions): ISiteState {
	switch(action.type) {
		case SiteActionTypes.SUCCESS_MESSAGE_SET:
			return { ...state, successMessage: action.message };
		case SiteActionTypes.SUCCESS_MESSAGE_CLEAR:
			return { ...state, successMessage: '' };
		case SiteActionTypes.ERROR_MESSAGE_SET:
			return { ...state, errorMessage: action.message };
		case SiteActionTypes.ERROR_MESSAGE_CLEAR:
			return { ...state, errorMessage: '' };
		case SiteActionTypes.ALL_MESSAGE_CLEAR:
			return { ...state, successMessage: '', errorMessage: '' };
		case SiteActionTypes.OPEN_SEARCH: 
			return { ...state, searchOpen: true, headerSearchType: action.headerSearchType };
		case SiteActionTypes.CLOSE_SEARCH:
			return { ...state, searchOpen: false };
		case SiteActionTypes.OPEN_SIDE_NAV: 
			return { ...state, sideNavOpen: true };
		case SiteActionTypes.CLOSE_SIDE_NAV:
			return { ...state, sideNavOpen: false };
		case SiteActionTypes.OPEN_VENDOR_PREVIEW: 
			return { ...state, vendorPreviewOpen: true };
		case SiteActionTypes.CLOSE_VENDOR_PREVIEW:
			return { ...state, vendorPreviewOpen: false };
		case SiteActionTypes.SET_NAV_MENU_OPEN:
			return { ...state, navMenuOpen: action.menu };
		case SiteActionTypes.SET_DASHBOARD_TITLE: 
			return { ...state, dashboardTitle: action.title };
		case SiteActionTypes.SET_DASHBOARD_HEADER_BUTTONS:
			return { ...state, dashboardHeaderButtons: action.buttons };
		case SiteActionTypes.SET_UNDO_MESSAGE:
			return { ...state, undoMessage: action.undoMessage };
		case SiteActionTypes.SHOW_UPLOAD_ORDER: 
			return { ...state, uploadOrderVisible: true };
		case SiteActionTypes.HIDE_UPLOAD_ORDER:
			return { ...state, uploadOrderVisible: false };
		case SiteActionTypes.OPEN_QUICK_ADD:
			return { ...state, quickAddOpen: true };
		case SiteActionTypes.CLOSE_QUICK_ADD:
			return { ...state, quickAddOpen: false };
		case SiteActionTypes.SHOW_DOWNLOAD_DIALOG:
			return { ...state, downloadData: action.downloadData, downloadTableInfo: action.downloadTableInfo, downloadHeaders: action.downloadHeaders, downloadFormats: action.downloadFormats }
		default:
			return state;
	}
}