import { Category } from "./category";
import { Display } from "./display";
import { Image } from "./image";
import { InstantRebate } from "./instant-rebate";
import { CmsVendor } from "./vendor";
import { marked } from 'marked';
import { Sanitizer } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { Author } from "./author";
import { Media } from "./media";

marked.setOptions({
	renderer: new marked.Renderer(),

	langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
	pedantic: false,
	gfm: true,
	breaks: false,
	sanitize: false,
	smartLists: true,
	smartypants: false,
	xhtml: false
  });
  export class Article {
	public id: number;
	public articleName: string;
	public postDate: Date;
	public removalDate: Date;
	public newsletterDate: Date;
	public categories: Array<Category>;
	public vendors: Array<CmsVendor>;
	public displays: Array<Display>;
	public content: string;
	public enableComments: boolean;
	public slug: string;
	public articleType: 'Header' | 'Feature' | 'None' | null;
	public newsletter: boolean;
	public proNews: boolean;
	public instantRebate: Array<InstantRebate>;
	public enableLikes: boolean;
	public excerpt: string;
	public image: any;
	public likes: number;
	public commentCount: number;
	public userCommented: boolean;
	public likeCount: number;
	public userLiked: boolean;
	public views: number;
	public word: string;
	public backgroundColor: string;
	public articlePageImage: any
	public contentSafeHtml: SafeHtml;
	public author: Author;

	public badge: string;

	constructor(o?: any) {
		let imageData = null, articlePageImageData = null, instantRebates = null;
		if(o.image) {
			if(o.image.data) {
				imageData = new Image({ id: o.image.data.id, ...o.image.data.attributes })
			} else {
				imageData = new Image(o.image);
			}
		}
		if(o.articlePageImage) {
			if(o.articlePageImage.data) {
				articlePageImageData = new Image({ id: o.articlePageImage.data[0].id, ...o.articlePageImage.data[0].attributes })
			} else {
				articlePageImageData = new Image(o.articlePageImage);
			}
		}
		if(o?.instant_rebates?.data) {
			instantRebates = o.instant_rebates.data.map(r => new InstantRebate({ id: r.id, ...r.attributes}))
		}
		if(o?.author?.data) {
			o.author = new Author({ id: o.author.data.id, ...o.author.data.attributes });
		}
		if(o.Media?.length > 0) {
			let media = o.Media.map(m => new Media(m))
			o.Media = media;
		}

		o.image = imageData;
		o.articlePageImage = articlePageImageData;
		o.instantRebate = instantRebates;
		o.content = marked.parse(o.content + '').replace('\\n', '<br>');
		Object.assign(this, o);
	}

}