import { Component, ElementRef, OnInit, Output, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Calendar } from "primeng/calendar";
import { Subject } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-sell-through-upload",
	templateUrl: "./sell-through-upload.component.html",
	styleUrls: ["./sell-through-upload.component.scss"],
})
export class SellThroughUploadComponent implements OnInit {
	@ViewChild('calendar') calendar: Calendar;
	@ViewChild('uploadOrderFileField') uploadFileFormField: ElementRef;
	@Output() dataSaved: Subject<boolean> = new Subject();
	public dateRange;
	public uploadErrors: Array<any> = [];

	constructor(private dataService: DataService, private store: Store<IAppState>) {}

	ngOnInit(): void {}

	selectWeek(e) {
		let start = new Date(e);
		start.setDate(start.getDate() - start.getDay());
		this.dateRange[0] = start;
	
		let end = new Date(start);
		end.setDate(start.getDate() + 6); 
		this.dateRange[1] = end;
		this.calendar.toggle();
	}

	closeUploadDialog() {
		this.dataSaved.next(true);
		this.dateRange = undefined;
		this.uploadErrors = [];
		this.uploadFileFormField.nativeElement.value = null;
	}

	upload(e) {
		this.uploadErrors = [];
		if(!this.dateRange) {
			this.uploadErrors = [ { error: 'Please enter a date range and then re-select your file.' } ];
			this.uploadFileFormField.nativeElement.value = null;
		}
		this.dataService.uploadSellThrough(this.dateRange[1].toLocaleDateString(), e.srcElement.files[0]).subscribe((resp) => {
			this.uploadFileFormField.nativeElement.value = null;
			if (resp.length == 0) {
				this.closeUploadDialog();
				this.store.dispatch(new SuccessMessageSet("Your file was uploaded without errors"));
			} else {
				this.uploadErrors = resp;
			}
		});
	}
}
