<div class="logged-in-as-overlay" *ngIf="user?.isLogInAs"></div>
<div class="logged-in-as" *ngIf="user?.isLogInAs">You are logged in as: {{user.name}}</div>
<div [ngClass]="{'user-logged-in-as': userLoggedInAs}">
	<router-outlet></router-outlet>
</div>

<p-messages></p-messages>


<div class="message-holder {{messageType}}" [ngClass]="{active: message !== ''}">
	{{message}}
</div>