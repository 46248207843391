
<div class="container">
	<ng-container [ngSwitch]="mode">
		<div class="bundle {{mode}}" *ngSwitchCase="'lite'">
			<div class="bundle-code"><a [routerLink]="['/shop', 'bundle', cartBundle.bundle.bundleCode]">{{cartBundle.bundle.bundleCode}}</a></div>
			<div class="bundle-name"><a [routerLink]="['/shop', 'bundle', cartBundle.bundle.bundleCode]">{{cartBundle.bundle.name}}</a></div>
			<div class="qty" [permission]="Permissions.ShopAccess">
				<input [(ngModel)]="quantity" (blur)="setQuantity()">
			</div>
			<div class="prices">
				<div class="unit-price"><span>Price</span><span>{{cartBundle.bundle.retail | currency}}</span></div>
				<div class="ext-price"><span>Extended</span><span>{{cartBundle.bundle.retail * cartBundle.quantity | currency}}</span></div>
			</div>
			<div class="delete" [permission]="Permissions.ShopAccess"><div (click)="deleteItem()"><mat-icon>delete_outline</mat-icon></div></div>
		</div>
		<div class="bundle {{mode}}" *ngSwitchDefault>
			<div class="bundle-name"><a [routerLink]="['/shop', 'bundle', cartBundle.bundle.bundleCode]">{{cartBundle.bundle.name}}</a></div>
			<div class="products">
				<div class="product-name header">Product</div>
				<div class="quantity header">Quantity</div>
				<div class="price header">Retail</div>
				<div class="price header">Price</div>
				<ng-container *ngFor="let bp of cartBundle.bundle.products">
					<div class="product-name">{{bp.product.modelName}}</div>
					<div class="quantity">{{bp.quantity}}</div>
					<div class="retail">{{bp.product.retail | currency}}</div>
					<div class="price">{{bp.priceProportion | currency}}</div>
				</ng-container>
			</div>
			<div class="qty" [permission]="Permissions.ShopAccess">
				<span class="decrement" (click)="increment(-1)">-</span>
				<input [(ngModel)]="quantity" (blur)="setQuantity()">
				<span class="increment" (click)="increment(1)">+</span>
			</div>
			<div class="delete" [permission]="Permissions.ShopAccess"><div (click)="deleteItem()"><mat-icon>delete_outline</mat-icon>Delete</div></div>
			<div class="prices">
				<div class="unit-price"><span>Price</span><span>{{cartBundle.bundle.retail | currency}}</span></div>
				<div class="ext-price"><span>Extended</span><span>{{cartBundle.bundle.retail * cartBundle.quantity | currency}}</span></div>
			</div>
		</div>
	</ng-container>
</div>
