import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { CalendarEvent } from "angular-calendar";
import { Event } from "src/app/models/strapi/event";
import { StrapiService } from "src/app/services/strapi.service";


@Component({
	selector: "app-calendar",
	templateUrl: "./calendar.component.html",
	styleUrls: ["./calendar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit {

	public viewDate: Date = new Date();

	public calendarEvents: Array<CalendarEvent> = [];
	public events: Array<Event>;
	public daysEvents: Array<Event> = [];

	constructor(private strapiService: StrapiService) {}

	ngOnInit(): void {
		this.getEvents();
	}

	getEvents() {
		this.strapiService
			.getEvents({
				filters: {
					$and: [
						{
							startDateTime: {
								$gte: new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1),
							},
						},
						{
							startDateTime: {
								$lte: new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0),
							},
						},
					],
				},
			})
			.subscribe((resp) => {
				this.calendarEvents = resp.map((e) => this.makeCalendarEvent(e));
				this.events = resp;
				setTimeout(() => (document.getElementsByClassName('cal-today')[1].firstElementChild as any).click(), 250);
			});

	}
	makeCalendarEvent(e: Event): CalendarEvent {
		return {
			id: e.id,
			start: new Date(e.startDateTime),
			end: new Date(e.endDateTime),
			title: e.title,
			allDay: e.isAllDayEvent,
			cssClass: "has-event",
		};
	}
	showDaysEvents(day) {
		this.daysEvents = this.events.filter((e) => day.events.map((d) => d.id).includes(e.id)).sort(this.eventSorter);
	}
	eventSorter(a: Event, b: Event) {
		if(a.isAllDayEvent && !b.isAllDayEvent) return -1;
		if(b.isAllDayEvent && !a.isAllDayEvent) return 1;
		if(a.startDateTime < b.startDateTime) return -1;
		if(b.startDateTime < a.startDateTime) return 1;
		if(a.endDateTime < b.endDateTime) return -1;
		if(b.endDateTime < a.endDateTime) return 1;
		return 0;
	}
	dayClicked(e) {
		this.viewDate = e.day.date;
		this.showDaysEvents(e.day);
	}

	changeMonth(amt) {
		this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + amt, this.viewDate.getDate());
		this.getEvents();
		this.daysEvents = [];
	}

}
