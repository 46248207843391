
export class InstantRebate {
	public title: string;
	public start: Date;
	public end: Date;
	public claimStart: Date;
	public claimEnd: Date;
	public pdf: InstantRebatePdf;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class InstantRebateGroups {
	public current: Array<InstantRebate>;
	public future: Array<InstantRebate>;
	public redeemable: Array<InstantRebate>;

	constructor(o?:any) {
		this.current = o?.current?.map(r => new InstantRebate(r))
		this.future = o?.future?.map(r => new InstantRebate(r))
		this.redeemable = o?.redeemable?.map(r => new InstantRebate(r))
	}
}

export class InstantRebatePdf {
	public id: number;
	public name: string;
	public alternativeText: string;
	public caption: string;
	public width: number;
	public height: number;
	public formats: any;
	public hash: string;
	public ext: string;
	public mime: string;
	public size: number;
	public url: string;
	public previewUrl: string;
	public provider: string;
	public provider_metadata: any;
	public createdAt: Date;
	public updatedAt: Date;
	constructor(o?: any) {
		Object.assign(this, o);
	}
}