import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetDashboardTitle } from 'src/app/store/actions/site.actions';
import { IAppState } from 'src/app/store/state/app.state';

@Component({
  selector: 'app-most-frequently-bought',
  templateUrl: './most-frequently-bought.component.html',
  styleUrls: ['./most-frequently-bought.component.scss']
})
export class MostFrequentlyBoughtComponent implements OnInit {

  constructor(private store: Store<IAppState>) { }

  ngOnInit(): void {
	this.store.dispatch(new SetDashboardTitle('Most Frequently Purchased'));
  }

}
