import { Component, OnInit, Input } from "@angular/core";
import { SarPrint, SarPrintItem } from "src/app/models/dashboard/sar";

@Component({
	selector: "app-sar-print-section",
	templateUrl: "./sar-print-section.component.html",
	styleUrls: ["./sar-print-section.component.scss"],
})
export class SarPrintSectionComponent implements OnInit {
	@Input() title: string;
	@Input() type: string;
	@Input() summary: SarPrint;
	@Input() items: Array<SarPrintItem>;
	constructor() {}

	ngOnInit(): void {}
}
