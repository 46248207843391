<div id="download_dialog">
	<p-dialog [(visible)]="showDownloadDialog" [dismissableMask]="true" position="right" styleClass="download-dialog" [closable]="false">
		<ng-template pTemplate="header"></ng-template>
		<div class="download-option">
			<mat-icon>download</mat-icon>
			<h3>Download Data</h3>
			<p>What format do you want to download?</p>
			<div class="actions">
				<div (click)="downloadAs('xls')" *ngIf="allow('xls')">Excel</div>
				<div (click)="downloadAs('csv')" *ngIf="allow('csv')">CSV</div>
				<div (click)="downloadAs('pdf')" *ngIf="allow('pdf')">PDF</div>
				<div class="cancel" (click)="this.showDownloadDialog = false">Cancel</div>
			</div>
		</div>
	</p-dialog>	
</div>

