import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
	selector: "app-product-quantity",
	templateUrl: "./product-quantity.component.html",
	styleUrls: ["./product-quantity.component.scss"],
})
export class ProductQuantityComponent implements OnInit {
	@Input() quantity: number;
	@Input() multiple: number = 1;
	@Output() quantityChange: EventEmitter<number> = new EventEmitter();
	constructor() {}

	ngOnInit(): void {
		this.quantity = this.multiple;
	}

	increment(amt) {
		if (isNaN(this.quantity)) {
			this.quantity = 0;
		}
		this.quantity = +this.quantity + amt * this.multiple;
		this.quantity = Math.max(this.quantity, 0);
		this.quantityChange.emit(this.quantity);
	}
	setQuantity() {
		this.quantityChange.emit(this.quantity);
	}
}
