import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ConfirmationService } from "primeng/api";
import { of, Subject } from "rxjs";
import { map, switchMap, tap, filter, withLatestFrom } from "rxjs/operators";
import { AddToCartResult, Cart, CartItemAlert } from "src/app/models/shopping/cart";
import { AppliedSpecial } from "src/app/models/shopping/specials";
import { DataService } from "src/app/services/data.service";
import { AddBundleToCart, AddBundleToCartSuccess, AddMultipleToCart, AddMultipleToCartSuccess, AddToCart, AddToCartSuccess, CartActionTypes, ClearCartSuccess, EditCartBundle, EditCartItem, GetCart, GetCartSpecialsSuccess, GetCartSuccess, GetDropShipCost, GetDropShipCostSuccess, GetMixMatchInvalids, ItemDeletedFromCart, OpenCart, SetCartItemAlerts, SetCartItemAlertsSuccess, SortCartItemsSuccess, UpdateCart } from "../actions/cart.actions";
import { SuccessMessageSet } from "../actions/site.actions";
import { IAppState } from "../state/app.state";


@Injectable()
export class CartEffects {
	
	constructor(private actions$:Actions, private store$:Store<IAppState>, private dataService:DataService, private confirmationService: ConfirmationService) {}

	getCart$ = createEffect(() => 
		this.actions$.pipe(
			ofType(CartActionTypes.GET_CART),
			switchMap((action: GetCart) => this.dataService.getCart()),
			switchMap((cart:Cart) => [
				new GetCartSuccess(cart),
				new GetDropShipCost(),
				new GetMixMatchInvalids(),
			])
		)	
	) 
	
	getCartSuccess$ = createEffect( () => 
		this.actions$.pipe(
			ofType(CartActionTypes.GET_CART_SUCCESS),
			switchMap((action: GetCartSuccess) => {
				return this.dataService.getSpecialsForCart().pipe(
					map((specials: Array<AppliedSpecial>) => new GetCartSpecialsSuccess(specials))
				)
			})
		)
	)


	addToCart$ = createEffect(() => {
		let addToCartAction;
		return this.actions$.pipe(
			ofType(CartActionTypes.ADD_TO_CART),
			tap((action:AddToCart) => addToCartAction = action),
			switchMap((action: AddToCart) => {
				if(action.quantity > 100) {
					const subject: Subject<boolean> = new Subject();
					this.confirmationService.confirm({ 
						message: 'You are adding over 100 items to your cart.  Are you sure you want to continue?',
						accept: () => subject.next(true),
						reject: () => subject.next(false)
					});
					return subject.asObservable();
				} else {
					return of(true);
				}
			}),
			filter(resp => resp),
			switchMap(() => this.dataService.addToCart(addToCartAction.productCode, addToCartAction.quantity)),

            switchMap((r: AddToCartResult) => [
				new AddToCartSuccess(r),

                new GetCart(),

                new SuccessMessageSet(`${r.data.quantity} unit${r.data.quantity == 1 ? '' : 's'} of ${r.data.modelName} added to cart`),

                new GetMixMatchInvalids(),
			]),
		)
		}
  )


	addBundleToCart$ = createEffect(() => 
		this.actions$.pipe(
			ofType(CartActionTypes.ADD_BUNDLE_TO_CART),
			switchMap((a:AddBundleToCart) => this.dataService.addToCart(a.bundleCode, a.quantity)),
			switchMap((r: AddToCartResult) => [
				new AddToCartSuccess(r),
				//new GetCart(),
				//new SuccessMessageSet(`${r.data.quantity} unit${r.data.quantity == 1 ? '' : 's'} of ${r.data.modelName} added to cart`),
			])
		)
	);

	editBundle$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CartActionTypes.EDIT_CART_BUNDLE),
			switchMap((a: EditCartBundle) => this.dataService.updateCartBundle(a.cartBundleId, a.quantity)),
			switchMap(() => [new GetCart()])
		)
	)

	clearCart$ = createEffect(() => 
		this.actions$.pipe(
			ofType(CartActionTypes.CLEAR_CART),
			switchMap((action: AddToCart) => this.dataService.clearCart()),
			switchMap((r: AddToCartResult) => [
				new ClearCartSuccess(),
				new GetCart(),
				new SuccessMessageSet('All items have been removed from your cart')
			])
		)

	)

	addMultipleToCart$ = createEffect(() => 
		this.actions$.pipe(
			ofType(CartActionTypes.ADD_MULTIPLE_TO_CART),
			switchMap((action: AddMultipleToCart) => this.dataService.addMultipleToCart(action.items)),
			switchMap(() => [
				new AddMultipleToCartSuccess(),
				new GetCart(),
				new GetMixMatchInvalids(),
			]),
		)

	)
	getDropShipCost$ = createEffect(() => 
		this.actions$.pipe(
			ofType(CartActionTypes.GET_DROP_SHIP_COST),
			switchMap((action: GetDropShipCost) => {
				return this.dataService.getDropShipShippingCharge().pipe(
					map((cost: number) => new GetDropShipCostSuccess(cost))
				)
			})			
		)

	)

	mixMatchInvalids$ = createEffect(() => 
		this.actions$.pipe(
			ofType(CartActionTypes.GET_MIXMATCH_INVALIDS),
			switchMap((action: GetMixMatchInvalids) => {
				return this.dataService.getMixMatchInvalid().pipe(
					map((mmi) => new SetCartItemAlerts(mmi.reduce((a,mmi) => ({ ...a, [mmi.cartItemId]: new CartItemAlert({ productCode: mmi.productCode, cartItemId: mmi.cartItemId, code: 10, message: 'This item must be purchased in specific quantities.  Please edit quantity before continuing'})}), {})))
				)
			})			
		)

	)

	cartAlerts$ = createEffect(() => 
			this.actions$.pipe(
				ofType(CartActionTypes.SET_CARTITEM_ALERTS),
				switchMap((a: SetCartItemAlerts) => [new SetCartItemAlertsSuccess(a.alerts)])
			)
	)
	updateCartItem$ = createEffect(() => {
		let editCartItemAction;
		return this.actions$.pipe(
			ofType(CartActionTypes.EDIT_CART_ITEM),
			tap(action => editCartItemAction = action),
			switchMap((action: EditCartItem) => this.dataService.updateCartItem({cartItemId: action.cartItemId, quantity: action.quantity, rejectUpsell: action.rejectUpsell})),
			switchMap((e) => {
				if(editCartItemAction.quantity == 0) {
					return [new ItemDeletedFromCart(editCartItemAction.productCode, editCartItemAction.quantity), new GetCart()]
				}
				return [
					new GetCart(), 
					new SuccessMessageSet('Product added to cart'), 
					new GetMixMatchInvalids()
				]
			}),
		)
	});

	sortCart$ = createEffect(() => {
		let editCartItemAction;
		return this.actions$.pipe(
			ofType(CartActionTypes.SORT_CART_ITEMS),
			withLatestFrom(this.store$),
			switchMap(([action, store]) => {
				let sortedItems = [];
				store.cartState.cart.cartItems.forEach((cartItem, sortId) => sortedItems.push({ cartItemId: cartItem.cartItemId, sortId }));
				this.dataService.sortCart(sortedItems).subscribe();
				return [new SortCartItemsSuccess()];
			}),
		)
	});
	
}
