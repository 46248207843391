
export class Comment {
	public id: number;
	public content: string;
	public blocked: boolean;
	public blockedThread: boolean;
	public blockReason: string;
	public removed: string;
	public approvalStatus: string;
	public createdAt: Date;
	public updatedAt: Date;
	public gotThread: boolean;
	public threadFirstItemId: number;
	public author: CommentAuthor;
	public children: Array<Comment>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class CommentAuthor {
	public id: string;
	public name: string;
	public email: string;
	public avatar: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}	
}

export class CommentSubmission {
	public author: CommentAuthor;
	public content: string;
	public threadOf: number;

	constructor(o: {name: string, email: string, id: string, content: string, avatar?: string, threadOf?: number}) {
		this.author = new CommentAuthor({name: o.name, email: o.email, id: o.id, avatar: o.avatar});
		this.content = o.content;
		this.threadOf = o.threadOf;
	}
}