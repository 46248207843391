import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarehouseComponent } from './warehouse/warehouse.component';

import { ChipModule } from 'primeng/chip'
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ControlsModule } from '../controls/controls.module';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [
    WarehouseComponent
  ],
  imports: [
    CommonModule,
	FormsModule,

	ControlsModule,

	ButtonModule,
	CheckboxModule,
	TriStateCheckboxModule,
	ChipModule,
	DropdownModule,
	SelectButtonModule,
	SliderModule,
	InputTextModule,

  ]
})
export class WarehouseModule { }
