

export class OrderSearchCriteria {
	//public orderStatusId: number | '';
	//public orderTypeId: number | '';
	public term: string;
	public userId: number;
	public addressId: number;
	public startDate: Date | '';
	public endDate: Date | '';
	public vendorId: number;

	constructor(o?: any) {
		Object.assign(this,o);
	}
}