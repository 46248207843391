

<div class="pane">
	<div class="tab-list">
		<div class="tab" (click)="setTab('Contact')" [ngClass]="{active: openTab == 'Contact'}">Contact</div>
		<div class="tab" (click)="setTab('Staff')" [ngClass]="{active: openTab == 'Staff'}">Staff</div>
		<div class="tab" (click)="setTab('Officers')" [ngClass]="{active: openTab == 'Officers'}">Officers</div>
		<div class="tab" (click)="setTab('Directors')" [ngClass]="{active: openTab == 'Directors'}">Directors</div>
	</div>
	<div class="tab-content">

		<ng-container *ngIf="openTab == 'Contact'">

			<h1>Address</h1>
			
			<div class="Address">
			<br /><br /><br />
					ProMaster <br />
					240 Long Hill Cross Rd. <br />
					Shelton, CT 06484
			</div>
		
		</ng-container>



		<ng-container *ngIf="openTab == 'Staff'">
			<h1>Staff</h1>
			<div class="department" *ngFor="let dept of departments">
				<h3 class="dept-name">{{dept.name}}</h3>
				<div class="staff-members">
					<div class="staff-member" *ngFor="let member of dept.staff">
						<div class="card">
							<div class="image" [ngStyle]="{'background-image': 'url(' + member.photo.url + ')'}"></div>
							<div class="name">{{member.name}}</div>
							<div class="title">{{member.title}}</div>
							<div class="description">{{member.description}}</div>
							<div class="email"><a href="mailto:{{member.email}}">{{member.email}}</a></div>
							<div class="extension">Extension: {{member.extension}}</div>
							<div class="phone">Direct: {{member.phone}}</div>

						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="openTab == 'Officers'">
			<h1>Officers</h1>
			<div class="row officer header">
				<div class="title">Title</div>
				<div class="name">Name</div>
				<div class="company">Company</div>
				<div class="address">Address</div>
				<div class="phone">Phone Number</div>
				<div class="email">Email</div>
			</div>
			<div class="row officer" *ngFor="let officer of officers">
				<div class="title">{{officer.title}}</div>
				<div class="name">{{officer.firstName}} {{officer.lastName}}</div>
				<div class="company">{{officer.company}}</div>
				<div class="address">{{officer.city}}, {{officer.state}}</div>
				<div class="phone">{{officer.phone}}</div>
				<div class="email"><a href="mailto:{{officer.email}}">{{officer.email}}</a></div>
			</div>
		</ng-container>
		<ng-container *ngIf="openTab =='Directors'">
			<h1>Board of Directors</h1>
			<div class="row director header">
				<div class="name">Name</div>
				<div class="company">Company</div>
				<div class="phone">Phone Number</div>
				<div class="email">Email</div>
				<div class="execCommittee">Executive Committee Member?</div>
			</div>
			<div class="row director" *ngFor="let director of directors">
				<div class="name">{{director.firstName}} {{director.lastName}}</div>
				<div class="company">{{director.company}}</div>
				<div class="phone">{{director.phone}}</div>
				<div class="email"><a href="mailto:{{director.email}}">{{director.email}}</a></div>
				<div class="execCommittee">{{director.isExecCommitteeMember ? 'YES' : 'NO'}}</div>
			</div>
		</ng-container>
	</div>
</div>
