
export class UndoMessage {
	public intro: string;
	public icon: string;
	public undoText: string;
	public callback: () => void;
	public parameters: any;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}