
<div class="container">
	<div class="row" *ngFor="let item of added">
		<div class="code">{{item.productCode}}</div>
		<div class="name">{{item.modelName}}&nbsp;</div>
		<div class="quantity">{{item.quantity}}</div>
	</div>
	<div class="row" >
		<div class="code"><input type="text" pInputText [(ngModel)]="code" (blur)="onCodeEntered()" #codeField /></div>
		<div class="name">{{modelName}}&nbsp;</div>
		<div class="quantity"><input type="number" pInputText [(ngModel)]="quantity" (blur)="onQuantityEntered()" /></div>
	</div>
	<button (click)="focusCode()">focus</button>	
</div>
