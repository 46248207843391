
<div class="pane" *ngIf="invoiceHistory">
	<p>Drill down to find the invoice you want to download.  Once you've found it, click the invoice number to start downloading.</p>
	<div class="year" *ngFor="let year of invoiceHistory.years">
		<div class="expander" (click)="expandYear(year.year)" [ngClass]="{ open: expandedYear == year.year}"><mat-icon>chevron_right</mat-icon>{{year.year}}</div>
		<div class="months" [ngClass]="{ open: expandedYear == year.year}">
			<div class="month" *ngFor="let month of year.months" >
				<div class="expander" (click)="expandMonth(month.month)" [ngClass]="{ open: expandedMonth == month.month}"><mat-icon>chevron_right</mat-icon>{{monthName(month.month)}}</div>
				<div class="vendors" [ngClass]="{ open: expandedMonth == month.month}">
					<div class="vendor" *ngFor="let vendor of month.vendors">
						<div class="expander" (click)="expandVendor(vendor.vendor)" [ngClass]="{ open: expandedVendor == vendor.vendor}"><mat-icon>chevron_right</mat-icon>{{vendor.vendor}}</div>
						<div class="invoices" [ngClass]="{ open: expandedVendor == vendor.vendor}">
							<div class="invoice header">
								<div class="invoice-number">Invoice Number</div>
								<div class="invoice-date">Date</div>
								<div class="brand">Brand</div>
								<div class="po">PO Number</div>
								<div class="terms">Terms</div>								
							</div>
							<div class="invoice" *ngFor="let item of vendor.items">
								<div class="invoice-number"><span (click)="downloadInvoice(item)">{{item.invoice}}</span></div>
								<div class="invoice-date">{{item.billDate | date}}</div>
								<div class="brand">{{item.brand}}</div>
								<div class="po">{{item.po}}</div>
								<div class="terms">{{item.terms}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>			
		</div>

	</div>
</div>