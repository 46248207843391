<ul>
	<li class="post" *ngFor="let post of posts">
		<header>
			<div class="avatar" [ngStyle]="{'background-image': 'url(' + post.avatarUrl + ')'}"></div>
			<div class="name">{{post.name}}</div>
			<div class="time-ago">{{post.timeAgo}}</div>
		</header>
		<div class="topic">
			<a href="https://forum.promaster.com/Forum/chat/{{ post.topicSlug }}">{{ post.topicName }}</a>
		</div>
		<div class="tags">
			<div class="tag" *ngFor="let tag of post.tags">{{tag.tagName}}</div>
		</div>
		<div class="post-actions">
			<div class="date">
				<i class="pi pi-calendar"></i>{{post.lastPostDate | date }}
			</div>
			<div class="comments">
				<i class="pi pi-comments"></i>{{post.comments}} comment{{ post.comments == 1 ? "" : "s" }}
			</div>
			<div class="views">
				<i class="pi pi-views"></i>{{post.views}} view{{ post.views == 1 ? "" : "s" }}
			</div>
		</div>
	</li>
</ul>
