

<h1 (click)="isOpen = !isOpen"><mat-icon [ngClass]="{ open: isOpen }">expand_more</mat-icon>{{title}}</h1>
<div class="content {{cssClass}}" hideable [ngClass]="{ open: isOpen }">
	<div class="content-inner">
		<div #contentContainer>
			<ng-content></ng-content>
		</div>
		<div class="article" *ngFor="let article of articles">
			<a [routerLink]="['/cms', 'article', article.id, article.slug]">{{article.articleName}}</a>
			<div class="date">{{article.postDate | date: 'MM/dd/yyyy'}}</div>
			<div class="interactions" *ngIf="showInteractions">
				<div class="likes" [ngClass]="{ userLiked: article.userLiked }" (click)="toggleLike(article)"><i class="pi pi-heart"></i>{{article.likeCount}}</div>
				<div class="comments" [ngClass]="{ userCommented: article.userCommented }"><i class="pi pi-comment"></i>{{article.commentCount}}</div>
			</div>
		</div>
		<div class="pad" *ngIf="articles && articles.length == 0 && !contentContainer.innerHTML.trim()">
			<span class="no-info"><mat-icon>info</mat-icon>No information at this time.</span>
		</div>
	</div>		
</div>


