import { Component, OnInit, Input } from '@angular/core';
import { RebateEntry } from 'src/app/models/dashboard/rebates';

@Component({
  selector: 'app-rebate-claim-details',
  templateUrl: './rebate-claim-details.component.html',
  styleUrls: ['./rebate-claim-details.component.scss']
})
export class RebateClaimDetailsComponent implements OnInit {

	@Input() rebate: RebateEntry
  constructor() { }

  ngOnInit(): void {
  }

  get claimTotal() {
	return this.rebate.rebateLineItems.reduce((a, b) => a + b.quantity * b.reimbursement, 0);
}
}
