<div class="container" *ngIf="order">
	<img src="../../../../../../assets/images/PRO_Connection_2022 1.png" alt="ProConnection">
	<dl>
		<dt>Order Number</dt>
		<dd>{{order.orderId}}</dd>
		<dt>PO Number</dt>
		<dd>{{order.poNumber}}</dd>
		<dt>Order Date</dt>
		<dd>{{order.enterDate | date:"MM/dd/yyyy"}}</dd>
		<dt>Status</dt>
		<dd class="status {{order.orderStatus}}">{{order.orderStatus}}</dd>
		<dt>Total Price</dt>
		<dd>{{order.orderTotal | currency}}</dd>
		<dt>Order Type</dt>
		<dd>{{order.orderType}}</dd>
	</dl>
	<app-order-history-details [orderId]="order.orderId"></app-order-history-details>
</div>
