<div class="panel">
	<header>
		<h1>Stolen Goods Registry</h1>
		<p>Enter the details of the incident below.</p>
	</header>
	
	<main *ngIf="form" [formGroup]="form">
		<section class="form">
			<div class="field double">
				<label>Store Name</label>
				<input pInputText type="text" formControlName="storeName">
			</div>
			<div class="field">
				<label>Contact Person</label>
				<input pInputText type="text" formControlName="contact">
			</div>
			<div class="field">
				<label>Phone</label>
				<input pInputText type="text" [formControlName]="'phone'" [imask]="{mask: '(000) 000-0000'}">
			</div>
			<div class="field">
				<label>City</label>
				<input pInputText type="text" [formControlName]="'city'">
			</div>
			<div class="field">
				<label>State</label>
				<input pInputText type="text" [formControlName]="'state'">
			</div>
			<div class="field">
				<label>Incident Date</label>
				<p-calendar [formControlName]="'dateTheft'"></p-calendar>
			</div>
			<div class="field">
				<label>Type of Loss</label>
				<p-dropdown [options]="incidentTypes" [formControlName]="'incidentType'" [autoDisplayFirst]="false"></p-dropdown>
			</div>


			<div class="field double">
				<label>Notes</label>
				<textarea rows="4" cols="35" pInputTextarea [formControlName]="'notes'"></textarea>
				<!--<input pInputText type="text" formControlName="notes">-->
			</div>

		</section>
		<section class="products" [formArrayName]="'products'">
			<div class="product header">
				<div class="description">Product Description</div>
				<div class="serial-number">Serial Number</div>
				<div class="delete"></div>
			</div>
			<div class="product" [formGroupName]="i" *ngFor="let item of reportProducts.controls; let i = index">
				<div class="description"><input pInputText formControlName="product"></div>
				<div class="serial-number"><input pInputText formControlName="serialNumber"></div>
				<div class="delete"><div class="button" (click)="deleteProduct(i)"></div></div>
			</div>
			<div class="actions">
				<div class="button" (click)="addNewProduct()">Add Another Product</div>
			</div>
		</section>
		<div class="actions">
			<div class="button" (click)="submit()">Submit</div>
		</div>

	</main>
</div>
