import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Permission } from 'src/app/models/dashboard/permission';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';
import { SetDashboardTitle, SuccessMessageSet } from 'src/app/store/actions/site.actions';
import { IAppState } from 'src/app/store/state/app.state';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

	public users: Array<{label: string, value: any}>
	public userId: number;
	public allPermissions: Array<Permission>;
	public userPermissions: Array<Permission>
  constructor(private dataService: DataService, private route: ActivatedRoute, private store: Store<IAppState>, private siteService: SiteService) { }

  ngOnInit(): void {
	this.store.dispatch(new SetDashboardTitle("Permissions"))
	this.dataService.getUsers().subscribe(users => this.users = users.map(u => ({label: `${u.firstName} ${u.lastName}`, value: String(u.userId) })));
	this.route.paramMap.subscribe(params => {
		if(params.get('userId')) {
			this.setUser({ value: params.get('userId')})
		}
	})
	this.siteService.disallowSizes(['xs', 's'])
  }

  setUser(e) {
	this.userId = e.value;
	this.dataService.getUserPermissions(e.value).subscribe(p => {
		this.userPermissions = p;
		this.dataService.getAllPermissions().subscribe(perms => this.allPermissions = perms.filter(p => !this.userPermissions.map(up => up.permissionId).includes(p.permissionId)));
	});
  }

  savePermissions() {
	this.dataService.setUserPermissions(this.userId, this.userPermissions.map(p => p.permissionId)).subscribe(resp => this.store.dispatch(new SuccessMessageSet("The permissions have been saved")))
  }
}
