<div id="forgot_password">
	<div class="img">

	</div>
	<div class="form">
		<div class="heading">
			<div>&nbsp;</div>
			<div class="logo"><img src="../../../../../assets/images/promaster_logo.png"></div>
		</div>
		<h1>Reset Password</h1>
		<div class="copy">
			<p>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
			<p>For security reasons, we will never send your password via email.</p>
		</div>

		<div class="field">
			<label for="password">Password</label>
			<p-password [toggleMask]="true" [(ngModel)]="password" id="password" placeholder="Input your password here" [ngClass]="{invalid: passwordInvalid}"></p-password>
		</div>
		<div class="field">
			<label for="confirmPassword">Confirm Password</label>
			<p-password [toggleMask]="true" [(ngModel)]="confirmPassword" id="confirmPassword" placeholder="Confirm your password here" [ngClass]="{invalid: confirmPasswordInvalid}"></p-password>
		</div>
		<div>
			<button (click)="updatePassword()" >Update Password</button>
		</div>
	</div>	
</div>


