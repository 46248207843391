import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { OrderHistoryItem } from "src/app/models/dashboard/order-history-item";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-order-history-list",
	templateUrl: "./order-history-list.component.html",
	styleUrls: ["./order-history-list.component.scss"],
})
export class OrderHistoryListComponent implements OnInit {
	@Input() orderId: number;
	@Input() orderHistoryItems: Array<OrderHistoryItem>;
	@Input() mode: string;

	public openOrder: number;
	constructor(private dataService: DataService, private store: Store<IAppState>) {}

	ngOnInit(): void {}

	expand(id) {
		this.openOrder = (this.openOrder == id) ? 0 : id;
	}
	print(id) {
		window.open('/order/print/'+id);
	}

	openInvoice(number) {
		this.dataService.downloadInvoice(number).subscribe((resp:any) => {
			//the line below may get caught by a popup blocker - if so, just move it to before the call to downloadInvoice()
			let wdw = window.open('', 'invoice')
			wdw.location.href = URL.createObjectURL(resp);

		}, err => {
			//wdw.close();
			switch(err.error.size) {
				case 30:// can't find file
					this.store.dispatch(new ErrorMessageSet("The invoice was not found"));
					break;
				case 32: //not your invoice
					this.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
					break;
			}
		})
	}
}
