
export class Newsletter {
	public id: number;
	public year: number;
	public month: number;
	public day: number;
	public name; string;

	constructor(o?: any) {
		Object.assign(this, o, { ...o.attributes })
	}
}