export class ShippingError {

	public userId: number;
	public accountNumber: string;
	public storeName: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public phone: string;
	public upsNotified: boolean | string;
	public keepOrRma: string;
	public keepDamagedProducts: boolean;
	public willForward: boolean;
  public products: Array<ShippingErrorProduct>
  public ShipNumber: string;
   

  constructor(o?: any) {Object.assign(this, o);}
  
	get isValid() {

      //All these fileds are filled out on form. 
let valid = !!this.accountNumber && !!this.storeName && !!this.firstName && !!this.lastName && !!this.email && !!this.ShipNumber;


    if (this.products.some(p => p.errorType == 'Overage'))
    {
		valid = valid && this.keepOrRma != undefined;
    }

    if (this.products.some(p => p.errorType == 'Damaged'))
    {
      valid = valid && this.upsNotified != undefined;
      //&& this.keepDamagedProducts != undefined;
    }

    if (this.products.some(p => p.errorType == 'NotMine'))
    {
    valid = valid && this.willForward != undefined;          
    }

    // valid = valid && (this.willForward || this.products.length > 0);
    if(this.willForward == undefined)
    valid = valid && this.products.length > 0

    return valid;

  }
	get name() {
		return `${this.firstName} ${this.lastName}`;
	}
}


export class ShippingProductList {
  public productCode: string;
  public productDescription: string;
  public productQuantity: number;
  constructor(o?: any) {
    Object.assign(this, o);
      }
}

export class ShippingErrorProduct {
	public index: number;
	public productCode: string;
	public description: string;
  public quantity: number;
  public serial: string;
	public errorType: AffectedProductType = ''

	constructor(o?: any) {
		Object.assign(this, o);
	}

	get isEmpty() {
		return !this.productCode && !this.description && !this.quantity && !this.errorType;
	}
	get isValid() {
    return !!this.productCode && !!this.description && !!this.quantity && !!this.errorType && this.quantity > 0; 
	}
}

export type AffectedProductType = '' |
	'Overage' |
	'DidNotOrder' |
	'Shortage' |
	'Damaged' |
	'NotMine';

