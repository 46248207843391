


export type InvoiceHistoryVendor = { vendor: string, items: Array<InvoiceHistoryItem> };
export type InvoiceHistoryMonth = { month: number, vendors: Array<InvoiceHistoryVendor> };
export type InvoiceHistoryYear = { year: number, months: Array<InvoiceHistoryMonth> }
export class InvoiceHistory {
	public years: Array<InvoiceHistoryYear> = [];
	constructor(items: Array<InvoiceHistoryItem>) {
		items.forEach(i => {
			if(!this.years.some(y => y.year == i.year)) { this.years.push({ year: i.year, months: [] })}
			let year = this.years.find(y => y.year == i.year);
			if(!year.months.some(m => m.month == i.month)) { year.months.push({ month: i.month, vendors: []}); }
			let month = year.months.find(m => m.month == i.month);
			if(!month.vendors.some(d => d.vendor == i.vendor)) { month.vendors.push({ vendor: i.vendor, items: []})}
			let day = month.vendors.find(d => d.vendor == i.vendor);
			day.items.push(i);
		});
		this.years.sort((a,b) => a.year - b.year);
		this.years.forEach(y => {
			y.months.sort((a,b) => a.month - b.month);
			y.months.forEach(m => {
				m.vendors.sort((a,b) => a.vendor < b.vendor ? -1 : (a.vendor > b.vendor ? 1 : 0))
			})
		})
	}
}

export class InvoiceHistoryItem {
	public path: string;
	public member: string;
	public invoice: string;
	public billDate: Date;
	public vendor: string;
	public vendorInvoiceNumber: string;
	public brand: string;
	public po: string;
	public terms: string;

	constructor(o?: any) {
		if(o.billDate) {
			o.billDate = new Date(o.billDate);
		}
		Object.assign(this, o);
	}

	public get year() {
		return this.billDate ? this.billDate.getFullYear() : 9999;
	}
	public get month() {
		return this.billDate ? this.billDate.getMonth() + 1 : 99;
	}
	public get day() {
		return this.billDate ? this.billDate.getDate() : 99;
	}
}