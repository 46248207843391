
<ul>
	<li *ngFor="let special of specials">
		<h1>{{special.title}}</h1>
		<p>{{special.description}}</p>
		<div>Valid from: {{special.startDate | date: 'MM/dd/yyyy'}} to {{special.endDate | date: 'MM/dd/yyyy'}}</div>
		<div class="actions">
			<button pButton (click)="getDetails(special)">Details</button>
		</div>
	</li>
</ul>