<div class="pane">

	<p>
		<ng-container *ngIf="isSearching && term">Search Results for: <b>{{term}}</b><div class="delete"><div class="button" (click)="cancelSearch()">Clear Search</div></div></ng-container>
	</p>

	<div class="table">

		<!--<table>
			<tr><td>CSV file required.</td><td width="23px"></td><td></td></tr>
			<tr><td>To bulk upload stolen products, use this template.<br /><a href="./stolen-goods-upload-template.CSV" download="PROStolenRegistry.CSV">CSV Template</a> </td><td></td><td></td></tr>
		</table>-->
		
	<br />
	

		<app-table [data]="reports" [tableInfo]="tableInfo" [caller]="this"></app-table>
	</div>
	
</div>

<p-sidebar [(visible)]="sidebarVisible" position="right" (onHide)="sidebarVisible = false" [style]="{width: '60vw'}">
	<app-stolen-goods-form *ngIf="activeReport" [stolenGoodsReport]="activeReport" (onReportSaved)="close()"></app-stolen-goods-form>
</p-sidebar>

<div id="search_mask" [ngClass]="{open: showSearchDialog}" (click)="showSearchDialog = false"></div>
<div id="search_dialog" [ngClass]="{open: showSearchDialog}">
	<header id="search_bar">
		<i class="pi pi-search" (click)="search()"></i>
		<input pInputText [(ngModel)]="term" (keyup.enter)="search()" #searchTermField>
	</header>
</div>


<p-dialog [(visible)]="showUploadReports" [dismissableMask]="false" position="bottom" styleClass="upload-reports-dialog">
	<ng-template pTemplate="header">Upload Reports</ng-template>
	<div class="upload-form">
		<div class="upload-button">
			<div class="button">
				<mat-icon>add</mat-icon>
				<span>Upload/Add File</span>
			</div>
			<input type="file" (change)="upload($event)" #uploadReportsFileField>
		</div>
	</div>
	<div class="errors" *ngIf="uploadErrors.length > 0">
		<p>The following error(s) were found in your file:</p>
		<ul>
			<li *ngFor="let err of uploadErrors">
				<span>{{err.input}}: <span class="error">{{err.error}}</span></span>
			</li>
		</ul>
	</div>
	<ng-template pTemplate="footer">
		<div class="button" (click)="closeUploadDialog()">Close</div>
	</ng-template>
</p-dialog>
