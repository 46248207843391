<div class="container">


	<div id="warehouse_content" [ngClass]="{collapsed: leftPanelOpen}">
		<header>
			<h1>Warehouse</h1>

			<div class="breadcrumbs">
				<div class="filter" *ngFor="let bc of breadcrumbs"><b>{{bc.label}}: </b><span>{{bc.value}}</span></div>
			</div>

			<div class="export">
				<div class="button" (click)="download()"><mat-icon>download</mat-icon>Download</div>
			</div>

			<div class="mode">
				<mat-icon class="table-view" (click)="setMode('table')" [ngClass]="{ active: mode == 'table'}">table_view</mat-icon>
				<mat-icon class="listing" (click)="mode = 'listing'" [ngClass]="{ active: mode == 'listing'}">format_align_justify</mat-icon>
				<mat-icon class="grid-view" (click)="mode = 'tile'" [ngClass]="{ active: mode == 'tile'}">grid_view</mat-icon>
			</div>


			<div class="search-panel-container mobile-flex">
				<div class="short">
					<div class="QuickSearch"><app-product-search [placeholder]="'Quick Product Search'" id="quick_search_term"></app-product-search></div>
				</div>
			</div>



			<div class="sort">
				<span>Sort By:&nbsp;</span>
				<p-dropdown [options]="sortOptions" (onChange)="sortBy($event)" [autoDisplayFirst]="false"></p-dropdown>
			</div>


		</header>




		<div class="filter-panel-container" [ngClass]="{expanded: showFilters}">
			<div class="filter-panel">
				<div class="filter-header mobile-flex" (click)="showFilters = !showFilters">
					<h1>Filters</h1>
					<mat-icon *ngIf="showFilters">expand_more</mat-icon>
					<mat-icon *ngIf="!showFilters">expand_less</mat-icon>


				</div>

				<section class="top-button-container">
					<div class="button" (click)="search(true)">Search</div>
					<div class="button white" (click)="clearFilters()">Clear Filters</div>
				</section>

				<section class="keyword collapsible" [ngClass]="{open: keywordOpen}">
					<h4 (click)="keywordOpen = !keywordOpen">Keyword</h4>
					<div class="filter-options">
						<input pInputText [(ngModel)]="keyword" placeholder="Search for..." (keyup.enter)="search(true)">
					</div>
				</section>

				<section class="filters collapsible" [ngClass]="{open: filtersOpen}">
					<h4 (click)="filtersOpen = !filtersOpen">Filters</h4>
					<div class="filter-options">
						<ul>

							<li>
								<div class="toggler">
									<input type="checkbox" [(ngModel)]="newProductsOnly">
									<div class="track-holder">
										<div class="track">
											<div class="dot"></div>
										</div>
									</div>
								</div>
								<label>New Products</label>
							</li>

							<li>
								<div class="toggler">
									<input type="checkbox" [(ngModel)]="inStockOnly">
									<div class="track-holder">
										<div class="track">
											<div class="dot"></div>
										</div>
									</div>
								</div>
								<label>In Stock Only</label>  	<div class="NewFeature">New </div>
							</li>

							<li>
								<div class="toggler">
									<input type="checkbox" [(ngModel)]="specials">
									<div class="track-holder">
										<div class="track">
											<div class="dot"></div>
										</div>
									</div>
								</div>
								<label>Specials</label>
							</li>

							<li>
								<div class="toggler">
									<input type="checkbox" [(ngModel)]="rebates">
									<div class="track-holder">
										<div class="track">
											<div class="dot"></div>
										</div>
									</div>
								</div>
								<label>Rebates</label>
							</li>
							<li>
								<div class="toggler">
									<input type="checkbox" [(ngModel)]="multiples">
									<div class="track-holder">
										<div class="track">
											<div class="dot"></div>
										</div>
									</div>
								</div>
								<label>Multiples</label>
							</li>
							<li>
								<div class="toggler">
									<input type="checkbox" [(ngModel)]="topSellers">
									<div class="track-holder">
										<div class="track">
											<div class="dot"></div>
										</div>
									</div>
								</div>
								<label>Top Sellers</label>
							</li>
							<li>
								<div class="toggler">
									<input type="checkbox" [(ngModel)]="memoryOnly">
									<div class="track-holder">
										<div class="track">
											<div class="dot"></div>
										</div>
									</div>
								</div>
								<label>Memory</label>
							</li>
							<li>
								<div class="toggler">
									<input type="checkbox" [(ngModel)]="vendorDirectShip">
									<div class="track-holder">
										<div class="track">
											<div class="dot"></div>
										</div>
									</div>
								</div>
								<label>Vendor Direct Ship</label>
							</li>
						</ul>
					</div>

				</section>

				<section class="category collapsible" [ngClass]="{open: categoryOpen}">
					<h4 (click)="categoryOpen = !categoryOpen">Category</h4>
					<div class="filter-options">
						<ul class="category-listing" *ngIf="categories">
							<app-category-tree *ngFor="let category of categories" [category]="category" [categoriesSelected]="categoriesSelected" [doClearAll]="clearAllCategories" (onCategoryDeselected)="onCategoryDeselected($event)" (onCategorySelected)="onCategorySelected($event)"></app-category-tree>
						</ul>
					</div>
				</section>

				<section class="brand collapsible" [ngClass]="{open: brandOpen}">
					<h4 (click)="brandOpen = !brandOpen">Brand</h4>
					<div class="filter-options">
						<div class="brand-search">
							<input pInputText [(ngModel)]="brandFilterTerm" (keyup)="filterBrands()" #searchTermField>
							<i class="pi pi-search"></i>
						</div>

						<ul class="brand-listing">
							<li *ngFor="let brand of brandOptionsToShow"><p-checkbox [(ngModel)]="brand.selected" [inputId]="'brand_' + brand.brand.brandId" binary="1"></p-checkbox><label for="brand_{{brand.brand.brandId}}">{{brand.brand.brandName}}</label></li>
						</ul>
					</div>
				</section>
				<section class="search-button-container">
					<div class="button" (click)="search(true)">Search</div>
				</section>
			</div>
		</div>

		<div class="scroll">
			<div class="expander" (click)="leftPanelOpen = !leftPanelOpen">
				<span class="material-symbols-outlined" *ngIf="leftPanelOpen">left_panel_close</span>
				<span class="material-symbols-outlined" *ngIf="!leftPanelOpen">left_panel_open</span>
			</div>
		</div>


		<div class="results {{mode}}" *ngIf="products?.length > 0">

			<div class="product-grid {{size}}">
				<div class="product-row header" *ngIf="mode == 'table'">
					<div class="product-code">Code</div>
					<div class="brand">Brand</div>
					<div class="product-description">Product Description</div>
					<div class="retail">Retail</div>
					<div class="map">MAP</div>
					<div class="cost">COST USD</div>
					<div class="qty">Vol Qty</div>
					<div class="vol-cost">Vol Cost</div>
					<div class="stock">In Stock</div>
					<div class="available">Date Available</div>
					<div class="rebate">Qtr Reb</div>
					<div class="upc">UPC</div>
					<div class="vend-cat-id">Vend Cat #</div>
					<div class="min-mult">Min Mult</div>
					<div class="cat">Category</div>
				</div>

				<div class="product-holder" *ngFor="let product of products">
					<app-product-tile [product]="product" [mode]="mode"></app-product-tile>
				</div>


				<div class="paging">
					<div class="page-size">
						<p-dropdown [options]="pageSizeOptions" [(ngModel)]="pageSize" [optionLabel]="'label'" [optionValue]="'value'" (onChange)="updatePageSize()"></p-dropdown>
					</div>
					<app-pager [pageCount]="pages" [page]="page" *ngIf="pages > 1" (onGoToPage)="goToPage($event)"></app-pager>
					<div>&nbsp;</div>
				</div>
				<div class="no-results" *ngIf="noResults">
					<h1>No Results</h1>
					<p>Your search did not return any results.  Please check your filters and try again.</p>
				</div>
			</div>


		</div>


	</div>
	</div>
