import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { BillingOption } from "src/app/models/dashboard/billing-options";
import { Company, BRM } from "src/app/models/dashboard/company";
import { User } from "src/app/models/dashboard/user";
import { DataService } from "src/app/services/data.service";
import { SetDashboardTitle, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";
import { ProUser } from "src/app/models/pro-user";
import { AuthService } from "src/app/services/auth.service";
import { Address } from "src/app/models/account/address";
import { switchMap, tap } from 'rxjs/operators';

@Component({
	selector: "app-account-data",
	templateUrl: "./account-data.component.html",
	styleUrls: ["./account-data.component.scss"],
})
export class AccountDataComponent implements OnInit {
	public legalContact: User;
	public companyData: Company;
    public brm: BRM;
	public year: number;
	public decideByDate: Date;
	public billingOption: number;
    public user: ProUser;
	public billingOptions: Array<BillingOption>;

	public editingCompany: boolean = false;
	public addresses: Array<Address> = [];
	public addressOptions: Array<{key: string, value: number}>;
	public users: Array<User> = []
	public userOptions: Array<{key: string, value: number}>;

  constructor(private dataService: DataService, private store: Store<IAppState>, private authService: AuthService) {}
  
  ngOnInit(): void {
        this.user = this.authService.user;
		this.store.dispatch(new SetDashboardTitle("Company Data"));
		this.dataService.getLegalContact().subscribe((l) => (this.legalContact = l));
		this.dataService.getCompanyData().subscribe((c) => (this.companyData = c));

         this.dataService.GetBRM().subscribe((b) => (this.brm = b));



		this.year = new Date().getFullYear() + 1;
		this.decideByDate = new Date(new Date().getFullYear(), 11, 28);

		this.getBillingHistory();

	}

	editLegal() {}
	editCompanyData() {
		this.dataService.getAddresses(-1).pipe(
			tap(a => this.addresses = a),
			switchMap(r => this.dataService.getUsers())	
		).subscribe(users => {
			this.users = users;
			this.addressOptions = this.addresses.map(a => ({key: `${a.address1} ${a.city} ${a.state}`, value: a.addressId}))
			this.userOptions = this.users.map(u => ({key: `${u.firstName} ${u.lastName}`, value: u.userId}))
			this.editingCompany = true;
		})
	}
	saveCompanyData() {
		this.dataService.editCompanyData(this.companyData).pipe(
			switchMap(r => this.dataService.getCompanyData())
		).subscribe(c => {
			this.companyData = c;
			this.editingCompany = false;
		})
	}

	submitBillingOption() {
		if(this.billingOption) {
			this.dataService.saveBillingOption(this.billingOption, this.year).subscribe(() => {
				this.store.dispatch(new SuccessMessageSet("Billing Option Saved.  Thank you!"));
				this.getBillingHistory();
			})
		}
	}

	getBillingHistory() {
		this.dataService.getBillingOptions().subscribe(o => this.billingOptions = o);
	}
}
