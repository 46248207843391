

<div class="container">
	<header>
		<h1>Order Number {{orderId}}</h1>
		<ul>
			<li>
				<span class="dt">PO Number</span>
				<span class="dd">{{order.poNumber}}</span>
			</li>
			<li>
				<span class="dt">Order Date</span>
				<span class="dd">{{order.enterDate | date: 'MMM d, yyyy'}}</span>
			</li>
			<li>
				<span class="dt">Signature</span>
				<span class="dd">{{order.signature}}</span>
			</li>
			<li>
				<span class="dt">Status</span>
				<span class="dd">{{order.orderStatus}}</span>
			</li>
			<li>
				<span class="dt">Total Price</span>
				<span class="dd">{{order.orderTotal | currency}}</span>
			</li>
			<li>
				<span class="dt">Order Type</span>
				<span class="dd">{{order.orderType}}</span>
			</li>
		</ul>
	</header>
	<app-order-history-details  [orderId]="orderId"></app-order-history-details>
</div>
