import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OrderHistoryItem } from "src/app/models/dashboard/order-history-item";
import { OverviewSearchResults } from "src/app/models/dashboard/overview-search";
import { Sar } from "src/app/models/dashboard/sar";
import { ProUser } from "src/app/models/pro-user";
import { TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-overview-search",
	templateUrl: "./overview-search.component.html",
	styleUrls: ["./overview-search.component.scss"],
})
export class OverviewSearchComponent implements OnInit {
	public searchTerm: string;
	public searchResults: OverviewSearchResults;
	public sarSearchData: Array<Sar>;
	public orderSearchData: Array<OrderHistoryItem>;
	public ezpaySearchData: Array<any>;
	public irSearchData: Array<any>;
	public user: ProUser;
	public ezPayName: string;
	constructor(private route: ActivatedRoute, private dataService: DataService, private authService: AuthService) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			if (params.get("term")) {
				this.searchTerm = params.get("term");
				this.search();
			}
		});
		this.user = this.authService.user;
		if(parseInt(this.user.accountNumber) > 7000) {
			this.ezPayName = 'Prompt Pay';
		}

	}

	search() {
		this.dataService.searchDashboard(this.searchTerm).subscribe((r:any) => {
			this.searchResults = r;
			this.sarSearchData = this.searchResults.draSearchResults.map(s => (new Sar({ ...s, status: s.status.toLowerCase() }))).slice(0,10);
			this.orderSearchData = this.searchResults.ordersSearchResults.slice(0,10);
			this.ezpaySearchData = this.searchResults.ezPaySearchResults.slice(0,10);
			this.irSearchData = this.searchResults.irSearchResults.slice(0,10);
		})
	}

	get sarColumns() {
		return new TableInfo({
			columns: [
				{ title: 'SAR', field: 'draId' },
				{ title: 'SAR Date', field: 'submitDate', type: 'date', format: 'MM/dd/yyyy' },
				{ title: 'Your Reference Number', field: 'referenceNum' },
				{ title: 'Status', field: 'status', type: 'status', cssClass: 'capitalize' },
				{ title: 'Notes', field: 'notes' },
			]
		});
	}
	get orderColumns() {
		return new TableInfo({
			columns: [
				{ title: 'Order Number', field: 'orderId' },
				{ title: 'PO Number', field: 'poNumber' },
				{ title: 'Order Date', field: 'enterDate', type: 'date', format: 'MM/dd/yyyy' },
				{ title: 'User', field: 'name' },
				{ title: 'Tracking Number', field: 'tracK_NO' },
				{ title: 'Status', field: 'orderStatus' },
				{ title: 'Total Price', field: 'orderTotal', type: 'currency' },
				{ title: 'Order Type', field: 'orderType' },
			]
		})
	}
	get ezpayColumns() {
		return new TableInfo({
			columns: [
				{ title: 'PRO Invoice #', field: 'invoice', id: true, sort: true },
				{ title: `1% ${this.ezPayName} Eligible`, field: 'showEligible', type: 'checkmark' },
				{ title: 'Due Date', field: 'date', type: 'date', format: 'MM/dd/yyyy' },
				{ title: 'Posted Date', field: 'date', type: 'date', format: 'MM/dd/yyyy' },
				{ title: 'Deduction Date', field: 'deductionDate', type: 'date', format: 'MM/dd/yyyy', sort: true },
				{ title: 'Vendor', field: 'vendor' },
				{ title: 'Vendor Invoice Number', field: 'vendorInvoiceNumber' },
				{ title: 'Gross', field: 'gross', type: 'currency', sort: true },
				{ title: 'Pro Disc', field: 'proDiscount', type: 'currency' },
				{ title: `${this.ezPayName} Discount`, field: 'ezPayDiscount', type: 'currency' },
				{ title: 'Total To Pay', field: 'totalToPay', type: 'currency', sort: true },
		]
		})
	}
	get irColumns() {
		return new TableInfo({
			columns: [
				{ title: 'Order ID', field: 'orderID', type: 'number', width: '1fr' },
				{ title: 'Date Submitted', field: 'submitDate', type: 'date', format: 'mm/dd/yyyy', width: '2fr' },
				{ title: 'Your Debit #', field: 'debit', width: '2fr' },
				{ title: 'Program Week', field: 'programWeek', width: '2fr' },
				{ title: 'Total Amount', field: 'total', type: 'currency', width: '2fr' },
				{ title: 'Status', field: 'status', width: '2fr', cssClass: 'capitalize', type:'status' },
				{ title: 'Reject Reason', field: 'rejectReason', width: '2fr' },
				{ title: 'Vendor', field: 'vendor', width: '2fr' },
			],
		})
	}
}
