import { Component, OnInit } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { filter, take } from 'rxjs/operators';
import { ConfirmationService } from "primeng/api";
import { Company, MemberType, Owner } from "src/app/models/dashboard/owner";
import { DataService } from "src/app/services/data.service";
import { SetDashboardTitle } from "src/app/store/actions/site.actions";
import { GetEzPayStatus, LogInAs, UserActionTypes } from "src/app/store/actions/user.actions";
import { IAppState } from "src/app/store/state/app.state";
import { Router } from "@angular/router";

@Component({
	selector: "app-log-in-as",
	templateUrl: "./log-in-as.component.html",
	styleUrls: ["./log-in-as.component.scss"],
})
export class LogInAsComponent implements OnInit {
	public owners: Array<Owner>;
	public memberTypes: Array<MemberType>;
	constructor(private dataService: DataService, private store: Store<IAppState>, private router: Router, private confirmService: ConfirmationService, private actions$: Actions) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle("Log In As"));
		this.dataService.getOwners().subscribe((o) => {
			this.owners = o;
			let companies: Array<Company> = [];
			this.owners.forEach((o) => {
				if (!companies.some((c) => c.accountNumber == o.accountNumber)) {
					companies.push(new Company({ accountNumber: o.accountNumber, companyName: o.company, memberTypeId: o.memberType, owners: [] }));
				}
				companies.find((c) => c.accountNumber == o.accountNumber).owners.push(new Owner(o));
			});
			this.memberTypes = [
				new MemberType({ memberTypeId: 1, memberType: "Members", companies: companies.filter((c) => (c.memberTypeId == 1)).sort(this.companySort) }), 
				new MemberType({ memberTypeId: 2, memberType: "Affilates", companies: companies.filter((c) => (c.memberTypeId == 2)).sort(this.companySort) }), 
				new MemberType({ memberTypeId: 3, memberType: "Clients", companies: companies.filter((c) => (c.memberTypeId == 3)).sort(this.companySort) })];
		});
	}

	logInAs(owner: Owner) {
		this.confirmService.confirm({ 
			message: `You will be logging in as ${owner.firstName} ${owner.lastName}.  To return to your own profile, you will need to log out and log back in as yourself.  Are you sure you want to continue?`,
			accept: () => {
				this.store.dispatch(new LogInAs(owner.userId));
				this.actions$.pipe(filter(a => a.type == UserActionTypes.LOG_IN_AS_SUCCESS), take(1)).subscribe(() => {
					this.router.navigate(['/']);
					this.store.dispatch(new GetEzPayStatus());
				});
			},
		})
	}
	private companySort(a,b) {
		if(a.companyName < b.companyName) return -1;
		if(a.companyName > b.companyName) return 1;
		return 0;
	}
}
