
export class Image {
	public id: number;
	public name: string;
	public alternativeText: string;
	public caption: string;
	public width: number;
	public height: number;
	public formats: {[size: string]: ImageDetail}

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class ImageDetail {
	public ext: string;
	public url: string;
	public hash: string;
	public mime: string;
	public name: string;
	public path: string;
	public size: number;
	public widht: number;
	public height: number;
}

