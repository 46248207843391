import { CurrencyPipe } from "@angular/common";

export class ShippingCostInfo {
	public brand: string;
	public totalDoll: number;
	public minFreeShip: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}

	get message() {
		if(this.totalDoll >= this.minFreeShip) {
			return "Free Shipping";
		}
		const pipe = new CurrencyPipe("en-US");
		return `${pipe.transform(this.minFreeShip - this.totalDoll)} remaining for free shipping`
	}
}
