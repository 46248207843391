import { Injectable } from "@angular/core";
import { AlgoliaEvent } from "../models/search/algolia";
import { HttpHeaders } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable()
export class AlgoliaService {
	private headers: HttpHeaders;
	private baseUrl: string = 'https://insights.algolia.io';

	constructor(private apiService: ApiService) {
	}

	private _queryID: string;
	private _index: string;
	set queryID(val: string) { this._queryID = val; }
	set index(val: string) { this._index = val; }

	generateEventObject(data: {eventType: string, eventName: string, userToken: string, objectID: string, position: number}) {
		let { objectID, position, ...eventData } = data;
		return { ...eventData, queryID: this._queryID, index: this._index, timestamp: Date.now(), objectIDs: [ objectID ], positions: [ position ] }
	}
	sendEvent(event: AlgoliaEvent) {
		this.apiService.post('/api/search/event', event).subscribe();
	}
}