
<div class="form">
    <textarea [(ngModel)]="content" placeholder="Write your comment"></textarea>
    <div class="actions">
        <button (click)="save()">Post {{threadOf ? 'Reply' : 'Comment'}}</button>
        <button (click)="cancel()" *ngIf="threadOf">Cancel</button>
    </div>    
</div>


