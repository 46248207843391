import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Brand } from 'src/app/models/shopping/brand';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-brand-search',
  templateUrl: './brand-search.component.html',
  styleUrls: ['./brand-search.component.scss']
})
export class BrandSearchComponent implements OnInit {


	@Output() onSelect: Subject<Brand> = new Subject();
	@Input() placeholder: string = '';
	public name: string;
	public brands: Array<Brand>;

	private cancelSearch: Subject<any> = new Subject();
	constructor(private dataService: DataService) {}

	ngOnInit(): void {}

	search() {
		if (this.name.length > 1) {
        this.cancelSearch.next(null);
			this.dataService
				.searchBrandsByName(this.name)
				.pipe(takeUntil(this.cancelSearch))
				.subscribe((resp:any) => (this.brands = resp));
		} else {
			this.brands = [];
		}
	}

	selectBrand(brands) {
		this.name = '';
		this.brands = [];
		this.onSelect.next(brands);
	}
}
