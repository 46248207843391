
<div id="filters">
	<h1>Refine your search</h1>
	<div class="term">Search Term: <input pInputText [(ngModel)]="tempSearchTerm"></div>
	<div class="dates">
		<div>Start: <input pInputText [(ngModel)]="searchCriteria.fromDate"></div>
		<div>End: <input pInputText [(ngModel)]="searchCriteria.toDate"></div>
	</div>
	<div class="type">
		<p-radioButton name="searchType" value="1" [(ngModel)]="searchCriteria.searchType" label="Invoice"></p-radioButton><br/>
		<p-radioButton name="searchType" value="2" [(ngModel)]="searchCriteria.searchType" label="Articles"></p-radioButton><br/>
		<p-radioButton name="searchType" value="4" [(ngModel)]="searchCriteria.searchType" label="Products"></p-radioButton><br/>
		<p-radioButton name="searchType" value="7" [(ngModel)]="searchCriteria.searchType" label="All"></p-radioButton><br/>
	</div>
	<button pButton (click)="search()" label="Search"></button>
</div>
<div id="results" *ngIf="searchResults">
	<div class="product-search-results" *ngIf="searchResults.productSearchResults?.length > 0">
		<h1>Products</h1>
		<div class="product-result search-result" *ngFor="let product of searchResults.productSearchResults">
			<h2><a [routerLink]="['/shop', 'product', product.productCode]">{{product.modelName}}</a></h2>
			<div [innerHTML]="product.searchTextClean | highlight:searchCriteria.searchTerm"></div>
		</div>
		<div><button pButton label="See All" (click)="seeAll(3)" *ngIf="searchCriteria.searchType == 4 && searchResults.productSearchResults.length == 20"></button></div>
	</div>
	<div class="invoice-search-results" *ngIf="searchResults.invoiceSearchResults?.length > 0">
		<h1>Invoices</h1>
		<div class="invoice-result search-result" *ngFor="let invoice of searchResults.invoiceSearchResults">
			<h2>{{invoice.invoiceNumber}} - {{invoice.tag}}</h2>
			<div [innerHTML]="invoice.searchTextClean | highlight:searchCriteria.searchTerm"></div>
		</div>
		<div><button pButton label="See All" (click)="seeAll(1)" *ngIf="searchCriteria.searchType == 4 && searchResults.invoiceSearchResults.length == 20"></button></div>
	</div>
</div>