import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProUser } from "src/app/models/pro-user";
import { Comment, CommentSubmission } from "src/app/models/strapi/comment";
import { StrapiService } from "src/app/services/strapi.service";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-comment-form",
	templateUrl: "./comment-form.component.html",
	styleUrls: ["./comment-form.component.scss"],
})
export class CommentFormComponent implements OnInit, OnDestroy {

	@Input() articleId: number;
	@Input() threadOf: number;
	@Output() onAddComment: Subject<Comment> = new Subject();
	@Output() onCancel: Subject<boolean> = new Subject();

	public content: string;
	private user: ProUser;

	private destroy$: Subject<boolean> = new Subject();

	constructor(private store: Store<IAppState>, private strapiService: StrapiService) {}

	ngOnInit(): void {
		this.store.select(selectUser).pipe(takeUntil(this.destroy$)).subscribe(u => this.user = u);
	}

	save() {
		const submission = new CommentSubmission({name: this.user.name, email: this.user.email, avatar: this.user.avatar, id: String(this.user.userId), content: this.content, threadOf: this.threadOf})
		this.strapiService.saveComment(this.articleId, submission).subscribe((resp) => {
			let comment = new Comment(resp)
			this.onAddComment.next(comment);
			this.content = '';
		})
	}

	cancel() {
		this.onCancel.next(true);
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
