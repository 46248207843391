
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { Article } from "../models/strapi/article";
import { Comment, CommentSubmission } from "../models/strapi/comment";
import { InstantRebateGroups } from "../models/strapi/instant-rebate";
import { Newsfeed } from "../models/strapi/newsfeed";
import { Department, Director, Officer, StaffMember } from "../models/strapi/staff-member";
import { selectUser } from "../store/selectors/user.selectors";
import { IAppState } from "../store/state/app.state";
import * as qs from 'qs';
import { Event } from "../models/strapi/event";
import { CmsVendor } from "../models/strapi/vendor";
import { Newsletter } from "../models/strapi/newsletter";
import { LoginPhoto } from "../models/strapi/login-photo";
import { headtohead } from "../models/strapi/headtohead";



@Injectable()
export class StrapiService {

	//private _apiUrl: string = "http://localhost:1337/api";
	private _apiUrl: string = 'https://strapiportal.promaster.com/api';
	private defaultHeaders: HttpHeaders;

	constructor(private http: HttpClient, private store: Store<IAppState>) {
		this.defaultHeaders = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");
		this.store.select(selectUser).subscribe(u => {
			if(u) {
				this.defaultHeaders = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8").set('prousertype', u.userType)
			}
		})
  }

  getHeadToHeadComparisons()
  {

    return this.get<headtohead>('head-to-head-comparisons?publicationState=live&sort[0]=createdAt:desc').pipe(
      map((resp: any) => new headtohead({ id: resp.data }))
    )

   // map((resp: any) => new LoginPhoto({ photographer: resp?.photographer, photo: resp?.photo.url }))
    //return (this.get<headtohead>(`head-to-head-comparisons?publicationState=live&sort[0]=createdAt:desc`).pipe(

    //  map(i => new headtohead(i)))
    //)

  }


  getHeadToHeadComparisonsComponent(id: number): Observable<headtohead> {
    return this.get<headtohead>(`head-to-head-comparisons/${id}?populate=*`)
  }

	getLoginPagePhoto() {
		return this.get<Article>('login-photo/current').pipe(
			map((resp: any) => new LoginPhoto({photographer: resp?.photographer, photo: resp?.photo.url}))
		)		
  }

	getArticles(count?): Observable<Array<Article>> {
		let req;
		if(count) {
			req = this.get<Array<Article>>(`articles?populate=deep&pagination[pageSize]=${count}&sort[0]=createdAt:desc`)
		} else {
			req = this.get<Array<Article>>(`articles?populate=deep&pagination[pageSize]=25`)
		}
		return req.pipe(
			map((resp:any) => {
				return resp.data.map(a => new Article(a))
			})
		);
	}
	getHeaderArticles() {
		return this.get<Article>('articles/type/headers?populate=deep&pagination[pageSize]=25').pipe(
			map((resp: any) => resp.data.map(a => new Article(a)))
		)
	}
	getFeatureArticles() {
		return this.get<Article>('articles/type/features?populate=deep&pagination[pageSize]=25').pipe(
			map((resp: any) => resp.data.map(a => new Article(a)))
		)
	}
	getNewsfeed(page: number = 1, pageSize: number = 25) {
		return this.get<Newsfeed>(`articles?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=createdAt:desc`).pipe(
			map((resp:any) => {
				return new Newsfeed({ articles: resp.data.map(a => new Article(a)), pagination: resp.meta.pagination})
			})
		);
  }
	getPopular() {
		return this.get<Article>('articles/get/popular').pipe(
			map((resp: any) => resp.data.map(a => new Article(a)))
		)		
	}
	getRecent() {
		return this.get<Article>('articles/get/recent').pipe(
			map((resp: any) => resp.data.map(a => new Article(a)))
		)		
	}
	getArticleInteractionCountsAll(userId?: number) {
		if(userId) {
			return this.get(`article-interactions/user/${userId}`);
		}
		return this.get(`article-interactions`);
	}
	getArticleInteractionCountsMultiple(articleIds: Array<number>, userId?: number) {
		if(userId) {
			return this.post(`article-interactions/user/${userId}`, { articleIds: articleIds });
		}
		return this.get(`article-interactions`);
	}
	getArticleInteractionCountsSingle(id: number, userId?: number) {
		if(userId) {
			return this.get(`article-interactions/${id}/user/${userId}`);
		}
		return this.get(`article-interactions/${id}`);
	}
	getLikers(id:number) {
		return this.get(`article-interactions/${id}/likers`)
	}
	getArticle(id: number): Observable<Article> {
    return this.get<Article>(`articles/${id}?populate[0]=author&populate[1]=author.avatar&populate[2]=image&populate[3]=articlePageImage&populate[4]=Media&populate[5]=Media.documentFile&populate[6]=Media.image`).pipe(
			map((resp:any) => new Article({ id: resp.data.id, ...resp.data.attributes}))
		)
	}
	getArticlesByTag(tag: string) {
		return this.get<Array<Article>>(`articles/tag/${tag}`).pipe(
			map((resp:any) => { return resp.data.map(a => new Article(a)) })
		);
	}
	searchArticless(term: string) {
		return this.get<Array<Article>>(`articles/search/${term}`).pipe(
			map((resp:any) => { return resp.data.map(a => new Article(a)) })
		);
	}
	logArticleView(articleId: number, userId: number) {
		return this.post('article-views', { data: { articleId, userId, dateViewed: new Date() } })
	}
	getArticleComments(id: number): Observable<Array<Comment>> {
		return this.get<Array<Comment>>(`comments/api::article.article:${id}`).pipe(retry(2));
	}

	saveComment(id: number, commentSubmission: CommentSubmission) {
		return this.post(`comments/api::article.article:${id}`, commentSubmission);
	}
	like(entityId: number, entityType: string, userId: number) {
		return this.post(`likes`, {data: { entityId, entityType, userId }});
	}
	unlike(entityId: number, entityType: string, userId: number) {
		return this.delete(`article-interactions/unlike/${entityType}/${entityId}/${userId}`)
	}

	getVendorArticles(id: number) {
    return this.get<Array<Article>>(`articles/vendor/${id}?populate=*`);
	}

	getInstantRebateGroups(count: number = 5) {
		return this.get<InstantRebateGroups>(`instant-rebates/groups/${count}`).pipe(
			map(resp => new InstantRebateGroups(resp))
		)
	}

	getDepartments() {
		return this.get<Array<StaffMember>>('departments').pipe(
			map((resp:any) => resp.data.map(d => new Department({ id: d.id, ...d.attributes })))
		)		
	}
	getStaff() {
		return this.get<Array<StaffMember>>('staff-members?populate=*').pipe(
			map((resp:any) => resp.data.map(d => new StaffMember({ id: d.id, ...d.attributes })))
		)
	}
	getOfficers() {
		return this.get<Array<Officer>>('officers?populate=*').pipe(
			map((resp:any) => resp.data.map(d => new Officer({ id: d.id, ...d.attributes })))
		)
	}
	getDirectors() {
		return this.get<Array<Director>>('directors?populate=*').pipe(
			map((resp:any) => resp.data.map(d => new Director({ id: d.id, ...d.attributes })))
		)
	}
	getEvents(criteria: any = {}) {
		const query = qs.stringify({ 'populate': '*',  ...criteria}, {encodeValuesOnly: true});
		return this.get<Array<Event>>(`events?${query}`).pipe(
			map((resp:any) => resp.data.map(e => new Event({ id: e.id, ...e.attributes})))
		);
	}
	getFeaturedVendors(count: number) {
		/*
		const query = qs.stringify({ 
			populate: '*', 
			pagination: { 
				pageSize: count 
			}, 
			sort: ['featuredDisplayOrder:asc'],
			filters: {
				featuredDisplayOrder: {
					$notNull: true
				},						
			}
		}, {encodeValuesOnly: true})
		*/
		return this.get<Array<CmsVendor>>(`vendors/featured`).pipe(
			map((resp:any) => resp.data.map(v => new CmsVendor(v)))
		)

	}

	getVendor(id) {
		return this.get<CmsVendor>(`vendors/slug/${id}`).pipe(
			map((resp:any) => new CmsVendor(resp))
		)
	}

	getVendors() {
		return this.get<Array<CmsVendor>>('vendors?populate=priceList').pipe(
			map((resp:any) => resp.data.map(v => new CmsVendor(v)))
		)
	}

	getInstantRebatesForVendor(vendorId) {
		return this.get<InstantRebateGroups>(`vendor/${vendorId}/rebates`).pipe(
			map(resp => new InstantRebateGroups(resp))
		)
	}
	getNewsletters() {
		return this.get<Array<Newsletter>>(`newsletters`).pipe(
			map((resp: any) => resp.data.map(n => new Newsletter(n)))
		)
	}
	getNewsletter(year, month, day) {
		return this.get<Array<Article>>(`articles/newsletter/${year}/${month}/${day}`).pipe(
			map((resp: any) => resp.data.map(a => new Article(a)))
		)
	}
	getLatestNewsletter() {
		return this.get<Array<Article>>(`articles/newsletter/latest`).pipe(
			map((resp: any) => resp.data.map(a => new Article(a)))
		)
	}
	/***** private functions copied from api service ********/
	private getUrl(url: string) {
		if (this._apiUrl != "") {
			return `${this._apiUrl}/${url}`;
		} else {
			return `${url}`;
		}
	}

	private get<T>(url: string): Observable<T> {
		return this.http.get<T>(this.getUrl(url), { headers: this.defaultHeaders });
	}

	private getBlob(url: string): Observable<any> {
		return this.http.get(this.getUrl(url), { responseType: "blob", headers: this.defaultHeaders });
	}
	private post<T>(url: string, objectToPost: any, headers: HttpHeaders = null): Observable<T> {
		let options = {};
		if (!!headers) {
			options = { headers: headers };
		} else {
			options = { headers: this.defaultHeaders };
		}
		return this.http.post<T>(this.getUrl(url), JSON.stringify(objectToPost), options);
	}

	private put<T>(url: string, objectToPost: any): Observable<T> {
		return this.http.put<T>(this.getUrl(url), JSON.stringify(objectToPost), { headers: this.defaultHeaders });
	}

	private delete<T>(url: string): Observable<T> {
		return this.http.delete<T>(this.getUrl(url));
	}

	private postBlob(url: string, formData: FormData): Observable<any> {
		const headers = new HttpHeaders({ enctype: "multipart/form-data" });
		return this.http.post(this.getUrl(url), formData, { headers: headers });
	}

}

