<div class="pane">

  <section class="contact-info">
    <h2>Store/Account</h2>

    ** In the event this claim is submitted 10 days post product arrival, the claim may be denied.
		<br>
    <div class="field-holder">
      <div class="field">
        <label>Account Number</label>
        <input pInputText [(ngModel)]="shippingError.accountNumber" disabled>
      </div>
      <div class="field">
        <label>Store Name</label>
        <input pInputText [(ngModel)]="shippingError.storeName" disabled>
      </div>
    </div>


    <h2>Contact Info</h2>



		<div class="field-holder">
      <div class="field">
        <label>First Name</label>

				<!--  If submitted is true and first name not empty  --> 

				<input type="text" pInputText [(ngModel)]="shippingError.firstName" [ngClass]="{error: submitted && !shippingError.firstName}">
      </div>
      <div class="field">
        <label>Last Name</label>
        <input type="text" pInputText [(ngModel)]="shippingError.lastName" [ngClass]="{error: submitted && !shippingError.lastName}">
      </div>
      <div class="field">
        <label>Email</label>
        <input type="text" pInputText [(ngModel)]="shippingError.email" [ngClass]="{error: submitted && !shippingError.email}">
      </div>     
    </div>



    <h2>Packing Slip</h2>
    <div>
      <p>Please upload a copy of the packing slip, if included.  If not, please provide a copy of the invoice</p><br><br />
      <p class="error" *ngIf="submitted && packingSlipFiles.length == 0">Please upload at least one document</p>
      <p-fileUpload (onSelect)="onSelect($event, 'packingslip')" (onRemove)="onRemove($event, 'packingslip')" multiple="multiple" [showUploadButton]="false" [showCancelButton]="false" #packingSlipUploads></p-fileUpload>
    </div>

    <h2>Shipping Order #</h2>


    <div>
			<p>
				Please select the shipping order # associated with order.  You may search by: PO, Invoice#, Order#, Ship# or date (MM-DD-YYYY). <br /><br />
				Products must all be on same invoice or packing slip. If on separate invoices or packing slip A SEPARATE SHIPPING ERROR MUST BE SUBMITTED.
			</p><br><br />

      <app-po-search (onSelect)="setShipNumber($event)"></app-po-search>

      <label class="error" *ngIf="submitted && !ShipNumber">Please be sure to select a shipping #.</label>


    </div>


  </section>
	<div class="tab-list">
		<div class="tab" (click)="setTab('OverageShortage')" [ngClass]="{active: openTab == 'OverageShortage'}">Overage/Shortage</div>
		<div class="tab" (click)="setTab('Damaged')" [ngClass]="{active: openTab == 'Damaged'}">Damaged</div>
		<div class="tab" (click)="setTab('Wrong')" [ngClass]="{active: openTab == 'Wrong'}">Wrong Order</div>
	</div>

	<div class="tab-content">
		<section *ngIf="openTab == 'OverageShortage'">
			<h3>Overage/Shortage</h3>

			<p>Which product(s) is/are affected?</p>
			<div class="affected-product header">
				<div class="product-code">Product Code</div>
				<div class="description">Description</div>
				<div class="quantity">Quantity</div>
				<div class="type">Type</div>
				<div class="serial">Serial</div>
				<div class="delete">&nbsp;</div>
			</div>


			  <div class="affected-product" *ngFor="let ap of affectedProductsOverage">
				<div class="product-code"><app-product-code-search [Exclude]="'ExcludeDiscontinued'" (onSelect)="setProduct($event, ap)"></app-product-code-search></div>
				<div class="description">{{ap.description}}</div>
				<div class="quantity"><input pInputText [(ngModel)]="ap.quantity" type="number" min="0" [ngClass]="{error: submitted && !ap.quantity}"></div>
				<div class="type"><p-dropdown [(ngModel)]="ap.errorType" (onChange)="AddSerial($event)" [options]="overageShortageOptions" [autoDisplayFirst]="false" [ngClass]="{error: submitted && !ap.errorType}"></p-dropdown></div>
				<div class="serial" *ngIf="ap.errorType == 'Overage'"> <input pInputText [(ngModel)]="ap.serial"></div>


				<div class="delete">
					<div class="button" (click)="deleteProduct(ap.index)"></div>
				</div>

			</div>

				<div class="actions">
					<div class="button white-button" (click)="addNew('overage')">Add Product</div>
				</div>

				<!--<p>Show our UPS account info and ask that the itme(s) be forwarded with our account.  If they do not have UPS please use another provider and email Brianna the total and we will issue credit for the forwarding.  We do not issue call tags.</p>-->

				<div class="field" *ngIf="hasOverages">
					<label [ngClass]="{error: submitted && shippingError.keepOrRma == undefined && hasOverages}">For the overages, or items you did not order, do you want to...</label><br /><br />
					<div class="options">
						<p-radioButton name="keepOrRma" value="keep" [(ngModel)]="shippingError.keepOrRma" label="Keep the products.  Please charge me for them."></p-radioButton>
						<p-radioButton name="keepOrRma" value="rma" [(ngModel)]="shippingError.keepOrRma" label="Request an RMA so I can return them"></p-radioButton>
					</div>
				</div>
</section>

		<section *ngIf="openTab == 'Damaged'">
			<h3>Damaged Items Received</h3>
			<p>Please provide photos of the damage</p>
			<p-fileUpload (onSelect)="onSelect($event, 'damage')" multiple="multiple" [showUploadButton]="false" [showCancelButton]="false" #damagePhotoUploads></p-fileUpload>

			<p>Which product(s) is/are damaged?</p>
			<div class="affected-product header">
				<div class="product-code">Product Code</div>
				<div class="description">Description</div>
				<div class="quantity">Quantity</div>
				<div class="type">Type</div>
				<div class="delete">&nbsp;</div>
			</div>

			<div class="affected-product" *ngFor="let ap of affectedProductsDamaged">

				<div class="product-code"><app-product-code-search [Exclude]="'ExcludeDiscontinued'"  (onSelect)="setProduct($event, ap)"></app-product-code-search></div>

				<div class="description">{{ap.description}}</div>

				<div class="quantity"><input pInputText [(ngModel)]="ap.quantity" min="0" type="number"></div>

				<div class="type">Damaged</div>

				<div class="delete"><div class="button" (click)="deleteProduct(ap.index)"></div></div>

			</div>

			<div class="actions">
				<div class="button white-button" (click)="addNew('damage')">Add Product</div>
			</div>

			<label [ngClass]="{error: submitted && shippingError.upsNotified == undefined && hasDamaged}">Was UPS notified of the damage at the time of delivery?</label>

			<div class="options">
				<p-radioButton name="upsNotified" [value]="true" [(ngModel)]="shippingError.upsNotified" label="Yes"></p-radioButton>
				<p-radioButton name="upsNotified" [value]="false" [(ngModel)]="shippingError.upsNotified" label="No"></p-radioButton>
			</div>

			<!--<label [ngClass]="{error: submitted && shippingError.keepDamagedProducts == undefined && hasDamaged}">Would you like to keep the products for display use, at 50% off the price?</label>
			<div class="options">
				<p-radioButton name="keepDamagedProducts" [value]="true" [(ngModel)]="shippingError.keepDamagedProducts" label="Yes"></p-radioButton>
				<p-radioButton name="keepDamagedProducts" [value]="false" [(ngModel)]="shippingError.keepDamagedProducts" label="No"></p-radioButton>
			</div>-->

		</section>
		<section *ngIf="openTab == 'Wrong'">

			<h3>Received box/order that is not mine</h3>
			<label>Will forward to correct store?</label>
			<div class="options">

				<p-radioButton name="willForward" [value]="true" [(ngModel)]="shippingError.willForward" label="Yes"></p-radioButton>
				<p-radioButton name="willForward" [value]="false" [(ngModel)]="shippingError.willForward" label="No, we would like a call from someone in the office to discuss"></p-radioButton>

				<sectionForward *ngIf="shippingError.willForward">

					<p>
						You have selected that you would like to forward this package to the correct store. If possible, please use our UPS account to forward this package:
						<br /><br />
						PRO UPS #     262-730
						<br /><br />
						Address used for UPS Billing:<br />
						Pro Warehouse<br />
						2950 Turnpike Dr Sec 4-9<br />
						Hatboro, PA 19040<br />
						<br /><br />
						If you do not have UPS as a shipping service, you may use your own shipping method and then submit the charges to your Pro Sales Contact to be reimbursed the forwarding cost. Thank you!'
					</p>

				</sectionForward>
			</div>
		</section>
	</div>

	<div class="actions">
		<div class="button" (click)="submit()">Submit</div>
	</div>


	<label class="error" *ngIf="submitted && shippingError.willForward == undefined && shippingError.products.length == 0 ">If you are submitting overage, shortages or damages, please provide at least one product.</label>


	<label class="error" *ngIf="submitted && !shippingError.isValid">Please be sure to fill in all the required fields.</label>


</div>
