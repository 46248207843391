import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router, UrlSegment } from '@angular/router';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authservice: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 || err.status === 403) {
          // auto logout if 401 response returned from api
          this.authservice.logout();
		  this.authservice.redirectTo = this.router.url;
          this.router.navigate(['/login']);
        }
        return throwError(err);
      })
    );
  }
}
