import { DatePipe } from "@angular/common";

export class Product {
  public stockMesssage: string;
	public id: number;
	public productCode: string;
	public brandId: number;
  public brandName: string;
	public modelName: string;
	public modelVersion: string;
  public additionalText: string;
	public catId: number;
	public catName: string;
	public subCatId: number;
	public upc: string;
	public retail: number;
	public ds_CHG: number;
	public last_Value: number;
	public cartonPricingRetail: number;
	public authorizedPrice: number;
	public isActive: number;
	public isPublic: number;
	public isForSale: number;
	public isDiscontinued: number;
	public replacementCode: string;
	public features: string;
	public specs: string;
	public instructionBook: string;
	public faqDoc: string;
	public p65: string;
	public enterDate: Date;
	public lastUpdated: Date;
	public updatedBy: number;
	public hold: string;
	public inventory: number;
	public multiple: number;
	public outOfStockMessage: string; 
	public stockDueDate: Date;
	public specialPriceQuantity: number;
	public carton: number;
	public topCatId: number;
	public map: number;
	public cusT_RETAIL: number;
	public rebate: string;
	public requireSerialForSar: number;
	public brandRebate: number;
	public faq: string;
	public caT_NO: string;
	public fastShippingAvailable: number;
	public supplementalShippingProductId: number;
	public supplementalShippingCost: number;
	public isGroup: number;
	public isClient: number;
	public isDropShipEligible: number;
  public dropShipFee: number = 44;
	public isNew: boolean;
	public hasSpecial: boolean;
	public ltlCost: number;
	public returnCode: number;
	public data: any;
  public specOrder: boolean;
  public images: Array<ProductImage> = [];


	constructor(o?:any) {
    Object.assign(this, o);
	}

	get featuresPlaintext() {
		return (this.features || '').replace(/<[^>]*>/g, '');
	}



  get inventoryMessage()
  {
        if (this.inventory <= 0)
        {
			let pipe = new DatePipe('en-US');
			let dueDate = pipe.transform(this.stockDueDate, 'MMM dd, yyyy')
			return this.stockDueDate ? `Due: ${dueDate}` : '';
		} 		
		const levels = [10, 50, 100, 1000];
		levels.forEach(l => {
			if(this.inventory < l) { return `<${l} Remaining`}
		})
		return '';
	}
}

export class ProductImage {
	public productImageId: number;
	public productId: number;
	public productCode: string;
	public imageName: string;
	public displayOrder: number;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}
export class Bundle {
	public bundleId: number;
	public bundleCode: string;
	public name: string;
	public retail: number;
	public startDate: Date;
	public endDate: Date;
	public createDate: Date;
	public products: Array<BundleProduct>;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class BundleProduct {
	public bundleProductId: number;
	public bundleId: number;
	public productId: number;
	public productCode: string;
	public quantity: number;
	public priceProportion: number;
	public promoPrice: number;
	public product: Product;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class BundleImage extends ProductImage {
	public productName: string;

	constructor(o?:any) {
		super(o);
		Object.assign(this, o);
	}
}

export class ProductTechSpec {
    public sectionId: number;
    public sectionName: string;
    public sectionDisplayOrder: number;
    public sectionLevel: number;
    public attributeId: number;
    public attributeName: string;
    public attributeDisplayOrder: number;
    public isRequired: boolean;
    public isNumeric: boolean;
    public unitTypeId: number | null;
    public valueNumeric: number | null;
    public unitId: number | null;
	public unit: string;
    public valueText: string;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class TechSpec {
	public sectionId: number;
	public sectionName: string;
	public sectionDisplayOrder: number;
	public attributes: Array<TechSpecAttribute>;

	constructor(o: Array<ProductTechSpec>) {

	}
}
export class TechSpecAttribute {
	public attributeName: string;
	public attributeDisplayOrder: number;
	public value: string;

}

export class ProductFile {
	public bucket: string;
	public link: string;
	public displayName: string; 
	constructor(o?: any) {
		Object.assign(this,o);
	}
}

export class ProductResponse {
	public product: Product;
	public returnCode: number;
	public data: any;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}
