

<div class="order-items {{mode}}">
	<div class="row headers">
		<div class="order-number">Order Number</div>
		<div class="po-number">PO Number</div>
		<div class="order-date">Order Date</div>
		<div class="invoice-number">Invoice Number</div>
		<div class="user">User</div>
		<div class="tracking">Tracking Number</div>
		<div class="status">Status</div>
		<div class="total-price">Total Price</div>
		<div class="order-type">Order Type</div>
		<div class="expander"></div>
	</div>
	<ng-container *ngFor="let item of orderHistoryItems">
		<div class="row" [ngClass]="{open: openOrder == item.orderId}">
			<div class="order-number">{{item.orderId}}</div>
			<div class="po-number">{{item.poNumber}}</div>
			<div class="order-date">{{item.enterDate | date: 'MM/dd/yyyy'}}</div>
			<div class="invoice-number">
				<ul>
					<li class="invoice" (click)="openInvoice(inv)" *ngFor="let inv of item.invoiceNumbers">{{inv}}</li>
				</ul>
			</div>
			<div class="user">{{item.name}}</div>
			<div class="tracking">
				<a *ngFor="let num of item.trackingNumbers" href="https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums={{num}}&requester=ST/trackdetails" target="_blank">{{num}}</a>
			</div>
			<div class="status {{item.orderStatus}}">{{item.orderStatus}}</div>
			<div class="total-price">{{item.orderTotal | currency}}</div>
			<div class="order-type">{{item.orderType}}</div>
			<div class="expander" (click)="expand(item.orderId)"><i class="pi pi-caret-down"></i></div>		
		</div>	
		<div class="order-details" [ngClass]="{open: openOrder == item.orderId}">
			<ng-container *ngIf="openOrder == item.orderId">
				<app-order-history-details [orderId]="item.orderId"></app-order-history-details>
				<div class="actions">
					<div class="button" (click)="print(item.orderId)">Print</div>
				</div>
			</ng-container>
		</div>		
	</ng-container>

</div>
