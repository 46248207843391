import { Component, Input, OnInit } from '@angular/core';
import { OrderHistoryDetails } from 'src/app/models/dashboard/order-history-details';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-history-details',
  templateUrl: './order-history-details.component.html',
  styleUrls: ['./order-history-details.component.scss']
})
export class OrderHistoryDetailsComponent implements OnInit {

	@Input() orderId: number;
	public environment = environment;

	public orderHistoryDetails: OrderHistoryDetails
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
	this.dataService.getOrderHistoryDetails(this.orderId).subscribe(o => this.orderHistoryDetails = o)
  }

  isBackorder(name): boolean {
	//MARK I HATE THIS!
	let regex = new RegExp(/(\*){2,3}(\s)?BACK(\s)?ORDER(\s)?(\*){2,3}$/gi);
	return name.match(regex);
  }

}
