import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Cart } from "src/app/models/shopping/cart";
import { MixMatch } from "src/app/models/shopping/mix-match";
import { DataService } from "src/app/services/data.service";
import { AddMultipleToCart, DeleteFromCart, EditCartItem } from "src/app/store/actions/cart.actions";
import { SuccessMessageSet } from "src/app/store/actions/site.actions";
import { selectCart } from "src/app/store/selectors/cart.selectors";
import { IAppState } from "src/app/store/state/app.state";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-mix-match",
	templateUrl: "./mix-match.component.html",
	styleUrls: ["./mix-match.component.scss"],
})
export class MixMatchComponent implements OnInit {
	public mixMatch: MixMatch;
	public quantities: Array<number>;
	public environment = environment;
	private cart: Cart;
	constructor(private dataService: DataService, private config: DynamicDialogConfig, private store: Store<IAppState>, private ref: DynamicDialogRef) {}

	ngOnInit(): void {
		this.dataService.getMixMatch(this.config.data.mixMatchId).subscribe((resp) => {
			this.mixMatch = resp;
			this.quantities = new Array(this.mixMatch.products.length).fill(0);
			this.store.select(selectCart).subscribe(cart => {
				this.cart = cart;
				//if you have any of these already in your cart, update with the quantity there
				this.mixMatch.products.forEach((p,i) => {
					let ci = cart.cartItems.find(item => item.product.productCode == p.product.productCode);
					if(ci) {
						this.quantities[i] = ci.quantity;
					}
				})
			})
		});
	}

	notifyCopy(payload) {
		this.store.dispatch(new SuccessMessageSet(`Copied ${payload} to clipboard`));
	}
	addToCart() {
		let items: Array<{productCode: string, quantity: number}> = [];
		this.mixMatch.products.forEach((p,i) => {
			let cartItem = this.cart.cartItems.find(ci => ci.product.productCode == p.mixMatchProductCode);
			if(cartItem) {
				//subtract what's already in the cart, so you get new amount to add
				items.push({productCode: p.mixMatchProductCode, quantity: this.quantities[i] - cartItem.quantity});
			} else {
				items.push({productCode: p.mixMatchProductCode, quantity: this.quantities[i]});
			}
		})
		this.store.dispatch(new AddMultipleToCart(items.filter(i => i.quantity != 0)));
		/*
		items.filter(i => i.quantity == 0).forEach(i =>  {
			//if the item was in the cart, and you changed the quantity to 0, remove it
			let ci = this.cart.cartItems.find(ci => ci.product.productCode == i.productCode);
			if(ci) {
				this.store.dispatch(new EditCartItem(ci.cartItemId, i.productCode, 0, true))
			}
		})
		*/
		this.ref.close();
	}
	get total() {
		return this.quantities.reduce((a,b) => a+b, 0)
	}
	get valid() {
		return this.total > 0 && (this.total % this.mixMatch.multiple == 0)
	}
}
