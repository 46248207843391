
<div class="pane">
	<div class="pre-header"> <span>Go to: </span> <a [routerLink]="['/dashboard', 'order-history']">Order History</a></div>

	<div class="InvoiceDownload" (click)="downloadPayables()"> Print All My Billings  </div>
	<br />


	<div class="tab-list">
		<div class="tab" (click)="setTab('MSS')" [ngClass]="{active: openTab == 'MSS'}">Open Invoices</div>

		<div class="tab" (click)="setTab('MSH')" [ngClass]="{active: openTab == 'MSH'}">Paid Invoices</div>

		<div class="tab" (click)="setTab('BOS')" [ngClass]="{active: openTab == 'BOS'}">Back Order Summary</div>

		<div class="tab" (click)="setTab('OSS')" [ngClass]="{active: openTab == 'OSS'}">Orders Shipped Summary</div>
	</div>
	<div class="tab-content">
		<app-table [data]="data" [tableInfo]="tableInfo" [caller]="this"></app-table>
		<app-pager [page]="pageNumber" [pageCount]="pageCount" (onGoToPage)="goToPage($event)"></app-pager>
	</div>

</div>

