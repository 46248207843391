import { Component, OnInit, Input } from '@angular/core';
import { RebateEntry } from 'src/app/models/dashboard/rebates';

@Component({
  selector: 'app-price-protection-details',
  templateUrl: './price-protection-details.component.html',
  styleUrls: ['./price-protection-details.component.scss']
})
export class PriceProtectionDetailsComponent implements OnInit {

	@Input() entry: RebateEntry
  constructor() { }

  ngOnInit(): void {
  }

  get quantityTotal() {
	return this.entry.rebateLineItems.reduce((a,b) => a + b.quantity, 0);
  }
  get reimbursementTotal() {
    return this.entry.rebateLineItems.reduce((a, b) => a + b.reimbursement, 0);
  }
}
