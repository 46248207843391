import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ADDRESS_TYPE } from "src/app/models/account/address";
import { OrderSearchCriteria } from "src/app/models/dashboard/order-search-criteria";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-order-history-search",
	templateUrl: "./order-history-search.component.html",
	styleUrls: ["./order-history-search.component.scss"],
})
export class OrderHistorySearchComponent implements OnInit {
	@Output() onSearch: EventEmitter<OrderSearchCriteria> = new EventEmitter();

	public orderNumber: string;
	public userId: number;
	public addressId: number;
	public startDate: Date;
	public endDate: Date;
	public invoiceNumber: string;
	public poNumber: string;
	public orderStatus: string;

	public userOptions = [];
	public addressOptions = [];
	public orderStatusOptions = [
		{ label: "All", value: "" },
		{ label: "On Hold", value: "On Hold" },
		{ label: "Processed", value: "Processed" },
		{ label: "Submitted", value: "Submitted" },
		{ label: "Completed", value: "Completed" },
	];
	/*
	public orderStatusId: number;
	*/
	public orderTypeId: number;
	public orderTypeOptions = [
		{ label: "All", value: "" },
		{ label: "Web Order", value: 1 },
		{ label: "Direct Vendor Ship", value: 5 },
		{ label: "POS Order", value: 2 },
	];
	public vendorOptions: { label: string, value: number }[];
	public vendorId: number;

	constructor(private dataService: DataService, private authService: AuthService) {}

	ngOnInit(): void {
		const companyId = this.authService.user.companyId;

		this.dataService.getUsers().subscribe((resp) => (this.userOptions = resp.map((u) => ({ label: `${u.firstName} ${u.lastName}`, value: u.userId }))));

		this.dataService.getAddresses(ADDRESS_TYPE.Shipping).subscribe((resp) => (this.addressOptions = resp.map((a) => ({ label: `${a.address1} ${a.city}, ${a.state}`, value: a.addressId }))));
		this.dataService.getAllVendors().subscribe(resp => this.vendorOptions = resp.map(v => ({label: v.name, value: v.vendorId })))
	}

  doSearch() {
    this.onSearch.emit(new OrderSearchCriteria({ userId: this.userId, addressId: this.addressId, term: [this.orderNumber, this.invoiceNumber, this.poNumber, this.addressId, this.userId, this.orderTypeId, this.vendorId].join(","), startDate: this.startDate, endDate: this.endDate, orderStatus: this.orderStatus, vendorId: this.vendorId }));
	}

	clear() {
		this.userId = undefined;
		this.addressId = undefined;
		this.orderNumber = '';
		this.startDate = undefined;
		this.endDate = undefined;
		this.invoiceNumber = undefined;
		this.poNumber = undefined;
		this.orderStatus = "";
        this.orderTypeId = undefined;
        this.vendorId = undefined;
	}
}
