import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { Contact, Department, Director, Officer, StaffMember } from "src/app/models/strapi/staff-member";
import { StrapiService } from "src/app/services/strapi.service";

@Component({
	selector: "app-staff",
	templateUrl: "./staff.component.html",
	styleUrls: ["./staff.component.scss"],
})
export class StaffComponent implements OnInit {

  public openTab: 'Contact' | 'Staff' | 'Officers' | 'Directors' = 'Staff';

	public staffMembers: Array<StaffMember>;
	public departments: Array<Department>;
	public officers: Array<Officer>;
    public directors: Array<Director>
  public Contact: Contact;

	constructor(private strapiService: StrapiService) {}

	ngOnInit(): void {
    let requests = [
      this.strapiService.getDepartments().pipe(tap((d) => (this.departments = d.sort((a, b) => a.displayOrder - b.displayOrder)))),
      this.strapiService.getStaff().pipe(tap((s) => (this.staffMembers = s))),
      this.strapiService.getOfficers().pipe(tap(o => this.officers = o.sort((a, b) => a.displayOrder - b.displayOrder))),
      this.strapiService.getDirectors().pipe(tap(d => this.directors = d.sort((a, b) => a.displayOrder - b.displayOrder))),


		];

		forkJoin(requests).subscribe(() => {
			this.departments.forEach(d => d.staff = this.staffMembers.filter(s => s.department.id == d.id).sort((a,b) => a.displayOrder - b.displayOrder));
		})
	}

	setTab(tab) {
		this.openTab = tab;
	}
}
