
<main>
	<p>Thank you {{rebate.rebateForm.fname}} {{rebate.rebateForm.lname}}.  We have received your claim submission for: <b *ngIf="rebate.rebateProgram">{{rebate.rebateProgram?.vendorName}} / Program Period of {{rebate.rebateProgram?.startDate | date: 'MMM dd'}} - {{rebate.rebateProgram?.endDate | date: 'MMM dd, yyyy'}}</b></p>
	<div class="form-values">
		<dl>
			<dt>First Name</dt>
			<dd>{{rebate.rebateForm.fname}}</dd>
			<dt>Last Name</dt>
			<dd>{{rebate.rebateForm.lname}}</dd>
			<dt>Phone</dt>
			<dd>{{rebate.rebateForm.phone}}</dd>
			<dt>Email</dt>
			<dd>{{rebate.rebateForm.email}}</dd>
			<dt>Debit Memo</dt>
			<dd>{{rebate.rebateForm.memo}}</dd>
			<dt>Claim Total</dt>
			<dd>{{claimTotal | currency}}</dd>
		</dl>
	</div>
	<div class="items">
		<ul>
			<li class="header">
				<span class="product-code">Product Code</span>
				<span class="product-name">Model Name</span>
				<span class="quantity">Quantity</span>
				<span class="reimbursement">Reimbursement Amount</span>
			</li>
			<li *ngFor="let item of rebate.rebateLineItems">
				<span class="product-code">{{item.modelID}}</span>
				<span class="product-name">{{item.model}}</span>
				<span class="quantity">{{item.quantity}}</span>
				<span class="reimbursement">{{item.reimbursement * item.quantity  | currency}}</span>
			</li>
		</ul>
	</div>
	<div class="files">
		<ul>
			<li class="header">Attachments</li>
			<li *ngFor="let file of rebate.rebateFiles">
				<i class="{{file.extension}}"></i>
				<span class="name">{{file.fileName}}</span>
				<span class="size">{{file.fileSizeWithUnits}}</span>
			</li>
		</ul>
	</div>

</main>