<!--
<div class="row" [ngClass]="{'has-special': cartItem.appliedSpecial}">
	<span><a [routerLink]="['/shop', 'product', cartItem.product.productCode]">{{cartItem.product.modelName}}</a></span>
	<div class="qty">
		<input [(ngModel)]="quantity" *ngIf="cartItem.product.multiple == 1">
		<select [(ngModel)]="quantity" *ngIf="cartItem.product.multiple > 1">
			<option *ngFor="let _ of [].constructor(100); let i = index" [value]="(i+1) * cartItem.product.multiple">{{(i+1) * cartItem.product.multiple}}</option>
		</select>
	</div>
	<div class="unit-price">{{cartItem.itemPrice | currency}}</div>
	<div class="ext-price">{{cartItem.itemPrice * cartItem.quantity | currency}}</div>
	<button pButton type="button" icon="pi pi-refresh" class="p-button-rounded p-button-text" (click)="updateItemQuantity()"></button>
</div>

<ng-container *ngIf="cartItem.appliedSpecial">
	<div class="row special" *ngFor="let details of cartItem.appliedSpecial.details">
		<span>{{details.special?.specialName || '(regular price)'}}</span>
		<div class="qty"><span *ngIf="cartItem.appliedSpecial.details.length > 1">{{details.quantity}}</span></div>
		<div class="unit-price">{{details.specialPrice | currency}}</div>
		<div class="ext-price">{{details.specialPrice * details.quantity | currency}}</div>
	</div>
</ng-container>
-->

<div class="block" [ngClass]="{'has-special': cartItem.appliedSpecial}">
	<div class="img" *ngIf="cartItem.product.images?.length > 0" [ngStyle]="{'background-image': 'url(' + environment.image_url + cartItem.product.images[0].imageName + ')'}"></div>
	<div class="name"><a [routerLink]="['/shop', 'product', cartItem.product.productCode]">{{cartItem.product.modelName}}</a></div>
	<div class="version">{{cartItem.product.modelVersion}}</div>


	<div class="quantity">
		<p-inputNumber [(ngModel)]="quantity" (ngModelChange)="updateItemQuantity()" [showButtons]="true" buttonLayout="horizontal" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" [step]="cartItem.product.multiple"></p-inputNumber>
	</div>

	<div class="price">
		{{cartItem.itemPrice * cartItem.quantity | currency}}</div>
	<div class="remove"><i class="pi pi-trash" (click)="remove()"></i></div>

	<ng-container *ngIf="cartItem.appliedSpecial">
		<div class="specials">
			<div class="special" *ngFor="let details of cartItem.appliedSpecial.details">
				<div class="special-description">
					<span><a [routerLink]="['/specials', details.special?.specialId]">{{details.special?.specialName || '(regular price)'}}</a></span>
				</div>
				<div class="disc">{{details.specialPrice * details.quantity | currency}}</div>
			</div>
		</div>
	</ng-container>
</div>
