import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { ControlsModule } from '../controls/controls.module';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
	ReactiveFormsModule,
	FormsModule,

	ControlsModule,

	InputTextModule,
	CalendarModule,
	DropdownModule,
	ButtonModule,
	FileUploadModule,
	TableModule,
	InputTextareaModule,

  ],
  providers: [DatePipe]
})
export class RetailerModule { }
