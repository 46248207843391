import { Component } from '@angular/core';

@Component({
  selector: 'app-headtohead-header',
  templateUrl: './headtohead-header.component.html',
  styleUrls: ['./headtohead-header.component.scss']
})
export class HeadtoheadHeaderComponent {

}
