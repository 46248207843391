
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Article } from "../models/strapi/article";
import { ApiService } from "./api.service";


@Injectable()
export class MockStrapiService {

	constructor(private api: ApiService) {
	}
	getArticles() {
		return of(JSON.parse('{"data":[{"id":1,"attributes":{"articleName":"test","postDate":"1969-12-31T05:01:00.000Z","removalDate":"2022-04-07T04:00:00.000Z","newsletterDate":"2022-04-21","content":"this is a test","enableComments":true,"slug":"test","headliner":false,"featured":false,"newsletter":false,"proNews":false,"enableLikes":true,"excerpt":null,"likes":null,"createdAt":"2022-04-22T20:05:05.348Z","updatedAt":"2022-04-22T20:05:08.176Z","publishedAt":"2022-04-22T20:05:08.168Z"}}],"meta":{"pagination":{"page":1,"pageSize":25,"pageCount":1,"total":1}}}')).pipe(
			map((resp:any) => {
				return resp.data.map(a => new Article({ id: a.id, ...a.attributes}))
			})
		);
	}


}

