
export enum Permissions {
	AccountManagement = 1,
	SarRead = 2,
	RebateProcess = 4,
	ProStaff = 8,
	OrdersShipped = 16,
	OrderHistory = 32,
	Subscriptions = 64,
	FinancialData = 128,
	Forums = 256,
	Payments = 512,
	ShopAccess = 1024,
	UserManagement = 2048,
	Comments = 4096,
	GeneralAccess = 8192,
	RebateRead = 16384,
	InvoiceAccess = 32768,
	CurrentEvents = 65536,
	MarketingMaterials = 131072,
	SarProcess = 262144,
	Vendor = 524288,
	PointsManagement = 1048576,
}