import { SearchActions, SearchActionTypes } from "../actions/search.actions";
import { UserActions, UserActionTypes } from "../actions/user.actions";
import { initialSearchState, ISearchState } from "../state/search.state";
import { initialUserState, IUserState } from "../state/user.state";


export function searchReducer(state: ISearchState = initialSearchState, action: SearchActions):ISearchState {
	switch(action.type) {
		case SearchActionTypes.SET_CRITERIA:
			return { ...state, criteria: action.criteria };
		case SearchActionTypes.SET_RESULTS:
			return { ...state, results: action.results };  
		default:
			return state;
	}

}