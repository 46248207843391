
<ng-template #customCellTemplate let-day="day" let-locale="locale">
	<div class="cal-cell-top" [ngClass]="{active: day.date.getTime() == viewDate.getTime()}">
	  <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
	</div>

  </ng-template>

<div class="calendar-holder">
	<header>
		<p class="date">{{viewDate | date: 'fullDate'}}</p>
		<div class="change-date">
			<div class="up" (click)="changeMonth(1)"></div>
			<div class="down" (click)="changeMonth(-1)"></div>
		</div>
	</header>
	<mwl-calendar-month-view [viewDate]="viewDate" [events]="calendarEvents" [cellTemplate]="customCellTemplate" (dayClicked)="dayClicked($event)">
		<mwl-calendar-month-view-header></mwl-calendar-month-view-header>
	</mwl-calendar-month-view>
</div>

<ul class="days-events">
	<li class="event {{event.eventType}}" *ngFor="let event of daysEvents" >
		<div class="time" *ngIf="event.isAllDayEvent">
			All Day
		</div>
		<div class="time" *ngIf="!event.isAllDayEvent">
			<span>{{event.startDateTime | date:'h:mm a'}}</span>
			<span *ngIf="event.endDateTime">&nbsp;&mdash;&nbsp;{{event.endDateTime | date: 'h:mm a'}}</span>
		</div>
		<div class="title">{{event.title}}</div>
		<div class="author" *ngIf="event.author">Created by {{event.author}}</div>
	</li>
</ul>
