import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribeMixin } from 'src/app/mixins/auto-unsubscribe.mixin';
import { SarPrint, SarPrintItem } from 'src/app/models/dashboard/sar';
import { takeUntil } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-sar-print',
  templateUrl: './sar-print.component.html',
  styleUrls: ['./sar-print.component.scss']
})
export class SarPrintComponent extends AutoUnsubscribeMixin() implements OnInit {

	public sarPrint: SarPrint;
	public sarSummary: SarPrint;
	public sarItems: Array<SarPrintItem>;
    public sarSections: { [key: string]: Array<SarPrintItem> } = {}

  constructor(private route: ActivatedRoute, private dataService: DataService) { super(); }

  ngOnInit(): void {
	this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
		if(params.get('sarId')) {
			this.dataService.getSarPrint(params.get('sarId')).subscribe(s => {
				this.sarPrint = s;
				let { sarItems, ...sarSummary } = this.sarPrint;
				this.sarItems = sarItems;
				this.sarSummary = new SarPrint(sarSummary);

				this.sarItems.forEach(i => {
					i.type = i.type.trim();
					if(!this.sarSections[i.type]) {
						this.sarSections[i.type] = [];
					}
					this.sarSections[i.type].push(new SarPrintItem(i))
				})
				setTimeout(() => window.print(), 500);
			})

		}
	})
  }

}
