<div class="product-search-container">

	<div class="box">
		<input pInputText [(ngModel)]="term" class="term" (keyup)="onKeyUp($event)" name="psterm" id="psterm" [placeholder]="placeholder" [ngClass]="{'ng-touched': touched, 'ng-valid': valid, 'ng-invalid': !valid }">
		<mat-icon (click)="search()">search</mat-icon>
	</div>

	<div class="options" *ngIf="showResults">
		<h4><span>Top Products</span></h4>
		<div class="top-products">

			<div class="top-product" *ngFor="let product of topProducts; index as i" [ngClass]="{active: activeIndex == i }" (click)="goToProduct(product.productCode, i+1)">

				<div class="long">
					<div class="image" *ngIf="product.imageName"><img src="http://images.promaster.com/{{product.imageName}}"></div>

					<div class="image" *ngIf="!product.imageName"><img src="../../../../../assets/images/no-image.png"></div>

					<div class="name">
						<div class="brand">{{product.brandName}}</div>
						<div class="model-name" [innerHTML]="product.modelName | highlight: term"></div>
					</div>
					
					<div class="retail"><em>Retail: </em>{{product.retail | currency}}</div>

					<div class="code"><em>Product Code: </em>{{product.productCode}}</div>

					<div class="features" [innerHTML]="product.featuresPlaintext | highlight: term"></div>
					
				</div>

				<div class="short">
					<div class="code"> <a> {{product.productCode}} </a> </div>
					<div class="model-name" [innerHTML]="'<a>' + product.modelName + '</a>' | highlight: term" ></div>
					<div class="brand">{{product.brandName}}</div>
				</div>

			</div>
		</div>
		<h4><span>Top Brands</span></h4>
		<div class="top-brands">
			<div class="top-brand" *ngFor="let brand of topBrands">
				<h5><a [routerLink]="['/', 'warehouse', 'brand', brand.brandId]">{{brand.brandName}}</a> </h5>
				<div class="top-brand-product" *ngFor="let product of brand.products">
					<div class="brand-product-link" (click)="goToProduct(product.productCode)"><a>{{product.productCode}} - {{product.modelName}}</a></div> 
				</div>
			</div>
		</div>
	</div>
</div>
