import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { POSearchResult, ShipSearchResult } from "src/app/models/search/search-results";
import { ShippingProductList } from "src/app/models/dashboard/shipping-error";


@Component({
  selector: 'app-po-search',
  templateUrl: './po-search.component.html',
  styleUrls: ['./po-search.component.scss']

})
export class PoSearchComponent implements OnInit {
  @Input() touched: boolean = false;
  @Input() valid: boolean = false;

  @Output() onSelect: Subject<POSearchResult> = new Subject();

  public searchCriteria: string;
  public results: Array<ShipSearchResult> = [];
  public activeIndex: number;
  private cancelSearch: Subject<any> = new Subject();
  public ProductString: string;
  public ShipNumber: string;
  public ShippingProducts: Array<ShippingProductList> = [];

  constructor(private dataService: DataService) { this.ProductString = "";}
  ngOnInit(): void {  }

  search() {
    if (this.searchCriteria?.length > 2) {
      this.cancelSearch.next(null);
      this.dataService
        .searchShipping(this.searchCriteria)
        .pipe(takeUntil(this.cancelSearch))
        .subscribe((resp) => { this.results = resp; this.activeIndex = undefined; });
    } else {
      this.results = [];    
    }
  }

   selectPO(result)
   {
     this.searchCriteria = result.shippingNumber;
     this.onSelect.next(result.shippingNumber);
     this.results = [];
     this.ProductString = "";

     //await this.repository.getById(Ids).toPromise();
     this.dataService
      .searchShippingProds(result.shippingNumber)
      .subscribe((resp) => {
        this.ShippingProducts = resp;

        for (var prod of this.ShippingProducts) {
          this.ProductString += prod.productCode + " (" + prod.productQuantity + ") " + prod.productDescription + '\n'
        }

      });


   
  }


  onKeyUp(e) {
   this.search();  
  }
  onKeyDown(e) {
    if (this.searchCriteria?.length > 0) {
      switch (e.keyCode) {
        case 38:     
          if (this.activeIndex) { this.activeIndex--; }
          this.scrollOptionIntoView('po-search_search_option_' + this.activeIndex)
          break;
        case 40:
          this.activeIndex === undefined ? this.activeIndex = 0 : this.activeIndex = Math.min(this.activeIndex + 1, this.results.length);
          this.scrollOptionIntoView('po-search_search_option_' + this.activeIndex)
          break;
        case 13:
          if (this.activeIndex) { this.selectPO(this.results[this.activeIndex]); }
          break;
      }
    }

  }

  scrollOptionIntoView(id) {
    //not implemented yet
  }

}
