<div class="dealers">
	<header>
		<h1>Dealers</h1>
	</header>
	<aside>
		<div class="search-container">
			<mat-icon>search</mat-icon>
			<input type="text" [(ngModel)]="searchTerm" (keyup)="search()" placeholder="Search">
			<div class="button">Search</div>
		</div>
		<br />
		<br />

		<div class="search-container">
			<div class="RefinedSearch">
				<p-dropdown id="state" [options]="states" (onChange)="searchState($event)" placeholder="Search by State" [showClear]="true"></p-dropdown>

				&nbsp;&nbsp;&nbsp;&nbsp;
				<p-dropdown id="retailerType" [options]="retailerType" (onChange)="searchRetailerType($event)" placeholder="Search by Retailer Type" [showClear]="true"></p-dropdown>

				&nbsp;&nbsp;&nbsp;&nbsp;
				<input type="text" [(ngModel)]="RosterZip" (keyup)="searchZip()" placeholder="Search by Zip">

				<p-dropdown id="Distance" [options]="Distance" (onChange)="searchDistance($event)"   placeholder="Search by zip proximity" [showClear]="true"></p-dropdown>

			</div>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<div class="button" (click)="reset()">Reset Search</div>
		</div>
	</aside>


	<main>
		<div class="row header">
			<div class="dba">DBA</div>
			<div class="name">Legal Name</div>
			<div class="address">Address</div>
			<div class="type">Dealer Type</div>
			<div class="phone">Phone</div>
			<div class="email">Email</div>
			<div class="website">Website</div>
		</div>
		<div class="row" *ngFor="let company of companiesToShow">
			<div class="dba">{{company.dba}}</div>
			<div class="name">{{company.legalName}}</div>
			<div class="address">{{company.address1}} {{company.city}}, {{company.state}} {{company.zip}}</div>
			<div class="type">{{company.customerType}}</div>
			<div class="phone">{{company.phone}}</div>
			<div class="email">{{company.email}}</div>
			<div class="website">{{company.website}}</div>
		</div>
	</main>
</div>
