import { Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, tap } from "rxjs/operators";
import { LoginPhoto } from "src/app/models/strapi/login-photo";
import { AuthService } from "src/app/services/auth.service";
import { StrapiService } from "src/app/services/strapi.service";
import { CloseCart } from "src/app/store/actions/cart.actions";
import { AllMessagesClear, ErrorMessageClear } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";
import { ErrorMessageSet } from "../../../../store/actions/site.actions";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

	public username: string;
	public password: string;

	public user: any;

	public photo: LoginPhoto

	constructor(private authService: AuthService, private store$: Store<IAppState>, private router: Router, private strapiService: StrapiService) { }

	ngOnInit(): void {
		this.router.events
		.pipe(
			filter((e) => e instanceof NavigationStart),
			tap(() => {
				this.store$.dispatch(new CloseCart());
				this.store$.dispatch(new AllMessagesClear());
			})
		);
		this.strapiService.getLoginPagePhoto().subscribe(resp => this.photo = resp);
	}

  login() {
    this.store$.dispatch(new ErrorMessageClear());
    this.authService.login(this.username, this.password)





	}
}
