import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Params } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { combineLatest, ReplaySubject, Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { WarehouseSearchCriteria } from "src/app/models/search/warehouse-search-results";
import { Brand } from "src/app/models/shopping/brand";
import { Category } from "src/app/models/shopping/category";
import { Product } from "src/app/models/shopping/product";
import { DataService } from "src/app/services/data.service";
import { SiteService } from "src/app/services/site.service";
import { AddToCart, CartActionTypes, CloseCart, OpenCart } from "src/app/store/actions/cart.actions";
import { SetWarehouseSearchCriteria, SetWarehouseSearchResults } from "src/app/store/actions/search.actions";
import { ShowDownloadDialog } from "src/app/store/actions/site.actions";
import { selectSearchCriteria, selectSearchResults } from "src/app/store/selectors/search.selectors";
import { IAppState } from "src/app/store/state/app.state";
import { TableInfo } from "../../controls/components/export-dialog/export-dialog.component";
import { MatBadgeModule } from '@angular/material/badge';

@Component({
	selector: "app-warehouse",
	templateUrl: "./warehouse.component.html",
  styleUrls: ["./warehouse.component.scss"]
})

export class WarehouseComponent extends AutoUnsubscribeMixin() implements OnInit {


	public openProductCode: string = "";

	public brandsSelected: Array<number> = [];
	public categoriesSelected: Array<number> = [];

	public products: Array<Product>;
	public keyword: string;
	public categories: Array<Category>;
	public brandOptions: Array<{ brand: Brand; selected: boolean }>;
	public brandOptionsToShow: Array<{ brand: Brand; selected: boolean }>;
	public brandFilterTerm: string = "";
	public page: number = 1;
	public pageSize: number = 100;
	public pages: number;
	public totalResults: number;
	public pageSizeOptions: Array<{ label: number; value: number }>;
	public sortField: string;
	public sortDir: sortDirType;
	public sortOptions = [
		{ label: 'Product Code', value: 'ProductCode|ASC' },
		{ label: 'Product Name', value: 'ModelName|ASC' },
		{ label: 'Brand Name', value: 'BrandName|ASC' },
		{ label: 'Category Name', value: 'CatName|ASC' },
		{ label: 'Price (lowest to highest)', value: 'Retail|ASC' },
		{ label: 'Price (highest to lowest)', value: 'Retail|DESC' },
	]
	public priceRange: Array<number> = [0, 10000];
	public newProductsOnly = false;
	public specials: boolean = false;
	public rebates: boolean = false;
	public multiples: boolean = false;
	public topSellers: boolean = false;
	public memoryOnly: boolean = false;
	public vendorDirectShip: boolean = false;
	public mode: string = 'listing';
	public size: string;
	public preselectBrand: ReplaySubject<Array<number>> = new ReplaySubject();
	public showFilters: boolean = false;
	public breadcrumbs: Array<{label: string, value: any}> = [];
	public clearAllCategories: Subject<any> = new Subject();
	public noResults: boolean = false;

	public leftPanelOpen: boolean = false;
	public keywordOpen: boolean = false;
	public filtersOpen: boolean = false;
	public categoryOpen: boolean = false;
	public brandOpen: boolean = false;
	public priceOpen: boolean = false;

  constructor(private siteService: SiteService, private dataService: DataService, private store: Store<IAppState>, private actions$: Actions, private route: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		this.pageSizeOptions = [
			{ label: 10, value: 100 },
			{ label: 20, value: 200 },
			{ label: 50, value: 500 },
            { label: 100, value: 1000 },
            { label: 1000, value: 10000 },
		];


		this.dataService.getNestedCategories().subscribe((resp: any) => (this.categories = resp[0].children));

		this.store.pipe(takeUntil(this.destroy$), select(selectSearchCriteria), take(1)).subscribe(c => {
			if(c) {
				this.keyword = c.keyword;
				this.newProductsOnly = c.newProductsOnly
				this.specials = c.specials;
				this.rebates = c.rebates;
				this.multiples = c.multiples;
				this.topSellers = c.topSellers;
				this.memoryOnly = c.memoryOnly;
				this.vendorDirectShip = c.vendorDirectShip;
				this.priceRange = [ c.minPrice, c.maxPrice];
				this.categoriesSelected = c.categoryIds?.map(x => x) || [];
				//this.brandOptions = this.brandOptions.map(o => ({ brand: o.brand, selected: c.brandIds.includes(o.brand.brandId )}))
				this.preselectBrand.next(c.brandIds);
				this.calcBreadcrumbs(c);
			} else {
				this.watchParams(true); 
			}			
		});
		
		this.store.pipe(takeUntil(this.destroy$), select(selectSearchResults)).subscribe(r => {
			if(r) {
				this.products = r.products;
				this.page = r.page;
				this.pages = r.pages;
				this.totalResults = r.totalResults;
			}
		})
		this.dataService.getBrands().subscribe((resp) => {
			this.brandOptions = resp.sort(this.sortBrandNames).map((b) => ({ brand: b, selected: false }));
			this.preselectBrand.asObservable().subscribe(ids => {
				if(ids?.length > 0) {
					this.brandOptions.forEach(b => b.selected = ids.includes(b.brand.brandId));
					this.brandOptionsToShow = [...this.brandOptions];
					this.search();
				}
			})
			this.brandOptionsToShow = [...this.brandOptions];
		});

		//this.doSearchFromParams();
		this.watchParams();
		this.siteService.onResize.pipe(takeUntil(this.destroy$)).subscribe(size => this.size = size)

	}

	watchParams(doSearch = false) {
		//runs search based on URL parameters
		combineLatest(
			this.route.params,
			this.route.data,
			(params: Params, data: Data) => ({
			  params,
			  data,
			})
		  ).subscribe((res: { params: Params; data: Data }) => {
			const { params, data} = res;

         if (data.newProductsOnly)
         {
          if (data.autoRun)
             {
	          this.search(false, { newProductsOnly: true});
	         }
         }
      
			if(params.type == 'brand') {
				//clear other filters so you can really do this search
				this.clearFilters();
				this.preselectBrand.next([parseInt(params.id)]);
              }

            if (params.type == 'search') {
                this.clearFilters();
				this.keyword = params.id;
				this.search();
			}
		  });

		//this.siteService.onResize.pipe(takeUntil(this.destroy$)).subscribe(size => this.size = size)

	}
	onCategorySelected({ category, checked }) {
		if (checked) {
			if (!this.categoriesSelected.some((c) => c == category.catId)) {
				this.categoriesSelected = [ ...this.categoriesSelected, category.catId ];
			}
		} else {
			this.categoriesSelected = this.categoriesSelected.filter((c) => c !== category.catId);
		}
	}

	priceChange(val, i) {
		this.priceRange = i == 0 ? [val, this.priceRange[1]] : [this.priceRange[0], val];
	}

	clearFilters() {
		this.keyword = '';
		this.brandOptions?.forEach(b => b.selected = false);
		this.categoriesSelected = [];
		this.clearAllCategories.next(true);
		this.newProductsOnly = false;
		this.memoryOnly = false;
		this.rebates = false;
		this.multiples = false;
		this.specials = false;
		this.vendorDirectShip = false;
		this.priceRange = [0, 10000];
		this.calcBreadcrumbs({});
		this.calcBreadcrumbs({});
		this.store.dispatch(new SetWarehouseSearchCriteria(new WarehouseSearchCriteria()))
  }

  search(resetPage = false, overrides = { }) {    
        this.noResults = false;
		this.products = [];
		this.pages = 0; //hide pager
		if (resetPage) {
			this.page = 1;
		}
		let criteria = {
			keyword: this.keyword,
			brandIds: this.brandOptions?.filter((o) => o.selected).map((b) => b.brand.brandId),
			categoryIds: this.categoriesSelected,
			pageSize: this.pageSize,
			pageNumber: this.page,
			sort: this.sortField,
			sortDir: this.sortDir,
			newProductsOnly: this.newProductsOnly,
			memoryOnly: this.memoryOnly,
			rebates: this.rebates,
			multiples: this.multiples,
			specials: this.specials,
			vendorDirectShip: this.vendorDirectShip,
			topSellers: this.topSellers,
			minPrice: this.priceRange[0],
			maxPrice: this.priceRange[1],
    };


  
  

		Object.assign(criteria, overrides);
		this.store.dispatch(new SetWarehouseSearchCriteria(criteria))

		this.calcBreadcrumbs(criteria);
		this.dataService.warehouseSearch(criteria).subscribe((resp: any) => {
			window.scrollTo(0, 0);
			if(resp.products?.length == 0) {
				this.noResults = true;
				return;
			}
			this.products = resp.products;
			this.page = resp.page;
			this.pages = resp.pages;
			this.totalResults = resp.totalResults;
			this.store.dispatch(new SetWarehouseSearchResults(resp));

		});
	}

	setMode(mode) {
		if(mode == 'table' && this.totalResults > this.products.length) {
			this.search(true, { pageSize: 0 })
		}
		this.mode = mode;
	}
	calcBreadcrumbs(criteria) {
		this.breadcrumbs = [];
		if(this.keyword) {
			this.breadcrumbs.push({label: 'Keyword', value: criteria.keyword})
		}
		const brandCount = this.brandOptions?.filter((o) => o.selected).length || 0;
		if(brandCount > 0) {
			this.breadcrumbs.push({ label: `Brand${brandCount == 1 ? '' : 's'}`, value: this.brandOptions?.filter((o) => o.selected).map(b => b.brand.brandName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())).join(', ')})
		}
		if(this.categories?.length > 0 && this.categoriesSelected.length > 0) {
			this.breadcrumbs.push({ label: `Categor${this.categoriesSelected.length == 1 ? 'y' : 'ies'}`, value: this.categories.filter(c => this.categoriesSelected.includes(c.catId)).map(c => c.catName).join(', ')})
		}
		if(this.newProductsOnly) {
			this.breadcrumbs.push({label: 'New Products', value: 'Yes'})
		}
		if(this.specials) {
			this.breadcrumbs.push({label: 'Specials', value: 'Yes'})
		}
		if(this.rebates) {
			this.breadcrumbs.push({label: 'Rebates', value: 'Yes'})
		}
		if(this.multiples) {
			this.breadcrumbs.push({label: 'Multiples', value: 'Yes'})
		}
		if(this.topSellers) {
			this.breadcrumbs.push({label: 'Top Sellers', value: 'Yes'})
		}
		if(this.memoryOnly) {
			this.breadcrumbs.push({label: 'Memory', value: 'Yes'})
		}
		if(this.vendorDirectShip) {
			this.breadcrumbs.push({label: 'Vendor Direct Ship', value: 'Yes'})
		}
	}
	filterBrands() {
		//remove any previous selections if you type in here
		this.brandsSelected = [];
		this.brandOptions.forEach(b => b.selected = false);
		this.brandOptionsToShow = [...this.brandOptions.filter((b) => b.brand.brandName.toLowerCase().indexOf(this.brandFilterTerm.toLowerCase()) > -1)];
	}
	openProduct(code) {
		this.openProductCode = this.openProductCode === code ? "" : code;
	}

	goToPage(n) {
		this.page = n;
		this.search();
	}
	updatePageSize() {
		this.page = 1;
		this.search();
	}

	addToCart(product) {
		this.store.dispatch(new AddToCart(product.productCode, 1));
		this.actions$
			.pipe(
				ofType(CartActionTypes.GET_CART),
				take(1),
				tap(() => {
					this.store.dispatch(new OpenCart());
					setTimeout(() => this.store.dispatch(new CloseCart()), 5000);
				})
			)
			.subscribe();
	}

	download() {
		this.store.dispatch(new ShowDownloadDialog(this.products, this.tableInfo, []));
	}
	sortBy(option) {
		const sortInfo = option.value.split('|')
		this.sortDir = sortInfo[1];
		this.sortField = sortInfo[0];
		this.search(true);
	}

	productDetails(product) {}

  Instock(row, caller) {
    const Receipt = row.receiptNumber;
  }

	sortBrandNames(a, b) {
		if (a.brandName > b.brandName) return 1;
		if (a.brandName < b.brandName) return -1;
		return 0;
	}

	private tableInfo = new TableInfo({
		columns: [
			{ title: 'Product Code', field: 'productCode'  },
			{ title: 'Brand Name', field: 'brandName' },
			{ title: 'Product Description', field: 'modelName' },
            { title: 'Cost', field: 'retail', type: 'currency' },
			{ title: 'MAP', field: 'map', type: 'currency' },
            { title: 'Retail', field: 'cusT_RETAIL', type: 'currency' },
			{ title: 'Qty', field: 'carton' },
			{ title: 'Volume Cost', field: 'cartonPricingRetail', type: 'currency' },

            { title: 'In Stock', field: 'stockMesssage' },

            { title: 'Date Available', field: 'stockDueDate', type: 'date' },
			{ title: 'Rebate', field: 'rebate' },
			{ title: 'UPC', field: 'upc' },
			{ title: 'Vendor Cat #', field: 'caT_NO' },
			{ title: 'Min Mult', field: 'multiple' },
            { title: 'Category', field: 'catName' },
            { title: 'Drop Ship Charge', field: 'dS_CHG' },,
		],
	})
}

type sortDirType = "ASC" | "DESC" | null;
