import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "searchResult" })
export class SearchResultPipe implements PipeTransform {
  constructor() { }

  transform(str: string) {

        if (str == null) {
          return "";
        }
		if (str.trim() == "") {
			return "";
		}
        const doc = new DOMParser().parseFromString(str, 'text/html');

		return doc.body.textContent.substring(0,200) || '';
	}
}
