import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Bundle, BundleImage, BundleProduct } from "src/app/models/shopping/product";
import { DataService } from "src/app/services/data.service";
import { AddBundleToCart, AddToCart } from "src/app/store/actions/cart.actions";
import { SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-bundle-page",
	templateUrl: "./bundle-page.component.html",
	styleUrls: ["./bundle-page.component.scss"],
})
export class BundlePageComponent implements OnInit {
	public bundle: Bundle;
	public allImages: Array<BundleImage>;
	public quantity: number = 1;

	constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private store: Store<IAppState>) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			if (params.get("id")) {
				const bundleCode = params.get("id");
				this.dataService.getBundle(bundleCode).subscribe(
					(resp: Bundle) => {
						this.bundle = new Bundle(resp);
						this.allImages = this.bundle.products.reduce((a, bp) => [ ...a, ...bp.product.images.map(i => (new BundleImage({ ...i, productName: bp.product.modelName})))], [])
					},
					(err) => {
						//didn't get a bundle, maybe it's a product
						this.dataService.getProduct(bundleCode).subscribe((resp) => {
							if (resp.product) {
								this.router.navigate(["/shop", "product", resp.product.productCode]);
							}
						});
					}
				);
			}
		});
	}
	addToCart() {
		this.store.dispatch(new AddToCart(this.bundle.bundleCode, this.quantity));
	}

	notifyCopy(payload) {
		this.store.dispatch(new SuccessMessageSet(`Copied ${payload} to clipboard`));
	}

	get isInStock() {
		return this.bundle.products.every(p => p.product.inventory > 0)
	}
	get fullPrice() {
		return this.bundle.products.reduce((a,p) => a + (p.quantity * p.product.retail), 0)
	}
}
