

<input [(ngModel)]="count">
<button (click)="refresh()">Refresh</button>

<div class="container">
	<div class="products">
		<app-product-tile [product]="product" *ngFor="let product of products"></app-product-tile>
	</div>
	<div class="cart">
		<app-cart-tile></app-cart-tile>
	</div>
</div>
	
