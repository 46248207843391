import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardTemplateComponent } from './components/dashboard-template/dashboard-template.component';
import { OverviewComponent } from './components/overview/overview.component';
import { EzPayComponent } from './components/ez-pay/ez-pay.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { MostFrequentlyBoughtComponent } from './components/most-frequently-bought/most-frequently-bought.component';
import { InstantRebatesComponent } from './components/instant-rebates/instant-rebates.component';
import { RebateClaimComponent } from './components/rebate-claim/rebate-claim.component';
import { SarComponent } from './components/sar/sar.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { StoreAddressComponent } from './components/store-address/store-address.component';
import { AccountDataComponent } from './components/account-data/account-data.component';
import { UsersComponent } from './components/users/users.component';
import { AccountManagementComponent } from './components/account-management/account-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ControlsModule } from '../controls/controls.module';
import { OrderHistoryListComponent } from './components/order-history/order-history-list/order-history-list.component';
import { OrderHistorySearchComponent } from './components/order-history/order-history-search/order-history-search.component';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MostFrequentlyBoughtListComponent } from './components/most-frequently-bought/most-frequently-bought-list/most-frequently-bought-list.component';
import { DialogModule } from 'primeng/dialog';
import { SarRequestComponent } from './components/sar/sar-request/sar-request.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MatIconModule } from '@angular/material/icon';
import { AddEditAddressComponent } from './components/store-address/add-edit-address/add-edit-address.component';
import { AddEditUserComponent } from './components/users/add-edit-user/add-edit-user.component';
import { RebateClaimFormComponent } from './components/rebate-claim/rebate-claim-form/rebate-claim-form.component';
import { SidebarModule } from 'primeng/sidebar';
import { OverviewSearchComponent } from './components/overview/overview-search/overview-search.component';
import { RebateClaimSummaryComponent } from './components/rebate-claim/rebate-claim-summary/rebate-claim-summary.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { PickListModule } from 'primeng/picklist';
import { PointsManagementComponent } from './components/points-management/points-management.component';
import { PointsUserComponent } from './components/points-management/points-user/points-user.component';
import { PointsCompanyComponent } from './components/points-management/points-company/points-company.component';
import { SarPrintComponent } from './components/sar/sar-print/sar-print.component';
import { SarPrintSectionComponent } from './components/sar/sar-print-section/sar-print-section.component';
import { SarPrintHeadingComponent } from './components/sar/sar-print-heading/sar-print-heading.component';
import { SarPrintItemsComponent } from './components/sar/sar-print-items/sar-print-items.component';
import { LogInAsComponent } from './components/log-in-as/log-in-as.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PaymentConfirmationComponent } from './components/ez-pay/payment-confirmation/payment-confirmation.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { PriceProtectionComponent } from './components/price-protection/price-protection.component';
import { PriceProtectionFormComponent } from './components/price-protection/price-protection-form/price-protection-form.component';
import { ShippingErrorsComponent } from './components/shipping-errors/shipping-errors.component';
import { FileUploadModule } from 'primeng/fileupload';
import { PriceProtectionSummaryComponent } from './components/price-protection/price-protection-summary/price-protection-summary.component';
import { PriceProtectionPrintComponent } from './components/price-protection/price-protection-print/price-protection-print.component';
import { PriceProtectionDetailsComponent } from './components/price-protection/price-protection-details/price-protection-details.component';
import { RebateClaimDetailsComponent } from './components/rebate-claim/rebate-claim-details/rebate-claim-details.component';
import { RebateClaimPrintComponent } from './components/rebate-claim/rebate-claim-print/rebate-claim-print.component';
import { OrderHistoryPrintComponent } from './components/order-history/order-history-print/order-history-print.component';
import { InvoiceHistoryComponent } from './components/invoice-history/invoice-history.component';
import { StolenGoodsReportComponent } from './components/stolen-goods-report/stolen-goods-report.component';
import { StolenGoodsFormComponent } from './components/stolen-goods-report/stolen-goods-form/stolen-goods-form.component';
import { IMaskModule } from 'angular-imask';
import { ShippingErrorsFormComponent } from './components/shipping-errors/shipping-errors-form/shipping-errors-form.component';
import { SellThroughComponent } from './components/sell-through/sell-through.component';
import { SellThroughUploadComponent } from './components/sell-through/sell-through-upload/sell-through-upload.component';
import { PointsApprovalComponent } from './components/points-management/points-approval/points-approval.component';
import { PointsPurchaseComponent } from './components/points-management/points-purchase/points-purchase.component';
import { ReportsComponent } from './components/reports/reports.component';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [
    DashboardTemplateComponent,
    OverviewComponent,
    EzPayComponent,
    InvoicesComponent,
    OrderHistoryComponent,
    MostFrequentlyBoughtComponent,
    InstantRebatesComponent,
    RebateClaimComponent,
    SarComponent,
    SubscriptionsComponent,
    StoreAddressComponent,
    AccountDataComponent,
    UsersComponent,
    AccountManagementComponent,
    OrderHistoryListComponent,
    OrderHistorySearchComponent,
    MostFrequentlyBoughtListComponent,
    SarRequestComponent,
    AddEditAddressComponent,
    AddEditUserComponent,
    RebateClaimFormComponent,
    OverviewSearchComponent,
    RebateClaimSummaryComponent,
    PermissionsComponent,
    PointsManagementComponent,
    PointsUserComponent,
    PointsCompanyComponent,
    SarPrintComponent,
    SarPrintSectionComponent,
    SarPrintHeadingComponent,
    SarPrintItemsComponent,
    LogInAsComponent,
    PaymentConfirmationComponent,
    PriceProtectionComponent,
    PriceProtectionFormComponent,
    PriceProtectionSummaryComponent,
    PriceProtectionPrintComponent,
    PriceProtectionDetailsComponent,
    RebateClaimDetailsComponent,
    RebateClaimPrintComponent,
    OrderHistoryPrintComponent,
    InvoiceHistoryComponent,
    ShippingErrorsComponent,
    StolenGoodsReportComponent,
    StolenGoodsFormComponent,
    ShippingErrorsFormComponent,
    SellThroughComponent,
    SellThroughUploadComponent,
    PointsApprovalComponent,
    PointsPurchaseComponent,
    ReportsComponent
  ],
  imports: [
    CommonModule,
	RouterModule,
	FormsModule,
	ReactiveFormsModule,
	ControlsModule,
	InputTextModule,
	InputTextareaModule,
	DropdownModule,
	RadioButtonModule,
	CalendarModule,
	DialogModule,
	MatIconModule,
	SidebarModule,
	PickListModule,
	DynamicDialogModule,
	FileUploadModule,
  IMaskModule,
  MultiSelectModule
  ]
})
export class DashboardModule { }
