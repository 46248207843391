
<ng-container *ngIf="!weeks">
	<div class="calendar">
		<label for="">Week of Sell Through:</label>
		<p-calendar [(ngModel)]="dateRange" selectionMode="range" (onSelect)="selectWeek($event)" hideOnDateTimeSelect="true" #calendar></p-calendar>
	</div>
	<div class="table">
		<div class="product-code header">Product Code</div>
		<div class="catalog header">Catalog Number</div>
		<div class="description header">Description</div>
		<div class="sales header">Sales</div>
		<div class="inventory header">On Hand</div>


		<ng-container *ngFor="let item of sellThroughItems; let i = index">
			<div class="product-code" [ngClass]="{odd: i % 2 == 0}"><span>{{item.productCode}}</span></div>
			<div class="catalog" [ngClass]="{odd: i % 2 == 0}"><span>{{item.cataglogNumber}}</span></div>
			<div class="description" [ngClass]="{odd: i % 2 == 0}"><span>{{item.description}}</span></div>
			<div class="sales" [ngClass]="{odd: i % 2 == 0}"><input type="text" [(ngModel)]="item.sales" (keydown)="numericOnly($event)" (focus)="clearZero(item, 'sales')" (blur)="replaceZero(item, 'sales')"></div>
			<div class="inventory" [ngClass]="{odd: i % 2 == 0}"><input type="text" [(ngModel)]="item.inventory" (keydown)="numericOnly($event)" (focus)="clearZero(item, 'inventory')" (blur)="replaceZero(item, 'inventory')"></div>
		</ng-container>
	</div>

	<div class="actions">
		<div class="button" (click)="submit()">Submit</div>
	</div>

	<p-dialog [(visible)]="showUpload" [dismissableMask]="false" position="bottom" styleClass="upload-sellthrough-dialog">
		<ng-template pTemplate="header">Upload Data</ng-template>
		<app-sell-through-upload (dataSaved)="closeUploadDialog()"></app-sell-through-upload>
	</p-dialog>
</ng-container>

<ng-container *ngIf="weeks">
	<h2>We have received your submissions for the following weeks:</h2>
	<ul>
		<li *ngFor="let week of weeks">{{week.weekEnding | date: 'MMMM d, yyyy'}}</li>
	</ul>
</ng-container>
