
export class ShippedSummary {
	public amount: number;
	public description: string;
	public packages: string;
	public po: string;
	public product: string;
	public quantity: number;
	public shipDate: Date;
	public tracking: string;

	constructor(o?:any) {
		Object.assign(this,o);
	}

	get trackingLinks() {
		let numbers = this.tracking.split(",").map(t => t.trim())
		let links = numbers.map(l => `<a href="https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${l}" target="_blank">${l}</a>`).join("<br>");
		return links;
	}
}