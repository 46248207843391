import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Special } from "src/app/models/shopping/specials";
import { AddMultipleToCart, AddToCart } from "src/app/store/actions/cart.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-special-details",
	templateUrl: "./special-details.component.html",
	styleUrls: ["./special-details.component.scss"],
})
export class SpecialDetailsComponent implements OnInit {
	public special: Special;
	constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig, private store: Store<IAppState>) {}

	ngOnInit(): void {
		this.special = this.config.data.special;
	}

	close() {
		this.ref.close();
	}

	addToCart() {
		//for now, just adding everything
		//also, should add an "add-multiple to cart" method
		let productsToAdd: Array<{ productCode: string, quantity: number }> = []
		this.special.specialRequirements.forEach((req) => {
			let q = req.quantity;
			req.specialRequirementProducts.forEach((qp) => {
				productsToAdd.push({productCode: qp.product.productCode, quantity: q})
				
			});
		});
		this.special.specialRewards.forEach((rew) => {
			if (rew.specialRewardTypeId == 3) {
				let q = rew.quantity;
				rew.specialRewardProducts.forEach((wp) => {
					productsToAdd.push({productCode: wp.product.productCode, quantity: q})
				});
			}
		});
		this.store.dispatch(new AddMultipleToCart(productsToAdd));
	}
}
