import { Address } from "../account/address";

export class OrderSubmission {
	public poNumber: string;
	public signature: string;
	public shipToAddressId: number;
	public dropShipAddress: Address;
	public orderTypeId: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}
