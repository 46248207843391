
export class OrderHistoryItem {
	public orderId: number;
	public poNumber; string;
	public enterDate: Date;
	public invoice: string;
	public orderStatus: string;
	public orderTotal: number;
	public firstName: string;
	public lastName: string;
	public orderTypeId: number;
	public orderStatusId: number;
	public tracK_NO: string; //need to fix casing of this in the api

	constructor(o?: any) {
		Object.assign(this,o);
	}

	get name() {
		return (this.firstName || '') + ' ' + (this.lastName || '');
	}
	get trackingNumbers() {
		return (this.tracK_NO || '').split(',').map(n => n.trim());
	}
	get invoiceNumbers() {
		return (this.invoice || '').split(",").map(i => i.trim())
	}
}