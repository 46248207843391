<div class="search-container">

	<header>
		<p-dropdown  [options]="searchTypeOptions" optionLabel="name" optionValue="value" [(ngModel)]="searchType"></p-dropdown>
		<span>|</span>
		<i class="pi pi-search" (click)="search()"></i>
		<input pInputText [(ngModel)]="term" (keyup)="search()" #searchTermField>
	</header>

	<main>
		<div class="search-results" *ngIf="searchResults" [ngClass]="{singleType: tempSearchType !== 4}">
			<div class="articles" *ngIf="(tempSearchType == 4 || tempSearchType == 2 ||  tempSearchType == 7 ) && searchResults.articleSearchResults?.length > 0">
				<div class="header">
					<h1>Articles</h1>
					<div><a [routerLink]="['/newsfeed', 'search', term]">View All</a></div>
				</div>
				<ul>
					<li *ngFor="let article of searchResults.articleSearchResults" class="result article-result">
						<a [routerLink]="['/cms', 'article', article.articleId, article.slug]">{{article.article}} <br /> Posted: {{article.createDate | date: 'MMM d,y'}} </a>
						<!--<div class="rating">
							<mat-icon *ngIf="article.rank > 3">star</mat-icon>
							<mat-icon *ngIf="article.rank > 1.5">star</mat-icon>
							<mat-icon *ngIf="article.rank > 0.45">star</mat-icon>
						</div>-->
						<p>{{article.extractedText | searchResult}}</p>
					</li>
				</ul>
			</div>
			<div class="invoices" *ngIf="(tempSearchType == 4 || tempSearchType == 1 ||  tempSearchType == 7 ) && searchResults.invoiceSearchResults?.length > 0" [permission]="Permissions.InvoiceAccess">
				<div class="header">
					<h1>Invoices</h1>
					<div><a [routerLink]="['/dashboard', 'invoices']" [queryParams]="{searchCriteria: term}">View All</a></div>
				</div>
				<ul>
					<li *ngFor="let invoice of searchResults.invoiceSearchResults" class="result invoice-result">
						<div class="invoice-download" (click)="downloadInvoice(invoice.invoiceNumber)">{{invoice.invoiceNumber}} <br /> Posted: {{invoice.modifyDate | date: 'MMM d'}}</div>
						<!--<div class="rating">
							<mat-icon *ngIf="invoice.rank > 3">star</mat-icon>
							<mat-icon *ngIf="invoice.rank > 1.5">star</mat-icon>
							<mat-icon *ngIf="invoice.rank > 0.45">star</mat-icon>
						</div>-->
						<p>{{invoice.searchText | searchResult}}</p>
					</li>
				</ul>
			</div>
			<div class="products" *ngIf="(tempSearchType == 4 || tempSearchType == 3 ||  tempSearchType == 7) && searchResults.productSearchResults?.length > 0">
				<div class="header">
					<h1>Products</h1>
					<div><a (click)="searchProducts()">View All</a></div>
				</div>
				<h4><span>Top Products</span></h4>
				<ul>
					<li class="product" *ngFor="let product of searchResults.productSearchResults; index as i " (click)="goToProduct(product.productCode, i+1)">

						<div class="img"><img *ngIf="product.productCode" src="http://images.promaster.com/{{product.productCode}}.jpg"></div>
						<div class="brand-code"><a>{{product.brandName}} | Product Code: {{product.productCode}}</a>  <div class="disc" *ngIf="product.discontinued== true"> Disc</div> </div>
						<div class="stock-message" *ngIf="product.stockMessage">{{product.stockMessage}}</div>
						<div class="name">{{product.modelName}}</div>
						<p>{{product.searchText | searchResult}}</p>
						<div class="retail">Price: {{product.retail | currency}}</div>
					</li>
				</ul>
				<h4><span>Top Brands</span></h4>
				<ul>
					<li class="top-brand" *ngFor="let brand of topBrands">
						<h5><a [routerLink]="['/warehouse', 'brand', brand.brandId]">{{brand.brandName}}</a></h5>
						<ul>
							<li class="top-brand-product" *ngFor="let product of brand.products"><div class="brand-product-link" (click)="goToProduct(product.productCode)"> <a>{{product.productCode}} - {{product.modelName}}</a></div></li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="no-results" *ngIf="searchResults.articleSearchResults?.length == 0 && searchResults.invoiceSearchResults?.length == 0 && searchResults.productSearchResults?.length == 0">
				Your search did not yield any results
			</div>
		</div>
	</main>
</div>

