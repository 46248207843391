<div class="product" *ngIf="product">




	<aside class="replacement-notice" *ngIf="product.isDiscontinued == 1 && product.returnCode != -10">
		The product you requested ({{product.productCode}}) is discontinued and is no longer available.
	</aside>

	<aside class="replacement-notice" *ngIf="product.returnCode == -10">
		The product you requested ({{product.data.originalProductName}}) is no longer available.  This is a replacement for that product
	</aside>



	<section class="images">
		<ng-container *ngIf="product.images?.length > 0">
			<app-product-images [images]="product.images"></app-product-images>
		</ng-container>
	</section>

	<section class="data">
		<app-product-details [product]="product" [techSpecs]="techSpecs" [productFiles]="productFiles" (onAddToCart)="addToCart($event)" [specials]="specials"></app-product-details>
	</section>

	<section class="related" *ngIf="relatedProducts?.length > 0">
		<h3>Related Products</h3>
		<div class="related-products">
			<div class="related-product" *ngFor="let product of relatedProducts">
				<app-product-tile [product]="product"></app-product-tile>
			</div>
		</div>

	</section>
	<!--
	<section class="features" [innerHtml]="product.features"></section>
	<section class="tech-specs">
		<app-tech-specs [productCode]="product.productCode"></app-tech-specs>
	</section>
	-->
</div>
