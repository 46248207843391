import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { RebateEntry, RebateFile, RebateForm, RebateLineItem } from "src/app/models/dashboard/rebates";
import { ProUser } from "src/app/models/pro-user";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-rebate-claim-form",
	templateUrl: "./rebate-claim-form.component.html",
	styleUrls: ["./rebate-claim-form.component.scss"],
})
export class RebateClaimFormComponent implements OnInit {
	@Input() rebate: RebateEntry;
	@Output() onSubmit: EventEmitter<RebateEntry> = new EventEmitter();

	public step: number = 1;
	public rebateForm: UntypedFormGroup;
	private user: ProUser;

	constructor(private dataService: DataService, private fb:UntypedFormBuilder, private store: Store<IAppState>, private datePipe: DatePipe) {}

	ngOnInit(): void {
		this.store.pipe(select(selectUser)).subscribe(u => this.user = u)
		if(!this.rebate.rebateForm) {
			this.rebate.rebateForm = new RebateForm();
		}
		if (!this.rebate.rebateLineItems) {
			this.dataService.getRebateProgramProducts(this.rebate.rebateProgram.vendorId, this.rebate.rebateProgram.startDate, this.rebate.rebateProgram.endDate).subscribe((r: any) => {
				this.rebate.rebateLineItems = r.map((i) => new RebateLineItem(i));
			});
		}
		this.rebateForm = this.fb.group({
			fname: [ this.rebate.rebateForm.fname || this.user.name.split(' ')[0], Validators.required ],
			lname: [ this.rebate.rebateForm.lname || this.user.name.split(' ')[1], Validators.required ],
			email: [ this.rebate.rebateForm.email || this.user.email, Validators.required ],
			phone: [ this.rebate.rebateForm.phone, Validators.required ],
			memo: [ this.rebate.rebateForm.memo, [Validators.required, Validators.minLength(5)] ],
		})
	}

	validateRebate(item: RebateLineItem) {
		this.dataService.validateRebate(item.modelID, item.quantity).subscribe(resp => {
			item.available = Math.max(0, resp.available);
			item.status = resp.status;
		})	
	}
	get total() {
		return this.rebate.rebateLineItems.filter(i => i.quantity > 0).reduce((a,b) => a + b.quantity * b.reimbursement, 0)
	}
	back() {
		if(this.step > 1){
			this.step--;
		}
	}
	next() {
		if(this.step < 3) {
			if(this.step == 2 && !this.rebate.rebateFiles?.length) {
				this.store.dispatch(new ErrorMessageSet("Please attach at least one supporting document"));
				return;
			}  
			this.step++;
		}
	}

	saveFile(elem){
		if(!this.rebate.rebateFiles) {
			this.rebate.rebateFiles = [];
		}
		const file = elem.target.files[0];
		this.rebate.rebateFiles.push(new RebateFile({file: file, fileName: file.name, fileSize: file.size }));
		elem.target.value = null
	}
	deleteFile(i) {
		this.rebate.rebateFiles.splice(i, 1);
	}
	
	submit() {
		this.rebateForm.markAllAsTouched();
		if(this.rebateForm.valid) {
			this.rebate.rebateForm = new RebateForm(this.rebateForm.value);
			this.rebate.rebateLineItems = this.rebate.rebateLineItems.filter(i => i.quantity > 0).map(i => new RebateLineItem({modelID: i.modelID, programDate: `${this.datePipe.transform(this.rebate.rebateProgram.startDate, "MMM dd")} - ${this.datePipe.transform(this.rebate.rebateProgram.endDate, "MMM dd, yyyy")}`, reimbursement: i.reimbursement, quantity: i.quantity, serial: i.serial, modelName: i.model}))
			//save files to send later
			const files = [ ...this.rebate.rebateFiles ];
			this.rebate.rebateFiles = this.rebate.rebateFiles.map(f => new RebateFile({ fileName: f.fileName, fileSize: f.fileSize }))
			const { rebateProgram, ...entry } = this.rebate
			this.dataService.submitRebate(entry).subscribe((resp:any) => {
				this.rebate.claimId = resp.data.claimId;
				this.dataService.submitRebateFiles(resp.data.claimId, files.map(f => f.file)).subscribe(resp => {
					this.store.dispatch(new SuccessMessageSet("Your rebate has been successfully submitted"));
					this.onSubmit.next(this.rebate);
				})
			})
		}
	}
}
