import { Bundle, Product } from "./product";
import { AppliedSpecial } from "./specials";

export class Cart {
	public cartId: number;
	public userId: number;
	public orderTypeId: number;
	public SpecialId: number;
	public poNumber: string;
	public shipToAddressId: number;
	public signature: string;
	public enterDate: Date;
	public ShippingMethod: number;

	public shippingCost: number | string;
	public discount: number | string ;

	public cartItems: Array<CartItem> = [];
	public cartBundles: Array<CartBundle> = [];

	constructor(o?:any) {
		this.shippingCost = 0;
		this.discount = 0;
		Object.assign(this, o);
	}

	getTotal() {
		let tot = this.cartItems.reduce((a,b) => {
			if(b.appliedSpecial) { 
				let thisitem = b.appliedSpecial.details.reduce((c,d) => c + (d.specialPrice * d.quantity), 0);
				return a + thisitem;
			}
			return a + b.itemPrice * b.quantity;
		}, 0);

		tot += this.cartBundles.reduce((a,b) => a + (b.bundle.retail * b.quantity), 0)
		return tot;
	}
	

}

export class CartItem {
	public cartItemId: number;
	public cartId: number;
	public productId: number;
	public quantity: number;
	public enterDate: Date;
	public itemPrice: number;
	public rejectUpsell: boolean;
	public cartSectionId: number;
	public sortID: number;
	public alerts: Array<CartItemAlert> = [];

	public product: Product;
	public itemUpsell: CartItemUpsell;
	public appliedSpecial: AppliedSpecial = null;

	public isDirty: boolean = false;

	constructor(o?:any) {
		Object.assign(this, o);
	}

	get extCost() {
		return this.quantity * this.itemPrice;
	}
}

export class CartItemAlert {
	public code: number;
	public message: string;
	public productdCode: string;
	public cartItemId: number;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class CartBundle {
	public cartBundleId: number;
	public cartId: number;
	public bundleId: number;
	public quantity: number;
	public enterDate: Date;
	public cartSectionId: number;

	public bundle: Bundle;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class CartItemUpsell {
	public cartonRetail: number;
	public cartonsToOrder: number;
	public itemsInCarton: number;
	public noUpsellQuantity: number;
	public upsellQuantity: number;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class AddToCartResult {
	public success: boolean;
	public data: any;
	public errorMessage?: string;
}