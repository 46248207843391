import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { InvoiceHistory } from "src/app/models/accounting/invoice-history";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardTitle } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-invoice-history",
	templateUrl: "./invoice-history.component.html",
	styleUrls: ["./invoice-history.component.scss"],
})
export class InvoiceHistoryComponent implements OnInit {

	public invoiceHistory: InvoiceHistory;
	public expandedYear: number = 0;
	public expandedMonth: number = 0;
	public expandedVendor: string = '';

	constructor(private dataService: DataService, private store: Store<IAppState>) {}

	ngOnInit(): void {
		this.dataService.getInvoiceHistory().subscribe((i) => (this.invoiceHistory = i));
		this.store.dispatch(new SetDashboardTitle("Invoice History"));
	}

	expandYear(yr) {
		this.expandedYear = (this.expandedYear == yr ? 0 : yr);
		this.expandedMonth = 0;
		this.expandedVendor = '';
	}
	expandMonth(month) {
		this.expandedMonth = (this.expandedMonth == month ? 0 : month);
		this.expandedVendor = '';
	}
	expandVendor(vendor) {
		this.expandedVendor = (this.expandedVendor == vendor ? '' : vendor);
	}

	monthName(month) {
		return new Date(2023, month - 1, 1).toLocaleString('default', { month: 'long' })
	}

	downloadInvoice(invoice) {
		this.dataService.downloadInvoiceFromHistory(invoice).subscribe((resp:any) => {
			//the line below may get caught by a popup blocker - if so, just move it to before the call to downloadInvoice()
			let wdw = window.open('', 'invoice')
			wdw.location.href = URL.createObjectURL(resp);

		}, err => {
			switch(err.error.size) {
				case 30:// can't find file
					this.store.dispatch(new ErrorMessageSet("The invoice was not found"));
					break;
				case 32: //not your invoice
					this.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
					break;
				default:
					this.store.dispatch(new ErrorMessageSet("We were not able to locate your invoice."));
					break;
			}
		})
  }
}
