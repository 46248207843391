<div class="po-search-search-container" (keydown)="onKeyDown($event)">
  <input pInputText [(ngModel)]="searchCriteria" class="po-search" (keyup)="onKeyUp($event)"
         [ngClass]="{'ng-touched': touched, 'ng-valid': valid, 'ng-invalid': !valid }" pTooltip="Seach for your shipping number" placeholder="Enter Search Criteria">

  <div class="options" *ngIf="results.length > 0">
    <div [id]="'po-search_search_option_'+i"
         *ngFor="let result of results; let i = index"
         [ngClass]="{active: activeIndex == i}"
         (click)="selectPO(result)"
         tooltipPosition="top"
         tooltipStyleClass="line-broken-tooltip"
         pTooltip="{{result.products}}">
      PO: {{result.po}}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
      &nbsp;&nbsp;&nbsp;&nbsp;Order ID: {{result.orderid}}
      &nbsp;&nbsp;&nbsp;&nbsp;|
      &nbsp;&nbsp;&nbsp;&nbsp;Invoice: {{result.invoice}}
      &nbsp;&nbsp;&nbsp;&nbsp;|
      &nbsp;&nbsp;&nbsp;&nbsp;Shipping #: {{result.shippingNumber}}
      &nbsp;&nbsp;&nbsp;&nbsp;|
      &nbsp;&nbsp;&nbsp;&nbsp;Ship Date: {{result.dT_POSTED | date: 'MM-dd-yy'}}
    </div>
  </div>

  <br />
  <br />
  Products listed in your selected shipping order #:<br />
  <!--style="background-color:black;color:white;"-->
  <textarea rows="15" cols="60" [(ngModel)]="ProductString" readonly> </textarea>


</div>


