import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { ErrorMessageSet, SuccessMessageSet } from 'src/app/store/actions/site.actions';
import { IAppState } from 'src/app/store/state/app.state';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

	private code: string;
	public password: string;
	public confirmPassword: string;
	public passwordInvalid: boolean = false;
	public confirmPasswordInvalid: boolean = false;

  constructor(private dataService: DataService, private route: ActivatedRoute, private store$: Store<IAppState>) { }

  ngOnInit(): void {
	this.route.paramMap.pipe(take(1)).subscribe(p => {
		if(p.get("code")) {
			this.code = p.get("code");
			this.validateCode();
		} else {
			this.store$.dispatch(new ErrorMessageSet("Your reset code is either invalid or expired.  Return to the Forgot Password to get another one."))
		}
	})
  }

  updatePassword() {
	if(this.isValid()) {
		this.dataService.resetPassword(this.code, this.password).subscribe((resp:any) => {
			if(resp.success) {
				this.store$.dispatch(new SuccessMessageSet("Your password has been updated.  You can now return to the login page."))
			}
		})
	}
  }

  private validateCode() {
	this.dataService.verifyForgotPasswordLink(this.code).subscribe((resp:any) => {
		if(resp.success) {

		} else {
			this.store$.dispatch(new ErrorMessageSet("Your reset code is either invalid or expired.  Return to the Forgot Password to get another one."))
		}
	})
  }

  private isValid(): boolean {
	if(!this.password) { this.passwordInvalid = true; }
	if(!this.confirmPassword) { this.confirmPasswordInvalid = true; }
	if(this.password != this.confirmPassword) { this.confirmPasswordInvalid = true; }
	return !(this.passwordInvalid || this.confirmPasswordInvalid)
  }
}
