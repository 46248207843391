import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { BundleImage } from "src/app/models/shopping/product";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-bundle-images",
	templateUrl: "./bundle-images.component.html",
	styleUrls: ["./bundle-images.component.scss"],
})
export class BundleImagesComponent implements OnInit {
	@Input() images: Array<BundleImage>;

	public activeImage: BundleImage;
	public activeThumbnail: BundleImage;
	public environment = environment;
	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes?.images?.currentValue) {
			this.activeImage = changes.images.currentValue[0];
		}
	}

	setActiveImage(image: BundleImage) {
		this.activeImage = image;
	}
	setActiveThumbnail(image: BundleImage) {
		this.activeThumbnail = image;
	}
}
