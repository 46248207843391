<div class="sars">
	<header>
		<h1>Satisfaction Adjustment Center</h1>
		<p>
            View Your below. Requests are removed after 30 days.Please note, you can have a Credit and an RA for the same reference number
            if you submitted a claim that required one or more items to be returned and others that were not.
		</p>
	</header>
	<aside>
		<div class="search-area">
			<input [(ngModel)]="searchTerm" (keyup.enter)="searchSar()">
			<div class="button transparent" (click)="clearSearch()" *ngIf="searchTerm">Clear</div>
			<div class="button" (click)="searchSar()">Search</div>
		</div>
	</aside>
	<main *ngIf="sars">
		<div class="row header">
			<div class="expander"></div>
			<div class="sar">SAR</div>
			<div class="date">SAR Date</div>
			<div class="reference-number">Your Reference Number</div>
			<div class="status">Status</div>
			<div class="notes">Notes</div>
			<div class="link" [permission]="Permissions.InvoiceAccess">Credit</div>
			<div class="print"></div>
		</div>
		<ng-container *ngFor="let sar of sars">
			<div class="row">
				<div class="expander"><i class="pi pi-caret-down" (click)="expandSar(sar)"></i></div>
				<div class="sar">{{sar.draId}}</div>
				<div class="date">{{sar.submitDate | date: 'MM/dd/yyyy'}}</div>
				<div class="reference-number">{{sar.referenceNum}}</div>
				<div class="status {{sar.status.toLowerCase()}}"><span>{{sar.status}}</span></div>
				<div class="notes">{{sar.notes}}</div>
				<div class="link" [permission]="Permissions.InvoiceAccess" (click)="downloadInvoice(sar.invoiceId)">View</div>
				<div class="print" (click)="printSar(sar.draId)">Print</div>
			</div>		
			<div class="sar-detail" *ngIf="openSarId == sar.draId && sar.details">
				<div class="row header">
					<div class="code">Item</div>
					<div class="description">Item Description</div>
					<div class="cost">Cost</div>
					<div class="quantity">Quantity</div>
					<div class="defect">Defect Description</div>
					<div class="defect-type">Defect Type</div>
					<div class="exceptions">Exceptions</div>
				</div>
				<div class="row" *ngFor="let item of sar.details">
					<div class="code">{{item.productCode}}</div>
					<div class="description">{{item.productDescription}}</div>
					<div class="cost">{{item.productCost | currency}}</div>
					<div class="quantity">{{item.quantity}}</div>
					<div class="defect">{{item.errorDescription}}</div>
					<div class="defect-type">{{item.returnType}}</div>
					<div class="exceptions">{{item.notes}}</div>
				</div>
			</div>	
		</ng-container>
		<app-pager [page]="page" [pageCount]="pageCount" (onGoToPage)="goToPage($event)"></app-pager>
	</main>
</div>



