import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	private _apiUrl: string;
	private defaultHeaders: HttpHeaders;
	constructor(private http: HttpClient) {
		this._apiUrl = "";
		this.defaultHeaders = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");
	}

	set apiUrl(val:string) {
		this._apiUrl = val;
	}

	getUrl(url: string) {
		if (this._apiUrl != "") {
			return `${this._apiUrl}/${url}`;
		} else {
			return `${url}`;
		}
	}

	get<T>(url: string): Observable<T> {
		return this.http.get<T>(this.getUrl(url));
	}

	getBlob(url: string): Observable<any> {
		return this.http.get(this.getUrl(url), { responseType: "blob" });
	}
	post<T>(url: string, objectToPost: any, headers: HttpHeaders = null): Observable<T> {
		let options = {};
		if (!!headers) {
			options = { headers: headers };
		} else {
			options = { headers: this.defaultHeaders };
		}
		return this.http.post<T>(this.getUrl(url), JSON.stringify(objectToPost), options);
	}

	postGetBlob(url: string, objectToPost: any): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		  });
		return this.http.post(this.getUrl(url), objectToPost, { headers,  responseType: "blob" as 'json'});
	}
	put<T>(url: string, objectToPost: any): Observable<T> {
		return this.http.put<T>(this.getUrl(url), JSON.stringify(objectToPost), { headers: this.defaultHeaders });
	}

	delete<T>(url: string, id: number): Observable<T> {
		return this.http.delete<T>(this.getUrl(url) + `/${id}`);
	}

	postBlob(url: string, formData: FormData): Observable<any> {
		const headers = new HttpHeaders({ enctype: "multipart/form-data" });
		return this.http.post(this.getUrl(url), formData, { headers: headers });
	}
}
