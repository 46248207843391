import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Article } from "src/app/models/strapi/article";
import { StrapiService } from "src/app/services/strapi.service";
import { tap } from 'rxjs/operators';

@Component({
	selector: "app-newsfeed-filtered",
	templateUrl: "./newsfeed-filtered.component.html",
	styleUrls: ["./newsfeed-filtered.component.scss"],
})
export class NewsfeedFilteredComponent implements OnInit {

	public articles: Array<Article> = [];
	
	constructor(private route: ActivatedRoute, private strapiService: StrapiService, private router: Router) {}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			let req;
			if(params.filterBy == 'tag') {
				req = this.strapiService.getArticlesByTag(params.term).pipe(tap(a => a.sort(this.sortByDateDesc)));
			}
			if(params.filterBy == 'search') {
				req = this.strapiService.searchArticless(params.term).pipe(tap(a => a.sort(this.sortByDateDesc)));
			}
			if(params.filterBy == 'popular') {
				req = this.strapiService.getPopular();
			}
			if(params.filterBy == 'recent') {
				req = this.strapiService.getRecent().pipe(tap(a => a.sort(this.sortByDateDesc)));
			}
			if(req) {
				req.subscribe(resp => this.articles = resp);
			}
		})
	}

	sortByDateDesc(a,b) {
		if(a.postDate > b.postDate) return -1;
		if(a.postDate < b.postDate) return 1;
		return 0;
	}
}
