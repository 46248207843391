import { Injectable } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Cart } from "../models/shopping/cart";
import { AppliedSpecial } from "../models/shopping/specials";
import { AddSpecialToItem, CartActionTypes, GetCart, GetCartSpecialsSuccess, GetCartSuccess } from "../store/actions/cart.actions";
import { selectCart } from "../store/selectors/cart.selectors";
import { IAppState } from "../store/state/app.state";


@Injectable() 
export class CartService {

	private _cartUpdate: Subject<Cart> = new Subject();
	public onCartUpdate = this._cartUpdate.asObservable();

	private _cart: Cart;

	constructor(private store$: Store<IAppState>, private actions$: Actions) {
		this.actions$.pipe(
			map(a => {
				switch(a.type) {
					case CartActionTypes.GET_CART_SUCCESS:
						this._cart = (a as GetCartSuccess).cart;
						break;
					case CartActionTypes.GET_CART_SPECIALS_SUCCESS:
						this.updateCartWithSpecials((a as GetCartSpecialsSuccess).appliedSpecials);
						break;

				}
			})
		).subscribe();
		this.store$.subscribe(state => console.log(state))
		this.store$.pipe(select(selectCart)).subscribe(cart => this._cartUpdate.next(cart));
	}
	getCart() {
		this.store$.dispatch(new GetCart());
	}


	cartUpdate(cart) {
		this._cartUpdate.next(cart);
	}

	private updateCartWithSpecials(specials: Array<AppliedSpecial>) {
		//this only works if an item can only have one special applied
		specials.forEach(s => {
			this.store$.dispatch(new AddSpecialToItem(s))
		})
	}
	
}