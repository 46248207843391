import { Component, OnInit, ChangeDetectorRef, ViewChild, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { FileUpload } from "primeng/fileupload";
import { Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ShippingError, ShippingErrorProduct, ShippingProductList} from "src/app/models/dashboard/shipping-error";
import { ProUser } from "src/app/models/pro-user";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardTitle, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";
import { forEachChild } from "typescript";

@Component({
  selector: 'app-shipping-errors-form',
  templateUrl: './shipping-errors-form.component.html',
  styleUrls: ['./shipping-errors-form.component.scss']
})


export class ShippingErrorsFormComponent implements OnInit {
  @ViewChild('packingSlipUploads') packingSlipUploads: FileUpload;
  @ViewChild('damagePhotoUploads') damagePhotoUploads: FileUpload;

  @Output() onSubmit: Subject<ShippingError> = new Subject();

  public openTab: "OverageShortage" | "Damaged" | "Wrong" = "OverageShortage";
  private user: ProUser;
  public shippingError: ShippingError = new ShippingError();
  public affectedProducts: Array<ShippingErrorProduct> = [];
  public willForward: boolean;
  public keepDamagedProducts: boolean;
  public upsNotified: boolean;
  public keepOrRma: 'keep' | 'rma' | undefined;
  public files: Set<File> = new Set();
  public packingSlipFiles: Array<string> = [];
  public submitted: boolean = false;
  public storeNameSupplied: boolean = false;
  public approximateCounter: number = 0;
  public ShipNumber: string;
  public notInShipping: boolean;
  public exceedsShortage: boolean;
  public ShippingProducts: Array<ShippingProductList> = [];

  public isShownSerial: boolean = false; // hidden by default

  public overageShortageOptions = [
    { label: 'Overage', value: 'Overage' },
    { label: 'Shortage', value: 'Shortage' },
    { label: 'Did Not Order', value: 'DidNotOrder' },
  ]
  constructor(private store: Store<IAppState>, private authService: AuthService, private dataService: DataService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.user = this.authService.user;
    this.shippingError = this.freshShippingError();

  }

  freshShippingError() {
    let shippingError = new ShippingError();
    shippingError.userId = this.user.userId;
    shippingError.accountNumber = this.user.accountNumber;
    let name = this.user.name.split(' ');
    shippingError.lastName = name.pop();
    shippingError.firstName = name.join(' ');
    shippingError.email = this.user.email;
    shippingError.ShipNumber = this.ShipNumber;

    this.dataService.getCompanyData().subscribe(resp => {
    this.shippingError.storeName = resp.company;
    this.storeNameSupplied = true;
    });
    return shippingError;
  }
  setTab(tab) {
    this.openTab = tab;
  }
  onSelect(event, location) {
    switch (location) {
      case 'packingslip': this.packingSlipFiles = event.currentFiles.map(f => f.name); break;
    }
    event.currentFiles.forEach(f => this.files.add(f));
  }
  onRemove(event, location) {
    switch (location) {
      case 'packingslip': this.packingSlipFiles = this.packingSlipFiles.filter(f => f != event.file.name)
    }
  }
  setShipNumber(shipNumber) {
    this.ShipNumber = shipNumber;
  }
  setProduct(product, ap) {
    ap.productCode = product.productCode;
    ap.description = product.modelName;
  }
  addNew(type) {
    switch (type) {
      case 'overage':
        this.affectedProducts.push(new ShippingErrorProduct({ index: this.approximateCounter++ })); break;
      case 'damage':
        this.affectedProducts.push(new ShippingErrorProduct({ errorType: 'Damaged', index: this.approximateCounter++ })); break;
      default:
        this.affectedProducts.push(new ShippingErrorProduct({ index: this.approximateCounter++ })); break;
    }
  }
  deleteProduct(i) {
    this.affectedProducts = this.affectedProducts.filter(p => p.index != i);
  }


  AddSerial(event)
  {
    //console.log('event :' + event);
    //console.log(event.value);

    if (event.value == "Overage")
      this.isShownSerial = true;
      //!this.isShownSerial;
  }


  submit() {

    this.submitted = true;
    this.affectedProducts = this.affectedProducts.filter(p => !p.isEmpty)
    this.shippingError.products = this.affectedProducts;
    this.shippingError.ShipNumber = this.ShipNumber;

    let valid = this.shippingError.isValid;

    //remove empty items
    this.shippingError.products = this.shippingError.products.filter(p => !p.isEmpty);

    //this.dataService
    //.searchShippingProds(this.ShipNumber)
    //.subscribe((resp) => { this.ShippingProducts = resp; });

   //Need to wait for all data to come back before we take any action.

    this.dataService.searchShippingProds(this.ShipNumber).subscribe((resp) =>
    { 

      this.ShippingProducts = resp;

      for (var EnteredProds of this.shippingError.products)
      {
        
        this.notInShipping = false;
        this.exceedsShortage = false;

          //Validate right here for products and quan.
          for (var prod of this.ShippingProducts)
          {

            if (EnteredProds.productCode == prod.productCode)
              this.notInShipping = true;

                    console.log(EnteredProds.errorType)
                    console.log(prod.productQuantity) //40
                    console.log(EnteredProds.quantity) //100

            if (EnteredProds.productCode == prod.productCode && EnteredProds.errorType == 'Shortage' && prod.productQuantity <= EnteredProds.quantity)
              this.exceedsShortage = false;
          }

        if (this.exceedsShortage == false && EnteredProds.errorType == 'Shortage')
        {
          valid = false;
          alert('Product ' + EnteredProds.productCode + ' @  Quantity ' + EnteredProds.quantity + " exceeds total billed.")
          break;
        }

        if (this.notInShipping == false && EnteredProds.errorType != 'Overage')
          {
            valid = false;
            alert(EnteredProds.productCode + ' ' + " not found in shipping log.")
            break;
          }

      }

 
 

      let files = new Array<File>();
      let iterator = this.files.values();
      let i = 0;
      while (i < this.files.size) {files.push(iterator.next().value);i++;}


      if (valid)
      {
          this.dataService.saveShippingError(this.shippingError)
          .pipe(
            switchMap((id: number) => this.dataService.saveShippingErrorFiles(id, files))
          ).subscribe(resp => {
            this.store.dispatch(new SuccessMessageSet("Thank you for your report."));
            this.shippingError = this.freshShippingError();
            this.affectedProducts = [new ShippingErrorProduct()];

            this.submitted = false;

            this.packingSlipUploads.clear();
            this.damagePhotoUploads?.clear();
            this.files = new Set();
            window.scrollTo(0, 0);
            this.changeDetector.detectChanges();
            this.onSubmit.next(this.shippingError);
          })
      }
      else {
        this.store.dispatch(new ErrorMessageSet("Please be sure to fill in all required fields"))
      }

    });


  

    //[(ngModel)]="ProductString"

  //  for (var EnteredProds of this.shippingError.products)
  //  {
 
  //   this.notInShipping = false;


  //  //Validate right here for products.
  //  for(var prod of this.ShippingProducts)
  //  {
  //    alert(prod.productCode)
  //      if (EnteredProds.productCode == prod.productCode)
  //      this.notInShipping = true;
  //  }

  //    if (this.notInShipping == false)
  //    {
  //      valid = false;
     
  //    alert(EnteredProds.productCode + ' ' + " not found in shipping log.")
  //    break;
  //    }

  //}

   
	}
	get affectedProductsOverage() {
		return this.affectedProducts.filter(p => p.errorType == 'Overage' || p.errorType == 'Shortage' || p.errorType == 'DidNotOrder' || p.errorType == '')
	}
	get affectedProductsDamaged() {
		return this.affectedProducts.filter(p => p.errorType == 'Damaged' || p.errorType == '')
	}
	get affectedProductsNotMine() {
		return this.affectedProducts.filter(p => p.errorType == 'NotMine' || p.errorType == '')
	}
	get hasOverages() {
		return this.affectedProducts.some(p => p.errorType == 'Overage' || p.errorType == 'DidNotOrder')
	}
	get hasDamaged() {
		return this.affectedProducts.some(p => p.errorType == 'Damaged')
	}
}

