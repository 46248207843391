import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import jwt_decode from "jwt-decode";
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard  {
 
    constructor(private router: Router, private authService: AuthService) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let token = this.authService.token;
		if(token) {
			let exp: number = (jwt_decode(token) as any).exp;
			if(new Date() > new Date(exp * 1000)){
				//token expired
				this.authService.redirectTo = state.url;
				this.router.navigateByUrl('/login');
			} else {
				return true;
			}

		} else {
			this.authService.redirectTo = state.url;
			this.router.navigateByUrl('/login');
		}
		return false;
	}
}