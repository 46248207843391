import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'mat-icon'
})
export class MatIconDirective {

  	constructor(el:ElementRef) {
		el.nativeElement.classList.add('material-icons-outlined');
	}
}