
<ng-container [ngSwitch]="mode">

	<div class="row" [ngClass]="{'has-special': cartItem?.appliedSpecial}" *ngSwitchCase="'row'">

		<div class="image">

		<img *ngIf="product.images?.length > 0" src="{{environment.image_url}}{{product.images[0].imageName}}" alt="">
		</div>
		<div class="tags">
			<div class="stock in" *ngIf="product.inventory > 0"><i class="check"></i>In Stock</div>
			<div *ngIf="!product.specOrder">
				<div class="stock out" *ngIf="product.inventory <= 0">Out of Stock</div>
			</div>
			<div class="stock in" *ngIf="product.outOfStockMessage"> {{product.outOfStockMessage}} </div>

			<div class="stock due" *ngIf="product.stockDueDate">Due: {{product.stockDueDate | date: 'MMM dd, yyyy'}}</div>


			<div class="chips">
				<div class="special" *ngIf="product.hasSpecial">Special</div>
				<div class="new-product" *ngIf="product.isNew">New</div>
				<div class="rebate" *ngIf="product.rebate !== 'NO'">Quarterly Rebate</div>
				<div class="multiples" *ngIf="product.specialPriceQuantity > 1">Multiples</div>
				<div class="special-order" *ngIf="product.specOrder">Special Order</div>
			</div>


		</div>

			<div class="multiple" *ngIf="product.multiple > 1"><div>Items sold in multiples of {{product.multiple}}</div></div>

			<div class="delete" *ngIf="cartItem" [permission]="Permissions.ShopAccess"><div (click)="deleteItem(cartItem.cartItemId)"><mat-icon>delete_outline</mat-icon>Delete</div></div>

			<div class="name">
				<a [routerLink]="['/shop', 'product', product.productCode]">{{product.modelName}}</a>
			</div>

			<div class="qty" [permission]="Permissions.ShopAccess" *ngIf="cartItem?.product.returnCode != 10">
				<span class="decrement" (click)="increment(-1)">-</span>
				<input [(ngModel)]="quantity" [disabled]="product.multiple > 1 ? true : null" (blur)="setQuantity()">
				<span class="increment" (click)="increment(1)">+</span>
			</div>
			<div class="qty" [ngClass]="{mixmatch: cartItem?.product.returnCode == 10}" [permission]="Permissions.ShopAccess" *ngIf="cartItem?.product.returnCode == 10">
				<div class="qty-amt">{{cartItem.quantity}}</div>
				<div class="edit-btn" (click)="editMMQuantity(cartItem.product.data.mixMatchId)">Edit</div>
			</div>

			<div class="cost" [permission]="Permissions.ShopAccess">
				<div class="unit-cost" *ngIf="cartItem"><span>Unit Cost:</span> <span>{{getPriceToShowForLite(false)}}</span></div>
				<div class="ext-cost" *ngIf="cartItem"><span>Ext Cost:</span> <span>{{getPriceToShowForLite(true)}}</span></div>
			</div>

			<div class="brand">
				<div class="brand-name" *ngIf="product.brandName"><a [routerLink]="['/warehouse', 'brand', product.brandId]">{{product.brandName}}</a></div>
				<div class="code">Product Code: {{product.productCode}}&nbsp;</div>
				<div class="copy"><span [copy-clipboard]="product.productCode" (copied)="notifyCopy($event)"><i class="pi pi-copy"></i></span></div>
			</div>

			<div class="show-hide" *ngIf="hasPromotions || (cartItem?.itemUpsell && !cartItem?.rejectUpsell)">
				<span *ngIf="!promosVisible" (click)="promosVisible = !promosVisible"><mat-icon>visibility</mat-icon>Show Promotions</span>
				<span *ngIf="promosVisible" (click)="promosVisible = !promosVisible"><mat-icon>visibility_off</mat-icon>Hide Promotions</span>
			</div>

			<div class="add-to-cart" *ngIf="!cartItem" [permission]="Permissions.ShopAccess">
				<div class="button" (click)="addToCart()">
					<mat-icon>shopping_cart</mat-icon>Buy Again
				</div>
			</div>

			<div class="alerts" *ngIf="cartItem && cartItemAlerts && cartItemAlerts[cartItem.cartItemId]">
				<div class="alert">
					{{cartItemAlerts[cartItem.cartItemId].message}}
				</div>
			</div>

			<div class="promos" [ngClass]="{open: promosVisible}">
				<div class="promo upsell" *ngIf="!!cartItem?.itemUpsell && !cartItem?.rejectUpsell">
					<mat-icon>error</mat-icon>
					<span>This item is available for {{cartItem.itemUpsell.cartonRetail | currency}} when purchased in quantities of {{cartItem.itemUpsell.itemsInCarton}}.  Would you like to purchase {{cartItem.itemUpsell.cartonsToOrder}} carton{{cartItem.itemUpsell.cartonsToOrder == 1 ? '' : 's'}} instead?</span>
					<div class="actions">
						<div class="button" (click)="acceptUpsell()">Yes</div>
						<div class="button" (click)="rejectUpsell()">No</div>
					</div>
					<div class="close-button"><mat-icon (click)="closePromo('upsell')">close</mat-icon></div>
				</div>
				<ng-container *ngIf="cartItem?.appliedSpecial">
					<div class="promo special" *ngIf="cartItem.appliedSpecial.details.length == 1">
						<mat-icon>savings</mat-icon>
						<span>{{cartItem.appliedSpecial.details[0].special.specialName}}</span>
					</div>
					<div class="promo special" *ngIf="cartItem.appliedSpecial.details.length > 1">
						<ng-container *ngFor="let detail of cartItem.appliedSpecial.details">
							<div class="special-row" *ngIf="detail.special?.specialId">
								<mat-icon>savings</mat-icon>
								<span>{{detail.quantity}} units qualify for {{detail.special.specialName}}</span>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>

		</div>

	<div class="lite" *ngSwitchCase="'lite'" [ngClass]="{'has-special': cartItem && cartItem.appliedSpecial}">
		<div class="delete" *ngIf="cartItem" [permission]="Permissions.ShopAccess"><div (click)="deleteItem(cartItem.cartItemId)"><mat-icon>delete_outline</mat-icon></div></div>

		<div class="code">{{product.productCode}}</div>
		<div class="name">
			<a [routerLink]="['/shop', 'product', product.productCode]">{{product.modelName}}</a>
		</div>

		<div class="prices">
			<div class="ext-cost" *ngIf="cartItem"><span>Unit Cost:</span> <span>{{getPriceToShowForLite(false)}}</span></div>
			<div class="ext-cost" *ngIf="cartItem"><span>Ext Cost:</span> <span>{{getPriceToShowForLite(true)}}</span></div>
		</div>

		<div class="quantity" [permission]="Permissions.ShopAccess"><input [(ngModel)]="quantity" [disabled]="product.multiple > 1 ? true : null" (blur)="setQuantity()"></div>

		<div class="promos" [ngClass]="{open: promosVisible}">
			<div class="promo upsell" *ngIf="!!cartItem?.itemUpsell && !cartItem?.rejectUpsell">
				<mat-icon>error</mat-icon>
				<span>This item is available for {{cartItem.itemUpsell.cartonRetail | currency}} when purchased in cartons of {{cartItem.itemUpsell.itemsInCarton}}.  Would you like to purchase {{cartItem.itemUpsell.cartonsToOrder}} carton{{cartItem.itemUpsell.cartonsToOrder == 1 ? '' : 's'}} instead?</span>
				<div class="actions">
					<div class="button" (click)="acceptUpsell()">Yes</div>
					<div class="button" (click)="rejectUpsell()">No</div>
				</div>
				<div class="close-button"><mat-icon (click)="closePromo('upsell')">close</mat-icon></div>
			</div>
			<ng-container *ngIf="cartItem?.appliedSpecial">
				<div class="promo special" *ngIf="cartItem.appliedSpecial.details.length == 1">
					<mat-icon>savings</mat-icon>
					<span>{{cartItem.appliedSpecial.details[0].special.specialName}}</span>
				</div>				
				<div class="promo special" *ngIf="cartItem.appliedSpecial.details.length > 1">
					<ng-container *ngFor="let detail of cartItem.appliedSpecial.details">
						<div class="special-row" *ngIf="detail.special?.specialId">
							<mat-icon>savings</mat-icon>
							<span>{{detail.quantity}} units qualify for {{detail.special.specialName}}</span>
						</div>
					</ng-container>
				</div>				
			</ng-container>

		</div>
		
	</div>

	<div class="table" *ngSwitchCase="'table'">
		<div class="product-row" *ngIf="mode == 'table'">
			<div class="product-code" title="{{product.productCode}}">{{product.productCode}}</div>
			<div class="brand" title="{{product.brandName}}">{{product.brandName}}&nbsp;</div>
			<div class="product-description" title="{{product.modelName}}">{{product.modelName}}&nbsp;</div>
			<div class="retail" title="{{product.retail | currency}}">{{product.retail | currency}}&nbsp;</div>
			<div class="map" title="{{product.map | currency}}">{{product.map | currency}}&nbsp;</div>
			<div class="cost" title="{{product.cusT_RETAIL | currency}}">{{product.cusT_RETAIL | currency}}&nbsp;</div>
			<div class="qty" title="{{product.carton}}">{{product.carton}}&nbsp;</div>
			<div class="vol-cost" title="{{product.cartonPricingRetail}}">{{product.cartonPricingRetail | currency}}&nbsp;</div>
			<div class="stock" title="{{product.inventory > 0 ? 'Yes' : 'No' }}">{{product.inventory > 0 ? 'Yes' : 'No' }}</div>
			<div class="available" title="{{product.stockDueDate | date}}">{{product.stockDueDate | date}}&nbsp;</div>
			<div class="rebate" title="{{product.rebate}}">{{product.rebate}}&nbsp;</div>
			<div class="upc" title="{{product.upc}}">{{product.upc}}&nbsp;</div>
			<div class="vend-cat-id" title="{{product.caT_NO}}">{{product.caT_NO}}&nbsp;</div>
			<div class="min-mult" title="{{product.multiple}}">{{product.multiple}}&nbsp;</div>
			<div class="cat" title="{{product.catName}}">{{product.catName}}&nbsp;</div>
		</div>
	</div>

	<div class="{{mode}}" *ngSwitchDefault>
		<header>
			<div class="discount"></div>
			<div class="message"></div>
		</header>

		<div class="tags">
			<div *ngIf="(product.isDiscontinued != 1 && product.returnCode != -10)   ||   (product.returnCode == -10) ">

				<div class="stock in" *ngIf="product.inventory > 0"><i class="check"></i>In Stock</div>

				<div *ngIf="!product.specOrder">
					<div class="stock out" *ngIf="product.inventory <= 0">Out of Stock</div>
				</div>

				<div class="stock in" *ngIf="product.outOfStockMessage"> {{product.outOfStockMessage}} </div>
				<div class="stock due" *ngIf="product.stockDueDate"> Due: {{product.stockDueDate | date: 'MMM dd, yyyy'}}</div>
			</div>

			<div class="chips" *ngIf="(product.isDiscontinued != 1 && product.returnCode != -10)   ||   (product.returnCode == -10) ">
				<div class="special" *ngIf="product.hasSpecial">Special</div>
				<div class="new-product" *ngIf="product.isNew">New</div>
				<div class="rebate" *ngIf="product.rebate !== 'NO'">Quarterly Rebate</div>
				<div class="multiples" *ngIf="product.specialPriceQuantity > 1">Multiples</div>
				<div class="special-order" *ngIf="product.specOrder">Special Order</div>
			</div>


			<div class="chips" *ngIf="(product.isDiscontinued == 1 && product.returnCode != -10)">
				<div class="Discontinued">Discontinued</div>   <div class="Replacement" *ngIf="product.replacementCode">Replacement  - {{product.replacementCode}}</div>   
			</div>


	


		</div>


			<div class="multiple" *ngIf="product.multiple > 1"><div>Items sold in multiples of {{product.multiple}}</div></div>
			<div class="photo"><a [routerLink]="['/shop', 'product', product.productCode]"><img *ngIf="product.images?.length > 0" src="{{environment.image_url}}{{product.images[0].imageName}}" /></a></div>
			<h1 class="name"><a [routerLink]="['/shop', 'product', product.productCode]">{{product.modelName}}</a></h1>
			<div class="details">
				<span class="brand"><a [routerLink]="['/warehouse', 'brand', product.brandId]">{{product.brandName}}</a></span>
				<span class="spacer">|</span>
				<span class="upc">UPC: {{product.upc}}</span>
			</div>
			<div class="product-code">
				<span>Product Code: {{product.productCode}}</span>&nbsp;
				<span [copy-clipboard]="product.productCode" (copied)="notifyCopy($event)"><i class="pi pi-copy"></i></span>
				<span *ngIf="product.caT_NO" class="spacer">|</span>
				<span *ngIf="product.caT_NO">Vendor #: {{product.caT_NO}}</span>&nbsp;
			</div>
			<div class="prices">
				<span><label>Price:</label><b>{{product.retail | currency}}</b></span>

				<span *ngIf="product.map"><label>MAP:</label><b>{{product.map | currency}}</b></span>

				<span><label>Retail:</label><b>{{product.cusT_RETAIL | currency}}</b></span>
			</div>
			<div class="multiple-banner" *ngIf="mode == 'tile' && product.multiple > 1">
				This product is only sold in multiples of {{product.multiple}}.
			</div>

			<div class="add-to-cart" [permission]="Permissions.ShopAccess" *ngIf="(product.isDiscontinued != 1 && product.returnCode != -10)   ||   (product.returnCode == -10) ">

				<div class="quantity" *ngIf="product.returnCode != 10">
					<app-product-quantity [multiple]="product.multiple" [(quantity)]="quantity"></app-product-quantity>
				</div>

				<div>
					<div class="button" (click)="addToCart()">
						<i class="pi pi-cart"></i>Add to Cart
					</div>
				</div>

			</div>

			<div class="quantity-only">
				{{cartItem?.quantity}}
			</div>
			<div class="delete" *ngIf="cartItem"><div (click)="deleteItem(cartItem.cartItemId)"><mat-icon>delete_outline</mat-icon></div></div>
		</div>

</ng-container>
