import { Image } from "./image";

export class Event {
	public title: string;
	public description: string;
	public startDateTime: Date;
	public endDateTime: Date;
	public author: string;
	public eventType: string;
	public image: any;
	public isAllDayEvent: boolean;
	public id: number;

	constructor(o?: any) {
		let imageData = null;
		if(o.image) {
			imageData = new Image(o.image);
		}
		o.image = imageData;
		Object.assign(this, o);
	}
}