import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { selectDashboardHeaderButtons, selectDashboardTitle } from "src/app/store/selectors/site.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-dashboard-template",
	templateUrl: "./dashboard-template.component.html",
	styleUrls: ["./dashboard-template.component.scss"],
})
export class DashboardTemplateComponent extends AutoUnsubscribeMixin() implements OnInit {
	public title: string;
	public buttons: Array<DashboardHeaderButton> = [];
	constructor(private store: Store<IAppState>) {
		super();
	}

	ngOnInit(): void {
		this.store.pipe(select(selectDashboardTitle), takeUntil(this.destroy$)).subscribe((t) => (this.title = t));
		this.store.pipe(select(selectDashboardHeaderButtons), takeUntil(this.destroy$)).subscribe(b => this.buttons = b);
	}
	do(callback) {
		callback();
	}
}
