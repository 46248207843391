import { Action } from '@ngrx/store';
import { AddToCartResult, Cart, CartItemAlert } from 'src/app/models/shopping/cart';
import { Bundle } from 'src/app/models/shopping/product';
import { AppliedSpecial } from 'src/app/models/shopping/specials';

export enum CartActionTypes {

	GET_CART = '[CART] Get Cart',
	GET_CART_SUCCESS = '[CART] Get Cart Success',

	GET_CART_SPECIALS = '[CART] Get Cart Specials',
	GET_CART_SPECIALS_SUCCESS = '[CART] Get Cart Specials Success',

	UPDATE_CART = '[CART] Update Cart',
	ADD_SPECIAL_TO_CART_ITEM = '[CART] Add Special To Item',

	OPEN_CART = '[CART] Open Cart',
	CLOSE_CART = '[CART] Close Cart',

	ADD_TO_CART = '[CART] Add',
	ADD_TO_CART_SUCCESS = '[CART] Add Success',
	EDIT_CART_ITEM = '[CART] Edit Cart Item',
	DELETE_FROM_CART = '[CART] Delete',
	ADD_MULTIPLE_TO_CART = '[CART] Add Multiple',
	ADD_MULTIPLE_TO_CART_SUCCESS = '[CART] Add Multiple Success',
	ADD_BUNDLE_TO_CART = '[CART] Add Bundle',
	ADD_BUNDLE_TO_CART_SUCCESS = '[CART] Add Bundle Success',
	EDIT_CART_BUNDLE = '[CART] Edit Bundle',
	ITEM_DELETED_FROM_CART = '[CART] Item Deleted',
	GET_DROP_SHIP_COST = '[CART] Get Drop Ship Cost',
	GET_DROP_SHIP_COST_SUCCESS = '[CART] Get Drop Ship Cost Success',
	GET_MIXMATCH_INVALIDS = '[CART] Get Mix Match Invalids',
	SET_CARTITEM_ALERTS = '[CART] Set CartItem Alert',
	SET_CARTITEM_ALERTS_SUCCESS = '[CART] Set CartItem Alert Success',

	SORT_CART_ITEMS = '[CART] Sort Cart Items',
	SORT_CART_ITEMS_SUCCESS = '[CART] Sort Cart Items Success',

	CLEAR_CART = '[CART] Clear Cart',
	CLEAR_CART_SUCCESS = '[CART] Clear Cart Success',

}

export class GetCart implements Action {
	public readonly type = CartActionTypes.GET_CART;
	constructor() {}
}

export class GetCartSuccess implements Action {
	public readonly type = CartActionTypes.GET_CART_SUCCESS;
	constructor(public cart: Cart) {}
}

export class GetCartSpecials implements Action {
	public readonly type = CartActionTypes.GET_CART_SPECIALS;
	constructor() {}
}

export class GetCartSpecialsSuccess implements Action {
	public readonly type = CartActionTypes.GET_CART_SPECIALS_SUCCESS;
	constructor(public appliedSpecials: Array<AppliedSpecial>) {}
}

export class UpdateCart implements Action {
	public readonly type = CartActionTypes.UPDATE_CART;
	constructor(public cart: Cart) {}
}

export class AddSpecialToItem implements Action {
	public readonly type = CartActionTypes.ADD_SPECIAL_TO_CART_ITEM;
	constructor(public special: AppliedSpecial) {}
}

export class OpenCart implements Action {
	public readonly type = CartActionTypes.OPEN_CART;
	constructor() {}
}
export class CloseCart implements Action {
	public readonly type = CartActionTypes.CLOSE_CART;
	constructor() {}
}

export class AddToCart implements Action {
	public readonly type = CartActionTypes.ADD_TO_CART;
	constructor(public productCode: string, public quantity: number) {}
}
export class AddToCartSuccess implements Action {
	public readonly type = CartActionTypes.ADD_TO_CART_SUCCESS;
	constructor(public result: AddToCartResult) {}
}

export class AddMultipleToCart implements Action {
	public readonly type = CartActionTypes.ADD_MULTIPLE_TO_CART;
	constructor(public items: Array<{productCode: string, quantity: number }>) {}
}
export class AddMultipleToCartSuccess implements Action {
	public readonly type = CartActionTypes.ADD_MULTIPLE_TO_CART_SUCCESS;
	constructor() {}
}

export class AddBundleToCart implements Action {
	public readonly type = CartActionTypes.ADD_BUNDLE_TO_CART;
	constructor(public bundleCode: string, public quantity: number) {}
}
export class AddBundleToCartSuccess implements Action {
	public readonly type: CartActionTypes.ADD_BUNDLE_TO_CART_SUCCESS;
	constructor(public result: AddToCartResult) {}
}
export class EditCartBundle implements Action {
	public readonly type = CartActionTypes.EDIT_CART_BUNDLE;
	constructor(public cartBundleId: number, public quantity: number) {}
}
export class EditCartItem implements Action {
	public readonly type = CartActionTypes.EDIT_CART_ITEM;
	constructor(public cartItemId: number, public productCode: string , public quantity: number, public rejectUpsell: boolean) {}
}
export class DeleteFromCart implements Action {
	public readonly type = CartActionTypes.DELETE_FROM_CART;
	constructor(public productCode: string) {}
}
export class ItemDeletedFromCart implements Action {
	public readonly type = CartActionTypes.ITEM_DELETED_FROM_CART;
	constructor(public productCode: string, public quantity: number) {}
}
export class SortCartItems implements Action {
	public readonly type = CartActionTypes.SORT_CART_ITEMS;
	constructor(public sortField: string) {}
}
export class SortCartItemsSuccess implements Action {
	public readonly type = CartActionTypes.SORT_CART_ITEMS_SUCCESS;
	constructor() {}
}
export class ClearCart implements Action {
	public readonly type = CartActionTypes.CLEAR_CART;
	constructor() {}
}
export class ClearCartSuccess implements Action {
	public readonly type = CartActionTypes.CLEAR_CART_SUCCESS;
	constructor() {}
}
export class GetDropShipCost implements Action {
	public readonly type = CartActionTypes.GET_DROP_SHIP_COST;
	constructor() {}
}
export class GetDropShipCostSuccess implements Action {
	public readonly type = CartActionTypes.GET_DROP_SHIP_COST_SUCCESS;
	constructor(public dropShipCost: number) {}
}
export class SetCartItemAlerts implements Action {
	public readonly type = CartActionTypes.SET_CARTITEM_ALERTS;
	constructor(public alerts: {[key: number]: CartItemAlert}) {}
}
export class SetCartItemAlertsSuccess implements Action {
	public readonly type = CartActionTypes.SET_CARTITEM_ALERTS_SUCCESS;
	constructor(public alerts: {[key: number]: CartItemAlert}) {}
}
export class GetMixMatchInvalids implements Action {
	public readonly type = CartActionTypes.GET_MIXMATCH_INVALIDS;
	constructor() {}
}

export type CartActions = 
	GetCart |
	GetCartSuccess |
	GetCartSpecials |
	GetCartSpecialsSuccess |
	UpdateCart |
	AddSpecialToItem |
	OpenCart | 
	CloseCart |
	AddToCart | 
	AddToCartSuccess |
	AddMultipleToCart | 
	AddMultipleToCartSuccess |
	AddBundleToCart | 
	AddBundleToCartSuccess |
	EditCartItem | 
	EditCartBundle |
	DeleteFromCart |
	ItemDeletedFromCart |
	SortCartItems |
	SortCartItemsSuccess |
	GetDropShipCost | 
	GetDropShipCostSuccess |
	ClearCart |
	GetMixMatchInvalids |
	ClearCartSuccess |
	SetCartItemAlerts
	;
