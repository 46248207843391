import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { CartItem } from "src/app/models/shopping/cart";
import { EditCartItem } from "src/app/store/actions/cart.actions";
import { IAppState } from "src/app/store/state/app.state";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-cart-tile-item",
	templateUrl: "./cart-tile-item.component.html",
	styleUrls: ["./cart-tile-item.component.scss"],
})
export class CartTileItemComponent implements OnInit, OnChanges {
	@Input() cartItem: CartItem;
	public quantity: number;
	public environment = environment;
	constructor(private store$:Store<IAppState>) {}

	ngOnInit(): void {
		this.quantity = this.cartItem.quantity;
	}
	ngOnChanges(changes: SimpleChanges): void {
		if(changes.cartItem && changes.cartItem.currentValue) {
			this.cartItem = changes.cartItem.currentValue;
		}
	}
	updateItemQuantity() {
		this.store$.dispatch(new EditCartItem(this.cartItem.cartItemId, this.cartItem.product.productCode, this.quantity, this.cartItem.rejectUpsell));
	}
	remove() {
		this.store$.dispatch(new EditCartItem(this.cartItem.cartItemId, this.cartItem.product.productCode, 0, this.cartItem.rejectUpsell));
	}
}
