import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Product, ProductFile, TechSpec } from "src/app/models/shopping/product";
import { Special } from "src/app/models/shopping/specials";
import { MixMatchComponent } from "src/app/modules/controls/components/mix-match/mix-match.component";
import { SpecialViewerComponent } from "src/app/modules/controls/components/special-viewer/special-viewer.component";
import { SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-product-details",
	templateUrl: "./product-details.component.html",
	styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit, OnChanges {
	@Input() product: Product;
	@Input() techSpecs: Array<TechSpec>;
  @Input() quantity: number;
	@Input() specials: Array<Special>;
	@Input() productFiles: Array<ProductFile>;

	@Output() onAddToCart: EventEmitter<number> = new EventEmitter();

	private dialogRef: DynamicDialogRef;
	public productFileBuckets: Array<{bucketName: string, files: Array<ProductFile>}> = [];
  public tabToShow: string = 'overview';


  
	constructor(private store: Store<IAppState>, private dialogService: DialogService) {}

  ngOnInit(): void {

  
		this.quantity = this.product.multiple || 1;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.productFiles?.currentValue) {
			let files = changes.productFiles.currentValue;
			files.forEach(f => {
				if(!this.productFileBuckets.some(b => b.bucketName == f.bucket)) {
					this.productFileBuckets.push({bucketName: f.bucket, files: []})
				}
				this.productFileBuckets.find(b => b.bucketName == f.bucket).files.push({ ...f, link: f.link.replace('http://', 'https://') })
			})
		}
		if(changes.product?.previousValue) {
			//changing to a new product, reset quantity
			this.quantity = this.product.multiple || 1;
		}
	}

	addToCart() {
		if(this.product.returnCode == 10) {
			//mix/match product
			let ref = this.dialogService.open(MixMatchComponent, {
				data: { mixMatchId: this.product.data.mixMatchId}, 
				header: 'Mix and Match'
			});
			return;
		}
		this.onAddToCart.emit(this.quantity);
	}
	notifyCopy(payload) {
		this.store.dispatch(new SuccessMessageSet(`Copied ${payload} to clipboard`));
	}
	loadSpecial(special) {
		this.dialogRef = this.dialogService.open(SpecialViewerComponent, {
			data: { specialId: special.specialId },
			showHeader: false,
			baseZIndex: 100,
		})
	}
}
