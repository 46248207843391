import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "phone" })
export class PhonePipe implements PipeTransform {
	constructor() {}
	transform(str: string) {
		if (str.trim() == "") {
			return "";
		}
		str = str.replace(/[^0-9*]/g, "");
		return `(${str.substring(0, 3)}) ${str.substring(3, 6)}-${str.substring(6)}`;
	}
}
