
<div class="sar-request">
	<header>
		<h1>Create New Claim</h1>
	</header>
	<main>
		<form [formGroup]="form" *ngIf="form">
			<section class="contact">
			
				<h2>Contact Information</h2>
				<div class="field">
					<label>Account</label>
					<div class="readonly">{{sarClaim.accountNumber}}</div>
				</div>
				<div class="field">
					<label>Reference Number</label>
					<input pInputText [formControlName]="'referenceNumber'">
				</div>
				<div class="field">
					<label>Contact First Name</label>
					<input pInputText [formControlName]="'firstName'">
				</div>
				<div class="field">
					<label>Contact Last Name</label>
					<input pInputText [formControlName]="'lastName'">
				</div>
				<div class="field">
					<label>Email</label>
					<input pInputText [formControlName]="'email'">
				</div>
				<div class="field">
					<label>Contact Phone</label>
					<input pInputText [formControlName]="'phone'">
				</div>
			
			</section>
			<section class="product" [formArrayName]="sarProducts">
				<ng-container *ngFor="let prodForm of sarProducts.controls; let i = index" [formGroup]="prodForm">
					<div class="field">
						<label>Product Code</label>
						<app-product-code-search (onSelect)="selectProduct($event,i)" [touched]="prodForm.get('productCode').touched" [valid]="prodForm.get('productCode').valid"></app-product-code-search>
					</div>
					<div class="field productName">
						<label>Product Name</label>
						<span>{{prodForm.get('productName').value}}</span>
					</div>
					<div class="field">
						<label>Quantity</label>
						<input pInputText type="number" [formControlName]="'quantity'" [pattern]="'[0-9]*'">
					</div>
					<div class="field">
						<label>Return Type</label>
						<p-dropdown [options]="returnTypeOptions" [formControlName]="'returnType'"></p-dropdown>
					</div>
					<div class="field description">
						<label>Description</label>
						<textarea pInputTextarea [formControlName]="'description'"></textarea>
					</div>
					<div class="actions">
						<div class="button delete" (click)="deleteSarProduct(i)"><mat-icon>clear</mat-icon>Delete</div>
						<div class="button" (click)="addNewSarProduct()"><mat-icon>add</mat-icon>Add New</div>
					</div>
				</ng-container>

			</section>
			<div class="actions">
				<div class="button close" (click)="close()">Close</div>
				<div class="button submit" (click)="submit()">Submit Claim</div>
			</div>
		</form>
	</main>
</div>
