<div class="container">
	<h1>Checkout</h1>
	<div class="contents">
		<div class="thankyou">
			<h2>Thank you for your order</h2> 
			<p>Order No #<span>{{orderNumber}}</span></p>
			<p>We'll email you an order confirmation with details and tracking info</p>
			<div class="print" (click)="print()">
				<mat-icon>printer</mat-icon>
				<span>Print receipt</span>
			</div>
		</div>
		<div class="hero"></div>
		<div class="info">
			<div class="next-steps">
				<h1>Your purchase</h1>
				<p>You have successfuly placed the orders. You can check status of your orders by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.</p>
				<div class="actions">
					<div class="button round-button filled" (click)="goTo('shopping')">Continue Shopping</div>
				</div>
			</div>
			<div class="contacts">
				<h2>Primary contacts for any questions</h2>
			</div>
		</div>
	</div>
</div>