import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { TableColumn, TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { Permissions } from "src/app/modules/controls/enums/permissions";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardHeaderButtons, SetDashboardTitle, ShowDownloadDialog } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
  selector: 'app-instant-rebates',
  templateUrl: './instant-rebates.component.html',
  styleUrls: ['./instant-rebates.component.scss']
})
export class InstantRebatesComponent implements OnInit {
	public openTab: 'IR' | 'PP' = "IR";
	public allData: { [key: string]: Array<any> } = {};
	public data: Array<any>;
	public tableInfo: TableInfo;
	public columnWidths: string;
	public pageSize: number = 20;
	public pageNumber: number = 1;
	public pageCount: number;
	public searchTerm: string;
	
	private permissions: Permissions;

	constructor(private store: Store<IAppState>, private dataService: DataService, private route: ActivatedRoute, private authService: AuthService) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle("Submitted Rebates"));
		let buttons: Array<DashboardHeaderButton> = [
			//new DashboardHeaderButton({ text: "Search", cssClass: "search-button", icon: "search", callback: () => {} }), 
			new DashboardHeaderButton({ text: "Download", icon: "download", callback: () => this.showDownloadOptions() })];
		this.store.dispatch(new SetDashboardHeaderButtons(buttons));

		this.route.paramMap.subscribe(param => {
			if(param.get('term')) {
				this.searchTerm = param.get('term');
				this.search();
			} else {
				this.loadTabData();
			}
		})
	}

	loadTabData() {
		if(!this.allData[this.openTab]) {
			let apiCall: Observable<any>;
			switch(this.openTab) {
				case 'IR': 
					apiCall = this.dataService.getRebateHistory(); break;
				case 'PP':
					apiCall = this.dataService.getPriceProtectionHistory(); break;
			}
			apiCall.subscribe(r => {
				this.allData[this.openTab] = r;
				this.setData();
			});
		} else {
			this.setData();
		}
		this.tableInfo = this.allTableInfo[this.openTab];
		this.columnWidths = this.tableInfo.columns.reduce((a,c) => `${a} ${c.width || '1fr'}`, '')

	}

	setData() {
		let first = (this.pageNumber - 1) * this.pageSize;
		let last = first + this.pageSize;
		this.data = this.allData[this.openTab].slice(first, last);
		this.pageCount = Math.ceil(this.allData[this.openTab].length / this.pageSize)
	}

	setTab(tab) {
		this.pageNumber = 1;
		this.openTab = tab;
		this.loadTabData();
	}

	showDownloadOptions() {
		this.store.dispatch(new ShowDownloadDialog(this.allData[this.openTab], this.tableInfo[this.openTab], []));
	}

	goToPage(page) {
		this.pageNumber = page;
		this.setData();
	}

	search() {
		this.openTab = 'IR';
		this.dataService.searchRebates(this.searchTerm).subscribe((d: any) => {
			this.allData[this.openTab] = d;
			this.setData();
		})
	}
	clearSearch() {
		this.searchTerm = '';
		this.allData[this.openTab] = null;
		this.loadTabData();
	}
	downloadInvoice(row, caller) {
		const id = row.link.replace('https://members.promaster.com/Invoices/', '').replace('.pdf', '')
		caller.dataService.downloadInvoice(id).subscribe((resp:any) => {
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = "Invoice"+id;
            a.href = URL.createObjectURL(resp);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);

		}, err => {
			switch(err.error.size) {
				case 30:// can't find file
					caller.store.dispatch(new ErrorMessageSet("The invoice was not found"));
					break;
				case 32: //not your invoice
					caller.store.dispatch(new ErrorMessageSet("The invoice number was not valid"));
					break;
			}
		})
	}
	printRebate(row, caller) {
		const id = row.orderID;
		window.open('/rebate/print/'+id);
	}
	printPP(row, caller) {
		const id = row.orderID;
		window.open('/price-protection/print/'+id);
	}

	get allTableInfo() {
		let data = {
			IR: new TableInfo({
				columns: [
					{ title: 'Order ID', field: 'orderID', type: 'number', width: '1fr', hideAtBp: 3 },
					{ title: 'Date Submitted', field: 'submitDate', type: 'date', format: 'MM/dd/yyyy', width: '2fr', hideAtBp: 2 },
					{ title: 'Your Debit #', field: 'debit', width: '2fr' },
					{ title: 'Program Week', field: 'programWeek', width: '2fr' },
					{ title: 'Total Amount', field: 'total', type: 'currency', width: '2fr' },
					{ title: 'Status', field: 'status', width: '2fr', cssClass: 'capitalize', type:'status' },
					{ title: 'Reject Reason', field: 'rejectReason', width: '2fr', hideAtBp: 2 },
					{ title: 'Vendor', field: 'vendor', width: '2fr', hideAtBp: 1 },
					{ title: 'Credit', text: 'View', showIf: {field: 'link'}, type: 'action', callback: this.downloadInvoice, hideAtBp: 1 },
					{ title: 'Print', text: 'Print', type: 'action', callback: this.printRebate },
				],
				hasSubtable: true,
				service: this.dataService,
				dataMethod: 'getRebateHistoryDetails',
				rowKey: 'orderID',
				subtableInfo: new TableInfo({
					columns: [
						{ title: 'Model', field: 'model' },
						{ title: 'Quantity', field: 'quantity' },
						{ title: 'Reimbursement Amount', field: 'reimbursementAmount', type: 'currency' },
						{ title: 'Net', field: 'net', type: 'currency' },
					]
				})
			}),
			PP: new TableInfo({
				columns: [
					{ title: 'Order ID', field: 'orderID', type: 'number', width: '1fr', hideAtBp: 3 },
					{ title: 'Date Submitted', field: 'submitDate', type: 'date', format: 'MM/dd/yyyy', width: '2fr', hideAtBp: 2 },
					{ title: 'Your Debit #', field: 'debit', width: '2fr' },
					{ title: 'Program Week', field: 'programWeek', width: '2fr' },
					{ title: 'Total Amount', field: 'total', type: 'currency', width: '2fr' },
					{ title: 'Status', field: 'status', width: '2fr', cssClass: 'capitalize', type:'status' },
					{ title: 'Reject Reason', field: 'rejectReason', width: '2fr', hideAtBp: 2 },
					{ title: 'Vendor', field: 'vendor', width: '2fr', hideAtBp: 1 },
					{ title: 'Print', text: 'Print', type: 'action', callback: this.printPP },
				],	
			}),		
		}
		if((this.authService.user.permissions & Permissions.InvoiceAccess) == 0) {
			data.IR.columns = data.IR.columns.filter(c => c.title != 'Credit');
		}
		return data;
	}
}

