import { Component, OnInit, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { CartBundle } from "src/app/models/shopping/cart";
import { EditCartBundle } from "src/app/store/actions/cart.actions";
import { IAppState } from "src/app/store/state/app.state";
import { Permissions } from "../../enums/permissions";

@Component({
	selector: "app-bundle-tile",
	templateUrl: "./bundle-tile.component.html",
	styleUrls: ["./bundle-tile.component.scss"],
})
export class BundleTileComponent implements OnInit {
	@Input() cartBundle: CartBundle;
	@Input() mode: string;
	public quantity: number;

	public Permissions = Permissions;
	constructor(private store: Store<IAppState>) {}

	ngOnInit(): void {
		this.quantity = this.cartBundle.quantity;
	}

	setQuantity() {
		this.store.dispatch(new EditCartBundle(this.cartBundle.cartBundleId, this.quantity))
	}
	increment(n) {
		this.quantity += n;
		this.setQuantity();
	}
	deleteItem() {
		this.quantity = 0;
		this.setQuantity();
	}
}
