<div *ngIf="categories">
	<ul>
		<li *ngFor="let cat of categories">
			<a [routerLink]="['/shop', 'category', cat.catId]">{{cat.catName}}</a><br/>
		</li>
	</ul>
</div>

<div *ngIf="products">
	<div *ngFor="let product of products">
		<app-product-tile [product]="product"></app-product-tile>
	</div>
	<div *ngIf="products.length == 0">There are no products in this category</div>
</div>