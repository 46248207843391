<div class="product-holder" *ngIf="product">
	
	<nav>
		<ul>
			<li (click)="tabToShow = 'overview'" [ngClass]="{active: tabToShow == 'overview'}">Overview</li>
			<li (click)="tabToShow = 'features'" [ngClass]="{active: tabToShow == 'features'}">Features</li>
			<li (click)="tabToShow = 'specifications'" [ngClass]="{active: tabToShow == 'specifications'}">Specifications</li>
			<li (click)="tabToShow = 'files'" [ngClass]="{active: tabToShow == 'files'}">Files</li>
			<li (click)="tabToShow = 'techspecs'" [ngClass]="{active: tabToShow == 'techspecs'}">Tech Specs</li>
		</ul>
	</nav>

	<main>
		<div class="pane overview" [ngClass]="{active: tabToShow == 'overview'}" *ngIf="tabToShow == 'overview'">

			<aside *ngIf="(product.isDiscontinued != 1 && product.returnCode != -10)   ||   (product.returnCode == -10) ">

				<div class="stock in" *ngIf="product.inventory > 0  && product.inventory < 99999 "><i class="check"></i>In Stock</div>
				<div class="stock in" *ngIf="product.inventory > 99998 "><i class="check"></i>Vendor Direct Ship</div>
				<div *ngIf="!product.specOrder">
					<div class="stock out" *ngIf="product.inventory <= 0">Out of Stock</div>
				</div>

				<div class="stock in" *ngIf="product.outOfStockMessage"> {{product.outOfStockMessage}} </div>
				<!-- Why is this not showing up ??  <div class="stock due" *ngIf="product.stockDueDate"> Due: {{product.stockDueDate | date: 'MMM dd, yyyy'}}</div>-->
				<div class="stock low" *ngIf="product.inventory > 0 && product.inventory <= 50">&nbsp;&nbsp;< 50 Remain </div>
				<div class="stock low" *ngIf="product.inventory > 50 && product.inventory <= 100">&nbsp;&nbsp;< 100 Remain </div>
				<div class="inventory-message">{{product.inventoryMessage}}</div>

				<div class="chips">
					<div class="rebate" *ngIf="product.rebate !== 'NO'">Quarterly Rebate</div>
					<div class="multiples" *ngIf="product.specialPriceQuantity > 1">Multiples</div>
					<div class="special" *ngIf="product.hasSpecial">Special</div>
					<div class="new-product" *ngIf="product.isNew">New</div>
					<div class="special-order" *ngIf="product.specOrder">Special Order</div>

					<!--IR Special < 50 remian and < 100 remain needed.-->
				</div>

			</aside>
			<h1>{{product.modelName}}</h1>
			<ul class="codes">
				<li class="brand-link"><a [routerLink]="['/warehouse', 'brand', product.brandId]">{{product.brandName}}</a></li>
				<li class="vendor-number">Vendor#: {{product.caT_NO}}</li>
				<li class="upc">UPC: {{product.upc}}</li>
			</ul>
			<div class="product-code"><span>Product Code:</span>&nbsp;
				<span>{{product.productCode}}</span>
				<span [copy-clipboard]="product.productCode" (copied)="notifyCopy($event)"><i class="pi pi-copy"></i></span>
			</div>
			<div class="costs">
				<div class="price" *ngIf="product.retail"><span>PRICE: </span><span class="price retail-value">{{product.retail | currency}}</span></div>				
				<div class="map" *ngIf="product.map"><span>MAP: </span><span class="price map-value">{{product.map | currency}}</span></div>
				<div class="cust-retail" *ngIf="product.cusT_RETAIL"><span>RETAIL: </span><span class="price cust-retail-value">{{product.cusT_RETAIL | currency}}</span></div>				
			</div>
			<div class="info multiple" *ngIf="product.multiple > 1">
				This item is sold in multiples of {{product.multiple}}
			</div>

			<div class="info special" *ngIf="specials.length > 0 &&  (product.isDiscontinued != 1 && product.returnCode != -10)    ||  (specials.length > 0  && product.returnCode == -10)">
				<div *ngIf="specials.length > 1" (click)="specialsOverlay.toggle($event)">{{specials.length}} specials</div>
				<div *ngIf="specials.length == 1" (click)="loadSpecial(specials[0])">{{specials[0].title}}</div>
				<p-overlayPanel #specialsOverlay [showCloseIcon]="false">
					<ng-template pTemplate="header">
						Select a special below to view the details
					</ng-template>
					<ng-template pTemplate="body">
						<div class="special-list" (click)="specialsOverlay.hide()">
							<div class="special-item" *ngFor="let special of specials" (click)="loadSpecial(special)">{{special.title}}</div>
						</div>
					</ng-template>
				</p-overlayPanel>
			</div>


			<div class="info offer" *ngIf="product.specialPriceQuantity > 1">					
				This product is eligible for discounted pricing of {{product.cartonPricingRetail | currency}} when ordered in quantities of  {{product.specialPriceQuantity}}.
			</div>

			<div class="purchase" *ngIf="(product.isDiscontinued != 1 && product.returnCode != -10)   ||   (product.returnCode == -10)">
				<app-product-quantity [multiple]="product.multiple" [(quantity)]="quantity" *ngIf="product.returnCode != 10"></app-product-quantity>
				<div class="add-to-cart" (click)="addToCart();"><mat-icon>shopping_cart</mat-icon>Add to Cart</div>				
			</div>

			<div class="dropship-fee" *ngIf="(product.isDiscontinued != 1 && product.returnCode != -10)   ||   (product.returnCode == -10) ">
				<mat-icon>local_shipping</mat-icon>Drop Ship Fee: <span>&nbsp;{{product.dS_CHG | currency }} </span>
			</div>

			<div class="dropship-fee" *ngIf="product.ltlCost">
				<mat-icon>local_shipping</mat-icon>LTL Ship Fee: <span>&nbsp;{{product.ltlCost | currency }} </span>
			</div>
	
		</div>
		<div class="pane specifications" [ngClass]="{active: tabToShow == 'specifications'}" *ngIf="tabToShow == 'specifications'">
			<div [innerHtml]="product.specs" *ngIf="product.specs"></div>
			<div class="no-data" *ngIf="!product.specs"><mat-icon>new_releases</mat-icon>&nbsp;There are no specifications yet for this item.</div>
		</div>
		<div class="pane features" [ngClass]="{active: tabToShow == 'features'}" *ngIf="tabToShow == 'features'">
			<div [innerHtml]="product.features" *ngIf="product.features"></div>
			<div class="no-data" *ngIf="!product.features"><mat-icon>new_releases</mat-icon>&nbsp;There are no features yet for this item.</div>
		</div>

		<div class="pane files" [ngClass]="{active: tabToShow == 'files'}" *ngIf="tabToShow == 'files'">
			<div class="bucket" *ngFor="let bucket of productFileBuckets">

				<h5>{{bucket.bucketName}}</h5>

				<ul>
					<li *ngFor="let file of bucket.files"><a href="{{file.link}}" target="_blank">{{file.displayName}} </a></li>
				</ul>

			</div>

			<div class="no-data" *ngIf="productFiles?.length == 0"><mat-icon>new_releases</mat-icon>&nbsp;There are no files yet for this item.</div>

		</div>
		<div class="pane techspecs" [ngClass]="{active: tabToShow == 'techspecs'}" *ngIf="tabToShow == 'techspecs'">
			<ng-container *ngIf="techSpecs?.length > 0; else noTechSpecs">
				<div class="section" *ngFor="let section of techSpecs">
					<h4>{{section.sectionName}}</h4>
					<div class="attribute-value" *ngFor="let attribute of section.attributes">
						<div class="attribute">{{attribute.attributeName}}</div>
						<div class="value">{{attribute.value}}</div>
					</div>
				</div>
			</ng-container>

			<ng-template #noTechSpecs>
				<div class="no-data"><mat-icon>new_releases</mat-icon>&nbsp;There are no tech specs yet for this item.</div>
			</ng-template>
		</div>
	</main>

	

</div>
