
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable() 
export class PermissionGuard  {

	constructor(private router: Router, private authService: AuthService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const permission = route.data.permission;

    const userPermissions = this.authService.user.permissions;

		if((userPermissions & permission) > 0) {
			return true
		} else {
			this.router.navigate(['/no-access'])
			return false;
		}		
	}
}




