

<div class="reports">
  <header>
    <h1>Discontinued Products</h1>
  </header>
  <main>
    <div class="row header">
      <div class="dba">Product #</div>
      <div class="dba">Catalog #</div>
      <div class="name">Description</div>
      <div class="name">Brand</div>
      <div class="address">Date Discontinued</div>
    </div>
    <div class="row" *ngFor="let discontinued of Discon">
      <div class="dba">{{discontinued.productcode}}</div>
      <div class="dba">{{discontinued.caT_NO}}</div>
      <div class="name">{{discontinued.desc_c}}</div>
      <div class="name">{{discontinued.brandName}}</div>
      <div class="address">{{discontinued.datediscontinued | date: 'MMM dd yyyy'}} </div>
    </div>
  </main>
</div>
