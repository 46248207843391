<div class="container">
  <app-headtohead-header></app-headtohead-header>
  <p></p>
  <div>
    <div class="wrapper">

      <ng-container *ngFor="let posting of headtohead   let l = count">

        <!--
      ID:  {{posting.id}}
      Title: {{posting.attributes.Title}}
      Link: {{posting.attributes.Document.data[0].attributes.url}}
      -->


        <div class="Header">
          <a href="{{posting.attributes.Document.data[0].attributes.url}}" target="_blank">
            {{posting.attributes.Title}}
          </a>
          <a href="{{posting.attributes.Document.data[0].attributes.url}}" target="_blank">
            <div class="box">

              <pdf-viewer [src]="posting.attributes.Document.data[0].attributes.url"  [original-size]="false"    style="display: block; min-height: 250px" >  </pdf-viewer>

       
            </div>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>

