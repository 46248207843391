import { Directive, Input, Output, EventEmitter, HostListener, ElementRef, OnInit, ViewContainerRef, AfterViewInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Directive({ selector: "[permission]" })
export class PermissionDirective implements AfterViewInit {
	@Input() permission: number;

	constructor(private el: ElementRef, private viewContainer: ViewContainerRef, private authService: AuthService) {
	}
	ngAfterViewInit(): void {
		const userPermissions = this.authService.user.permissions;
		if((userPermissions & this.permission) == 0) {
			//user does not have permission
			//setTimeout(() => this.viewContainer.clear(), 2000);
			this.el.nativeElement.remove();
		}
		
	}
}
