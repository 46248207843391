import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';
import { ProductCodeSearchComponent } from './components/product-code-search/product-code-search.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { VendorSearchComponent } from './components/vendor-search/vendor-search.component';
import { RecentForumPostsComponent } from './components/recent-forum-posts/recent-forum-posts.component';
import { BrandSearchComponent } from './components/brand-search/brand-search.component';
import { CategoryTreeComponent } from './components/category-tree/category-tree.component';
import { PagerComponent } from './components/pager/pager.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ProductTileComponent } from './components/product-tile/product-tile.component';
import { CopyClipboardDirective } from 'src/app/modules/controls/directives/copy-clipboard';
import { ProductQuantityComponent } from './components/product-quantity/product-quantity.component';
import { LandingArticleTileComponent } from './components/landing-article-tile/landing-article-tile.component';
import { MatIconDirective } from './directives/mat-icon.directive';
import { InstantRebateGroupsComponent } from './components/instant-rebate-groups/instant-rebate-groups.component';
import { LandingRebateTileComponent } from './components/landing-rebate-tile/landing-rebate-tile.component';
import { HideableDirective } from './directives/hideable';
import { ExportDialogComponent } from './components/export-dialog/export-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { TableComponent } from './components/table/table.component';
import { SpecialViewerComponent } from './components/special-viewer/special-viewer.component';
import { PermissionDirective } from './directives/permissions.directive';
import { OrderHistoryDetailsComponent } from './components/order-history-details/order-history-details.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
import { DropdownModule } from 'primeng/dropdown';
import { MixMatchComponent } from './components/mix-match/mix-match.component';
import { BundleTileComponent } from './components/bundle-tile/bundle-tile.component';
import { PhonePipe } from './pipes/phone.pipe';
import { PoSearchComponent } from './components/po-search/po-search.component';
import { HighlightTermPipe } from './pipes/highlight-term.pipe';


@NgModule({
  declarations: [
        ProductCodeSearchComponent,
		VendorSearchComponent,
		RecentForumPostsComponent,
		BrandSearchComponent,
		CategoryTreeComponent,
		PagerComponent,
		ProductTileComponent,
		CopyClipboardDirective,
		MatIconDirective,
		HideableDirective,
		PermissionDirective,
		ProductQuantityComponent,
		LandingArticleTileComponent,
		LandingRebateTileComponent,
		InstantRebateGroupsComponent,
		ExportDialogComponent,
		TableComponent,
		SpecialViewerComponent,
		OrderHistoryDetailsComponent,
        ProductSearchComponent,
        MixMatchComponent,
        BundleTileComponent,
        PhonePipe,
		HighlightTermPipe,
        PoSearchComponent,
  ],
  imports: [
		CommonModule,
		FormsModule,
		RouterModule,

		TableModule,
		ButtonModule,
		CheckboxModule,
		DropdownModule,
		TooltipModule,
		InputTextModule,
		DialogModule,

	],
	exports: [
		ProductCodeSearchComponent,
		VendorSearchComponent,
		RecentForumPostsComponent,
		BrandSearchComponent,
		CategoryTreeComponent,
		PagerComponent,
		ProductTileComponent,
		BundleTileComponent,
		ProductQuantityComponent,
		LandingArticleTileComponent,
		LandingRebateTileComponent,
		MatIconDirective,
		HideableDirective,
		CopyClipboardDirective,
		PermissionDirective,
		InstantRebateGroupsComponent,
		ExportDialogComponent,
		TableComponent,
		OrderHistoryDetailsComponent,
        ProductSearchComponent,
        PoSearchComponent,
		HighlightTermPipe,
	],
	providers: [CurrencyPipe]
})
export class ControlsModule { }
