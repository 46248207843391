import { Component, OnInit, ViewChild, ElementRef, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { filter, take, takeUntil } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { Address, ADDRESS_TYPE } from "src/app/models/account/address";
import { Cart, CartItem, CartItemAlert } from "src/app/models/shopping/cart";
import { MixMatchInvalid } from "src/app/models/shopping/mix-match";
import { OrderSubmission } from "src/app/models/shopping/order-submission";
import { ShippingCostInfo } from "src/app/models/shopping/shipping-cost-info";
import { DataService } from "src/app/services/data.service";
import { AddToCart, CartActionTypes, ClearCart, GetCart, GetDropShipCostSuccess, SetCartItemAlerts, SortCartItems } from "src/app/store/actions/cart.actions";
import { ErrorMessageSet, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { selectCart, selectCartItemAlerts } from "src/app/store/selectors/cart.selectors";
import { IAppState } from "src/app/store/state/app.state";


@Component({
	selector: "app-cart-page",
	templateUrl: "./cart-page.component.html",
	styleUrls: ["./cart-page.component.scss"],
})

export class CartPageComponent extends AutoUnsubscribeMixin() implements OnInit {

 
	@ViewChild("productCodeField") productCodeField: ElementRef;
  @ViewChild("quantityField") quantityField: ElementRef;

  public myvalue: string = '';
  public maskValue: string = "";

 

	public cart: Cart;
	public addresses: Array<Address>;
	public quickAddProductCode: string;
	public quickAddQuantity: number;
	private quickAddMultiple: number;
	public addressOptions: Array<{ label: string; value: number }>;
	public addressId: number;
	public poNumber: string;
	public signature: string;
	public dropShipAddress: Address = new Address();
	public dropShipShippingCharge: number;
	public shippingCostInfo: Array<ShippingCostInfo> = [];
	public LTLCost: number = 0;
	public orderTypeId: 1 | 5;
	public errorMessage: string = "";
	public showCartTypeDialog: boolean = false;
	public showAddressSelection: boolean = false;
	public showDropShipAddress: boolean = false;
  public containsSpecialOrderItems: boolean = false;
  public acknowledgement: boolean = false;
	public form: UntypedFormGroup;
	public modeInputChecked: boolean;
	public mode: string = "row";
	public sortField: string;


    public sortOptions = [
		{ label: "Product Code", value: "productCode" },
		{ label: "Product Name", value: "modelName" },
	];


  omit_special_char(event) {

    if (!this.form.get('companyName').value)
    {
      this.form.get('firstName').setValidators(Validators.required);
      this.form.get('firstName').updateValueAndValidity();

      this.form.get('lastName').setValidators(Validators.required);
      this.form.get('lastName').updateValueAndValidity();
    }
    else
    {
      this.form.get('firstName').setValidators(null);
      this.form.get('firstName').updateValueAndValidity();

      this.form.get('lastName').setValidators(null);
      this.form.get('lastName').updateValueAndValidity();
    }
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }



  public cities = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Canal Zone", value: "CZ" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District of Columbia", value: "DC" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Guam", value: "GU" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" }
    ];

	public mixMatchInvalids: {[key: number]: CartItemAlert} = {};

	private quickAdding: boolean = false;
    constructor(private dataService: DataService, private store: Store<IAppState>, private actions$: Actions, private router: Router, private fb: UntypedFormBuilder) {
    super();
	}

  ngOnInit(): void {


		this.actions$
			.pipe(
				filter((a) => a.type == CartActionTypes.GET_CART_SUCCESS),
				takeUntil(this.destroy$)
			)
			.subscribe((a) => {
				this.getShippingCosts();
			});


		this.store.pipe(select(selectCart)).subscribe((cart) => {
			this.cart = new Cart(cart);
        });

		this.actions$.pipe(
			filter(a => a.type == CartActionTypes.SET_CARTITEM_ALERTS_SUCCESS),
			takeUntil(this.destroy$),
		).subscribe((a:SetCartItemAlerts) => {
			this.mixMatchInvalids = a.alerts;
		})

		this.dataService.getAddresses(ADDRESS_TYPE.Shipping).subscribe((resp: any) => {
			this.addresses = resp;
			this.addressOptions = this.addresses.map((a) => ({ label: `${a.address1} ${a.city}, ${a.state} ${a.zip}`, value: a.addressId }));
		});

		this.getShippingCosts();


		this.actions$.pipe(filter((a) => a.type == CartActionTypes.GET_DROP_SHIP_COST_SUCCESS)).subscribe((a: GetDropShipCostSuccess) => (this.dropShipShippingCharge = a.dropShipCost));
  }


	getShippingCosts() {
		this.dataService.getShippingCost().subscribe((v) => (this.shippingCostInfo = v.map((i) => new ShippingCostInfo(i))));

		this.dataService.getShippingCost().subscribe((resp: any) => {
			const ShippingArray = resp.map(({ ltlCost }) => ltlCost);
			const result = ShippingArray.reduce(function (a, b) {
				return a + b;
			}, 0);
			
			this.LTLCost = result;
		});
	}
  goToCheckout() {
        //Run check here.
        //If this.containsSpecialOrderItems;
        //then we set flag to mandate check box.

        //This.containsSpecialOrderItems=true        
        //If both are ture, then we can allow order.
        //If no special items, then we can just move forward.

        //Call to determine of cart has special order item.
        this.dataService.determineSpecialOrder().subscribe((data: boolean) => { this.containsSpecialOrderItems = data });

		if(Object.keys(this.mixMatchInvalids).length > 0) return;
        this.showCartTypeDialog = true;
  }

	goShop() {
		this.router.navigate(["/warehouse", "new"]);
	}

	clearCart() {
		this.store.dispatch(new ClearCart());
	}
	getQuickAddProduct() {
		if (this.quickAddProductCode) {
			this.dataService.getProduct(this.quickAddProductCode).subscribe(
				(prod) => (this.quickAddMultiple = prod.product.multiple),
				(err) => {
					this.store.dispatch(new ErrorMessageSet("Invalid Product Code"));
					this.quickAddProductCode = "";
					this.productCodeField.nativeElement.focus();
				}
			);
		}
	}
	quickAdd() {
		if (this.quickAdding) {
			return;
		} //prevent double adding, specifically by clicking add button, causing the blur to fire also
		this.quickAdding = true;
		if (this.quickAddProductCode && this.quickAddQuantity) {
			if (this.quickAddQuantity % this.quickAddMultiple > 0) {
				this.store.dispatch(new ErrorMessageSet(`Product ${this.quickAddProductCode} is only available in multiples of ${this.quickAddMultiple}`));
				this.quickAddQuantity = undefined;
				this.quantityField.nativeElement.focus();
				this.quickAdding = false;
				return;
			}
			this.store.dispatch(new AddToCart(this.quickAddProductCode, this.quickAddQuantity));
			this.actions$
				.pipe(
					filter((a) => a.type == CartActionTypes.ADD_TO_CART_SUCCESS),
					take(1)
				)
				.subscribe((r: any) => {
					if (r.result.success) {
						this.store.dispatch(new SuccessMessageSet(`${this.quickAddProductCode} added`));
					} else {
						this.store.dispatch(new ErrorMessageSet(`${this.quickAddProductCode} is not a valid product`));
					}
					this.quickAddProductCode = null;
					this.quickAddQuantity = null;
					this.quickAdding = false;
				});
		}
  }

  setAck(e) {


    this.acknowledgement = e.target.checked

     if (this.acknowledgement) {
     const b = <HTMLElement>document.querySelector('#warningMessage');
      b.style.color = 'black'
    }

  }

  setOrderType(type) {
    if (this.containsSpecialOrderItems && !this.acknowledgement)
    {
     // alert("You must ack.")
     const b = <HTMLElement>document.querySelector('#warningMessage');
     b.style.color = 'red'
      return;
    }
		this.orderTypeId = type == "dropship" ? 5 : 1;
		this.showCartTypeDialog = false;
    if (this.orderTypeId == 1)
    {
			this.form = this.fb.group({
                poNumber: [this.poNumber, Validators.required],
				signature: [this.signature, Validators.required],
				addressId: [this.addressId, Validators.required],
			});
			if(this.addressOptions.length == 1) {
				this.form.get('addressId').setValue(this.addressOptions[0].value)
			}

			this.showAddressSelection = true;
    }
    else
    {
        this.form = this.fb.group({
        companyName: [this.dropShipAddress.companyName],
        firstName: [this.dropShipAddress.firstName, Validators.required],
        lastName: [this.dropShipAddress.lastName, Validators.required],
        address1: [this.dropShipAddress.address1, Validators.required],
        address2: [this.dropShipAddress.address2],
        city: [this.dropShipAddress.city, Validators.required],
        state: [this.dropShipAddress.state, Validators.required],
        zip: [this.dropShipAddress.zip, Validators.required],
        email: [this.dropShipAddress.email, Validators.required],
        phone: [this.dropShipAddress.phone, Validators.required],
				poNumber: [this.poNumber, Validators.required],
				signature: [this.signature, Validators.required],
      });


			this.showDropShipAddress = true;
		}
	}

	setMode() {
		this.mode = this.modeInputChecked ? "lite" : "row";
	}
	submitOrder() {
		this.errorMessage = "";
		this.form.markAllAsTouched();
		let { poNumber, signature, addressId, ...dropShipAddress } = this.form.value;
		if (this.form.valid) {
			const submission: OrderSubmission = new OrderSubmission({
				poNUmber: poNumber,
				signature: signature,
				shipToAddressId: addressId,
				dropShipAddress: dropShipAddress,
				orderTypeId: this.orderTypeId,
			});
			this.dataService.submitOrder(submission).subscribe(
				(resp: any) => {
					if (resp.success) {
						this.store.dispatch(new GetCart());
						this.router.navigate(["/shop", "thankyou"], { state: { orderId: resp.data.OrderId } });
          } else {
       
            this.errorMessage = "Your order could not be completed: " + resp.data.message;

      
            ////this.form.setControl('title', 'Duplicate');
            //this.form.get('poNumber').setValidators(Validators.required);
            //this.form.get('poNumber').updateValueAndValidity();

            this.form.controls['poNumber'].setErrors({ 'incorrect': true });

        //    this.controls['poNumber'].invalid

            alert(this.errorMessage)
					}
				},
				(err) => {
					this.errorMessage = "There was an error trying to submit your order.   Please contact ProMaster.";
				}
			);
		}
	}
	sortCart(e) {
		this.sortField = e.value;
		this.store.dispatch(new SortCartItems(this.sortField));
  }



	get mixMatchInvalid() {
		return Object.keys(this.mixMatchInvalids).length > 0;
  }





}
