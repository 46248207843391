<h1 *ngIf="brand">{{brand.brandName}}</h1>

<div *ngIf="brands">
	<ul>
		<li *ngFor="let brand of brands">
			<a [routerLink]="['/shop', 'brand', brand.brandId]">{{brand.brandName}}</a><br/>
		</li>
	</ul>
</div>

<div *ngIf="products">
	<div *ngFor="let product of products">
		<app-product-tile [product]="product"></app-product-tile>
	</div>
	<div *ngIf="products.length == 0">There are no products for this brand</div>
</div>