import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VendorShort } from 'src/app/models/vendor/vendor';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-vendor-search',
  templateUrl: './vendor-search.component.html',
  styleUrls: ['./vendor-search.component.scss']
})
export class VendorSearchComponent implements OnInit {

	@Output() onSelect: Subject<VendorShort> = new Subject();
	@Input() placeholder: string = '';
	public name: string;
	public vendors: Array<VendorShort>;

	private cancelSearch: Subject<any> = new Subject();
	constructor(private dataService: DataService) {}

	ngOnInit(): void {}

	search() {
		if (this.name.length > 1) {
			this.cancelSearch.next(null);
			this.dataService
				.searchVendorsByName(this.name)
				.pipe(takeUntil(this.cancelSearch))
				.subscribe((resp:any) => (this.vendors = resp));
		} else {
			this.vendors = [];
		}
	}

	selectVendor(vendor) {
		this.name = '';
		this.vendors = [];
		this.onSelect.next(vendor);
	}

}
