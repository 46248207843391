import { Tag } from "./tag";

export class RecentForumPost {
	public topicId: string;
	public topicName: string;
	public topicSlug: string;
	public lastPostDate: Date;
	public views: number;
	public comments: number;
  public avatar: string;
  public postContent: string;
	public email: string;
	public slug: string;
	public memberId: string;
	public firstName: string;
	public lastName: string;
	public tags: Array<Tag>;

	constructor(o?: any) {
		Object.assign(this, o);
	}

	get avatarUrl() {
		return `https://members.promaster.com/content/uploads/${this.memberId}/${this.avatar}`;
	}
	get name() {
		return `${this.firstName} ${this.lastName}`;
	}
	get timeAgo() {
		const date = this.lastPostDate instanceof Date ? this.lastPostDate : new Date(this.lastPostDate);
		const formatter = new Intl.RelativeTimeFormat("en");
		const ranges = {
			years: 3600 * 24 * 365,
			months: 3600 * 24 * 30,
			weeks: 3600 * 24 * 7,
			days: 3600 * 24,
			hours: 3600,
			minutes: 60,
			seconds: 1,
		};
		const secondsElapsed = (date.getTime() - Date.now()) / 1000;
		for (let key in ranges) {
			if (ranges[key] < Math.abs(secondsElapsed)) {
				const delta = secondsElapsed / ranges[key];
				return formatter.format(Math.round(delta), key as any);
			}
		}
	}
}
