
<div class="form">
	<form [formGroup]="form">
		<div class="field">
			<label>Street</label>
			<input pInputText type="text" [formControlName]="'address1'">
		</div>
		<div class="field">
			<label></label>
			<input pInputText type="text" [formControlName]="'address2'">
		</div>
		<div class="field">
			<label>City</label>
			<input pInputText type="text" [formControlName]="'city'">
		</div>
		<div class="field">
			<label>State</label>
			<input pInputText type="text" [formControlName]="'state'">
		</div>
		<div class="field">
			<label>Zip</label>
			<input pInputText type="text" [formControlName]="'zip'">
		</div>
		<div class="field">
			<label>Country</label>
			<input pInputText type="text" [formControlName]="'country'">
		</div>
		<div class="field">
			<label>Address Type</label>
			<p-dropdown appendTo="body" [options]="addressTypeOptions" [formControlName]="'addressTypeId'" [autoDisplayFirst]="false" [style]="{width: '100%'  }"></p-dropdown>
		</div>
	</form>
	<div class="actions">
		<div class="cancel" (click)="cancel()">Cancel</div>
		<div class="submit" (click)="submit()">{{address.addressId > 0 ? 'Edit Address' : 'Add Address'}}</div>
	</div>
</div>
