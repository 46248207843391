import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SarPrint } from 'src/app/models/dashboard/sar';

@Component({
  selector: 'app-sar-print-heading',
  templateUrl: './sar-print-heading.component.html',
  styleUrls: ['./sar-print-heading.component.scss']
})
export class SarPrintHeadingComponent implements OnInit, OnChanges {

	@Input() title: string;
	@Input() summary: SarPrint;
	@Input() type: string;

	public mustReceiveBy: Date;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
	if(changes.summary?.currentValue && changes.type?.currentValue) {
		if(changes.type.currentValue == "RA") {
			const approvedDate = new Date(changes.summary?.currentValue.dateApproved);
			this.mustReceiveBy = new Date(approvedDate.setDate(approvedDate.getDate() + 30));
		}
	}
  }

}
