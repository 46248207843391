import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "highlight", pure: false })
export class HighlightTermPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string, term: string, className: string = "highlight") {
	  if(value.length < 3) {
		  return value;
	  }
    let words = term.trim().split(" ");
    words.forEach(w => {
      if (w.length > 0) {
		var idx = value.toLowerCase().indexOf(w.toLowerCase());
 		if (idx > -1) {
			 //using |~ ~| as placeholders for span /span to prevent next pass from finding text within html tag
			value = value.replace(new RegExp('('+w+')', 'gi'), `|~$1~|`);
		}
		}
    });
	
	return this.sanitizer.bypassSecurityTrustHtml(`${value.split('|~').join('<span class="'+className+'">').split('~\|').join('</span>')}`);
	
  }
  escape(s: string): string {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  }
}
