import { Component, OnInit } from "@angular/core";
import { Order } from "src/app/models/shopping/order";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-order-history-print",
	templateUrl: "./order-history-print.component.html",
	styleUrls: ["./order-history-print.component.scss"],
})
export class OrderHistoryPrintComponent implements OnInit {
	public order: Order;
	constructor(private dataService: DataService, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((map) => {
			if (map.get("orderId")) {
				this.dataService.getOrderDetails(map.get("orderId")).subscribe((resp) => {
					this.order = new Order(resp);
					setTimeout(window.print, 500);
				});
			}
		});
	}

}
