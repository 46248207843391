import { Component, Input, OnInit } from "@angular/core";
import { InstantRebateGroups } from "src/app/models/strapi/instant-rebate";

@Component({
	selector: "app-instant-rebate-groups",
	templateUrl: "./instant-rebate-groups.component.html",
	styleUrls: ["./instant-rebate-groups.component.scss"],
})
export class InstantRebateGroupsComponent implements OnInit {
	@Input() instantRebateGroups: InstantRebateGroups;
	@Input() instantRebateLimit: number = 9999;

	public currentRebatesVisible: boolean = false;
	public redeemableRebatesVisible: boolean = false;
	public futureRebatesVisible: boolean = false;
	constructor() {}

	ngOnInit(): void {}
}
