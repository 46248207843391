<section class="purchase">
  <div class="row">
    <div class="column">
      <img class="user-photo" [src]="pointsPurchase.photo" alt="{{pointsPurchase.product}}">
      <br />

      <ng-container>
        <input type="checkbox" (change)="onChange(pointsPurchase.orderID,ref.value,$event)">Order ID {{pointsPurchase.orderID}}
        <br />

        <select #ref (change)="addressChange(ref.value,pointsPurchase.orderID,$event)">
          <option *ngFor="let addy of pointsPurchase.retailerAddress" value="{{addy.addressId}}">{{addy.address1}}</option>
        </select>

      </ng-container>


      <p class="purchase-summary">
        <br />
        {{pointsPurchase.user.firstName}}{{pointsPurchase.user.lastName}}
        <br />
        Order Total: {{pointsPurchase.orderTotal | currency }}
        <br />
        Points Applied: {{pointsPurchase.pointsApplied | currency }}
        <br />
        Paid:{{pointsPurchase.paid  | currency }}
        <br />
        YTD Purchases:{{pointsPurchase.ytdPurchase  | currency }}
      </p>

    </div>
    <div class="column">
      <h2 class="purchase-heading">Products Ordered</h2>
      <p class="purchase-summary">
        <ng-container *ngFor="let prod of pointsPurchase.prodDetails">
          Product:  {{prod.productCode}}
          <br />
          {{prod.productName}}
          <br />
          Cost: {{prod.cost | currency}}
          <br />
          <hr />
        </ng-container>
      </p>
    </div>
  </div>




</section>



