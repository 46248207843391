
<div class="panel">
	<header>
		<h1>Price Protection Claim</h1>
		<div class="stepper">
			<div class="dot" [ngClass]="{seen: step >= 1}"></div>
			<div class="dot" [ngClass]="{seen: step >= 2}"></div>
			<div class="dot" [ngClass]="{seen: step >= 3}"></div>
		</div>
	</header>
	<main *ngIf="step == 1">
		<section class="program-info">
			<div class="dates">
				<div class="title">Program Date:</div>
				<div class="value">{{programDate | date: 'M/d/yyyy'}}</div>
			</div>
			<div class="vendor">
				<div class="title">Program Name:</div>
				<div class="value">{{program.vendorName}}</div>
			</div>
		</section>
		<section class="models">
			<div class="row header">
				<div class="model">Model</div>
				<div class="model-name">Model Name</div>
				<div class="reimbursement">Amount</div>
				<div class="quantity">Quantity</div>

				<div *ngIf="program.vendorName != 'ProMaster Price Protection'">
					<div class="serial-numbers">Serial Numbers</div>
				</div>

			</div>
			<div class="row" *ngFor="let item of entry.priceProtectionLineItems">

				<div class="model">{{item.modelNumber}}</div>

				<div class="model-name">{{item.modelName}}</div>

				<div class="reimbursement">{{item.amount | currency}}</div>

				<div class="quantity"><input [(ngModel)]="item.quantity" type="number" min="0" (change)="setSerials(item)" (keyup)="setSerials(item)"></div>

				<div *ngIf="program.vendorName != 'ProMaster Price Protection'">
					<div class="serial-numbers">
						<div *ngFor="let serial of item.serials; let i = index"> <input [(ngModel)]="serial.number" [ngClass]="{invalid: submitted && !serial.number}"> </div>
					</div>
				</div>

			</div>
		</section>
	</main>


	<div *ngIf="program.vendorName != 'ProMaster Price Protection'">
		<main *ngIf="step == 2">
			<section class="instructions">
				<h2>Instructions</h2>
				<p>
					You must attach an electronic copy of your POS report or sales receipts to process claim. Only PDF, Word, Excel and
					Text files accepted. There is a maximum of 5 attachement allowed. Please make sure to limit the quantity of pages
					submitted as this causes delay in processing.
				</p>
				<div class="upload-button">
					<div class="button">
						<mat-icon>add</mat-icon>
						<span>Upload/Add File</span>
					</div>
					<input type="file" (change)="saveFile($event)">
				</div>
			</section>
			<section class="files">
				<ul>
					<li *ngFor="let file of entry.priceProtectionFiles; let i = index">
						<div class="number">{{i + 1}}</div>
						<div class="file-name">{{file.fileName}}</div>
						<div class="file-size">{{file.fileSizeWithUnits}}</div>
						<div class="delete" (click)="deleteFile(i)"><mat-icon>clear</mat-icon></div>
					</li>
				</ul>
			</section>
		</main>
	</div>

	<div *ngIf="program.vendorName == 'ProMaster Price Protection'">
		<h2>Instructions</h2>
		<p>
			No support is needed for ProMaster price protection claims.  Please continue by selecting next.
		</p>
		
	</div>

		<main *ngIf="step == 3">
			<section class="form" [formGroup]="rebateForm">
				<div class="field">
					<label>First Name</label>
					<input type="text" pInputText [formControlName]="'fname'">
				</div>
				<div class="field">
					<label>Last Name</label>
					<input type="text" pInputText [formControlName]="'lname'">
				</div>
				<div class="field">
					<label>Email</label>
					<input type="text" pInputText [formControlName]="'email'">
				</div>
				<div class="field">
					<label>Phone</label>
					<input type="text" pInputText [formControlName]="'phone'">
				</div>
				<div class="field">
					<label>Debit Memo</label>
					<input type="text" pInputText [formControlName]="'memo'">
					<div class="error" *ngIf="rebateForm.get('memo').touched && rebateForm.get('memo').hasError('minlength')">Please enter at least 5 characters</div>
				</div>
			</section>
		</main>
		<footer>
			<div class="back">
				<div class="button" (click)="back()" *ngIf="step > 1">Back</div>
			</div>
			<div class="next" *ngIf="step < 3">
				<div class="button" (click)="next()">Next</div>
			</div>
			<div class="submit" *ngIf="step == 3">
				<div class="button" (click)="submit()">Submit My Claim</div>
			</div>
		</footer>
	</div>
