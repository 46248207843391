
<div class="overview">
	<div class="top-cards" *ngIf="memberSummary" [permission]="Permissions.FinancialData">
		<div class="top-card balance">
			<h4>Balance</h4>
			<div class="value">{{memberSummary.balance | currency}}</div>
			<div class="callout"><i class="pi pi-info-circle"></i>Total Outstanding</div>
		</div>
		<div class="top-card current">
			<h4>Current</h4>
			<div class="value">{{memberSummary.current | currency}}</div>
			<div class="callout"><i class="pi pi-info-circle"></i>Payment Due</div>
		</div>
		<div class="top-card future">
			<h4>Future</h4>
			<div class="value">{{memberSummary.future | currency}}</div>
			<div class="callout"><i class="pi pi-info-circle"></i>Future Due</div>
		</div>
		<div class="top-card days">
			<h4>Days Out</h4>
			<div class="value">{{memberSummary['days'+daysOut] | currency}}</div>
			<select (change)="setDaysOut($event)" [(ngModel)]="daysOut">
				<option value="30">30 Days</option>
				<option value="60">60 Days</option>
				<option value="90">90 Days</option>
			</select>
		</div>
	</div>



		<div class="aside">

			<div class="search">
				<div class="search-area">
					<input pInputText [(ngModel)]="term" class="term" (keyup)="search()" name="psterm" id="psterm" placeholder="Search Backorders, Invoices , IRs and Credits" [ngClass]="{'ng-touched': touched, 'ng-valid': valid, 'ng-invalid': !valid }">
					<div class="button" (click)="search()">Searching</div>


				</div>				
				<div class="search-results" *ngIf="searchResults">
					<h4 *ngIf="searchResults.invoiceSearchResults?.length > 0"><span>Invoices</span></h4>
					<div class="search-result invoice-result" *ngFor="let invoice of searchResults.invoiceSearchResults">
						<h2 (click)="openInvoice(invoice.invoiceNumber)">{{invoice.invoiceNumber}} - {{invoice.tag}}</h2>
						<div [innerHTML]="invoice.searchTextClean | highlight:searchCriteria.searchTerm"></div>
					</div>
					<h4 *ngIf="searchResults.backorderResults?.length > 0"><span>Backorders</span></h4>
					<div class="search-result backorder-result" *ngFor="let backorder of searchResults.backorderResults">
						<h2>PO: {{backorder.poNumber}}  |  {{backorder.productcode}}   |  {{backorder.modelname}}  | BO QTY:  {{backorder.leftOver}} |  {{backorder.date  | date: 'MMM dd'}}</h2>
						<div [innerHTML]="backorder.productcode + ' ' +  backorder.poNumber + ' ' + backorder.modelname | highlight:searchCriteria.searchTerm"></div>
					</div>
				</div>
			</div>



			<!-- Thsi is all new 
			<div class="invoices" *ngIf="(tempSearchType == 4 || tempSearchType == 1) && searchResults.invoiceSearchResults?.length > 0" [permission]="Permissions.InvoiceAccess">

				<div class="header">
					<h1>Invoices</h1>

					<div><a [routerLink]="['/dashboard', 'invoices']" [queryParams]="{searchCriteria: term}">View All</a></div>

				</div>

				<ul>
					<li *ngFor="let invoice of searchResults.invoiceSearchResults" class="result invoice-result">
						<div class="invoice-download" (click)="downloadInvoice(invoice.invoiceNumber)">{{invoice.invoiceNumber}} <br /> Posted: {{invoice.modifyDate | date: 'MMM d'}}</div>

						<div class="rating">
							<mat-icon *ngIf="invoice.rank > 3">star</mat-icon>
							<mat-icon *ngIf="invoice.rank > 1.5">star</mat-icon>
							<mat-icon *ngIf="invoice.rank > 0.45">star</mat-icon>
						</div>

						<p>{{invoice.searchText }}</p>

					</li>
				</ul>
			</div>
-->
	</div>




	<div class="order-history" [permission]="Permissions.OrderHistory">
		<header>
			<h2>Order History</h2>
			<div><a [routerLink]="['/dashboard', 'order-history']">Manage Orders&nbsp;&rarr;</a></div>
		</header>

		<app-order-history-list [orderHistoryItems]="orderHistoryItems"></app-order-history-list>

	</div>
	<div class="most-frequently-bought" [permission]="Permissions.ShopAccess">
		<header>
			<h2>Most Frequently Purchased </h2>  <div class="Note"><i>*Determined by frequency of same product code reorders in the last 6 months.*</i></div>
			<div><a [routerLink]="['/dashboard', 'most-frequently-bought']">View All&nbsp;&rarr;</a></div>
		</header>
		<app-most-frequently-bought-list></app-most-frequently-bought-list>
	</div>
</div>
