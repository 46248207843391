<header>
	<h1>News &amp; Updates</h1>
	<div class="search-container" *ngIf="!newsletters">
		<mat-icon>search</mat-icon>
		<input [(ngModel)]="searchTerm" (keyup.enter)="search()">
		<div class="button transparent" (click)="clearSearch()" *ngIf="searchTerm">Clear</div>
		<div class="button" (click)="search()">Search</div>
	</div>
	<div class="tags" *ngIf="!newsletters">
		<span>Popular Tags:</span>
		<div class="tag" *ngFor="let tag of tagList" [ngClass]="{active: activeTag == tag}">
			<div class="tag-button" (click)="toggleTag(tag)">{{tag}}</div>
		</div>
	</div>
	<div class="newsletter-options" *ngIf="newsletters">
		<p-dropdown [options]="newsletterOptions" [(ngModel)]="newsletterId" (onChange)="goToNewsletter($event)" [autoDisplayFirst]="false" placeholder="View Other Newsletters"></p-dropdown>
	</div>
</header>
