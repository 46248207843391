<div class="main-sidebar-container">
	<p-sidebar [(visible)]="sidebarVisible" (onHide)="onSideNavHide()">
		<aside class="main-nav">
			<div class="close-container" (click)="closeSideNav()"><div class="close-x"></div></div>
			<div class="spacer">&nbsp;</div>
			<div class="social-links">
				<div class="terms" *ngIf="user.userType != 'Client'"> <a href="./Terms_Conditions.htm" target="_blank"> <img src="../../../assets/images/document.png"> </a> </div>
				<div class="insta"><a href="https://www.instagram.com/promasterphoto/" target="_blank"><img src="../../../assets/images/nav-insta.png"></a></div>
				<div class="youtube"><a href="https://www.youtube.com/user/ProMasterChannel" target="_blank"><img src="../../../assets/images/nav-youtube.png"></a></div>
				<div class="facebook"><a href="https://www.facebook.com/promasterphoto/" target="_blank"><img src="../../../assets/images/nav-fb.png"></a></div>
				<div class="vimeo"><a href="http://vimeo.com/promaster" target="_blank"><img src="../../../assets/images/nav-vimeo.png"></a></div>
			</div>
			<div class="avatar">
				{{user.initials}}
			</div>
		</aside>	
		<div class="sidebar-content">
			<header class="sidebar-header">
				<div class="logo"><a routerLink="/"><img src="../../../assets/images/PRO_Connection_2022 1.png" id="logo" alt="ProConnection"></a></div>
			</header>
			<main class="sidebar-nav">
				<ul>
					<li><mat-icon>home</mat-icon><a [routerLink]="['/']">Home</a></li>
					<li><mat-icon>shopping_bag</mat-icon><a href="https://www.marketing.promaster.com" target="_blank">ProMaster Marketing Materials</a></li>

				<!--<li *ngIf="user.userType == 'Client'"><a href="https://members.promaster.com/Files/PROWH.csv" target="_blank">ProMaster Warehouse Price List</a></li>-->

					<li *ngIf="user.userType != 'Client'"  [permission]="Permissions.FinancialData"><mat-icon>download</mat-icon><a href="https://members.promaster.com/Files/ProMaster-Brand-Only.csv" target="_blank">ProMaster Warehouse Price List</a></li>
					<li [permission]="Permissions.CurrentEvents" ><mat-icon>feed</mat-icon><a [routerLink]="['/HeadtoHead']">Head to Head Comparison</a></li>
					<li [permission]="Permissions.CurrentEvents"><mat-icon>groups</mat-icon><a [routerLink]="['/newsfeed', 'tag', 'convention']">Convention</a></li>

					<li><mat-icon>warehouse</mat-icon><a [routerLink]="['/warehouse', 'new']">Warehouse</a></li>

					<li [permission]="Permissions.Forums"><mat-icon>forum</mat-icon><a href="https://forum.promaster.com" target="_blank">Forum</a></li>

					<li [permission]="Permissions.OrderHistory"><mat-icon>list_alt</mat-icon><a [routerLink]="['/dashboard', 'order-history']">Order History</a></li>

					<li [permission]="Permissions.ShopAccess"><mat-icon>shopping_cart</mat-icon><a [routerLink]="['/shop', 'cart']">Cart</a></li>
					<li><mat-icon>dashboard</mat-icon><a (click)="showDashboardSubmenu = true">Dashboard</a></li>
					<li [permission]="Permissions.Vendor"><mat-icon>shopping_bag</mat-icon><a [routerLink]="['/vendors']">Vendors</a></li>
					<li [permission]="Permissions.GeneralAccess"><mat-icon>groups</mat-icon><a [routerLink]="['/dealers']">Dealers</a></li>
					<li [permission]="Permissions.CurrentEvents"><mat-icon>feed</mat-icon><a [routerLink]="['/newsfeed']">News &amp; Updates</a></li>
					<li [permission]="Permissions.GeneralAccess"><mat-icon>phone_in_talk</mat-icon><a [routerLink]="['/staff']">PRO Team Directory</a></li>
					<li (click)="logout()"><mat-icon>logout</mat-icon>Logout</li>

				</ul>
				<div class="submenu" [ngClass]="{show: showDashboardSubmenu}">
					<div class="header">
						<h1>Dashboard</h1>
						<mat-icon (click)="showDashboardSubmenu = false">close</mat-icon>
					</div>
					<ul>
						<li><a [routerLink]="['/dashboard', 'overview']">Overview</a></li>
						<li [permission]="Permissions.Payments" *ngIf="ezPayText == 'EZPay'"><a [routerLink]="['/dashboard', 'ezpay']">EZ-Pay</a></li>
						<li [permission]="Permissions.Payments" *ngIf="ezPayText == 'PromptPay'"><a [routerLink]="['/dashboard', 'promptpay']">Prompt Pay</a></li>
						<li><a [routerLink]="['/dashboard', 'invoices']">Invoice</a></li>
						<li [permission]="Permissions.OrderHistory">    <a [routerLink]="['/dashboard', 'order-history']">Order History</a></li>
						<li [permission]="Permissions.GeneralAccess"  ><a [routerLink]="['/dashboard', 'most-frequently-bought']">Most Frequently Purchased</a></li>
						<li [permission]="Permissions.RebateRead"><a [routerLink]="['/dashboard', 'instant-rebates']">Submitted Rebates & Price Protection</a></li>
						<li [permission]="Permissions.RebateProcess"><a [routerLink]="['/dashboard', 'rebate-claim']">Claim a Rebate</a></li>
						<li [permission]="Permissions.RebateProcess"><a [routerLink]="['/dashboard', 'price-protection']">Price Protection</a></li>
						<li [permission]="Permissions.SarRead"><a [routerLink]="['/dashboard', 'sar']">SAR Satisfaction Adjustment Requests </a></li>
						<li [permission]="Permissions.Shipping"> <a [routerLink]="['/dashboard', 'shipping-errors']">Shipping Errors</a>    </li>
						<li [permission]="Permissions.AccountManagement"><a [routerLink]="['/dashboard', 'store-address']">Address Management</a></li>
						<li [permission]="Permissions.AccountManagement"><a [routerLink]="['/dashboard', 'account-data']">Company Info</a></li>
						<li [permission]="Permissions.UserManagement"><a [routerLink]="['/dashboard', 'users']">Users</a></li>
						<li [permission]="Permissions.AccountManagement"><a [routerLink]="['/dashboard', 'permissions']">Permissions</a></li>
						<li [permission]="Permissions.PointsManagement"><a [routerLink]="['/dashboard', 'points-management']">PRO Insider</a></li>
					</ul>
				</div>
			</main>	
			<footer class="sidebar-footer">
				 <!--<ul>
					<li (click)="logout()"><mat-icon>logout</mat-icon>Logout</li>
				</ul>-->	

				<div class="account-number">Account Number #{{user.accountNumber}}</div>

			</footer>	
		</div>
	</p-sidebar>
</div>
<div id="container">
	<aside class="main-nav">
		<div class="hamburger-container " (click)="openSideNav()"><div class="hamburger"></div></div>
		<div class="spacer">&nbsp;</div>
		<div class="social-links">
		<div class="terms" *ngIf="user.userType != 'Client'"><a href="./Terms_Conditions.htm" target="_blank">
	<div class="tooltip"><img src="../../../assets/images/document.png"><span class="tooltiptext">Legal Docs</span></div></a></div>
		


			<div class="insta"><a href="https://www.instagram.com/promasterphoto/" target="_blank"><img src="../../../assets/images/nav-insta.png"></a></div>
			<div class="youtube"><a href="https://www.youtube.com/user/ProMasterChannel" target="_blank"><img src="../../../assets/images/nav-youtube.png"></a></div>
			<div class="facebook"><a href="https://www.facebook.com/promasterphoto/" target="_blank"><img src="../../../assets/images/nav-fb.png"></a></div>
			<div class="vimeo"><a href="http://vimeo.com/promaster" target="_blank"><img src="../../../assets/images/nav-vimeo.png"></a></div>
		</div>
			<div class="avatar">
				{{user.initials}}
				<div class="avatar-flyout">
					<div class="name">{{user.name}}</div>
				</div>
			</div>
	</aside>
	<header id="main_header" [ngClass]="{'user-logged-in-as': user.isLogInAs}">
		<div class="hamburger-container mobile" (click)="sidebarVisible = true"><div class="hamburger"></div></div>
		<div class="logo"><a routerLink="/"><img src="../../../assets/images/PRO_Connection_2022 1.png" id="logo" alt="ProConnection"></a></div>
		<div class="log-in-as-link" [permission]="Permissions.ProStaff">
			<a [routerLink]="['/dashboard', 'loginas']">Log In As</a>
		</div>
		<div class="quick-links">
			<a [routerLink]="['/']">Home</a>
			<a [routerLink]="['/warehouse', 'new']" [permission]="Permissions.GeneralAccess">Warehouse</a>
			<a [routerLink]="['/newsletter']" [permission]="Permissions.CurrentEvents">NewsLetter</a>
			<div>
				<div class="link"><span>Dashboard&nbsp;</span><mat-icon>expand_more</mat-icon></div>
				<div class="submenu">
					<ul>
						<li><a [routerLink]="['/dashboard', 'overview']">Overview</a></li>
						<li [permission]="Permissions.Payments" *ngIf="ezPayText == 'EZPay'"><a [routerLink]="['/dashboard', 'ezpay']">EZ-Pay</a></li>
						<li [permission]="Permissions.Payments" *ngIf="ezPayText == 'PromptPay'"><a [routerLink]="['/dashboard', 'promptpay']">Prompt Pay</a></li>
						<li><a [routerLink]="['/dashboard', 'invoices']">Invoice</a></li>
						<li><a [routerLink]="['/dashboard', 'invoice-history']">Invoice History</a></li>
						<li [permission]="Permissions.OrderHistory"><a [routerLink]="['/dashboard', 'order-history']">Order History</a></li>
						<li [permission]="Permissions.GeneralAccess"><a [routerLink]="['/dashboard', 'most-frequently-bought']">Most Frequently Purchased</a></li>
						</ul>
					<ul>
						<li [permission]="Permissions.RebateRead"><a [routerLink]="['/dashboard', 'instant-rebates']">Submitted Rebates & Price Protection</a></li>
						<li [permission]="Permissions.RebateProcess"><a [routerLink]="['/dashboard', 'rebate-claim']">Claim a Rebate</a></li>
						<li [permission]="Permissions.RebateProcess"><a [routerLink]="['/dashboard', 'price-protection']">Price Protection</a></li>
						<li [permission]="Permissions.GeneralAccess"> <a [routerLink]="['/dashboard', 'sell-through']">Panasonic Sell Through</a></li>
						<li [permission]="Permissions.SarRead"><a [routerLink]="['/dashboard', 'sar']">SAR Satisfaction Adjustment Requests </a></li>
						<li><a href="https://www.marketing.promaster.com" target="_blank">ProMaster Marketing Materials</a></li>
						<li [permission]="Permissions.Shipping"> <a [routerLink]="['/dashboard', 'shipping-errors']">Shipping Errors ( BETA ) </a></li>
					</ul>
					<ul>
						<li [permission]="Permissions.Subscriptions"><a [routerLink]="['/dashboard', 'subscriptions']">Subscriptions</a></li>
						<li [permission]="Permissions.AccountManagement"><a [routerLink]="['/dashboard', 'store-address']">Address Management</a></li>
						<li [permission]="Permissions.AccountManagement"><a [routerLink]="['/dashboard', 'account-data']">Company Info</a></li>
						<li [permission]="Permissions.UserManagement"><a [routerLink]="['/dashboard', 'users']">Users</a></li>
						<li [permission]="Permissions.AccountManagement"><a [routerLink]="['/dashboard', 'permissions']">Permissions</a></li>
						<li [permission]="Permissions.PointsManagement"><a [routerLink]="['/dashboard', 'points-management']">PRO Insider</a></li>
					</ul>
				</div>
			</div>
			<app-product-search (onSelect)="quickProductSelect($event)" [placeholder]="'Quick Product Search'" id="quick_search_term" [permission]="Permissions.ShopAccess"></app-product-search>
		</div>
		<div class="actions">
			<div class="nav-button transparent" (click)="openSearch()" [permission]="Permissions.ShopAccess"> <i class="pi pi-maginfying-glass"></i>Search</div>
			<div class="nav-button outline" (click)="openQuickOrder()" [permission]="Permissions.ShopAccess"><i class="pi pi-folder"></i>Quick Order</div>
			<div class="nav-button" [permission]="Permissions.ShopAccess"><a [routerLink]="['/shop', 'cart']"><i class="pi pi-cart"></i>Cart<em class="cart-quantity" *ngIf="cartQuantity > 0">{{cartQuantity}}</em></a></div>
		</div>
		<div class="search-only mobile">
			<mat-icon (click)="openSearch()">search</mat-icon>
		</div>
	</header>
	<main id="main_content">
		<router-outlet *ngIf="!disallowed"></router-outlet>
		<div class="disallowed" *ngIf="disallowed">
			<h1>Not available</h1>
			<p>This page was not designed to be viewed on a mobile device.  Please use a larger screen to access this page.</p>
		</div>
	</main>
	<footer id="main_footer">
		<div class="col">
			<h2>PRO</h2>
			<ul>
				<li [permission]="Permissions.GeneralAccess"><a [routerLink]="['/warehouse']">Warehouse</a></li>
				<li [permission]="Permissions.Forums"><a href="https://forum.promaster.com" target="_blank">Forum</a></li>
				<li [permission]="Permissions.Vendor"><a [routerLink]="['/vendors']">Vendors</a></li>
			</ul>
		</div>
		<div class="col">
			<h2>Resources</h2>
			<ul>
				<li [permission]="Permissions.RebateProcess"><a [routerLink]="['/dashboard', 'rebate-claim']">Rebates</a></li>
				<li [permission]="Permissions.CurrentEvents"><a [routerLink]="['/newsfeed', 'tag', 'newsletter']">Current Events</a></li>
				<li [permission]="Permissions.Subscriptions"><a [routerLink]="['/dashboard', 'subscriptions']">Subscriptions</a></li>
			</ul>
		</div>
		<div class="col">
			<h2>PRO Team</h2>
			<ul>
				<li><a [routerLink]="['/', 'staff']">Directory</a></li>
				<li><a [routerLink]="['/', 'dealers']">Roster</a></li>
			</ul>
		</div>
		<div class="col">
			<h2>Get in touch</h2>
			<p>Photo retailers working together for greater success!</p>
			<ul class="social">
				<li><a href="http://instagram.com/promasterphoto/" target="_blank"><img src="../../../assets/images/instagram.png"></a></li>
				<li><a href="http://youtube.com/user/ProMasterChannel/featured" target="_blank"><img src="../../../assets/images/youtube.png"></a></li>
				<li><a href="http://facebook.com/promasterphoto/" target="_blank"><img src="../../../assets/images/facebook.png"></a></li>
				<li><a href="http://vimeo.com/promaster" target="_blank"><img src="../../../assets/images/vimeo.png"></a></li>
			</ul>
		</div>
	</footer>
	<div id="sub_footer">
		<div class="logo"><a routerLink="/"><img src="../../../assets/images/PRO_Connection_2022 1.png" id="logo" alt="ProConnection"></a></div>
		<div class="copyright">Copyright www.promaster.com | All rights reserved.</div>
	</div>
</div>

<div class="loading" *ngIf="loaderVisible">
	<div class="spinner-container"><div class="spinner">
		<div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
		</div></div>
</div>

<p-dialog [(visible)]="showUploadOrder" [dismissableMask]="false" position="bottom" styleClass="upload-order-dialog">
	<ng-template pTemplate="header">Upload Order</ng-template>
	<div class="upload-form">
		<div class="type">
			<label>Select File Format</label>
			<p-dropdown [options]="uploadOrderTypeOptions" [(ngModel)]="uploadOrderType"></p-dropdown>
		</div>
		<div class="field">
			<label>Remove the following text from product codes in the file</label>
			<input pInputText [(ngModel)]="removeText">
		</div>
		<div class="upload-button">
			<div class="button">
				<mat-icon>add</mat-icon>
				<span>Upload/Add File</span>
			</div>
			<input type="file" (change)="upload($event)" #uploadOrderFileField>
		</div>
	</div>
	<div class="errors" *ngIf="uploadErrors.length > 0">
		<p>The following error(s) were found in your file:</p>
		<ul>
			<li *ngFor="let err of uploadErrors" [ngClass]="{error: err.code < 0}">
				<span>{{err.productCode}}: {{err.reason}}</span>
			</li>
		</ul>
		<p>Other items have been added to your cart</p>
	</div>
	<ng-template pTemplate="footer">
		<div class="button" (click)="closeUploadOrder()">Close</div>
	</ng-template>
</p-dialog>

<p-sidebar [(visible)]="showSideCart" position="right" class="side-cart">
	<app-cart-tile></app-cart-tile>
</p-sidebar>

<p-sidebar [(visible)]="showQuickAdd" (onHide)="hideQuickAdd()" position="right" [styleClass]="'side-quick-add'">
	<app-quick-add-widget></app-quick-add-widget>
</p-sidebar>

<div id="search_mask" [ngClass]="{open: showSearchDialog}" (click)="showSearchDialog = false"></div>
<div id="search_dialog" [ngClass]="{open: showSearchDialog}">
	<app-search-widget></app-search-widget>
</div>

<div id="item_deleted_alert" [ngClass]="{open: showItemDeleted}">
	<mat-icon>error</mat-icon>Item deleted.  Click here to <u (click)="undoDelete()">UNDO DELETE.</u><mat-icon class="close" (click)="showItemDeleted = false">close</mat-icon>
</div>

<div id="undo_alert" [ngClass]="{open: showUndoMessage}" *ngIf="undoMessage">
	<mat-icon>{{undoMessage.icon}}</mat-icon><span>{{undoMessage.intro}} <u (click)="doUndo(undoMessage.callback, undoMessage.parameters)">{{undoMessage.undoText}}.</u></span><mat-icon class="close" (click)="showUndoMessage = false">close</mat-icon>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<app-export-dialog></app-export-dialog>
