<div class="container">
	<div class="col" *ngFor="let type of memberTypes">
		<h1>{{type.memberType}}</h1>
		<ul>
			<li class="company" *ngFor="let company of type.companies">
				<h3>{{company.accountNumber}} - {{company.companyName}}</h3>
				<ul>
					<li class="owner" *ngFor="let owner of company.owners" (click)="logInAs(owner)">{{owner.firstName}} {{owner.lastName}}</li>
				</ul>
			</li>
		</ul>
	</div>
</div>
