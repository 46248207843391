import { EzPayStatus } from "../modules/controls/enums/ezpayStatus.enum";

export class ProUser {

	public username: string;
	public email: string;
	public companyId: number;
	public accountNumber: string;
	public userId: number;
	public userType: string;
	public name: string;
	public avatar: string = null;
	public permissions: number;
	public isLogInAs: boolean;
  public ezPayStatus: EzPayStatus;
	public vendors: Array<number>;


	constructor(o?: any) {
		if(o) {
			this.userId = o.UserId;
			this.username = o.UserName;
			this.email = o.Email;
			this.companyId = o.CompanyId;
			this.accountNumber = o.AccountNumber;
			this.userType = o.MemberType;
			this.name = o.Name;		
			this.permissions = o.Permissions;
			this.isLogInAs = o.IsLogInAs == 'True';	
			this.ezPayStatus = o.ezpayStatus;
      this.vendors = o.Vendors?.split("|").filter(v => v.length > 0);

		}
	}
	get initials() {
		if(!this.name) return '';
		return this.name[0] + (this.name.match(/\s([a-zA-Z0-9]){1}/g) ? this.name.match(/\s([a-zA-Z0-9]){1}/g).map(l => l.trim()).join('') : '')
	}
}
