import { createSelector } from "@ngrx/store";
import { IAppState } from "../state/app.state";
import { ISiteState } from "../state/site.state";


const selectSiteState = (state: IAppState) => state.siteState;

export const selectSuccessMessage = createSelector(selectSiteState, (state: ISiteState) => state.successMessage);
export const selectErrorMessage = createSelector(selectSiteState, (state: ISiteState) => state.errorMessage);
export const selectSearchOpen = createSelector(selectSiteState, (state: ISiteState) => state.searchOpen);
export const selectHeaderSearchType = createSelector(selectSiteState, (state: ISiteState) => state.headerSearchType);
export const selectNavMenuOpen = createSelector(selectSiteState, (state: ISiteState) => state.navMenuOpen);
export const selectDashboardTitle = createSelector(selectSiteState, (state: ISiteState) => state.dashboardTitle);
export const selectDashboardHeaderButtons = createSelector(selectSiteState, (state: ISiteState) => state.dashboardHeaderButtons);
export const selectUploadOrderVisible = createSelector(selectSiteState, (state: ISiteState) => state.uploadOrderVisible);
export const selectDownloadData = createSelector(selectSiteState, (state: ISiteState) => state.downloadData);
export const selectDownloadTableInfo = createSelector(selectSiteState, (state: ISiteState) => state.downloadTableInfo);
export const selectDownloadHeaders = createSelector(selectSiteState, (state: ISiteState) => state.downloadHeaders);
export const selectDownloadFormats = createSelector(selectSiteState, (state: ISiteState) => state.downloadFormats);
