import { Article } from "./article";

export class Newsfeed {
    public articles: Array<Article>;
    public pagination: Pagination;

    constructor(o?: any) {
        Object.assign(this, o);
    }
}

export class Pagination {
    public page: number;
    public pageCount: number;
    public pageSize: number;
    public total: number;

    constructor(o?: any) {
        Object.assign(this, o);
    }
}