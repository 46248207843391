import { cartReducer } from "./cart.reducer";
import { searchReducer } from "./search.reducer";
import { siteReducer } from "./site.reducer";
import { userReducer } from "./user.reducer";

export const appReducers = {
	cartState: cartReducer,
	userState: userReducer,
	siteState: siteReducer,
	searchState: searchReducer,
}