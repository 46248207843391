export class SearchResults {
	public productSearchResults: ProductSearchResult[];
	public invoiceSearchResults: InvoiceSearchResult[];
	public queryID: string;
	public index: string;

	constructor(o?: any) {
		if(o.productSearchResults) {
			o.productSearchResults = o.productSearchResults.map(r => new ProductSearchResult(r))
		}
		if(o.invoiceSearchResults) {
			o.invoiceSearchResults = o.invoiceSearchResults.map(r => new InvoiceSearchResult(r))
		}
		Object.assign(this, o);
	}
}





export class ProductSearchResult {
	public rank: number;
	public key: string;
	public type: string;
	public productCode: string;
	public upc: string;
	public modelName: string;
	public searchText: string;
	public stockMessage: string ;

	constructor(o?: any) {
		Object.assign(this, o);
	}
	get searchTextClean() {
		return this.searchText.replace(/<[^>]*>/g, '');
	}
}



export class BackOrderSearchResult {
  public ponumber: number;
  public productcode: string;
  public modelname: string;
  public weborders: string;
  public date: string;
  public duedate: string;
  public searchText: string;
  public leftover: string;
  constructor(o?: any) {
    Object.assign(this, o);
  }
  get searchTextClean() {
    return this.searchText.replace(/<[^>]*>/g, '');
  }
}




export class InvoiceSearchResult {
	public rank: number;
	public type: string;
	public invoiceNumber: string;
	public tag: string;
	public invoiceLocation: string;
	public searchText: string;
    public modifyDate: string;
	constructor(o?: any) {
		Object.assign(this, o);
	}
	get searchTextClean() {
		return this.searchText.replace(/<[^>]*>/g, '');
	}
}

export class ArticleSearchResult {
	public rank: number;
	public type: string;
	public article: string;
	public extractedText: string;
	public excerpt: string;
	public caption: string;
	public url: string;
	public hash: string;
  public articleId: number;
  public createDate: string;
	public slug: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}	
	
}



export class ShipSearchResult {
  
  public OrderID: string;
  public ID: number;
  public Invoice: string;
  public PO: string;
  public shippingNumber: string;
  public DT_POSTED: Date;
  public products: string;

  constructor(o?: any) {
    Object.assign(this, o);
  }
  //get searchTextClean() {
  //  return this.searchText.replace(/<[^>]*>/g, '');
  //}
}



export class POSearchResult {
  public PO: string;
  public Date: string;
  public searchText: string;

  constructor(o?: any) {
    Object.assign(this, o);
  }
  get searchTextClean() {
    return this.searchText.replace(/<[^>]*>/g, '');
  }
}


export class SearchCriteria {
	public searchType: number;
	public searchTerm: string;
	public fromDate: string;
	public toDate: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}
