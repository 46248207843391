import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { AddToCart, CartActionTypes } from "src/app/store/actions/cart.actions";
import { ErrorMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-quick-add",
	templateUrl: "./quick-add.component.html",
	styleUrls: ["./quick-add.component.scss"],
})
export class QuickAddComponent implements OnInit {
	@ViewChild('codeField') codeField: ElementRef;

	public added: Array<{ productCode: string; modelName: string; quantity: number }> = [];
	public code: string;
	public modelName: string;
	public quantity: number;
	constructor(private dataService: DataService, private store:Store<IAppState>, private actions$: Actions) {}

	ngOnInit(): void {
		this.actions$
		.pipe(
			map((a) => {
				switch (a.type) {
					case CartActionTypes.ADD_TO_CART_SUCCESS:
						this.added.push({productCode: this.code, modelName: this.modelName, quantity: this.quantity});
						this.code = '';
						this.quantity = undefined;
						this.modelName = '';
						setTimeout(() => this.codeField.nativeElement.focus(), 0);
						break;
				}
			})
		)
		.subscribe();
	}

	onCodeEntered() {
		if(this.code) {
			this.dataService.getProduct(this.code).subscribe((p:any) => {
				if(p.product) {
					this.modelName = p.product.modelName;
				} else {
					this.store.dispatch(new ErrorMessageSet(this.code + ' is an invalid product code'))
					this.code = '';
				}
			})
		}
	}
	onQuantityEntered() {
		if(this.code && this.quantity) {
			this.store.dispatch(new AddToCart(this.code, this.quantity));
		}
		if(!this.code) {
			this.quantity = undefined;
			setTimeout(() => this.codeField.nativeElement.focus(), 0);
		}
	}
	focusCode() {
		this.codeField.nativeElement.focus()
	}
}
