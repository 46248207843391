
<div class="pane">

	<div class="cart">
		<header>
			<h1>Cart</h1>
			<div class="sort">
				<span>Sort By:&nbsp;</span>
				<p-dropdown [options]="sortOptions" [(ngModel)]="sortField" (onChange)="sortCart($event)" [autoDisplayFirst]="false"></p-dropdown>
			</div>
			<div class="change-mode" *ngIf="cart?.cartItems.length > 0 || cart?.cartBundles.length > 0">
				<div class="toggler">
					<input type="checkbox" [(ngModel)]="modeInputChecked" (ngModelChange)="setMode()">
					<div class="track-holder">
						<div class="track">
							<div class="dot"></div>
						</div>
					</div>
				</div>
				<h5>Lite Mode</h5>
			</div>
		</header>


		<ng-container *ngIf="cart?.cartItems.length > 0 || cart?.cartBundles.length > 0; else cartEmpty">
			<div class="cart-contents">
				<div class="quick-add">
					<h3>Quick Order</h3>
					<div class="qa-code"><span>Item #</span><input pInputText [(ngModel)]="quickAddProductCode" #productCodeField (blur)="getQuickAddProduct()"></div>
					<div class="qa-qty"><span>Qty</span><input [(ngModel)]="quickAddQuantity" class="p-inputtext" (keyup.enter)="quickAdd()" #quantityField></div>
					<button (click)="quickAdd()"><div class="button"><mat-icon>folder_plus_outline</mat-icon>Add Item</div></button>
				</div>
				<div class="cart-items">
					<div class="headers" *ngIf="mode == 'lite'">
						<div class="code">Product Code</div>
						<div class="name">Product Name</div>
						<div class="quantity">Quantity</div>
						<div class="prices">Price</div>
						<div class="delete"></div>
					</div>
					<app-product-tile *ngFor="let item of cart.cartItems" [cartItem]="item" [mode]="mode"></app-product-tile>
				</div>
				<div class="cart-bundles">
					<app-bundle-tile *ngFor="let item of cart.cartBundles" [cartBundle]="item" [mode]="mode"></app-bundle-tile>
				</div>
			</div>

			<div class="summary">
				<h2>Summary</h2>
				<ul>
					<li class="em">
						<div>Subtotal:</div>
						<div>{{cart.getTotal() | currency}}</div>
					</li>
					<li class="shipping">
						<div>Shipping</div>
						<div>
							<div class="shipping-brand" *ngFor="let info of shippingCostInfo">
								<div class="brand">{{info.brand}}</div>
								<div class="message">{{info.message}}</div>

							</div>
						</div>
						<div class="drop-ship-fee">
							<span>Drop Ship Fee (if applicable)</span>
							<span>{{dropShipShippingCharge | currency}}</span>
						</div>

						<div class="LTL-fee">
							<span>LTL Fee</span>
							<span>{{LTLCost | currency}}</span>
						</div>

					</li>

				</ul>

				<div class="actions">
					<div class="round-button filled" (click)="goToCheckout()" [attr.disabled]="mixMatchInvalid ? true : null">Go to checkout</div>
					<div class="round-button" (click)="goShop()">Go back to shop</div>
					<div class="round-button clear-cart" (click)="clearCart()">Clear Cart</div>
				</div>
			</div>

		</ng-container>
	</div>
</div>

<ng-template #cartEmpty>
	<div class="cart-empty">You have nothing in your shopping cart</div>
</ng-template>

<p-dialog [(visible)]="showCartTypeDialog" [dismissableMask]="true" position="bottom" styleClass="cart-type-dialog">
	<ng-template pTemplate="header">Order Type</ng-template>

	<div class="order-types">
		<div class="order-type">
			<h4>Drop Ship</h4>
			<p>Select if you are buying these items to be delivered directly to one of your customers</p>

			<div class="round-button" (click)="setOrderType('dropship')">Select</div>

		</div>


		<div class="order-type shaded">
			<h4>Standard Order</h4>
			<p>Select if you are buying these items to be delivered to your business</p>
			<div class="round-button" (click)="setOrderType('standard')">Select</div>

		</div>
	</div>
	<div *ngIf="containsSpecialOrderItems">
		<div class="order-types">
			<div class="order-types terms">
				<br /><br />Your order contains Special Order items. Special Order items are not held in regular stock and are ordered at the specific request of the dealer. As such, these items cannot be cancelled, returned, or exchanged. Please reach out to your Business Relationship Manager with any questions.
			</div>
		</div>
		<br /><br />
		<div id="warningMessage">
			By selecting this box, I acknowledge special order policy.	<input type="checkbox" ng-model="value" (click)="setAck($event)">
		</div>

	</div>

</p-dialog>

<p-dialog [(visible)]="showAddressSelection" [dismissableMask]="true" styleClass="address-selection-dialog">
	<ng-template pTemplate="header">Standard</ng-template>
	<form [formGroup]="form" *ngIf="form && orderTypeId == 1">
		<div class="address-form">
			<div class="shipping">
				<h2>Shipping address</h2>
				<p *ngIf="addressOptions.length > 1">Please select your shipping address</p>
				<p-dropdown *ngIf="addressOptions.length > 1" [options]="addressOptions" formControlName="addressId" optionValue="value" placeholder="Select an address" styleClass="address-selection-dropdown"></p-dropdown>
				<p *ngIf="addressOptions.length == 1">{{addressOptions[0].label}}</p>
			</div>
			<div class="po-signature">
				<h2>PO Number</h2>
				<p>Enter a unique PO number for this order</p>
				<input pInputText formControlName="poNumber" required>
				<h2>Signature</h2>
				<input pInputText formControlName="signature"  required >
			</div>
		</div>
		<div class="error" *ngIf="errorMessage">{{errorMessage}}</div>
	</form>
	<ng-template pTemplate="footer">
		<div>&nbsp;</div>
		<div class="round-button" (click)="submitOrder()">Finalize Order</div>
	</ng-template>
</p-dialog>


<p-dialog [(visible)]="showDropShipAddress" [dismissableMask]="true" styleClass="drop-ship-address-dialog" >
	<ng-template pTemplate="header">Drop Ship</ng-template>
	<form [formGroup]="form" *ngIf="form && orderTypeId == 5">
	  <div class="address-form">
			<div class="shipping">
				<h2>Shipping address</h2>
				<p>Enter drop ship address below</p>

				<div class="field">
					<span class="p-float-label">
						<input type="text" pInputText formControlName="companyName" id="companyName" (keypress)="omit_special_char($event)">
						<label for="companyName">Company Name</label>
					</span>
				</div>


				<div class="field">
					<span class="p-float-label">
						<input type="text" pInputText formControlName="firstName" id="firstName" (keypress)="omit_special_char($event)">
						<label for="firstName">First Name</label>
					</span>
				</div>

				<div class="field">
					<span class="p-float-label">
						<input type="text" pInputText formControlName="lastName" id="lastName" (keypress)="omit_special_char($event)">
						<label for="lastName">Last Name</label>
					</span>
				</div>


				<div class="field">
					<span class="p-float-label">
						<input type="text" pInputText formControlName="address1" id="address1" (keypress)="omit_special_char($event)">
						<label for="address1">Address 1</label>
					</span>
				</div>


				<div class="field">
					<span class="p-float-label">
						<input type="text" pInputText formControlName="address2" id="address2" (keypress)="omit_special_char($event)">
						<label for="address2">Address 2</label>
					</span>
				</div>

				<div class="field">
					<span class="p-float-label">
						<input type="text" pInputText formControlName="city" id="city" (keypress)="omit_special_char($event)">
						<label for="city">City</label>
					</span>
				</div>

				<!--<div class="field">
	<label for="state">State</label>
	<input type="text" pInputText formControlName="state" id="state">
	</div>-->

				<div class="field">
					<span class="p-float-label">
						<p-dropdown id="state" appendTo="body" [options]="cities" formControlName="state"></p-dropdown>
						<label for="state">State</label>
					</span>
				</div>

				<div class="field">
					<span class="p-float-label">
						<input pInputText formControlName="zip" id="zip" pKeyFilter="int">
						<label for="zip">Postal Code</label>
					</span>
				</div>
				<div class="field">
					<span class="p-float-label">
						<input type="text" pInputText formControlName="phone" id="phone" pKeyFilter="int">
						<label for="phone">Phone</label>
					</span>
				</div>
			</div>



			<div class="po-signature">


				<h2>PO Number</h2>

				<p>Enter a unique PO number for this order</p>
				<input pInputText formControlName="poNumber" pKeyFilter="alphanum">


				<h2>Signature</h2>
				<input pInputText formControlName="signature" pKeyFilter="alphanum">
				<div class="field">
					<label for="email">Email</label>
					<input type="text" pInputText formControlName="email" id="email">
				</div>
				<h2>Shipping Charge</h2>
				<div>{{dropShipShippingCharge | currency}}</div>
			</div>
			</div>
	</form>
	<ng-template pTemplate="footer">
		<div>&nbsp;</div>
		<div class="round-button" (click)="submitOrder()">Finalize Order</div>
	</ng-template>
</p-dialog>
