import { Image } from "./image";

export class Author {
	public name: string;
	public email: string;
	public avatar: Image;

	constructor(o?:any) {
		if(o?.avatar) {
			o.avatar = new Image({ id: o.avatar.data.id, ...o.avatar.data.attributes })
		}
		Object.assign(this, o);
	}
}