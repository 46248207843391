import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { FileUpload } from "primeng/fileupload";
import { Sidebar } from "primeng/sidebar";
import { switchMap } from "rxjs/operators";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { ShippingError, ShippingErrorProduct } from "src/app/models/dashboard/shipping-error";
import { ProUser } from "src/app/models/pro-user";
import { TableColumn, TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SetDashboardHeaderButtons, SetDashboardTitle, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-shipping-errors",
	templateUrl: "./shipping-errors.component.html",
	styleUrls: ["./shipping-errors.component.scss"],
})
export class ShippingErrorsComponent implements OnInit {
	@ViewChild('sidebar') sidebar: Sidebar;
	public data: Array<ShippingError>
	public sidebarVisible: boolean = false;

	constructor(private store: Store<IAppState>, private authService: AuthService, private dataService: DataService, private changeDetector: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle("Shipping Errors"));
		let buttons: Array<DashboardHeaderButton> = [];
		buttons.push(new DashboardHeaderButton({ text: "Submit New", icon: "add", callback: () => this.addNewShippingError() }));
		this.store.dispatch(new SetDashboardHeaderButtons(buttons));
		this.dataService.getShippingErrors().subscribe((resp:any) => this.data = resp.map(i => new ShippingError(i)))
	}

	get tableInfo(): TableInfo {
		return new TableInfo({
      columns: [
        new TableColumn({ field: 'id', title: 'Shipping Error ID' }),
				new TableColumn({ field: 'storeName', title: 'Store Name' }),
				new TableColumn({ field: 'name', title: 'Name' }),
				new TableColumn({ field: 'email', title: 'Email' }),
		
			],
			hasSubtable: true,
			service: this.dataService,
			dataMethod: 'getShippingErrorDetails',
			rowKey: 'id',
			subtableInfo: new TableInfo({
				columns: [
					new TableColumn({ field: 'productCode', title: 'Product Code' }),
					new TableColumn({ field: 'description', title: 'Description' }),
					new TableColumn({ field: 'quantity', title: 'Quantity' }),
					new TableColumn({ field: 'errorType', title: 'Error Type' }),
				]
			})
		})
	}

	onSubmit(err) {
		this.sidebar.close(null);
		//this.sidebarVisible = false;
		this.dataService.getShippingErrors().subscribe((resp:any) => this.data = resp.map(i => new ShippingError(i)));
	}

	addNewShippingError() {
		this.sidebarVisible = true;
	}
}

