import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { SiteService } from "src/app/services/site.service";
import { TableColumn, TableInfo } from "../export-dialog/export-dialog.component";
import { takeUntil } from 'rxjs/operators';
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";

@Component({
	selector: "app-table",
	templateUrl: "./table.component.html",
	styleUrls: ["./table.component.scss"],
})
export class TableComponent extends AutoUnsubscribeMixin() implements OnInit, OnChanges {
	@Input() tableInfo: TableInfo;
	@Input() data: Array<any>;
	@Input() caller: any;
	@Output() onCheckboxChecked: EventEmitter<any> = new EventEmitter();
	@Output() onCheckboxUnchecked: EventEmitter<any> = new EventEmitter();

	public columns: Array<TableColumn>;
	public columnCount: number;
	public columnWidths: string;
	public idColName: string;
	public expandedRow: any;
	public sortCol: string;
	public sortDir: number;
	public pageData: Array<any>;

	public pageSize = 999999;
	public pageNumber = 1;
	public pageCount: number;

	private currentSize: string;
	constructor(private siteService: SiteService) { super(); }

	ngOnInit(): void {
		this.siteService.onResize.pipe(takeUntil(this.destroy$)).subscribe(size => {
			this.currentSize = size;
			this.setColumnsAndWidths();
		})
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.data?.currentValue) {
			if(this.tableInfo.paging) {
				this.pageNumber = 1;
				this.pageSize = this.tableInfo.paging.pageSize;
				this.pageCount = Math.ceil(this.data.length / this.pageSize);
				this.setData();
		
			} else {
				this.pageData = this.data;
			}
			this.expandedRow = null;
		}
		if(changes.tableInfo?.currentValue) {
			this.setColumnsAndWidths();
			let idCol = this.columns.filter(c => c.id == true)[0]
			if(idCol) {
				this.idColName = idCol.field
			} else {
				this.idColName = this.columns[0].field;
			}
		} 
	}

	setColumnsAndWidths() {
		this.columnWidths = this.tableInfo.hasSubtable ? '36px ' : ''
		let myCols;
		switch(this.currentSize) {
			case 'xs': 
				myCols = this.tableInfo.columns.filter(c => (c.hideAtBp == undefined || c.hideAtBp > 3) && c.type !== 'hidden');
				this.columnCount = myCols.length + (this.tableInfo.hasSubtable ? 1 : 0);
				this.columnWidths += myCols.reduce((a,b) => a + (b.width || '1fr') + ' ', '');
				break;
			case 's': 
				myCols = this.tableInfo.columns.filter(c => (c.hideAtBp == undefined || c.hideAtBp > 2) && c.type !== 'hidden');
				this.columnCount = myCols.length + (this.tableInfo.hasSubtable ? 1 : 0);
				this.columnWidths += myCols.reduce((a,b) => a + (b.width || '1fr') + ' ', '');
				break;
			case 'm': 
				myCols = this.tableInfo.columns.filter(c => (c.hideAtBp == undefined || c.hideAtBp > 1) && c.type !== 'hidden');
				this.columnCount = myCols.length + (this.tableInfo.hasSubtable ? 1 : 0);
				this.columnWidths += myCols.reduce((a,b) => a + (b.width || '1fr') + ' ', '');
				break;
			case 'l': 
				myCols = this.tableInfo.columns.filter(c => (c.hideAtBp == undefined || c.hideAtBp > 0) && c.type !== 'hidden');
				this.columnCount = myCols.length + (this.tableInfo.hasSubtable ? 1 : 0);
				this.columnWidths += myCols.reduce((a,b) => a + (b.width || '1fr') + ' ', '');
				break;
			default: 
				myCols = this.tableInfo.columns.filter(c => c.type !== 'hidden');
				this.columnCount = myCols.length + (this.tableInfo.hasSubtable ? 1 : 0);
				this.columnWidths += myCols.reduce((a,b) => a + (b.width || '1fr') + ' ', '');
				break;
		}
		this.columns = myCols;
	}

	goToPage(pg) {
		this.pageNumber = pg;
		this.setData();
	}
	setData() {
		let first = (this.pageNumber - 1) * this.pageSize;
		let last = first + this.pageSize;
		this.pageData = this.data.slice(first, last);
		this.pageCount = Math.ceil(this.data.length / this.pageSize)
	}

	checkboxChecked(e, data) {
		if (e.checked) {
			this.onCheckboxChecked.next(data);
		} else {
			this.onCheckboxUnchecked.next(data);
		}
	}

	doCallback(fn, row) {
		fn(row, this.caller);
	}

	expand(row) {
		if(row[this.tableInfo.rowKey] == this.expandedRow) {
			this.expandedRow = '';
		} else {
			this.expandedRow = row[this.tableInfo.rowKey];
			this.tableInfo.service[this.tableInfo.dataMethod](row[this.tableInfo.rowKey]).subscribe(resp => row.subtableData = resp)
		}
	}
	sort(col) {
		if(!col.sort) return;
		if(col.field == this.sortCol) {
			this.sortDir = this.sortDir * -1;
		} else {
			this.sortDir = 1;
		}
		this.sortCol = col.field;
		this.data.sort(this.getSortFn(col.field, this.sortDir));
		this.setData();
	}

	sum(field) {
		if(!this.data) {
			return 0;
		}
		return this.data.reduce((a,b) => a + b[field], 0);
	}
	getSortFn(field:string, dir: number) {
		return (a,b) => {
			if(a[field] < b[field]) return -1 * dir;
			if(a[field] > b[field]) return 1 * dir;
			return 0
		}
	}
}
