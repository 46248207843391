<div class="comment">
	<div class="comment-content">
		<div>{{comment.content}}</div>
	</div>
	<div class="reply-link" [permission]="Permissions.Comments" (click)="showReply = !showReply">Reply to comment</div>
	<div class="avatar" [ngStyle]="{'background-image': 'url('+comment.author.avatar+')'}"></div>
	<div class="author">{{comment.author.name}}</div>
	<div class="date">{{comment.createdAt | date: 'MMM dd, yyyy'}}</div>
	<div class="reply" [permission]="Permissions.Comments"><app-comment-form *ngIf="showReply" [articleId]="articleId" [threadOf]="comment.id" (onAddComment)="addComment($event)" (onCancel)="showReply = false"></app-comment-form></div>

	<div class="children">
		<ng-container *ngIf="comment.children?.length > 0">
			<div *ngFor="let child of comment.children">
				<app-comments-display [comment]="child" [articleId]="articleId"></app-comments-display>
			</div>
		</ng-container>
	</div>
</div>
