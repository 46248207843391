import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleComponent } from './components/article/article.component';
import { CommentsDisplayComponent } from './components/comments-display/comments-display.component';
import { CommentFormComponent } from './components/comment-form/comment-form.component';
import { FormsModule } from '@angular/forms';
import { VendorComponent } from './components/vendor/vendor.component';
import { VendorCardComponent } from './components/vendor/vendor-card/vendor-card.component';
import { RouterModule } from '@angular/router';
import { ControlsModule } from '../controls/controls.module';
import { NewsfeedComponent } from './components/newsfeed/newsfeed.component';
import { NewsfeedArticleTileComponent } from './components/newsfeed-article-tile/newsfeed-article-tile.component';
import { InteractionsComponent } from './components/interactions/interactions.component';
import { VendorsComponent } from './components/vendors/vendors.component';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { MatIconModule } from '@angular/material/icon';
import { CarouselModule } from 'primeng/carousel';
import { NewsfeedFilteredComponent } from './components/newsfeed-filtered/newsfeed-filtered.component';
import { NewsfeedHeaderComponent } from './components/newsfeed-header/newsfeed-header.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { NewsfeedArticleListingComponent } from './components/newsfeed-article-listing/newsfeed-article-listing.component';
import { DropdownModule } from 'primeng/dropdown';
import { HeadtoheadComponent } from './components/headtohead/headtohead.component';
import { HeadtoheadHeaderComponent } from './components/headtohead-header/headtohead-header.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';




@NgModule({
  declarations: [
    ArticleComponent,
    CommentsDisplayComponent,
    CommentFormComponent,
    VendorComponent,
    VendorCardComponent,
    NewsfeedComponent,
    NewsfeedArticleTileComponent,
    InteractionsComponent,
    VendorsComponent,
    NewsfeedFilteredComponent,
    NewsfeedHeaderComponent,
    NewsletterComponent,
    NewsfeedArticleListingComponent,
    HeadtoheadComponent,
    HeadtoheadHeaderComponent
  ],
  imports: [
    CommonModule,
	FormsModule,
	RouterModule,
    ControlsModule,
    PdfViewerModule,
	InputTextModule,
	DropdownModule,
	SidebarModule,
	MatIconModule,
	CarouselModule,
  ]
})
export class CmsModule { }
