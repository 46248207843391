import { Cart, CartItem } from "src/app/models/shopping/cart";
import { CartActions, CartActionTypes } from "../actions/cart.actions";
import { ICartState, initialCartState } from "../state/cart.state";


export function cartReducer(state: ICartState = initialCartState, action: CartActions):ICartState {
	switch(action.type) {
		case CartActionTypes.GET_CART:
			return state;
		case CartActionTypes.GET_CART_SUCCESS:
			let newcartItems = [ ...action.cart.cartItems].map(i => new CartItem(i));
			newcartItems.sort((a,b) => {
				if(a.sortID < b.sortID) return -1;
				if(a.sortID > b.sortID) return 1;
				return 0;
			});
			return { ...state, cart: new Cart({ ...action.cart, cartItems: newcartItems }) };
		case CartActionTypes.GET_CART_SPECIALS:
			return state;
		case CartActionTypes.GET_CART_SPECIALS_SUCCESS: 
			let cartItemsWSpecials = state.cart.cartItems.map(i => ({ ...i, appliedSpecial: action.appliedSpecials.find(s => s.cartItemId == i.cartItemId ) }))
			return { ...state, cart: new Cart({ ...state.cart, cartItems: cartItemsWSpecials}), appliedSpecials: action.appliedSpecials };
		case CartActionTypes.UPDATE_CART:
			return { ...state, cart: action.cart };
		case CartActionTypes.ADD_SPECIAL_TO_CART_ITEM:
			let cartItems = [ ...state.cart.cartItems];
			let updatedItemIndex = cartItems.findIndex(i => i.cartItemId == action.special.cartItemId);
			if(updatedItemIndex > -1) { //should always be the case
				cartItems.splice(updatedItemIndex, 1, new CartItem({ ...cartItems[updatedItemIndex], appliedSpecial: action.special }))
			}
			return { ...state, cart: new Cart({ ...state.cart, cartItems }) };
		case CartActionTypes.OPEN_CART:
			return { ...state, isCartOpen: true };
		case CartActionTypes.CLOSE_CART:
			return { ...state, isCartOpen: false }
		case CartActionTypes.ADD_TO_CART:
			//effect called to add item and update cart
			return state;
		case CartActionTypes.ADD_TO_CART_SUCCESS:
			return state;
		case CartActionTypes.ADD_MULTIPLE_TO_CART:
			//effect called to add item and update cart
			return state;
		case CartActionTypes.ADD_MULTIPLE_TO_CART_SUCCESS:
			return state;
		case CartActionTypes.EDIT_CART_ITEM:
			//effect called to add item and update cart
			return state;
		case CartActionTypes.DELETE_FROM_CART:
			//effect called to add item and update cart
			return state;
		case CartActionTypes.SORT_CART_ITEMS:
			let items = [ ...state.cart.cartItems];
			items.sort((a,b) => {
				if(a.product[action.sortField] < b.product[action.sortField]) return -1;
				if(a.product[action.sortField] > b.product[action.sortField]) return 1;
				return 0;
			});
			return { ...state, cart: new Cart({ ...state.cart, cartItems: items }) };
		case CartActionTypes.GET_DROP_SHIP_COST_SUCCESS:
			return { ...state, dropShipCost: action.dropShipCost }
		case CartActionTypes.SET_CARTITEM_ALERTS:
			/*
			let ci = state.cart.cartItems.find(c => c.cartItemId == action.cartItemId);
			if(ci) {
				let newCartItem = new CartItem({ ...ci, alerts: [ ...ci.alerts, action.alert] });
				let cartItems = [ ...state.cart.cartItems.filter(i => i.cartItemId != action.cartItemId), newCartItem ]
				return { ...state, cart: new Cart({ ...state.cart, cartItems })}
			} else {
				return state;
			}
			*/
			return { ...state, cartItemAlerts: action.alerts}
		default: 
			return state;
	}
}