import { Injectable } from "@angular/core";
import { Router, UrlSegment } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ProUser } from "../models/pro-user";
import { GetCart } from "../store/actions/cart.actions";
import { GetEzPayStatus, Login, LoginSuccess, Logout, UserActionTypes } from "../store/actions/user.actions";
import { IAppState } from "../store/state/app.state";

@Injectable({
	providedIn: "root",
})
export class AuthService {
  private _user;

	constructor(private actions$: Actions, private router: Router, private store: Store<IAppState>) {}

	//Auth
	set token(token: string) {
		document.cookie = `token=${token}; path=/`;
		//sessionStorage.setItem("token", token);
	}
	get token(): string {
		var match = document.cookie.match(new RegExp('(^| )token=([^;]+)'));
  		if (match) return match[2];
		return '';
		//return sessionStorage.getItem("token");
	}
	set redirectTo(page: string) {
		if(page !== '/login') {
			sessionStorage.setItem("redirectTo", page);
		}
	}
	get redirectTo() {
		let page: string;
		page = sessionStorage.getItem("redirectTo") || '/';
		return page;
	}

  login(username: string, password: string)  {
		this.store.dispatch(new Login(username, password));
		this.actions$.pipe(
			tap(a => {
        if (a.type == UserActionTypes.LOGIN_SUCCESS) {       
       
          this.router.navigateByUrl(this.redirectTo);
          this.token = (a as LoginSuccess).token;
          this.store.dispatch(new GetCart());
          this.store.dispatch(new GetEzPayStatus())
        }
      
      }
      )
    ).subscribe() 
	}

	logout() {
		sessionStorage.removeItem("token");
    this.store.dispatch(new Logout());
	}

  isLoggedIn()
  {
		return this.token != null;
	}




	get user(): ProUser {
		return new ProUser(JSON.parse(atob(this.token.split('.')[1])));
	}
}
