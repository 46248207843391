<div class="container">
	<h1>Thank you for your payment</h1>
	<div class="number">Receipt Number: <span>{{config.data.receiptNumber}}</span></div>
	<div class="invoices" *ngIf="invoices">
		<div class="row header">
			<div class="invoice-number">Invoice Number</div>
			<div class="deduction-date">Posted Date</div>
			<div class="vendor">Vendor</div>
			<div class="vendor-invoice">Vendor Invoice Number</div>
			<div class="gross">Gross</div>
			<div class="pro-discount">Pro Discount</div>
			<div class="ezpay-discount">EZ-Pay Discount</div>
			<div class="net">Net to Pasy</div>
		</div>
		<div class="row" *ngFor="let invoice of invoices">
			<div class="invoice-number">{{invoice.invoice}}</div>
			<div class="deduction-date">{{invoice.date | date: 'M/d/yyyy'}}</div>
			<div class="vendor">{{invoice.vendor}}</div>
			<div class="vendor-invoice">{{invoice.vendorInvoiceNumber}}</div>
			<div class="gross">{{invoice.gross | currency}}</div>
			<div class="pro-discount">{{invoice.proDiscount | currency}}</div>
			<div class="ezpay-discount">{{invoice.ezPayDiscount | currency}}</div>
			<div class="net">{{invoice.totalToPay | currency}}</div>
		</div>
		<div class="row totals">
			<div class="invoice-number"></div>
			<div class="deduction-date"></div>
			<div class="vendor"></div>
			<div class="vendor-invoice"></div>
			<div class="gross">{{sum('gross') | currency}}</div>
			<div class="pro-discount">{{sum('proDiscount') | currency}}</div>
			<div class="ezpay-discount">{{sum('ezPayDiscount') | currency}}</div>
			<div class="net">{{sum('totalToPay') | currency}}</div>
		</div>
	</div>
	<div class="actions">
		<div class="button primary" (click)="showDownloadOptions()">Export</div>
		<div class="button primary" (click)="print()">Print</div>
		<div class="button" (click)="close()">Close</div>
	</div>
</div>
