
<div class="container">
	<div class="user-select">
		<label>Select a user to modify</label>
		<p-dropdown [options]="users" [(ngModel)]="userId" (onChange)="setUser($event)" [autoDisplayFirst]="false" name="user"></p-dropdown>
	</div>



	

	<div class="permissions">
		<p-pickList  sourceHeader="Available Permissions" targetHeader="Allocated Permissions"  [source]="allPermissions" [target]="userPermissions" [showSourceControls]="false" [showTargetControls]="false">
			<ng-template pTemplate="item" let-permission>
				<div class="permission">{{permission.permissionName}}</div>
				<div class="description">{{permission.description}}</div>
			</ng-template>
		</p-pickList>
	</div>
	<div class="actions">
		<div class="button" (click)="savePermissions()">Save</div>
	</div>
</div>
