
<div class="pane">
	<header>
		<h1>{{title}}</h1>
		<div class="buttons">
			<div class="button" *ngFor="let button of buttons" class="{{button.cssClass}}" (click)="do(button.callback)">
				<mat-icon *ngIf="button.icon">{{button.icon}}</mat-icon>{{button.text}}
			</div>
		</div>
	</header>
	<main>
		<router-outlet></router-outlet>
	</main>
</div>

