import { Component, OnInit } from "@angular/core";
import { Company } from "src/app/models/dashboard/company";
import { DataService } from "src/app/services/data.service";

@Component({
	selector: "app-dealers",
	templateUrl: "./dealers.component.html",
	styleUrls: ["./dealers.component.scss"],
})
export class DealersComponent implements OnInit {

    public companies: Array<Company>;

	public companiesToShow: Array<Company>
    public searchTerm: string;
    public SelectedState: string;
    public SelectedRetailerType: string;
    public RosterZip: string;
    public SelectedDistance: number;


	constructor(private dataService: DataService) {}


  public states = [
    { label: "Search By State", value: "" },
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Canal Zone", value: "CZ" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District of Columbia", value: "DC" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Guam", value: "GU" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" }
  ];


  public retailerType =
    [
    { label: "Retailer Type", value: ""},
    { label: "Patron", value: "1"},
    { label: "Affiliate", value: "2"},
    { label: "Client", value: "3"},
    { label: "All", value: "4"}
    ];

  public Distance  =  [
    { label: "Proximity", value: "" },
    { label: "10mi", value: 10 },
    { label: "30mi", value: 30 },
    { label: "50mi", value: 50 },
    { label: "100mi", value: 100 }
  ];

	ngOnInit(): void {
		this.dataService.getMembers().subscribe((c) => {
			this.companies = c;
			this.companiesToShow = c;
		});
	}

   search() {
    const searchTerm = this.searchTerm.toLowerCase();
    this.companiesToShow = this.companies.filter(c => c.dba.toLowerCase().includes(searchTerm) || c.legalName.toLowerCase().includes(searchTerm) );
  }

  searchDistance(event) {
    this.SelectedDistance = event.value;

    const ZipSearch = this.RosterZip.toLowerCase();

    if (this.SelectedDistance == null)
      this.SelectedDistance = 200;


    this.dataService.getMembersZip(ZipSearch, this.SelectedDistance).subscribe((c) => {
      this.companies = c;
      this.companiesToShow = c;
    });

  }

  searchZip() {
    const ZipSearch = this.RosterZip.toLowerCase();
  
    if (this.SelectedDistance ==null) 
      this.SelectedDistance = 200;

    
    this.dataService.getMembersZip(ZipSearch, this.SelectedDistance).subscribe((c) => {
      this.companies = c;
      this.companiesToShow = c;
    });
  }


  reset() {
    this.SelectedState = null;
    this.SelectedRetailerType = null;
    this.searchTerm = null;
    this.RosterZip = null;
    this.SelectedDistance = null;


    

        this.dataService.getMembers().subscribe((c) => {
        this.companies = c;
        this.companiesToShow = c;
      });
  }

  searchState(event) {
    const state = event.value.toLowerCase();
    this.SelectedState = state;
    this.companiesToShow = this.companies.filter(c => c.state.toLowerCase().includes(this.SelectedState)).sort((a, b) => (a.dba < b.dba ? -1 : 1));;
  }

  searchRetailerType(event) {
    const retailerType = event.value;
    if (event.value == "1")
      this.SelectedRetailerType = "Patron";
    if (event.value == "2")
      this.SelectedRetailerType = "Affiliate";
    if (event.value == "3")
      this.SelectedRetailerType = "Client";



    if (this.SelectedState != null)
    {
      //Includes state.
      this.companiesToShow = this.companies.filter(c => c.customerType.includes(this.SelectedRetailerType) && c.state.toLowerCase().includes(this.SelectedState)).sort((a, b) => (a.dba < b.dba ? -1 : 1));
       if (event.value == "4")
        this.companiesToShow = this.companies.filter(c =>  c.state.toLowerCase().includes(this.SelectedState)).sort((a, b) => (a.dba < b.dba ? -1 : 1));

    }
    else
    {
      this.companiesToShow = this.companies.filter(c => c.customerType.includes(this.SelectedRetailerType)).sort((a, b) => (a.dba < b.dba ? -1 : 1));;
       if (event.value == "4")
        this.companiesToShow = this.companies.sort((a, b) => (a.dba < b.dba ? -1 : 1));
    }

    

  }



}
