import { CurrencyPipe } from "@angular/common";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { filter, take } from "rxjs/operators";
import { CartItem, CartItemAlert } from "src/app/models/shopping/cart";
import { Product } from "src/app/models/shopping/product";
import { AppliedSpecialDetail } from "src/app/models/shopping/specials";
import { AddToCart, CartActionTypes, EditCartItem } from "src/app/store/actions/cart.actions";
import { SuccessMessageSet } from "src/app/store/actions/site.actions";
import { selectCartItemAlerts } from "src/app/store/selectors/cart.selectors";
import { IAppState } from "src/app/store/state/app.state";
import { environment } from "src/environments/environment";
import { Permissions } from "../../enums/permissions";
import { MixMatchComponent } from "../mix-match/mix-match.component";

@Component({
	selector: "app-product-tile",
	templateUrl: "./product-tile.component.html",
	styleUrls: ["./product-tile.component.scss"],
})
export class ProductTileComponent implements OnInit, OnChanges {
	@Input() product: Product;
	@Input() cartItem: CartItem;
	@Input() mode: 'tile' | 'row' | 'summary' = 'tile';

	public quantity: number = 1;
	public promosVisible: boolean = true;
	public Permissions = Permissions;
	public environment = environment;
	private ref: DynamicDialogRef;
	public cartItemAlerts: {[key:number]:CartItemAlert}

	constructor(private store: Store<IAppState>, private currencyPipe: CurrencyPipe, private dialogService: DialogService) {}

	ngOnInit(): void {
		if(this.cartItem && !this.product) {
			this.product = this.cartItem.product;
		}
		this.quantity = this.cartItem ? this.cartItem.quantity : this.product.multiple  || 1;
		this.store.select(selectCartItemAlerts).subscribe(a => this.cartItemAlerts = a)
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.cartItem?.currentValue) {
			this.cartItem = new CartItem(changes.cartItem.currentValue);
		}
	}
	notifyCopy(payload) {
		this.store.dispatch(new SuccessMessageSet(`Copied ${payload} to clipboard`));
	}

	addToCart() {
		if(this.product.returnCode == 10) {
			//mix/match product
			this.ref = this.dialogService.open(MixMatchComponent, {
				data: { mixMatchId: this.product.data.mixMatchId},
				header: 'Mix and Match',
				showHeader: true,
			});
			return;
		}
		if(this.quantity > 0) {
			this.store.dispatch(new AddToCart(this.product.productCode, this.quantity));
		}
	}

	updateQuantity() {
		this.store.dispatch(new EditCartItem(this.cartItem.cartItemId, this.cartItem.product.productCode, this.quantity, this.cartItem.rejectUpsell))
	}
	deleteItem(cartItemId: number) {
		this.store.dispatch(new EditCartItem(cartItemId, this.cartItem.product.productCode, 0, true));
	}
	acceptUpsell() {
		this.store.dispatch(new EditCartItem(this.cartItem.cartItemId, this.cartItem.product.productCode, this.cartItem.itemUpsell.upsellQuantity, true));
	}
	rejectUpsell() {
		this.store.dispatch(new EditCartItem(this.cartItem.cartItemId, this.cartItem.product.productCode, this.cartItem.itemUpsell.noUpsellQuantity, true));
	}
	sortSpecialDetails(a: AppliedSpecialDetail, b: AppliedSpecialDetail) {
		if(a.special == null && b.special != null) { return -1; }
		if(a.special != null && b.special == null) { return 1; }
		return 0;
	}
	increment(n) {
		this.quantity += this.product.multiple * n;
		this.quantity = Math.max(this.quantity, 0);
		if(this.cartItem) {
			this.updateQuantity();
		}
	}
	setQuantity() {
		if(this.cartItem) {
			this.updateQuantity();
		}
	}
	getPriceToShowForLite(ext: boolean = false): string {
		let price;
		if(this.cartItem.appliedSpecial) {
			let price, savings;
			price = this.cartItem.appliedSpecial.details.reduce((a,i) => a + ( ext ? i.quantity : 1) * i.specialPrice, 0);
			savings = (this.product.retail * (ext ? this.quantity : 1)) - price;
			return `${this.currencyPipe.transform(price)} (Save ${this.currencyPipe.transform(savings)})`;
		} else {
			return this.currencyPipe.transform(this.cartItem.itemPrice * (ext ? this.cartItem.quantity : 1));
		}
	}
	closePromo(type) {}

	editMMQuantity(id) {
		let ref = this.dialogService.open(MixMatchComponent, {
			data: { mixMatchId: id}, 
			header: 'Mix and Match'
		});

	}
}
