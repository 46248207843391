import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Cart, CartBundle, CartItem } from "src/app/models/shopping/cart";
import { DataService } from "src/app/services/data.service";
import { SiteService } from "src/app/services/site.service";
import { CartActionTypes, CloseCart, EditCartItem, GetCartSuccess } from "src/app/store/actions/cart.actions";
import { selectCart } from "src/app/store/selectors/cart.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-cart-tile",
	templateUrl: "./cart-tile.component.html",
	styleUrls: ["./cart-tile.component.scss"],
})
export class CartTileComponent implements OnInit, OnDestroy {
	public cart: Cart;
	private destroy$: Subject<boolean> = new Subject();
	constructor(public dataService: DataService, private store$: Store<IAppState>, private actions$: Actions, private router: Router) {}

	ngOnInit(): void {
		this.actions$
			.pipe(
				map((a) => {
					switch (a.type) {
						case CartActionTypes.GET_CART_SUCCESS:
							this.cart = new Cart((a as GetCartSuccess).cart);
					}
				})
			)
			.subscribe();

		this.store$.pipe(select(selectCart)).subscribe(cart => this.cart = cart);
	}


/*
	updateBundleQuantity(cartBundleId: number) {
		const bundle: CartBundle = this.cart.cartBundles.find((i) => i.cartBundleId == cartBundleId);
		this.dataService.updateCartBundle(cartBundleId, bundle.quantity).subscribe(() => this.loadCart());
	}
*/
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
	closeCart() {
		this.store$.dispatch(new CloseCart());
	}

	goToCheckout() {
		this.router.navigate(['/shop', 'cart']);
	}
}
