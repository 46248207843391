import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[hideable]'
})

export class HideableDirective {
	@Input("hideable") public payload: string; //this is the transition, should be for height
	private defaultTransition = 'height 250ms cubic-bezier(0, 0, 0.2, 1)'

  	constructor(el:ElementRef) {
		
		el.nativeElement.style.height = '0px';
		el.nativeElement.style.overflow = 'hidden';
		let changes = new MutationObserver((mutations: MutationRecord[]) => {
			mutations.forEach(m => {
				let target = m.target as HTMLElement;
				if(target.hasAttribute('hideable')) {
					if(target.classList.contains('open')) {
						target.style.transition = this.payload || this.defaultTransition;
						target.style.height = String(target.scrollHeight) + 'px';
						target.style.overflow = 'hidden';
					} else {
						target.style.height = '0px';
					}
				} else {
					
				}
				
			})
		})
		changes.observe(el.nativeElement, { attributes: true, attributeFilter: ['class'], subtree: true })
	}
}