import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Address, ADDRESS_TYPE } from "src/app/models/account/address";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { UndoMessage } from "src/app/models/dashboard/undo-message";
import { DataService } from "src/app/services/data.service";
import { SetDashboardHeaderButtons, SetDashboardTitle, SetUndoMessage } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-store-address",
	templateUrl: "./store-address.component.html",
	styleUrls: ["./store-address.component.scss"],
})
export class StoreAddressComponent implements OnInit {
	public addresses: {[key: number]: Array<Address>} = {};
	public privateAddresses: Array<Address>;
	public otherAddresses: Array<Address>;
	public showAddressForm: boolean = false;
	public addEditAddress: Address;
	public openActions: number;
	public Address_Types = ADDRESS_TYPE;

	constructor(private dataService: DataService, private store: Store<IAppState>) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle('Addresses'))
		this.store.dispatch(new SetDashboardHeaderButtons([
			new DashboardHeaderButton({ text: 'Add New Store Address', callback: () => this.addNew() })
		]))
		this.dataService.getAddresses(ADDRESS_TYPE.Store).subscribe((a) => (this.addresses[ADDRESS_TYPE.Store] = a));
		this.dataService.getAddresses(ADDRESS_TYPE.User_Private).subscribe((a) => (this.addresses[ADDRESS_TYPE.User_Private] = a));
        this.dataService.getAddresses(ADDRESS_TYPE.Other).subscribe((a) => (this.addresses[ADDRESS_TYPE.Other] = a));
        this.dataService.getAddresses(ADDRESS_TYPE.Shipping).subscribe((a) => (this.addresses[ADDRESS_TYPE.Shipping] = a));
        //Will add shipping here. 

		document.getElementsByTagName('body')[0].addEventListener('click', () => this.openActions = 0)

	}

	showActions(e, address) {
		this.openActions = address.addressId;
		e.stopPropagation();
	}

	addNew() {
		this.addEditAddress = new Address();
		this.showAddressForm = true;
	}
	edit(address: Address) {
		this.addEditAddress = address;
		this.showAddressForm = true;
	}
	delete(address: Address) {
		this.dataService.deleteAddress(address).subscribe(resp => {
			this.addresses[address.addressTypeId] = this.addresses[address.addressTypeId].filter(a => a.addressId != address.addressId);
			this.store.dispatch(new SetUndoMessage(new UndoMessage({intro: 'Address deleted', undoText: 'UNDO DELETE', callback: this.undoDelete, params: {address}})))
		})
	}

	saveAddress(address: Address) {
		this.showAddressForm = false;
		if(address.addressId > 0) {
			this.dataService.editAddress(address).subscribe((resp:any) => {
				this.addresses[address.addressTypeId] = this.addresses[address.addressTypeId].map(a => a.addressId == resp.data.address.addressId ? resp.data.address : a);
			})
		} else {
			address.addressId = 0;
			this.dataService.addAddress(address).subscribe((resp: any) => {
				this.addresses[address.addressTypeId].push(resp.data.address);
			})
		}
	}
	undoDelete(params) {
		this.dataService.restoreAddress(params.address.addressId).subscribe((resp: any) => {
			this.addresses[params.address.addressTypeId].push(resp);
		})
	}
}
