import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { SetDashboardTitle } from "src/app/store/actions/site.actions";
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state';
import { PointsPurchase, PointsOrders } from "src/app/models/dashboard/points";
import { AuthService } from "src/app/services/auth.service";


@Component({
  selector: 'app-points-approval',
  templateUrl: './points-approval.component.html',
  styleUrls: ['./points-approval.component.scss']
})

export class PointsApprovalComponent implements OnInit {
  public pointsPurchaseList: Array<PointsPurchase>
  public PointsOrderIDs: Array<PointsOrders> = [];

  constructor(private store: Store<IAppState>,private dataService: DataService, private authService: AuthService,private route: ActivatedRoute,private router: Router){}

  ngOnInit(): void {
    this.store.dispatch(new SetDashboardTitle("Approve Orders"))
    this.dataService.getPointsPurchases().subscribe(d => this.pointsPurchaseList = d)
  }

  public payAll()
  {
      this.pointsPurchaseList.forEach(item =>
      {

        var AddressCount = this.pointsPurchaseList[0].retailerAddress.length;

        if (AddressCount == 1)
        {
               var addressID = this.pointsPurchaseList[0].retailerAddress[0].addressId;
               this.pointsPurchaseList.forEach(item => {            
               this.PointsOrderIDs.push({ 'orderID': item.orderID, 'addressID': addressID })
          })
        } 
         // this.PointsOrderIDs.forEach(item => {alert(item.orderID + " " + item.addressID);})
    })

    this.dataService.submitPointsApproval(this.PointsOrderIDs).subscribe((resp: any) => {
      if (resp.success == true) { window.location.reload();}
    });
  }

  public paySelected() {
    this.dataService.submitPointsApproval(this.PointsOrderIDs).subscribe((resp: any) => {
      if (resp.success == true) {
        window.location.reload();
      }
    });
  }

  public receiveChildData({ orderID, Checked, AddressID }) {
    if (Checked)
    {
      this.PointsOrderIDs.push({ 'orderID': orderID, 'addressID': AddressID })
      //  this.PointsOrderIDs.forEach(item => {
      //    alert(item.orderID + " " + item.addressID);
      //})
    }
    if (!Checked)
    {
      this.PointsOrderIDs.splice(this.PointsOrderIDs.indexOf(orderID));
    }
  }

  reset() {
    this.PointsOrderIDs = [];
    window.location.reload();
  }

}
