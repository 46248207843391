import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { filter, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { ProUser } from "src/app/models/pro-user";
import { Product } from "src/app/models/shopping/product";
import { Article } from "src/app/models/strapi/article";
import { InstantRebateGroups } from "src/app/models/strapi/instant-rebate";
import { CmsVendor } from "src/app/models/strapi/vendor";
import { Vendor } from "src/app/models/vendor/vendor";
import { Permissions } from "src/app/modules/controls/enums/permissions";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { StrapiService } from "src/app/services/strapi.service";

@Component({
	selector: "app-vendor",
	templateUrl: "./vendor.component.html",
	styleUrls: ["./vendor.component.scss"],
})
export class VendorComponent extends AutoUnsubscribeMixin() implements OnInit {

    private vendorId: number;
	public vendor: Vendor;
	public cmsVendor: CmsVendor;
	public allArticles: Array<Article>;
	public instantRebateGroups: InstantRebateGroups
	public newProducts: Array<Product> = [];
	public specialProducts: Array<Product> = [];
	public newProductsVisible: number = 4;
	public Permissions = Permissions;
	private user: ProUser;

	 public responsiveOptions = [
		{
			breakpoint: '1200px',
			numVisible: 3,
			numScroll: 3
		},
		{
			breakpoint: '950px',
			numVisible: 2,
			numScroll: 2
		},
		{
			breakpoint: '650px',
			numVisible: 1,
			numScroll: 1
		}
	];

	public isOpenNewProducts: boolean = true;
	public isOpenRebates: boolean = true;
	public isOpenSpecials: boolean = true;
	public isOpenNews: boolean = true;
	
	constructor(private route: ActivatedRoute, private dataService: DataService, private strapiService: StrapiService, private authService: AuthService) {
		super();
	}

	ngOnInit(): void {
		this.user = this.authService.user;
		this.route.paramMap
			.pipe(
				filter((p) => p.get("id") != null),
				map((p: ParamMap) => p.get("id")),
				tap((id) => (this.vendorId = parseInt(id))),
				filter(id => this.user.vendors.length == 0 || this.user.vendors.some(v => String(v) == id)),
				switchMap(() => this.dataService.getVendor(this.vendorId)),
				tap((v) => {
					this.vendor = new Vendor({ ...v, contacts: v.contacts.slice(0, 2) });
					this.dataService.warehouseSearch({
						vendorIds: [ this.vendor.vendorId ],
						newProductsOnly: true,
					}).subscribe((p:any) => this.newProducts = p.products);
					this.dataService.warehouseSearch({
						vendorIds: [ this.vendor.vendorId ],
						specials: true
					}).subscribe((p:any) => this.specialProducts = p.products);
					this.strapiService.getVendor(this.vendorId).subscribe(v => this.cmsVendor = v)
				}),
				switchMap(() => this.strapiService.getVendorArticles(this.vendorId)),
				tap((a) => (this.allArticles = a.map(art => new Article(art)))),
				switchMap(() => this.strapiService.getInstantRebatesForVendor(this.vendorId)),
				takeUntil(this.destroy$)
			)
			.subscribe((ir: any) => this.instantRebateGroups = ir);
		
	}
	

	getArticles(filter, count) {
		const articles = this.allArticles.filter(f => f.categories?.some(d => d.categoryName == filter))
		articles.sort((a,b) => {
			if(a.postDate < b.postDate) return 1;
			if(a.postDate > b.postDate) return -1;
			return 0;
		})
		if(count) {
			return articles.slice(0, count)
		}
		return articles;
	}
}
