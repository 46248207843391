import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { DashboardHeaderButton } from "src/app/models/dashboard/dashboard-header-button";
import { StolenGoodsReport } from "src/app/models/dashboard/stolen-goods-report";
import { TableInfo } from "src/app/modules/controls/components/export-dialog/export-dialog.component";
import { DataService } from "src/app/services/data.service";
import { SetDashboardHeaderButtons, SetDashboardTitle, ShowDownloadDialog, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-stolen-goods-report",
	templateUrl: "./stolen-goods-report.component.html",
	styleUrls: ["./stolen-goods-report.component.scss"],
})
export class StolenGoodsReportComponent implements OnInit {
	public sidebarVisible: boolean = false;
	public activeReport: StolenGoodsReport;
	public reports: Array<any>;
	public details: any;
	public showSearchDialog: boolean = false;
	public term: string;
	public isSearching: boolean = false;
	public showUploadReports: boolean = false;
	public uploadErrors: Array<any> = [];
	@ViewChild('uploadReportsFileField') uploadReportsFileField: ElementRef;
	constructor(private store: Store<IAppState>, private dataService: DataService) {}

	ngOnInit(): void {
		this.store.dispatch(new SetDashboardTitle("Stolen Goods Registry"));
		this.dataService.getStolenGoodsReports().subscribe((r: Array<any>) => {
			this.reports = r.map((i) => new StolenGoodsReport(i));
			this.reports.sort((a,b) => {
				if(a.dateTheft < b.dateTheft) return 1;
				if(a.dateTheft > b.dateTheft) return -1;
				return 0;
			})
		});
		let buttons: Array<DashboardHeaderButton> = [
			new DashboardHeaderButton({ text: "Search", cssClass: "search-button", icon: "search", callback: () => { this.showSearch()} }), 
			new DashboardHeaderButton({ text: "Add New", icon: "add", callback: () => this.addNew() }),
			new DashboardHeaderButton({ text: "Download", icon: "download", callback: () => this.showDownloadOptions() }),
            new DashboardHeaderButton({ text: "Upload", icon: "upload", callback: () => this.openUploadDialog() }),
      		new DashboardHeaderButton({ text: "CSV Template",  callback: () => this.Download() })
		];
		this.store.dispatch(new SetDashboardHeaderButtons(buttons));

	}

	showSearch() {
		this.showSearchDialog = true;
		this.isSearching = false;		
		this.term = '';
	}
	addNew() {
		this.activeReport = new StolenGoodsReport();
		this.sidebarVisible = true;
  }

  Download() {



  //this.sidebarVisible = true;
    window.open("./stolen-goods-upload-template.CSV");
  }

	showDownloadOptions() {
		this.dataService.getStolenGoodsReportsFlat().subscribe((resp:any) => {
			this.store.dispatch(new ShowDownloadDialog(resp.map(i => new StolenGoodsReport(i)), this.tableInfoForExport, []));
		})
	}
	search() {
		if(this.term) {
			this.dataService.searchStolenGoods(this.term).subscribe((r: Array<any>) => {
				this.reports = r.map((i) => new StolenGoodsReport(i));
				this.isSearching = true;
				this.showSearchDialog = false;
			})
		}
	}
	cancelSearch() {
		this.isSearching = false;
		this.reports = [];
		this.dataService.getStolenGoodsReports().subscribe((r: Array<any>) => (this.reports = r.map((i) => new StolenGoodsReport(i))));
	}

	close(report) {
		this.activeReport = null;
		this.sidebarVisible = false;
		document.getElementsByClassName('p-sidebar-mask')[0]?.remove();
		this.dataService.getStolenGoodsReports().subscribe((r: Array<any>) => (this.reports = r.map((i) => new StolenGoodsReport(i))));
	}
	openUploadDialog() {
		this.showUploadReports = true;
	}
	closeUploadDialog() {
		this.showUploadReports = false;
		this.uploadErrors = [];
	}
	upload(e) {
		this.uploadErrors = [];
		this.dataService.uploadStolenGoodsReports(e.srcElement.files[0]).subscribe(resp => {
			this.uploadReportsFileField.nativeElement.value = null;
			if(resp.length == 0) {
				this.showUploadReports = false;
				this.store.dispatch(new SuccessMessageSet("Your file was uploaded without errors"));
			} else {
				this.uploadErrors = resp;
			}
			this.dataService.getStolenGoodsReports().subscribe((r: Array<any>) => (this.reports = r.map((i) => new StolenGoodsReport(i))));
		})
	}
	get tableInfo() {
		return new TableInfo({
			columns: [
                { title: "id", field: "id", type: 'hidden', id: true },
                { title: "DBA", field: "dba"},
				{ title: "Store Name", field: "storeName" },
				{ title: "City", field: "city" },
				{ title: "State", field: "state" },
				{ title: "Contact", field: "contact" },
				{ title: "Phone", field: "phone", type: 'phone' },
				{ title: "Incident Date", field: "dateTheft", type: "date", format: "MM/dd/yyyy" },
                { title: "Incident Type", field: "incidentTypeName" },
                { title: "Notes", field: "notes" , width:"25%" },
			],
			service: this.dataService,
			dataMethod: "getStolenGoodsDetails",
			hasSubtable: true,
			rowKey: "id",
			subtableInfo: new TableInfo({
				columns: [
					{ title: 'Product Description', field: 'product' },
					{ title: 'Serial Number', field: 'serialNumber' },
				],
			}),
		});
	}
	get tableInfoForExport() {
		return new TableInfo({
			columns: [
                { title: "Store Name", field: "storeName" },
                { title: "DBA", field: "dba" },
				{ title: "City", field: "city" },
				{ title: "State", field: "state" },
				{ title: "Contact", field: "contact" },
				{ title: "Phone", field: "phone", type: 'phone' },
				{ title: "Incident Date", field: "dateTheft", type: "date", format: "MM/dd/yyyy" },
				{ title: "Incident Type", field: "incidentTypeName" },
				{ title: 'Product Description', field: 'product' },
				{ title: 'Serial Number', field: 'serialNumber' },

			],
		});
		
	}
}
