
export class Media {
    public documentName: string;
    public documentLink: string; 
	public althernativeText: string;
	public caption: string;
	public ext: string;
	public mime: string;
	public name: string;
	public size: number;
	public height: number;
	public width: number;
	public url: string;

    constructor(o?: any) {
      let data = {
        documentName: o.documentName, documentLink: o.documentLink,
        ...o.documentFile,
        ...o.documentFile?.data?.attributes,
           imageName: o.title,
        ...o.image,
        ...o.image?.data?.attributes
      }
	    Object.assign(this, o, data);
    }
}
