
<div class="addresses" *ngIf="addresses[Address_Types.Store]">
	<h1>Store Addresses</h1>
	<div class="row header">
		<div class="street">Street</div>
		<div class="city">City</div>
		<div class="state">State</div>
		<div class="zip">Zip</div>
		<div class="country">Country</div>
		<div class="more"></div>
	</div>

	<div class="row" *ngFor="let address of addresses[Address_Types.Store]">

		<div class="street"><span>{{address.address1}}</span><span *ngIf="address.address2">{{address.address2}}</span></div>
		<div class="city">{{address.city}}</div>
		<div class="state">{{address.state}}</div>
		<div class="zip">{{address.zip}}</div>
		<div class="country">{{address.country}}</div>
		<div class="more">
			<mat-icon (click)="showActions($event, address)">more_horiz</mat-icon>
			<div class="actions" hideable="height 200ms linear" [ngClass]="{open: openActions == address.addressId}">
				<div class="edit" (click)="edit(address)"><mat-icon>edit</mat-icon> Edit</div>
				<div class="delete" (click)="delete(address)"><mat-icon>delete</mat-icon> Delete</div>
			</div>
		</div>
	</div>
</div>

<div class="addresses" *ngIf="addresses[Address_Types.Shipping]">
	<h1>Shipping Addresses</h1>
	<div class="row header">
		<div class="street">Street</div>
		<div class="city">City</div>
		<div class="state">State</div>
		<div class="zip">Zip</div>
		<div class="country">Country</div>
		<div class="more"></div>
	</div>

	<div class="row" *ngFor="let address of addresses[Address_Types.Shipping]">

		<div class="street"><span>{{address.address1}}</span><span *ngIf="address.address2">{{address.address2}}</span></div>
		<div class="city">{{address.city}}</div>
		<div class="state">{{address.state}}</div>
		<div class="zip">{{address.zip}}</div>
		<div class="country">{{address.country}}</div>
		<div class="more">
			<mat-icon (click)="showActions($event, address)">more_horiz</mat-icon>
			<div class="actions" hideable="height 200ms linear" [ngClass]="{open: openActions == address.addressId}">
				<div class="edit" (click)="edit(address)"><mat-icon>edit</mat-icon> Edit</div>
				<div class="delete" (click)="delete(address)"><mat-icon>delete</mat-icon> Delete</div>
			</div>
		</div>
	</div>
</div>





<div class="addresses" *ngIf="addresses[Address_Types.User_Private]">
	<h1>User Private Addresses</h1>
	<div class="row header">
		<div class="street">Street</div>
		<div class="city">City</div>
		<div class="state">State</div>
		<div class="zip">Zip</div>
		<div class="country">Country</div>
		<div class="more"></div>
	</div>
	<div class="row" *ngFor="let address of addresses[Address_Types.User_Private]">
		<div class="street"><span>{{address.address1}}</span><span *ngIf="address.address2">{{address.address2}}</span></div>
		<div class="city">{{address.city}}</div>
		<div class="state">{{address.state}}</div>
		<div class="zip">{{address.zip}}</div>
		<div class="country">{{address.country}}</div>
		<div class="more">
			<mat-icon (click)="showActions($event, address)">more_horiz</mat-icon>
			<div class="actions" hideable="height 200ms linear" [ngClass]="{open: openActions == address.addressId}">
				<div class="edit" (click)="edit(address)"><mat-icon>edit</mat-icon> Edit</div>
				<div class="delete" (click)="delete(address)"><mat-icon>delete</mat-icon> Delete</div>
			</div>
		</div>
	</div>
</div>

<div class="addresses" *ngIf="addresses[Address_Types.Other]">
	<h1>Other Addresses</h1>
	<div class="row header">
		<div class="street">Street</div>
		<div class="city">City</div>
		<div class="state">State</div>
		<div class="zip">Zip</div>
		<div class="country">Country</div>
		<div class="more"></div>
	</div>
	<div class="row" *ngFor="let address of addresses[Address_Types.Other]">
		<div class="street"><span>{{address.address1}}</span><span *ngIf="address.address2">{{address.address2}}</span></div>
		<div class="city">{{address.city}}</div>
		<div class="state">{{address.state}}</div>
		<div class="zip">{{address.zip}}</div>
		<div class="country">{{address.country}}</div>
		<div class="more">
			<mat-icon (click)="showActions($event, address)">more_horiz</mat-icon>
			<div class="actions" hideable="height 200ms linear" [ngClass]="{open: openActions == address.addressId}">
				<div class="edit" (click)="edit(address)"><mat-icon>edit</mat-icon> Edit</div>
				<div class="delete" (click)="delete(address)"><mat-icon>delete</mat-icon> Delete</div>
			</div>
		</div>
	</div>
</div>
<p-dialog [(visible)]="showAddressForm" showHeader="false" *ngIf="addEditAddress" [closable]="false" [style]="{width: '40vw'}">
	<app-add-edit-address [address]="addEditAddress" (onCancel)="showAddressForm = false" (onSubmit)="saveAddress($event)"></app-add-edit-address>
</p-dialog>
