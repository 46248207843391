

export class EzPay {
	public invoice: string;
	public date: Date;
	public due_date: Date;
	public account: string;
	public receiptNumber: string;
	public vendor: string;
    public deductionDate: Date;
	public deductionDateDisplay: Date;
    public DateSubmitted: Date;
	public vendorInvoiceNumber: string;
	public gross: number;
	public proDiscount: number;
	public ezPayDiscount: number;
	public totalToPay: number;
	public checkTerms: string;
	public showEligible: string;
	public dolSaved: string;
    public 

	public isChecked: boolean;

	constructor(o?: any) {
		Object.assign(this, o);
		if(!this.deductionDateDisplay) { this.deductionDateDisplay = this.deductionDate; }
	}
}

export class EzPaySavings {
	public type: string;
	public amount: number;
}
