import { Image } from './image';

export class StaffMember {
	public id: number;
	public name: string;
	public title: string;
	public phone: string;
	public extension: string;
	public email: string;
	public description: string;
	public photo: Image;
	public department: Department;
	public displayOrder: number;

	constructor(o?: any) {
		let imageData = null, departmentData = null;
		if(o.photo?.data) {
			imageData = new Image({ id: o.photo.data.id, ...o.photo.data.attributes });
		}
		if(o.department.data) {
			departmentData = new Department({ id: o.department.data.id, ...o.department.data.attributes })
		}
		o.photo = imageData;
		o.department = departmentData;
		Object.assign(this, o);
	}
}

export class Department {
	public id: number;
	public name: string;
	public displayOrder: number;
	public staff: Array<StaffMember> = [];

	constructor(o?: any) {
		Object.assign(this, o);
	}

}

export class Contact {
  public Address: string;
  public Zip: string;
  public State: string;
  public Phone: string;

  constructor(o?: any) {
    Object.assign(this, o);
  }

}


export class Officer {
	public title: string;
	public firstName: string;
	public lastName: string;
	public company: string;
	public city: string;
	public state: string;
	public phone: string;
	public email: string;
	public displayOrder: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}

}

export class Director {
	public firstName: string;
	public lastName: string;
	public company: string;
	public phone: string;
	public email: string;
	public isExecCommitteeMember: boolean;
	public displayOrder: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}

}
