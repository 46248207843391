import { Component, OnInit, Input } from '@angular/core';
import { SarPrintItem } from 'src/app/models/dashboard/sar';

@Component({
  selector: 'app-sar-print-items',
  templateUrl: './sar-print-items.component.html',
  styleUrls: ['./sar-print-items.component.scss']
})
export class SarPrintItemsComponent implements OnInit {

	@Input() items: Array<SarPrintItem>
  constructor() { }

  ngOnInit(): void {
  }

}
