import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { PriceProtectionEntry, PriceProtectionLineItem, PriceProtectionProgram, RebateEntry, RebateFile, RebateForm, RebateLineItem } from "src/app/models/dashboard/rebates";
import { ProUser } from "src/app/models/pro-user";
import { DataService } from "src/app/services/data.service";
import { ErrorMessageSet, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { selectUser } from "src/app/store/selectors/user.selectors";
import { IAppState } from "src/app/store/state/app.state";

@Component({
  selector: 'app-price-protection-form',
  templateUrl: './price-protection-form.component.html',
  styleUrls: ['./price-protection-form.component.scss']
})
export class PriceProtectionFormComponent implements OnInit {
	@Input() program: PriceProtectionProgram;
	@Input() programDate: Date;
	@Output() onSubmit: EventEmitter<PriceProtectionEntry> = new EventEmitter();

	public step: number = 1;
	public rebateForm: UntypedFormGroup;
	private user: ProUser;
	private entry: PriceProtectionEntry;
	public submitted: boolean = false;

	constructor(private dataService: DataService, private fb:UntypedFormBuilder, private store: Store<IAppState>, private datePipe: DatePipe) {}

	ngOnInit(): void {
		this.store.pipe(select(selectUser)).subscribe(u => this.user = u)
		this.entry = new PriceProtectionEntry();
		this.entry.priceProtectionForm = new RebateForm();

		this.dataService.getPriceProtectionDetails(this.program.vendorId, this.programDate).subscribe((resp:any) => this.entry.priceProtectionLineItems = resp.map(l => new PriceProtectionLineItem({...l, reimbursement: l.amount})))

		this.rebateForm = this.fb.group({
			fname: [ this.entry.priceProtectionForm.fname || this.user.name.split(' ')[0], Validators.required ],
			lname: [ this.entry.priceProtectionForm.lname || this.user.name.split(' ')[1], Validators.required ],
			email: [ this.entry.priceProtectionForm.email || this.user.email, Validators.required ],
			phone: [ this.entry.priceProtectionForm.phone, Validators.required ],
      memo: [this.entry.priceProtectionForm.memo, [Validators.required, Validators.minLength(5)]],
		})

  }
	setSerials(item) {
		if(item.quantity == item.serials.length) { return; }
		if(item.quantity > item.serials.length) {
			item.serials.push( ...Array.apply(null, Array(item.quantity - item.serials.length)).map(() => ({ number: '' })) );
		} else {
			item.serials.splice(item.quantity - item.serials.length);
		}
	}
	back() {
		if(this.step > 1){
			this.step--;
		}
	}
	next() {
		if(this.step < 3) {
			if(this.step == 1) {
        this.submitted = true;
        //Validate Serials
        let valid = true;
        if (this.program.vendorName != 'ProMaster Price Protection')
        {
          let valid = this.entry.priceProtectionLineItems.reduce((a, b) => a && b.serials.reduce((c, d) => c && d.number != '', true), true);
        }
        if (!valid) {
					this.store.dispatch(new ErrorMessageSet("Please provide serial numbers for each item"));
          return; 
				}
			}
      if (this.step == 2 && !this.entry.priceProtectionFiles?.length && this.program.vendorName != 'ProMaster Price Protection') {
        this.store.dispatch(new ErrorMessageSet("Please attach at least one supporting document"));
        return;
      }
     

			this.step++;
		}
	}

  saveFile(elem)
  {
    if (!this.entry.priceProtectionFiles)
    {this.entry.priceProtectionFiles = [];}
		const file = elem.target.files[0];
		this.entry.priceProtectionFiles.push(new RebateFile({file: file, fileName: file.name, fileSize: file.size }));
		elem.target.value = null
	}
  deleteFile(i)
  {this.entry.priceProtectionFiles.splice(i, 1);}
	

	submit() {
    this.rebateForm.markAllAsTouched();

    if (this.program.vendorName == 'ProMaster Price Protection') {
      this.entry.priceProtectionFiles = [];
    }
   
    if (this.rebateForm.valid)
    {
        this.entry.priceProtectionForm = new RebateForm(this.rebateForm.value);
			  this.entry.priceProtectionLineItems = this.entry.priceProtectionLineItems.filter(i => i.quantity > 0).map(i => ({modelId: i.modelId, programDate: `${this.datePipe.transform(this.programDate, "MMM dd")}`, reimbursement: i.reimbursement, quantity: i.quantity, serials: i.serials, modelName: i.modelName}))

        //save files to send later
        const files = [...this.entry.priceProtectionFiles];
        this.entry.priceProtectionFiles = this.entry.priceProtectionFiles.map(f => new RebateFile({ fileName: f.fileName, fileSize: f.fileSize }))       
        this.dataService.submitPriceProtection(this.entry).subscribe((resp: any) =>
        {
          this.entry.claimId = resp.data.claimId;
				  this.dataService.submitRebateFiles(resp.data.claimId, files.map(f => f.file)).subscribe(r => {
				  this.store.dispatch(new SuccessMessageSet("Your price protection claim has been successfully submitted"));
				  this.onSubmit.next({ ...this.entry, claimId: resp.data.claimId });
			  })
			})
		}
	}
}
