import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Product, ProductFile, ProductResponse, TechSpec } from 'src/app/models/shopping/product';
import { Special } from 'src/app/models/shopping/specials';
import { DataService } from 'src/app/services/data.service';
import { AddToCart } from 'src/app/store/actions/cart.actions';
import { IAppState } from 'src/app/store/state/app.state';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {

	public product: Product;
	public techSpecs: Array<TechSpec>;
	public relatedProducts: Array<Product>;
	public specials: Array<Special> = [];
	public productFiles: Array<ProductFile>;

	constructor(private route: ActivatedRoute, private dataService: DataService, private store: Store<IAppState>, private router: Router) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			if (params.get("id")) {
				const productCode = params.get("id");
				this.dataService.getProduct(productCode).subscribe((resp:ProductResponse) => {
					if(!resp.product) {
						this.checkIfBundle(productCode);
					}

                    this.product = new Product({ ...resp.product, returnCode: resp.returnCode, data: resp.data });

					this.dataService.getSpecialsForProduct(this.product.id).subscribe(resp => this.specials = resp)
				}, (err) => {
					//didn't get a product, maybe it's a bundle
					this.checkIfBundle(productCode);
				})
				this.dataService.getTechSpecs(productCode).subscribe((resp:any) => this.techSpecs = resp);
                this.dataService.getRelatedProducts(productCode).subscribe(resp => this.relatedProducts = resp);          
				this.dataService.getProductFiles(productCode).subscribe(resp => this.productFiles = resp)
			}
		});
		
	}

	addToCart(quantity) {
		this.store.dispatch(new AddToCart(this.product.productCode, quantity));
	}

	checkIfBundle(code) {
		this.dataService.getBundle(code).subscribe(resp => {
			if(resp) {
				this.router.navigate(['/shop', 'bundle', resp.bundleCode])
			}
		})

	}
}
