
<div class="pre-header">
	<span>Go to: </span>

	<a [routerLink]="['/dashboard', 'invoices']">Open Invoices</a>

	<!--<a [routerLink]="['/dashboard', 'PaymentConfirmationComponent']">Conf</a>-->

	<a [routerLink]="['/dashboard', 'invoices', 'backorder']">Back Order Summary</a>
	<a [routerLink]="['/dashboard', 'invoices', 'shipped']">Orders Shipped Summary</a>
	<a [routerLink]="['/dashboard', 'invoices', 'paid']">Paid Invoices</a>

</div>
<app-order-history-search (onSearch)="search($event)"></app-order-history-search>
<app-order-history-list [orderHistoryItems]="orderHistoryItems" [mode]="'dark'"></app-order-history-list>
