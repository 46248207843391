import { Component, Input, OnInit } from "@angular/core";
import { Comment } from "src/app/models/strapi/comment";
import { Permissions } from "src/app/modules/controls/enums/permissions";

@Component({
	selector: "app-comments-display",
	templateUrl: "./comments-display.component.html",
	styleUrls: ["./comments-display.component.scss"],
})
export class CommentsDisplayComponent implements OnInit {
	@Input() articleId: number;
	@Input() comment: Comment;

	public showReply: boolean = false;
	public Permissions = Permissions;
	constructor() {}

	ngOnInit(): void {}

	addComment(comment: Comment) {
		this.comment.children.push(comment);
		this.showReply = false;
	}
}
