import { Component, OnInit } from "@angular/core";
import { NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { MessageService } from "primeng/api";
import { filter, takeUntil, tap } from "rxjs/operators";
import { AutoUnsubscribeMixin } from "src/app/mixins/auto-unsubscribe.mixin";
import { ProUser } from "src/app/models/pro-user";
import { AuthService } from "src/app/services/auth.service";
import { SiteService } from "src/app/services/site.service";
import { CloseCart } from "src/app/store/actions/cart.actions";
import { AllMessagesClear, CloseSearch, CloseSideNav, CloseVendorPreview, ErrorMessageSet, Navigating, SetDashboardHeaderButtons, SetNavMenuOpen, SiteActionTypes, SuccessMessageSet } from "src/app/store/actions/site.actions";
import { LoginSuccess, SetUser, UserActionTypes } from "src/app/store/actions/user.actions";
import { IAppState } from "src/app/store/state/app.state";

@Component({
	selector: "app-root",
	templateUrl: "./root.component.html",
	styleUrls: ["./root.component.scss"],
})
export class RootComponent extends AutoUnsubscribeMixin() implements OnInit {
	public message: string = "";
	public messageType: "success" | "error";
	public user: ProUser;
	public userLoggedInAs: boolean = false;

	constructor(private actions$: Actions, private router: Router, private store: Store<IAppState>, private siteService: SiteService) {
		super();
	}

	ngOnInit(): void {
		
		this.actions$
			.pipe(
				takeUntil(this.destroy$),
				tap((a) => {
					switch (a.type) {
						case SiteActionTypes.ERROR_MESSAGE_SET:
							this.clearMessage().then(() => {
								this.messageType = 'error';
								this.message = (a as ErrorMessageSet).message;
								setTimeout(() => {
									this.clearMessage();
								}, 6000);
							})
							break;
						case SiteActionTypes.SUCCESS_MESSAGE_SET:
							this.clearMessage().then(() => {
								this.messageType = 'success';
								this.message = (a as ErrorMessageSet).message;
								setTimeout(() => {
									this.clearMessage();
								}, 6000);
							})
							break;
						case SiteActionTypes.ALL_MESSAGE_CLEAR:
							this.clearMessage();
							break;
						case UserActionTypes.LOGIN_SUCCESS:
							this.user = (a as LoginSuccess).user;
							break;
						case UserActionTypes.LOG_IN_AS_SUCCESS:
							this.user = (a as LoginSuccess).user;
							this.userLoggedInAs = true;
							break;
						case UserActionTypes.LOGOUT:
							this.user = null;
							break;
					}
				})
			)
			.subscribe();

		this.router.events
			.pipe(
				filter((e) => e instanceof NavigationStart || e instanceof NavigationError),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.store.dispatch(new CloseCart());
				this.store.dispatch(new AllMessagesClear());
				this.store.dispatch(new CloseSearch());
				this.store.dispatch(new CloseSideNav());
				this.store.dispatch(new CloseVendorPreview());
				this.store.dispatch(new SetNavMenuOpen(""));
				this.store.dispatch(new SetDashboardHeaderButtons([]));
				this.store.dispatch(new Navigating());
				document.getElementsByClassName('p-sidebar-mask')[0]?.remove();
				window.scrollTo(0,0);
				this.siteService.disallowSizes([])
			});
	}

	clearMessage(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if(this.message) {
				this.message = '';
				setTimeout(() => {
					resolve(true);
				}, 150);
			} else {
				resolve(false);
			}
		})
	}
}
