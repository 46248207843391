
<div class="container">
	<app-newsfeed-header></app-newsfeed-header>
	<div class="article-lists" [permission]="Permissions.CurrentEvents">
		<div class="main-article" *ngIf="mainArticle">
			<app-newsfeed-article-tile [mode]="'large'" [article]="mainArticle"></app-newsfeed-article-tile>
		</div>
		<div class="articles popular" *ngIf="popularArticles">
			<h1>Popular Articles</h1>
			<p>We share common trends, strategeis, ideas, opinions, short &amp; long stories from the team behind the company</p>
			<div class="button"><a [routerLink]="['/newsfeed', 'popular']">View All &rarr;</a></div>
			<div class="tiles">
				<app-newsfeed-article-tile [mode]="'large-tile'" [article]="article" *ngFor="let article of popularArticles" class="large"></app-newsfeed-article-tile>
			</div>
		</div>
		<div class="articles recent" *ngIf="recentArticles">
			<h1>Recent Articles</h1>
			<p>Here's what we've been up to recently.</p>
			<div class="button"><a [routerLink]="['/newsfeed', 'recent']">View All &rarr;</a></div>
			<div class="tiles">
				<app-newsfeed-article-tile [mode]="'small-tile'" [article]="article" *ngFor="let article of recentArticles" class="small"></app-newsfeed-article-tile>
			</div>
		</div>
		<div class="articles all" *ngIf="allArticles">
			<h1>All Articles</h1>
			<div class="tiles">
				<ng-container *ngFor="let article of allArticles; let i = index">
					<app-newsfeed-article-tile [mode]="i <= 1 ? 'large-tile' : 'small-tile'" [article]="article" [ngClass]="{ small: i >= 2, large: i <= 1 }"></app-newsfeed-article-tile>
				</ng-container>				
			</div>
		</div>		
	</div>

</div>

