
<div class="account-data">
	<section class="legal-contact">
		<header>
			<h1>Legal Information Contact</h1>
			<mat-icon (click)="editLegal()">edit</mat-icon>
		</header>
		<div class="info" *ngIf="legalContact">
			<ul>
				<li>
					<span>Name</span>
					<span>{{legalContact.firstName}} {{legalContact.lastName}}</span>
				</li>
				<li>
					<span>Address</span>
					<span>{{legalContact.address1}}<br>{{legalContact.city}}, {{legalContact.state}} {{legalContact.zip}}</span>
				</li>
				<li>
					<span>Phone</span>
					<span>{{legalContact.phone}}</span>
				</li>
				<li>
					<span>Email</span>
					<span>{{legalContact.email}}</span>
				</li>
			</ul>
		</div>
	</section>



	<section class="company-data">
		<header>
			<h1>Company Info</h1>
			<mat-icon *ngIf="!editingCompany" (click)="editCompanyData()">edit</mat-icon>
			<mat-icon *ngIf="editingCompany" (click)="saveCompanyData()">save</mat-icon>
		</header>
		<div class="info" *ngIf="companyData">
			<ul>
				<li>
					<span>Company Name</span>
					<span *ngIf="!editingCompany">{{companyData.company}}</span>
					<span *ngIf="editingCompany"><input pInputText [(ngModel)]="companyData.company"></span>
				</li>
				<li>
					<span>Doing Business As</span>
					<span *ngIf="!editingCompany">{{companyData.dba}}</span>
					<span *ngIf="editingCompany"><input pInputText [(ngModel)]="companyData.dba"></span>
				</li>
				<li>
					<span>Phone</span>
					<span *ngIf="!editingCompany">{{companyData.phone}}</span>
					<span *ngIf="editingCompany"><input pInputText [(ngModel)]="companyData.phone"></span>
				</li>
				<li>
					<span>Fax</span>
					<span *ngIf="!editingCompany">{{companyData.fax}}</span>
					<span *ngIf="editingCompany"><input pInputText [(ngModel)]="companyData.fax"></span>
				</li>
				<li>
					<span>Email</span>
					<span *ngIf="!editingCompany">{{companyData.email}}</span>
					<span *ngIf="editingCompany"><input pInputText [(ngModel)]="companyData.email"></span>
				</li>
				<li>
					<span>Website</span>
					<span *ngIf="!editingCompany">{{companyData.website}}</span>
					<span *ngIf="editingCompany"><input pInputText [(ngModel)]="companyData.website"></span>
				</li>
				<li *ngIf="companyData.address">
					<span>Address</span>
					<span *ngIf="!editingCompany">
						{{companyData.address.address1}}<br>
						{{companyData.address.city}}, {{companyData.address.state}} {{companyData.address.zip}}<br>
						{{companyData.address.country}}
					</span>
					<span *ngIf="editingCompany">
						<p-dropdown [options]="addressOptions" optionLabel="key" optionValue="value" [(ngModel)]="companyData.addressId"></p-dropdown>
					</span>
				</li>
				<li>
					<span>Primary Contact</span>
					<span *ngIf="!editingCompany && companyData.primaryContact">
						{{companyData.primaryContact.firstName}} {{companyData.primaryContact.lastName}} <br />
						<ng-container *ngIf="companyData.primaryContact.address">
							{{companyData.primaryContact.address.address1}}<br>
							{{companyData.primaryContact.address.city}}, {{companyData.primaryContact.address.state}} {{companyData.primaryContact.address.zip}}<br>
							{{companyData.primaryContact.address.country}}
						</ng-container>
					</span>
					<span *ngIf="editingCompany">
						<p-dropdown [options]="userOptions" optionLabel="key" optionValue="value" [(ngModel)]="companyData.primaryContactId"></p-dropdown>
					</span>
				</li>
			</ul>
		</div>
	</section>



	

	<section class="company-data">
		<header>
			<h1>Business Relationship Manager</h1>
		</header>

		<span>{{brm.Email}}</span>


		<div class="info" *ngIf="companyData">
			<ul>
				<li>
					<span>Name</span>
					<span> {{brm.firstName}} {{brm.lastName}}</span>	
				</li>

				<li>
					<span>Phone</span>
					<span>{{brm.phone}}</span>	
				</li>

				<li>
					<span>E-mail</span>
					<span>{{brm.email}}</span>
				</li>			
			
			</ul>
		</div>
	</section>



	<ng-container *ngIf="user.userType != 'Client'">
		<section class="billing-options">

			<header>
				<h1>Billing Options</h1>
			</header>

			<div class="info">
				<p class="b">How would you like to pay for your {{year}} dues?</p>
				<p>Your {{year}} membership dues will be billed accroding to your selected option below.  If possible, please take advantage of the prepaid option that will save you 5%.</p>
				<p>Utilizing EZ-Pay will save you and additional 1% on either option!</p>
				<p>Please make your selection no later than {{decideByDate | date: 'MMMM d, yyyy'}}.</p>
				<p class="b">Please select an option for {{year}}.</p>
				<ul>
					<li>
						<p-radioButton name="billingOption" value="1" [(ngModel)]="billingOption" label="Prepaid Dues Option - onetime charge of $3,990, billed in January and due for payment in full on February 10th (5% savings)."></p-radioButton><br />
					</li>
					<li>
						<p-radioButton name="billingOption" value="2" [(ngModel)]="billingOption" label="Monthly Dues Payment Option - does of $350 will be billed each month (total $4,200 for the year)"></p-radioButton><br />
					</li>
				</ul>
				<div class="button" (click)="submitBillingOption()">Submit</div>
				<p>For questions, please call Shawn Voisine at 203-292-4430.</p>
				<p>Thank you Members!</p>

				<p class="b">Your historical Payment Options</p>
				<ul>
					<li *ngFor="let option of billingOptions">In {{option.year}}, you selected {{option.optionID == 1 ? 'Annual' : 'Monthly'}} Dues.</li>
				</ul>
			</div>
		</section>
	</ng-container>

</div>
