import { ProUser } from "src/app/models/pro-user";
import { UserActions, UserActionTypes } from "../actions/user.actions";
import { initialUserState, IUserState } from "../state/user.state";


export function userReducer(state: IUserState = initialUserState, action: UserActions):IUserState {
	switch(action.type) {
		case UserActionTypes.LOGIN:
			return { ...state, username: action.username, password: action.password };
		case UserActionTypes.LOGIN_SUCCESS:
			return { ...state, token: action.token, user: action.user, password: '' };  //clear out password just cuz
		case UserActionTypes.LOGOUT: 
			return { ...state, user: null};
		case UserActionTypes.SET_USER:
			return { ...state, user: action.user };
		case UserActionTypes.SET_EZPAY_STATUS:
			return { ...state, ezPayStatus: action.status }
		default:
			return state;
	}

}