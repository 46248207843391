<div class="order-history-search">
	<h1>Find Order</h1>
	<div class="criteria">
		<div class="order-number">
			<label>Order Number</label>
			<input pInputText [(ngModel)]="orderNumber">
		</div>
		<div class="user">
			<label>User</label>
			<p-dropdown [options]="userOptions" [(ngModel)]="userId" [autoDisplayFirst]="false"></p-dropdown>
		</div>
		<div class="address">
			<label>Shipping Address</label>
			<p-dropdown [options]="addressOptions" [(ngModel)]="addressId" [autoDisplayFirst]="false"></p-dropdown>
		</div>
		<div class="date">
			<div class="start-date">
				<label>Start Date</label>
				<p-calendar [(ngModel)]="startDate"></p-calendar>
			</div>
			<div class="emd-date">
				<label>End Date</label>
				<p-calendar [(ngModel)]="endDate"></p-calendar>
			</div>
		</div>
		<div class="invoice-number">
			<label>Invoice Number</label>
			<input pInputText [(ngModel)]="invoiceNumber">
		</div>
		<div class="po-number">
			<label>PO Number</label>
			<input pInputText [(ngModel)]="poNumber">
		</div>
		<div class="po-number">
			<label>Order Status</label>
			<p-dropdown [options]="orderStatusOptions" [(ngModel)]="orderStatus"></p-dropdown>
		</div>
		<div class="order-type">
			<label>Order Type</label>
			<p-dropdown [options]="orderTypeOptions" [(ngModel)]="orderTypeId"></p-dropdown>
		</div>
		<!--
		<div class="order-status">
			<label>Order Status</label>
			<p-dropdown [options]="orderStatusOptions" [(ngModel)]="orderStatusId"></p-dropdown>
		</div>
		-->
		<div class="vendor">
			<label>Vendor</label>
			<p-dropdown [options]="vendorOptions" [(ngModel)]="vendorId"></p-dropdown>
		</div>
		<div class="search">
			<div class="search button" (click)="doSearch();"><i class="pi pi-search"></i>Search</div>
			<div class="clear button" (click)="clear();"><i class="pi pi-times-circle"></i>Clear</div>
		</div>
	</div>
</div>
