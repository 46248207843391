
export class Rebate {
	public orderID: number;
	public submitDate : Date;
	public debit: string;
	public programWeek: string;
	public total: number;
	public status: string;
	public rejectReason: string;
	public vendor: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}


export class RebateDetail {
	public model: string;
	public quantity: number;
	public reimbursementAmount: number;
	public net: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class PriceProtection {
	public orderId: number;
	public submitDate: Date;
	public debit: string;
	public programWeek: string;
	public total: number;
	public status: string;
	public rejectReason: string;
	public vendor: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}

}

export class PriceProtectionDetail {
	public model: string;
	public quantity: number;
	public reimbursementAmount: number;
	public net: number;
	
	constructor(o?: any) {
		Object.assign(this, o);
	}

}

export class RebateEntry {
	public rebateProgram?: RebateProgram;
	public rebateForm: RebateForm;
	public rebateFiles: Array<RebateFile>;
	public rebateLineItems: Array<RebateLineItem>;
	public claimId: number;
	public vendor: string;
	public programWeek: string;
	public createdDate: Date;

	constructor(o?: any) {
		Object.assign(this, o);
	}

}

export class RebateProgram {
	public vendorId: number;
	public vendorName: string;
	public startDate: Date;
	public endDate: Date;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}
export class RebateForm {
	public fname: string;
	public lname: string;
	public phone: string;
	public email: string;
	public memo: string;
		
	constructor(o?: any) {
		Object.assign(this, o);
	}

}

export class RebateLineItem {
	public modelID: number;
	public modelNumber?: number;
	public modelName?: string;
	public programDate: string;
	public reimbursement: number;
	public proCode?: string;
	public quantity: number;
	public serialRequired?: boolean;
	public serial: string;
	public status?: string;
	public available?: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}
	get model() { return this.modelName; }

}

export class RebateFile {
	public file?: File;
	public fileName: string;
	public fileSize: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}

	get fileSizeWithUnits() {
		let bytes = this.fileSize;
		let bytesWithUnits: string;
		if      (bytes >= 1073741824) { bytesWithUnits = (bytes / 1073741824).toFixed(2) + " GB"; }
		else if (bytes >= 1048576)    { bytesWithUnits = (bytes / 1048576).toFixed(2) + " MB"; }
		else if (bytes >= 1024)       { bytesWithUnits = (bytes / 1024).toFixed(2) + " KB"; }
		else if (bytes > 1)           { bytesWithUnits = bytes + " bytes"; }
		else if (bytes == 1)          { bytesWithUnits = bytes + " byte"; }
		else                          { bytesWithUnits = "0 bytes"; }
		return bytesWithUnits;
	}
	get extension() {
		return this.fileName.split('.').pop();
	}

}

export class RebateVendor {
	public vendorId: number;
	public vendorName: string;
	public vendorImage: string;
	public programs: Array<RebateProgram>;

	constructor(o?: any) {
		Object.assign(this, o);
	}

}

export class RebateValidation {
	public claimsToDate: number;
	public rebateOverrideCount: number;
	public unitsShipped: number;
	public status: string;
	public available: number;
}

export class PriceProtectionProgram {
	public programId: number;
	public vendorId: number;
	public vendorName: string;
	public vendorImage: string;
	public programDates: Array<Date>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class PriceProtectionClaimDetails {
	public modelId: number;
	public modelNumber: string;
	public modelName: string;
	public amount: number;
	public serialRequired: boolean;
}

export class PriceProtectionEntry {
	public priceProtectionProgram: PriceProtectionProgram;
	public priceProtectionForm: RebateForm;
	public priceProtectionFiles: Array<RebateFile>;
	public priceProtectionLineItems: Array<PriceProtectionLineItem>;
	public claimId: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class PriceProtectionLineItem {
	public modelId: number;
	public modelNumber?: number;
	public modelName?: string;
	public programDate: string;
	public reimbursement: number;
	public quantity: number;
	public serials: Array<{ number: string }>;

	constructor(o?: any) {
		this.serials = [];
		Object.assign(this, o);
	}

}