


	<div class="type" *ngFor="let type of subscriptions">
		<header>
			<h2>{{type.subscriptionName}}&nbsp;&mdash;&nbsp;{{type.mailingTypeId == 1 ? 'Email Delivery' : 'Mail Delivery'}}</h2>
			<div class="expander" (click)="expand(type.subscriptionTypeId)"></div>
		</header>
		<div class="data type-1" *ngIf="type.mailingTypeId == 1" [ngClass]="{ open: openLists['list'+type.subscriptionTypeId] }">
			<div class="row" *ngFor="let user of type.users">
				<div class="name">{{user.firstName}} {{user.lastName}}</div>
				<div class="email">{{user.email}}</div>
				<div class="delete" ><div class="button"(click)="unsubscribe(user.subscriptionId, type.subscriptionTypeId)">Delete</div></div>
			</div>
			<div class="add">
				<span>Add Subscription</span>
				<select [(ngModel)]="userId">
					<option *ngFor="let user of allUsers" value="{{user.userId}}">{{user.firstName}} {{user.lastName}}</option>
				</select>
				<div class="button" (click)="subscribe(type.subscriptionTypeId)">Add</div>
			</div>
		</div>
		<div class="data type-2" *ngIf="type.mailingTypeId == 2" [ngClass]="{ open: openLists['list'+type.subscriptionTypeId] }">
			<div class="card" *ngFor="let user of type.users">
				<div class="name">{{user.firstName}} {{user.lastName}}</div>
				<div class="address">{{user.address1}}</div>
				<div class="csz">{{user.city}}, {{user.state}} {{user.zip}}</div>
				<div class="delete" (click)="unsubscribe(user.subscriptionId, type.subscriptionTypeId)">Delete</div>
			</div>
			<div class="add">
				<span>Add Subscription</span>
				<select [(ngModel)]="userId">
					<option></option>
					<option *ngFor="let user of allUsers" value="{{user.userId}}">{{user.firstName}} {{user.lastName}}</option>
				</select>
				<div class="button" (click)="subscribe(type.subscriptionTypeId)">Add</div>
			</div>
		</div>
	</div>
	
	

	