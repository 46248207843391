import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Brand } from 'src/app/models/shopping/brand';
import { Product } from 'src/app/models/shopping/product';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-brand-page',
  templateUrl: './brand-page.component.html',
  styleUrls: ['./brand-page.component.scss']
})
export class BrandPageComponent implements OnInit {

	private brandId: number;
	public brand: Brand;
	public brands: Array<Brand>;
	public products: Array<Product>;
  constructor(private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit(): void {
	this.route.paramMap.subscribe((params) => {
		if (params.get("id")) {
			this.brandId = parseInt(params.get("id"));
			this.dataService.getBrandProducts(this.brandId).subscribe((resp:Array<Product>) => {
				this.products = resp.map(p => new Product(p));
			})
			this.dataService.getBrand(this.brandId).subscribe((resp:any) => this.brand = resp);
		} else {
			this.dataService.getBrands().subscribe((resp: Array<Brand>) => {
				this.brands = resp.map(b => new Brand(b));
			})
		}
	});
}

}
