import { ProUser } from "src/app/models/pro-user";
import { EzPayStatus } from "src/app/modules/controls/enums/ezpayStatus.enum";



export interface IUserState {
	username: string,
	password: string;
	token: string;
	user: ProUser;
	ezPayStatus: EzPayStatus;
}

export const initialUserState: IUserState = {
	username: null,
	password: null,
	token: null,
	user: null,
	ezPayStatus: 0,
}